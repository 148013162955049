import { navigate } from "@gatsbyjs/reach-router";
import { actionsTableListType } from "customHooks/useCompleoSimpleList";
import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";

const useActionsTable = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();
    const showMessage = useShowMessage();

    return (refreshListFunction: any) => {
        const result: actionsTableListType = [
            {
                icon: "edit",
                tooltip: t("buttonEditPipeline"),
                onClick: (event: any, rowData: any) => {
                    navigate(`/pipeline/edit/${rowData.skGS1pk.split(":")[1]}`);
                }
            },
            (rowData: any) => {
                let hidden = false;
                if (rowData.default === true) {
                    hidden = true;
                }

                return {
                    icon: "check",
                    tooltip: t("buttonMakeDefault"),
                    onClick: async (event: any, rowData: any) => {
                        const uuid = rowData.skGS1pk.split(":")[1];
                        const postAddress = `/pipeline/makedefault`;

                        const result = await apiDirectCall(
                            postAddress,
                            "post",
                            {
                                companyId: companyId,
                                uuid: uuid
                            }
                        );
                        if (result.status === 200) {
                            showMessage(
                                t("PipelineMakeDefault_succesMessage"),
                                "success"
                            );
                            refreshListFunction();
                            return true;
                        }
                    },
                    hidden: hidden
                };
            },
            {
                icon: "delete",
                tooltip: t("buttonDeletePipeline"),
                onClick: async (event: any, rowData: any) => {
                    const uuid = rowData.skGS1pk.split(":")[1];
                    const postAddress = `/pipeline/delete`;

                    callDialog({
                        title: t("PipelineDelete_ModalTitle"),
                        bodyText: t("PipelineDelete_Message"),
                        agreeButtonText: t("PipelineDelete_yes"),
                        disagreeButtonText: t("PipelineDelete_no"),
                        agreeFunction: async () => {
                            //const uuid = rowData.skGS1pk.split(":")[1];
                            const result = await apiDirectCall(
                                postAddress,
                                "post",
                                {
                                    companyId: companyId,
                                    uuid: uuid
                                }
                            );
                            if (result.status === 200) {
                                showMessage(
                                    t("PipelineDelete_succesMessage"),
                                    "success"
                                );
                                refreshListFunction();
                                return true;
                            }
                            return false;
                        },
                        disagreeFunction: () => {
                            return false;
                        }
                    });
                }
            }
        ];

        return result;
    };
};

export default useActionsTable;
