import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useApi, useApiCache } from "customHooks/useApi";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import useCompleoForm from "customHooks/useCompleoReactHookForm";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useValuesFromSource from "customHooks/useValuesFromSource";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import CustomComponents from "./components/CustomComponents";
import { CompanyAICreditStatus } from "./CompanyAICreditStatus";
import { useCompanyAIStatus } from "./CompanyAICreditStatus/useCompanyAIStatus";
import ModalConfirmChangeAISettings from "./components/ModalConfirmChangeSettings";

const AiSettings = (props: any) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const moduleName = "AISETTINGSMAINPAGE";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const {
        dataReturnGetAiModels,
        dataCompanyAiSettings,
        ready: readyQuery
    } = useCompanyAIStatus();
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        dataCompanyAiSettings,
        ["data", "data"]
    );

    const postAddress = `/ai/saveaicompanysettings`;
    const [addDataReturn, addData] = useApi(postAddress, "post");

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "mainSettings",
            props: {
                compleoFeaturesAI:
                    dataReturnGetAiModels.response?.data?.compleoFeaturesAI ||
                    []
            }
        }
    ];

    const [formCompleo, finished, handleSubmit, methods] = useCompleoForm({
        t,
        ready: readyQuery && readyTranslation,
        i18nV: i18n,
        postMethod: addData,
        postReturn: addDataReturn,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        CustomComponents: CustomComponents,
        valuesFromSource,
        additionalFieldProperties: addProps,
        redirectAddress: "/ai"
    });
    const { watch } = methods;

    const ready = finished && readyTranslation && readyQuery;
    if (ready) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"}>
                        <CompanyAICreditStatus />
                    </Box>
                    <Box display={"flex"}>{formCompleo}</Box>
                    <Box display={"flex"} mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModalOpen(true)}
                        >
                            {t("COMPLEOGENERAL_SAVE")}
                        </Button>
                    </Box>
                    {modalOpen && (
                        <Box>
                            <ModalConfirmChangeAISettings
                                handleSubmit={handleSubmit}
                                onClose={() => setModalOpen(false)}
                                open={modalOpen}
                                t={t}
                                watch={watch}
                                initialValues={valuesFromSource}
                            />
                        </Box>
                    )}
                </Box>
            </Page>
        );
    } else {
        return (
            <Page>
                <Loading />
            </Page>
        );
    }
};

export default AiSettings;
