import React from "react";

import { TFunction } from "i18next";
import "jspdf-autotable";
import { apiDirectCall } from "customHooks/useApi/api";
import { AxiosResponse } from "axios";
import {
    upperString,
    maskPhone,
    formatBoolean,
    maskCNPJ,
    maskCPF,
    maskCNPJCPF,
    revertFormatBoolean,
    complexObjectWithLanguage,
    complexObjectArray,
    formatDateTime,
    renderBulkActionStatus,
    renderExportFileName,
    renderCareersDomainStatus,
    renderEmailType,
    renderTestType,
    renderAICreditType,
    renderAIFeature,
    renderUser
} from "./formatColumnsFunctions";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import _ from "lodash";
import { transformDBData } from "functions/util";
import JobDateLimitFlagColumnRender from "Pages/Dashboard/JobsDateLimit/JobDateLimitFlagColumnRender";
import { Link } from "@gatsbyjs/reach-router";
import { compleoSimpleListFuncionParamsType } from ".";
import { Button, Icon } from "@material-ui/core";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ButtonViewApplicant from "./ButtonViewApplicant";
const { CsvBuilder } = require("filefy");
const jsPDF = require("jspdf");

// Tipos de conversões realizadas quando não é server side
const rendersToTransform = ["formatBoolean"];
export const maxLineServerExportFallBack: number = 10000;

export const getServerOptions = (ListDefinition: any) => {
    let enabledServerSide = (
        (
            (ListDefinition.otherOptions || []).filter(
                (i: any) => i.id === "serverSide"
            )[0] || {}
        ).definition || {}
    ).enabled;
    enabledServerSide =
        enabledServerSide === undefined ? true : enabledServerSide;

    const rowsToChangeServerSide =
        (
            (
                (ListDefinition.otherOptions || []).filter(
                    (i: any) => i.id === "serverSide"
                )[0] || {}
            ).definition || {}
        ).afterRowsToChangeServerSide || 10000;
    return { enabledServerSide, rowsToChangeServerSide };
};

const renderColumn = (
    value: any,
    typeRender: string,
    t: TFunction,
    processAll: boolean = false,
    language: string = "en-US",
    rowData: any,
    functionParams?: compleoSimpleListFuncionParamsType
) => {
    if (!processAll && rendersToTransform.includes(typeRender)) {
        return value;
    }
    const splitRenderAndParams = typeRender.split(":");
    const onlyTypeRender = splitRenderAndParams[0];
    const renderParam = splitRenderAndParams[1] || "";

    switch (onlyTypeRender) {
        case "formatBoolean":
            return formatBoolean(value, t);
        case "upperString":
            return upperString(value, t);
        case "maskPhone":
            return maskPhone(value, t);
        case "maskCPF":
            return maskCPF(value, t);
        case "maskCNPJ":
            return maskCNPJ(value, t);
        case "maskCNPJCPF":
            return maskCNPJCPF(value, t);
        case "complexObject":
            if (value?.[renderParam]) {
                return value[renderParam];
            } else {
                return "";
            }
        case "complexObjectWithLanguage":
            if (value?.[`${renderParam || "label"}-${language}`]) {
                return complexObjectWithLanguage(value, renderParam, language);
            } else {
                return "";
            }
        case "stringArray":
            if (value) {
                return value.join(" - ");
            } else {
                return "";
            }
        case "objectArray":
            if (value) {
                return complexObjectArray(value, renderParam);
            } else {
                return "";
            }
        case "dateTimeUTC":
        case "dateTime": {
            if (value) {
                return formatDateTime(value, t);
            } else {
                return value;
            }
        }
        case "dateUTC":
        case "date": {
            if (value) {
                return transformDBData({
                    value: value,
                    t: t,
                    language: language
                });
            } else {
                return value;
            }
        }
        case "renderBulkActionStatus": {
            return renderBulkActionStatus(value, t);
        }
        case "renderCareersDomainStatus": {
            return renderCareersDomainStatus(value, t);
        }
        case "renderEmailType": {
            return renderEmailType(value, t);
        }
        case "stars": {
            return (
                <CompleoCompanyRating
                    evaluationValue={value || 0}
                    showTextValue
                />
            );
        }
        case "customJobStatusFlag": {
            return <JobDateLimitFlagColumnRender value={value} />;
        }
        case "jobLink": {
            const fieldId = rowData[renderParam];
            let jobId = "";
            if (fieldId) {
                if (fieldId.includes(":")) {
                    jobId = fieldId.split(":")[1];
                } else {
                    jobId = fieldId;
                }
            }
            if (jobId) {
                return <Link to={`/job/view/${jobId}`}>{value}</Link>;
            } else {
                return value;
            }
        }
        case "viewApplicant": {
            return <ButtonViewApplicant t={t} applicantId={value} />;
        }
        case "evaluateApplicant": {
            const fnToCall = functionParams?.evaluateApplicant;
            const viewFnToCall = functionParams?.viewApplicant;

            if (fnToCall && viewFnToCall) {
                return (
                    <div
                        style={{
                            whiteSpace: "nowrap"
                        }}
                    >
                        <Button
                            onClick={async () => await viewFnToCall(rowData)}
                            startIcon={<Icon>visibility</Icon>}
                            variant="outlined"
                            color="secondary"
                            size="small"
                        >
                            {t("view")}
                        </Button>
                        <Button
                            onClick={async () => await fnToCall(rowData)}
                            startIcon={<Icon>star</Icon>}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            style={{
                                marginLeft: 8
                            }}
                        >
                            {t("evaluate")}
                        </Button>
                    </div>
                );
            } else {
                return value;
            }
        }
        case "exportReportName":
            return renderExportFileName(value, t);
        case "renderTestType": {
            return renderTestType(value, t);
        }
        case "renderAIFeature": {
            return renderAIFeature(value, t);
        }
        case "renderAICreditType": {
            return renderAICreditType(value, t);
        }
        case "renderUser": {
            return renderUser(value, t);
        }
        default:
            return value;
    }
};

export const generateColumns = (
    fieldsTable: Compleo.IObject[],
    tFN: TFunction,
    serverSide: boolean = false,
    language: string = "en-US",
    functionParams?: compleoSimpleListFuncionParamsType
) => {
    const fieldTablesSorted = fieldsTable.sort(
        (a: any, b: any) => a.order - b.order
    );
    const columns = fieldTablesSorted.map((f: any) => {
        let filtering = f.filtering === undefined ? false : f.filtering;
        const renderFunction = f.renderFunction;

        if (renderFunction === undefined) {
            const customSort = (aParam: any, bParam: any) => {
                const processFieldToSort = (param: any) => {
                    let returnData: any = param;
                    switch (typeof param) {
                        case "string":
                            returnData = param.toLowerCase();
                            break;
                        case "undefined":
                            returnData = "";
                            break;
                        default:
                            break;
                    }
                    return returnData;
                };

                let a = processFieldToSort(_.get(aParam, f.fieldName));
                let b = processFieldToSort(_.get(bParam, f.fieldName));

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                }
                return 0;
            };
            const customFilterAndSearch = (term: any, rowData: any) => {
                const value = _.get(rowData, f.fieldName);
                if (!term || !value) {
                    return false;
                }
                const lowerCaseValue = value.toString().toLowerCase();
                const lowerCaseSearchValue = term.toString().toLowerCase();
                return lowerCaseValue.includes(lowerCaseSearchValue);
            };

            const objReturn: any = {
                title: tFN(f.fieldName),
                field: f.fieldName,
                filtering,
                customSort
            };
            if (f.defaultSort) {
                objReturn.defaultSort = f.defaultSort;
            }
            objReturn.customFilterAndSearch = customFilterAndSearch;
            return objReturn;
        } else {
            const customFilterAndSearch = (term: any, rowData: any) => {
                let value = renderColumn(
                    _.get(rowData, f.fieldName),
                    renderFunction,
                    tFN,
                    serverSide,
                    language,
                    rowData,
                    functionParams
                );
                if (typeof value === "object" && value?.props?.children) {
                    value = value.props.children;
                }
                if (!term || !value) {
                    return false;
                }
                const lowerCaseValue = value.toString().toLowerCase();
                const lowerCaseSearchValue = term.toString().toLowerCase();
                return lowerCaseValue.includes(lowerCaseSearchValue);
            };

            const customSort = (aParam: any, bParam: any) => {
                const processFieldToSort = (param: any) => {
                    let returnData: any = param;
                    switch (typeof param) {
                        case "string":
                            returnData = param.toLowerCase();
                            break;
                        case "undefined":
                            returnData = "";
                            break;
                        default:
                            break;
                    }
                    return returnData;
                };
                const functionsToIgnore: string[] = ["date", "dateTime"];
                const aValue = functionsToIgnore.includes(renderFunction)
                    ? _.get(aParam, f.fieldName)
                    : renderColumn(
                          _.get(aParam, f.fieldName),
                          renderFunction,
                          tFN,
                          serverSide,
                          language,
                          aParam,
                          functionParams
                      );
                const bValue = functionsToIgnore.includes(renderFunction)
                    ? _.get(bParam, f.fieldName)
                    : renderColumn(
                          _.get(bParam, f.fieldName),
                          renderFunction,
                          tFN,
                          serverSide,
                          language,
                          aParam,
                          functionParams
                      );

                let a = processFieldToSort(aValue);
                let b = processFieldToSort(bValue);

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                }
                return 0;
            };
            // se exige transformação no client, por enquanto desabilitar no server este tipo
            // de filtro
            if (rendersToTransform.includes(renderFunction) && serverSide) {
                filtering = false;
            }
            const objReturn: any = {
                title: tFN(f.fieldName),
                field: f.fieldName,
                filtering,
                customSort,
                render: (rowData: any) =>
                    renderColumn(
                        _.get(rowData, f.fieldName),
                        renderFunction,
                        tFN,
                        serverSide,
                        language,
                        rowData,
                        functionParams
                    )
            };
            if (f.defaultSort) {
                objReturn.defaultSort = f.defaultSort;
            }
            objReturn.customFilterAndSearch = customFilterAndSearch;
            return objReturn;
        }
    });
    return columns;
};

export const generateMaterialTableOptions = (materialTableOptions: any) => {
    const materialTableOptionsObj: Compleo.IObject = {};
    (materialTableOptions || []).map((m: any) => {
        materialTableOptionsObj[m.id] = m.value;
    });
    return materialTableOptionsObj;
};

export const generateLocalizatinObject = (t: TFunction) => {
    return {
        body: {
            emptyDataSourceMessage: t("body.emptyDataSourceMessage"),
            addTooltip: t("body.addTooltip"),
            deleteTooltip: t("body.deleteTooltip"),
            editTooltip: t("body.editTooltip"),
            filterRow: {
                filterTooltip: t("body.filterRow.filterTooltip")
            },
            editRow: {
                deleteText: t("body.editRow.deleteText"),
                cancelTooltip: t("body.editRow.cancelTooltip"),
                saveTooltip: t("body.editRow.saveTooltip")
            }
        },
        grouping: {
            placeholder: t("grouping.placeholder"),
            groupedBy: t("grouping.groupedBy")
        },
        header: {
            actions: t("header.actions")
        },
        pagination: {
            labelDisplayedRows: t("pagination.labelDisplayedRows"),
            labelRowsSelect: t("pagination.labelRowsSelect"),
            labelRowsPerPage: t("pagination.labelRowsPerPage"),
            firstAriaLabel: t("pagination.firstAriaLabel"),
            firstTooltip: t("pagination.firstTooltip"),
            previousAriaLabel: t("pagination.previousAriaLabel"),
            previousTooltip: t("pagination.previousTooltip"),
            nextAriaLabel: t("pagination.nextAriaLabel"),
            nextTooltip: t("pagination.nextTooltip"),
            lastAriaLabel: t("pagination.lastAriaLabel"),
            lastTooltip: t("pagination.lastTooltip")
        },
        toolbar: {
            addRemoveColumns: t("toolbar.addRemoveColumns"),
            nRowsSelected: t("toolbar.nRowsSelected"),
            showColumnsTitle: t("toolbar.showColumnsTitle"),
            showColumnsAriaLabel: t("toolbar.showColumnsAriaLabel"),
            exportTitle: t("toolbar.exportTitle"),
            exportAriaLabel: t("toolbar.exportAriaLabel"),
            exportName: t("toolbar.exportName"),
            exportCSVName: t("toolbar.exportCSVName"),
            exportPDFName: t("toolbar.exportPDFName"),
            searchTooltip: t("toolbar.searchTooltip"),
            searchPlaceholder: t("toolbar.searchPlaceholder")
        }
    };
};

export const handleColumnDragged = (
    sourceIndex: any,
    destinationIndex: any
) => {
    // console.log("sourceIndex", sourceIndex);
    // console.log("destinationIndex", destinationIndex);
    // const sourceColumn = savedColumns[sourceIndex];
    // const destinationColumn = savedColumns[destinationIndex];
    // // Swapping the column order
    // savedColumns[sourceIndex] = destinationColumn;
    // savedColumns[destinationIndex] = sourceColumn;
    // localStorage.setItem('table1', JSON.stringify(savedColumns));
};

export const convertData = (
    columns: any,
    data: any,
    title: string,
    items: any,
    endpoint: string,
    method: "post" | "get",
    params: any,
    callDialog: any,
    tMaterial: TFunction,
    ListDefinition: any,
    language: string,
    maxLineServerExport: number = maxLineServerExportFallBack,
    typeConvert: "csv" | "pdf" = "csv"
) => {
    if (items.serverSide && items.total <= maxLineServerExport) {
        apiDirectCall(endpoint, method, {
            ...params,
            sizeProcessLocal: items.total + 1
        }).then((result: AxiosResponse<any>) => {
            const dataDB = result.data.data;
            if (typeConvert === "csv") {
                toCSV(
                    title,
                    columns,
                    dataDB,
                    ListDefinition,
                    tMaterial,
                    language
                );
            } else {
                toPDF(title, columns, dataDB);
            }
        });
    }
    if (items.serverSide && items.total > maxLineServerExport) {
        callDialog({
            title: tMaterial("dialogExportErrorTitle"),
            bodyText: tMaterial("dialogExportErrorMessage"),
            agreeButtonText: tMaterial("dialogExportCloseButton"),
            agreeFunction: async () => {},
            disagreeFunction: () => {}
        });
        // alert("não há suporte para csv nesta quantidade");
    }
    if (!items.serverSide) {
        if (typeConvert === "csv") {
            toCSV(title, columns, data, ListDefinition, tMaterial, language);
        } else {
            toPDF(title, columns, data);
        }
    }
};

const toCSV = (
    title: string,
    columns: any,
    data: any,
    ListDefinition: any,
    t: TFunction,
    language: string = "en-US"
) => {
    const newDataTransformed = transformAllLocalData(
        data,
        ListDefinition,
        t,
        language
    );
    debugger;

    const newColumns = columns.map((columnDef: any) => columnDef.title);
    const columnsName = columns.map((columnDef: any) => columnDef.field);
    const newData = newDataTransformed.map((d: any) => {
        const arrReturn: any[] = [];
        columnsName.map((c: any) => {
            arrReturn.push(_.get(d, c));
        });
        return arrReturn;
    });

    new CsvBuilder(`${title}.csv`)
        .setDelimeter(",")
        .setColumns(newColumns)
        .addRows(newData)
        .exportFile();
};

const toPDF = (title: string, columns: any, data: any) => {
    // const [columns, data] = this.getTableData();
    const newColumns = columns.map((columnDef: any) => columnDef.title);
    const columnsName = columns.map((columnDef: any) => columnDef.field);
    const newData = data.map((d: any) => {
        const arrReturn: any[] = [];
        columnsName.map((c: any) => {
            arrReturn.push(d[c]);
        });
        return arrReturn;
    });

    let content = {
        startY: 50,
        head: [newColumns],
        body: newData
    };

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save((title || "data") + ".pdf");
};

export const transformLocalData = (
    data: any,
    ListDefinition: any,
    t: TFunction,
    language: string = "en-US",
    functionParams?: compleoSimpleListFuncionParamsType
) => {
    const columnsToTransform = (ListDefinition.fieldsTable || []).filter(
        (c: any) => {
            const renderFunction = (c.renderFunction || "").split(":")[0];
            return rendersToTransform.includes(renderFunction);
        }
    );
    data = data.map((d: any) => {
        const newObjConverted: Compleo.IObject = {};
        for (const c of columnsToTransform) {
            _.set(
                newObjConverted,
                c.fieldName,
                renderColumn(
                    _.get(d, c.fieldName),
                    c.renderFunction,
                    t,
                    true,
                    language,
                    d,
                    functionParams
                )
            );
        }
        return { ...d, ...newObjConverted };
    });
    return data;
};

export const transformAllLocalData = (
    data: any,
    ListDefinition: any,
    t: TFunction,
    language: string = "en-US",
    functionParams?: compleoSimpleListFuncionParamsType
) => {
    const rendersToTransformAllData = [
        "formatBoolean",
        "complexObject",
        "complexObjectWithLanguage",
        "dateTime",
        "date",
        "renderBulkActionStatus"
    ];

    const columnsToTransform = (ListDefinition.fieldsTable || []).filter(
        (c: any) => {
            const renderFunction = (c.renderFunction || "").split(":")[0];
            return rendersToTransformAllData.includes(renderFunction);
        }
    );
    data = data.map((d: any) => {
        const newObjConverted: Compleo.IObject = {};
        for (const c of columnsToTransform) {
            _.set(
                newObjConverted,
                c.fieldName,
                renderColumn(
                    _.get(d, c.fieldName),
                    c.renderFunction,
                    t,
                    true,
                    language,
                    d,
                    functionParams
                )
            );
        }
        return { ...d, ...newObjConverted };
    });
    return data;
};
