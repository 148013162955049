import { useAuthState } from "_ReactContext/AuthContext";
import useApplicantViewContext from "../useApplicantViewContext";
import { useApiCache } from "customHooks/useApi";

const useApplicantAssessmentsHelper = (jobId?: string) => {
    const [data] = useApplicantViewContext();
    const { company } = useAuthState();

    const applicantData = data.applicantData || {};
    const jobs = applicantData.Jobs || [];
    const jobIds: string[] = jobId
        ? [jobId]
        : jobs.map((j: Compleo.IObject) => j.JobId);

    const isEvaluation =
        data.externalData?.evaluationId !== undefined ||
        data.evaluationId !== undefined;
    const [listResult] = useApiCache(
        isEvaluation
            ? "/test/applicantassessmentsexternal"
            : "/test/applicantassessments",
        "post",
        {
            companyId: data.externalData
                ? data.externalData?.companyId
                : company.companyId,
            applicantId: data.applicantId,
            jobIds: jobIds,
            evaluationId: data.externalData?.evaluationId || data.evaluationId
        },
        false,
        60 * 1000 * 10
    );
    const assessmentList:
        | CompleoShared.Assessments.IReturnGetAssessmentByApplicant[]
        | null = listResult.response?.data?.data || [];

    const filteredList = (assessmentList || []).filter((assessment) =>
        jobIds.includes(assessment.assessment.jobId)
    );

    return {
        assessmentList: filteredList,
        ready: listResult.status === "success"
    };
};

export default useApplicantAssessmentsHelper;
