import React from "react";
import { UseFormWatch, FieldValues } from "react-hook-form";

interface IHandleValuesFromSource {
    valuesFromSource: CompleoShared.IObject;
    aiFeature: CompleoShared.AI.compleoFeaturesAI;
    watch: UseFormWatch<FieldValues>;
}
export const useHandleValuesFromSource = (params: IHandleValuesFromSource) => {
    const { watch, aiFeature, valuesFromSource } = params;
    const [valuesFromSourceReturn, setValuesFromSourceReturn] = React.useState<
        Compleo.IObject
    >(valuesFromSource);
    const title = watch("title");
    console.log("title", title);

    React.useEffect(() => {
        switch (aiFeature) {
            case "jobDescriptionGeneration": {
                setValuesFromSourceReturn({
                    ...valuesFromSource,
                    title: title
                });
                break;
            }
            default:
                break;
        }
    }, [title]);
    return valuesFromSourceReturn;
};
