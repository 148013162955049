export const fieldsRules = {
    fieldsToRemove: [
        "applicantSchedule",
        "applicantEmail",
        "applicantHistoryData",
        "applicantRating",
        // "applicantAssessments",
        "applicantJobMatch",
        "applicantQuestionnaires"
    ],
    formsToRemove: [
        "applicantHistoryData",
        "applicantEmailData",
        "applicantScheduleData",
        "applicantRating",
        // "applicantAssessments",
        "applicantJobMatch",
        "applicantQuestionnaires"
    ],
    blockedFields: ["applicantAssessments", "cv", "documents"]
};
