import { Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { transformDBData } from "functions/util";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" }
    })
);

const ApplicantHistoryChangeStageAssessmentResult = (
    props: IApplicantHistoryProps
) => {
    const { historyItem, tLocal, language } = props;

    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("NewStage")}
                transformDBDataParam={{
                    value: historyItem.stage.name,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("OldStage")}
                transformDBDataParam={{
                    value: historyItem.oldStage.name,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("assessmentName")}
                transformDBDataParam={{
                    value: historyItem.operationInfo?.assessmentName,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("assessmentScore")}
                transformDBDataParam={{
                    value: historyItem.operationInfo?.assessmentResult,
                    language: language,
                    t: tLocal,
                    numberDecimalScale: 2
                }}
                textAppendAfter="%"
            />
        </>
    );
};

export default ApplicantHistoryChangeStageAssessmentResult;
