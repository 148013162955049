import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useCompleoForm from "customHooks/useCompleoReactHookForm";
import { useApi } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useFormContext } from "react-hook-form";
import { useHandleValuesFromSource } from "./utils";
import { Box, Divider, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

interface IModalAIFormHelperProps {
    open: boolean;
    handleClose: () => void;
    aiFeature: CompleoShared.AI.compleoFeaturesAI;
}
export default function ModalAIFormHelper(props: IModalAIFormHelperProps) {
    const { open, handleClose, aiFeature } = props;
    const module = getModuleFromAIFeature(aiFeature);
    const [metadata] = useGetMetadata(module);
    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const { setValue, watch } = useFormContext();
    const postAddress = `/ai/runaifeatures`;
    const [addDataReturn, addData] = useApi(postAddress, "post");
    const [valuesFromSource] = useValuesFromSource(metadata, true, {
        jobId: 0
    });

    const valuesFromSourceUpdated = useHandleValuesFromSource({
        watch,
        valuesFromSource,
        aiFeature
    });

    const [formCompleo, finished, handleSubmit, methods] = useCompleoForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addData,
        postReturn: addDataReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSourceUpdated
        // additionalFieldProperties: addProps
    });
    const handleSave = async () => {
        await handleSubmit();
    };
    const result = (addDataReturn?.response?.data?.jobDescription || "")
        .split("\n")
        .join("<br>");
    const showResult = addDataReturn.status === "success" && result;
    const running = addDataReturn.status === "fetching";

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("modalTitle")}</DialogTitle>
            <DialogContent>
                {formCompleo}
                {showResult && (
                    <Box p={2} borderColor={"#ccc"} border={1}>
                        <Typography variant="h6" gutterBottom>
                            Descrição gerada por IA:
                        </Typography>
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: result
                            }}
                        ></div>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                    disabled={running}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => handleSave()}
                    color="secondary"
                    type="button"
                    variant="contained"
                    disabled={running}
                >
                    Gerar nova Descrição
                </Button>
                {showResult && (
                    <Button
                        onClick={() => {
                            setValue("description", result);
                            handleClose();
                        }}
                        color="primary"
                        autoFocus
                        variant="contained"
                        type="button"
                    >
                        Usar este resultado
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

const getModuleFromAIFeature = (
    aiFeature: CompleoShared.AI.compleoFeaturesAI
) => {
    const allowedFeatures: CompleoShared.AI.compleoFeaturesAI[] = [
        "jobDescriptionGeneration"
    ];
    if (!allowedFeatures.includes(aiFeature)) {
        throw new Error(
            `The feature ${aiFeature} is not allowed in this context`
        );
    }
    return `AIACTIONS_${aiFeature.toUpperCase()}`;
};
