import { Link } from "@gatsbyjs/reach-router";
import { Box, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useTranslation } from "react-i18next";
import { useCompanyAIStatus } from "./useCompanyAIStatus";
import { transformDBData } from "functions/util";

export function CompanyAICreditStatus() {
    const [t, i18n, readyTranslation] = useTranslation(
        "AISETTINGSCOMPANYSTATUS",
        {
            useSuspense: false
        }
    );
    const {
        monthlyCredits,
        compleoAIFeatures,
        packages,
        ready
    } = useCompanyAIStatus();

    if (!readyTranslation || !ready) return null;

    return (
        <Alert
            severity="info"
            style={{
                width: "100%"
            }}
        >
            <AlertTitle>
                <strong>{t("title")}</strong>
            </AlertTitle>
            <Typography variant="body2" style={{ fontWeight: 700 }}>
                {t("monthlyCredits")}
            </Typography>
            <Typography variant="body2">
                {t("messageDayRenewal", {
                    dayRenew: monthlyCredits?.paymentCycleDay
                })}
            </Typography>
            <Typography variant="body2">
                <CreditDetails
                    compleoAIFeatures={compleoAIFeatures}
                    creditDetails={monthlyCredits?.creditDetails}
                    t={t}
                />
            </Typography>
            <Typography
                variant="body2"
                style={{ fontWeight: 700, marginBottom: 16 }}
            >
                {t("packages")}
            </Typography>

            {packages.length === 0 && (
                <Box>
                    <Typography variant="body2" color="secondary">
                        {t("messageNoPackages")}
                    </Typography>
                </Box>
            )}
            {packages?.map((packageItem: Compleo.IObject) => {
                return (
                    <Box key={packageItem.uuid}>
                        <Typography variant="body2">
                            <Box>
                                <strong>{t("validUntil")}: </strong>
                                {transformDBData({
                                    value: packageItem?.validUntil,
                                    t: t,
                                    language: i18n.language
                                })}
                            </Box>
                            <CreditDetails
                                compleoAIFeatures={compleoAIFeatures}
                                creditDetails={packageItem?.creditDetails}
                                t={t}
                            />
                        </Typography>
                    </Box>
                );
            })}
        </Alert>
    );
}

interface ICreditDetailsProps {
    creditDetails: CompleoShared.AI.AICreditDetails;
    compleoAIFeatures: CompleoShared.AI.compleoFeaturesAI[];
    t: any;
}
const CreditDetails = (props: ICreditDetailsProps) => {
    const { creditDetails, compleoAIFeatures, t } = props;
    const credits = creditDetails?.credits || 0;
    const usedCredits = creditDetails?.creditsUsed || 0;
    const availableCredits = credits - usedCredits;

    return (
        <ul>
            <li>
                <div
                    dangerouslySetInnerHTML={{
                        __html: t("modelCreditsDetail", {
                            credits: credits,
                            available: availableCredits,
                            used: usedCredits
                        })
                    }}
                ></div>
            </li>
        </ul>
    );
};
