import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import { useTranslation } from "react-i18next";
import { useHandlePossibleResults } from "./useHandlePossibleResults";

interface IUseQuestionsFieldsData {
    questionsFieldName: string;
    sectionsFieldName: string;
    testTypeFieldName: string;
    possibleResultsFieldName: string;
    t: any;
}

const useQuestionsFieldsData = (props: IUseQuestionsFieldsData) => {
    const {
        questionsFieldName,
        sectionsFieldName,
        t,
        testTypeFieldName,
        possibleResultsFieldName
    } = props;
    const {
        watch,
        setFieldValue: setFieldValueQuestions,
        error: errorQuestion,
        message: messageQuestion
    } = useRhfFieldWithoutControllerOrRegister(questionsFieldName);
    const {
        setFieldValue: setFieldValueSections,
        error: errorSection,
        message: messageSection
    } = useRhfFieldWithoutControllerOrRegister(sectionsFieldName);

    const [tSection, i18n, readyTranslation] = useTranslation(
        ["TESTSECTIONMAIN", "TESTQUESTIONMAIN"],
        {
            useSuspense: false
        }
    );
    useHandlePossibleResults({
        questions: watch(questionsFieldName),
        possibleResults: watch(possibleResultsFieldName),
        setFieldValueQuestions
    });

    const returnData: CompleoShared.Assessments.IQuestionsReturnData = {
        questionsData: watch(questionsFieldName),
        sectionsData: watch(sectionsFieldName),
        setFieldValueQuestions,
        setFieldValueSections,
        t: t,
        tSection: tSection,
        ready: readyTranslation,
        testType: watch(testTypeFieldName),
        possibleResults: watch(possibleResultsFieldName),
        errorSection,
        messageSection,
        errorQuestion,
        messageQuestion
    };
    return returnData;
};

export default useQuestionsFieldsData;
