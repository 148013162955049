import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import { ListItemIcon, PopoverOrigin } from "@material-ui/core";
import { navigate } from "@gatsbyjs/reach-router";
import { useTranslation } from "react-i18next";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    options: any[];
    showIcon?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

const MenuModal = (props: IProps) => {
    const {
        anchorEl,
        setAnchorEl,
        options,
        showIcon = true,
        anchorOrigin = {
            vertical: "top",
            horizontal: "right"
        },
        transformOrigin = {
            vertical: "top",
            horizontal: "left"
        }
    } = props;
    const [t, i18n, readyTranslation] = useTranslation("NAVIGATION", {
        useSuspense: false
    });

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (route: string) => {
        navigate(route);
        handleClose();
    };
    const listMenuOptionsTranslated = options.map((option: any) => {
        return {
            ...option,
            label: t(option.uniqueName)
        };
    });
    listMenuOptionsTranslated.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
    );

    if (readyTranslation) {
        return (
            <div>
                <Menu
                    id="card-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {listMenuOptionsTranslated.map((option: any) => {
                        return (
                            <MenuItem
                                key={option.uniqueName}
                                onClick={() => clickMenuItem(option.route)}
                            >
                                {showIcon && (
                                    <ListItemIcon>
                                        <Icon>{option.icon}</Icon>
                                    </ListItemIcon>
                                )}
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    } else {
        return null;
    }
};

export default MenuModal;
