import React, { useContext, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { DraggableProvided } from "react-beautiful-dnd";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
import { ButtonAddEditQuestion, ButtonDeleteQuestion } from "./QuestionsHelper";
import Answers from "../03_Answers/Answers";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { createMarkup } from "functions/util";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // "& > *": {
            //     margin: theme.spacing(0.5)
            // }
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600
        },
        grow: {
            flexGrow: 1
        },
        chip: {
            margin: theme.spacing(0)
        },
        accordionsummary: {
            alignItems: "center",
            maxWidth: "100%"
        },
        htmlContainer: {
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "auto",
            "& img": {
                // Applies to all img elements within the Box
                maxWidth: "100%",
                maxHeight: "100%"
            }
        }
    })
);
interface IQuestionProps {
    question: CompleoShared.Assessments.IQuestion;
    provided: DraggableProvided;
    setModalAddEditQuestionData: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            values?: CompleoShared.Assessments.IQuestion | undefined;
        }>
    >;
    index: number;
}

const Question = (props: IQuestionProps) => {
    const classes = useStyles();
    const { question, provided, setModalAddEditQuestionData, index } = props;

    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const questionsData: CompleoShared.Assessments.IQuestion[] =
        ContextQuestionFieldsData?.questionsData || [];
    const { setFieldValueQuestions } = ContextQuestionFieldsData || {};

    /**
     * Expand or collapse section
     */
    const handleExpanded = () => {
        const newQuestionsData = questionsData.map((item) => {
            if (item.id === question.id) {
                return {
                    ...item,
                    expanded: !item.expanded
                };
            }
            return item;
        });
        if (setFieldValueQuestions) {
            setFieldValueQuestions(newQuestionsData);
        }
    };

    return (
        <>
            <Accordion
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                expanded={question.expanded}
                onChange={handleExpanded}
                className={classes.root}
                elevation={0}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{ content: classes.accordionsummary }}
                >
                    <Typography variant="caption" style={{ marginRight: 16 }}>
                        {index + 1}
                    </Typography>
                    <DragHandleIcon style={{ marginRight: 16 }} />
                    <Box className={classes.htmlContainer} mr={2}>
                        <Box
                            dangerouslySetInnerHTML={createMarkup(
                                question.name
                            )}
                        />
                    </Box>
                    <div className={classes.grow} />
                    <Chip
                        className={classes.chip}
                        //@ts-ignore
                        label={question.questiontype?.["label"]}
                    />
                    <ButtonAddEditQuestion
                        type="edit"
                        values={question}
                        setModalAddEditQuestionData={
                            setModalAddEditQuestionData
                        }
                    />
                    <ButtonDeleteQuestion values={question} />
                </AccordionSummary>
                {["singleChoice", "multipleChoice"].includes(
                    question?.questiontype?.value
                ) && (
                    <AccordionDetails>
                        <Answers questionData={question} />
                    </AccordionDetails>
                )}
            </Accordion>
            <Divider light={true} />
        </>
    );
};

export default Question;
