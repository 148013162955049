import useMenuFormGroup from "customHooks/useCompleoForm/components/useMenuFormGroup";
import React from "react";

const useAdditionalProperties = (
    translations: any,
    messages: Compleo.IObject[],
    languages: any,
    defaultLanguage: any,
    readyInitialValues: boolean,
    t: any,
    jobId: string,
    applicantsByJobStage?: Compleo.IObject
) => {
    const [additionalProperties, setAdditionalProperties] = React.useState<
        Compleo.useCompleoForm.AdditionalPropertiesField[]
    >([]);
    const [readyStep1, setReadyStep1] = React.useState(false);
    // const [readyStep2, setReadyStep2] = React.useState(false);
    const [
        dialogChooseApplicationFormTemplateOpen,
        setDialogChooseApplicationFormTemplateOpen
    ] = React.useState(false);
    const [
        dialogChoosePipelineTemplateOpen,
        setDialogChoosePipelineTemplateOpen
    ] = React.useState(false);
    const [
        dialogChooseScorecardTemplateOpen,
        setDialogChooseScorecardTemplateOpen
    ] = React.useState(false);
    const [
        dialogChooseQuestionnaireTemplateOpen,
        setDialogChooseQuestionnaireTemplateOpen
    ] = React.useState(false);

    React.useEffect(() => {
        const newAdditionalProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [];
        if (
            translations &&
            messages &&
            languages &&
            defaultLanguage &&
            readyInitialValues &&
            !readyStep1
        ) {
            newAdditionalProps.push({
                fieldName: "applicationFormtranslations",
                props: { messages, translations, languages }
            });

            newAdditionalProps.push({
                fieldName: "translatedData",
                props: { defaultLanguage, languages }
            });

            newAdditionalProps.push({
                fieldName: "questionnairetranslation",
                props: { defaultLanguage, languages }
            });

            const stageIdsWithApplicants = getStagesWithApplicants(
                applicantsByJobStage
            );
            newAdditionalProps.push({
                fieldName: "pipeline",
                props: {
                    stageIdsWithApplicants: stageIdsWithApplicants,
                    jobId: jobId
                }
            });

            setAdditionalProperties(newAdditionalProps);
            setReadyStep1(true);
        }
    }, [
        translations,
        messages,
        languages,
        defaultLanguage,
        readyInitialValues,
        readyStep1
    ]);

    React.useEffect(() => {
        if (readyStep1) {
            const removeItems = [
                "applicationformmodel",
                "pipelinemodel",
                "scorecardmodel",
                "questionnairemodel"
            ];
            const newAdditionalProps = [...additionalProperties].filter(
                (i) => !removeItems.includes(i.fieldName)
            );
            newAdditionalProps.push({
                fieldName: "applicationformmodel",
                props: {
                    dialogChooseApplicationFormTemplateOpen,
                    setDialogChooseApplicationFormTemplateOpen
                }
            });

            const stageIdsWithApplicants = getStagesWithApplicants(
                applicantsByJobStage
            );
            newAdditionalProps.push({
                fieldName: "pipelinemodel",
                props: {
                    dialogChoosePipelineTemplateOpen,
                    setDialogChoosePipelineTemplateOpen,
                    stageIdsWithApplicants: stageIdsWithApplicants
                }
            });
            newAdditionalProps.push({
                fieldName: "scorecardmodel",
                props: {
                    dialogChooseScorecardTemplateOpen,
                    setDialogChooseScorecardTemplateOpen
                }
            });
            newAdditionalProps.push({
                fieldName: "questionnairemodel",
                props: {
                    dialogChooseQuestionnaireTemplateOpen,
                    setDialogChooseQuestionnaireTemplateOpen
                }
            });

            setAdditionalProperties(newAdditionalProps);
        }
    }, [
        dialogChooseApplicationFormTemplateOpen,
        dialogChoosePipelineTemplateOpen,
        dialogChooseScorecardTemplateOpen,
        dialogChooseQuestionnaireTemplateOpen,
        readyStep1
    ]);

    const menuDef = useMenuFormGroup({
        id: "menu1",
        menuOptions: [
            {
                text: t("buttonChooseAnotherTemplate"),
                fnToRun: () => {
                    setDialogChooseApplicationFormTemplateOpen(true);
                }
            }
        ]
    });
    const menuDefPipeline = useMenuFormGroup({
        id: "menuPipeline",
        menuOptions: [
            {
                text: t("buttonChooseAnotherTemplate"),
                fnToRun: () => {
                    setDialogChoosePipelineTemplateOpen(true);
                }
            }
        ]
    });
    const menuDefScorecard = useMenuFormGroup({
        id: "menuScorecard",
        menuOptions: [
            {
                text: t("buttonChooseAnotherTemplate"),
                fnToRun: () => {
                    setDialogChooseScorecardTemplateOpen(true);
                }
            }
        ]
    });

    const menuDefQuestionnaire = useMenuFormGroup({
        id: "menuQuestionnaire",
        menuOptions: [
            {
                text: t("buttonChooseAnotherTemplate"),
                fnToRun: () => {
                    setDialogChooseQuestionnaireTemplateOpen(true);
                }
            }
        ]
    });
    const FormMenuActions = [
        { formGroupId: "applicationformmodel", menuDefinition: menuDef },
        { formGroupId: "pipelinemodel", menuDefinition: menuDefPipeline },
        { formGroupId: "scorecardmodel", menuDefinition: menuDefScorecard },
        {
            formGroupId: "questionnairemodel",
            menuDefinition: menuDefQuestionnaire
        }
    ];

    return {
        additionalProperties,
        otherProps: {
            setDialogChooseApplicationFormTemplateOpen,
            setDialogChooseScorecardTemplateOpen,
            setDialogChoosePipelineTemplateOpen,
            setDialogChooseQuestionnaireTemplateOpen
        },
        FormMenuActions: FormMenuActions,
        ready: readyStep1
    };
};

const getStagesWithApplicants = (applicantsByJobStage?: Compleo.IObject) => {
    if (!applicantsByJobStage) {
        return [];
    }
    const applicantsByStage: Compleo.IObject[] =
        applicantsByJobStage?.applicantsByStage || [];
    const resultData: string[] = applicantsByStage
        .filter((i) => i.doc_count && i.key?.pipelineStageId)
        .map((i) => i.key?.pipelineStageId);
    return resultData;
};

export default useAdditionalProperties;
