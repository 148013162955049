import React from "react";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import Chip from "@material-ui/core/Chip";

import { useStyles } from "./AssessmentsListStyle";
import ModalViewEditTest from "./ModalViewEditTest";
import { extractValue, transformDBData } from "functions/util";
import { getTestResult } from "./assessmentsTestCorrectionFunctions";
import { Alert } from "@material-ui/lab";
import BarChartIcon from "@material-ui/icons/BarChart";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ModalReportAnswerToResults from "./ModalReportAnswerToResults";

interface ITestListProps {
    tests: CompleoShared.Assessments.IAssessmentApplicant[];
    assessment: CompleoShared.Assessments.IAssessment;
    t: any;
}
const TestList = (props: ITestListProps) => {
    const { tests, assessment, t } = props;
    const classes = useStyles();
    const [modalIdOpen, setModalIdOpen] = React.useState(-1);
    const [modalReportIdOpen, setModalReportIdOpen] = React.useState(-1);

    // TODO: at this moment, we could check if the test match with the assessment test list

    return (
        <>
            <List component="div" disablePadding dense>
                {(assessment.tests || []).map((testDefinition, index) => {
                    const test = getApplicantAnsweredTest(
                        tests,
                        testDefinition
                    );
                    const showReportsAnswerToResults =
                        test?.testType === "answersToResults" &&
                        test?.status === "completed";

                    return (
                        <React.Fragment key={index}>
                            <ListItem className={classes.nested}>
                                <ListItemText
                                    primary={testDefinition.name}
                                    secondary={<TestDetail test={test} t={t} />}
                                />
                                {showReportsAnswerToResults && (
                                    <ListItemIcon>
                                        <ButtonIconTooltipCompleo
                                            label={t("reports")}
                                            onClick={() =>
                                                setModalReportIdOpen(index)
                                            }
                                        >
                                            <BarChartIcon />
                                        </ButtonIconTooltipCompleo>
                                    </ListItemIcon>
                                )}
                                {test && (
                                    <ListItemIcon>
                                        <ButtonIconTooltipCompleo
                                            label={t("viewOrCorrectTest")}
                                            onClick={() =>
                                                setModalIdOpen(index)
                                            }
                                        >
                                            <Visibility />
                                        </ButtonIconTooltipCompleo>
                                    </ListItemIcon>
                                )}
                            </ListItem>
                            <Divider className={classes.dividerNested} />
                            {modalIdOpen === index && (
                                <ModalViewEditTest
                                    test={test}
                                    handleClose={() => setModalIdOpen(-1)}
                                    open={modalIdOpen === index}
                                    t={t}
                                />
                            )}
                            {modalReportIdOpen === index && (
                                <ModalReportAnswerToResults
                                    test={test}
                                    handleClose={() => setModalReportIdOpen(-1)}
                                    open={modalReportIdOpen === index}
                                    t={t}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </List>
        </>
    );
};

interface IPropsTestDetail {
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined;
    t: any;
}

export const TestDetail = (props: IPropsTestDetail) => {
    const { test, t } = props;
    const classes = useStyles();
    const status: CompleoShared.Assessments.IAssessmentApplicantStatus = test
        ? test.status
        : "not_started";
    const correctionStatus: CompleoShared.Assessments.ICorrectionStatus =
        test?.correctionStatus || "pending";
    const testScore = transformDBData({
        value: Number(getTestResult(test)?.score || 0),
        t: t,
        language: "pt-BR",
        replaceOption: "-",
        numberDecimalScale: 2
    });
    const statusToShowLabelCorrection: CompleoShared.Assessments.IAssessmentApplicantStatus[] = [
        "completed",
        "global_completed"
    ];

    // sort descending answersToResultScore
    test?.answersToResultScore?.sort((a, b) => {
        return Number(b.score) - Number(a.score);
    });

    return (
        <Box mt={1}>
            <Chip
                label={`${t(`assessmentStatus_${status}`)}`}
                size="small"
                className={classes.chipCardTestStatus}
                style={{
                    backgroundColor: "#2196f3",
                    color: "white"
                }}
            />
            {statusToShowLabelCorrection.includes(status) && (
                <Chip
                    label={`${t(`correction`)}: ${t(
                        `assessmentCorrectionStatus_${correctionStatus}`
                    )}`}
                    size="small"
                    className={classes.chipCardTestStatus}
                    style={{
                        backgroundColor: "#004d40",
                        color: "white"
                    }}
                />
            )}
            {correctionStatus === "completed" && (
                <Chip
                    label={
                        <>
                            {t("score")}: {testScore}%
                        </>
                    }
                    size="small"
                    className={classes.chipCardTestStatus}
                    style={{
                        backgroundColor: "#0097a7",
                        color: "white"
                    }}
                />
            )}
            {test?.testType === "answersToResults" &&
                correctionStatus === "completed" && (
                    <Box>
                        <Alert
                            severity="info"
                            style={{ marginTop: 16, marginRight: 8 }}
                        >
                            {test?.answersToResultScore?.map((score, index) => {
                                const scoreToShow = transformDBData({
                                    value: Number(score.score || 0),
                                    t: t,
                                    language: "pt-BR",
                                    replaceOption: "-",
                                    numberDecimalScale: 2
                                });
                                return (
                                    <Box key={score.resultName}>
                                        {score.resultName}: {scoreToShow}%
                                    </Box>
                                );
                            })}
                        </Alert>
                    </Box>
                )}
        </Box>
    );
};

const getApplicantAnsweredTest = (
    tests: CompleoShared.Assessments.IAssessmentApplicant[],
    testDefinition: CompleoShared.Assessments.ITest
): CompleoShared.Assessments.IAssessmentApplicant | undefined => {
    const applicantTests = tests.filter((item) =>
        item.skGS1pk.includes("#QUESTIONID:GLOBAL")
    );
    const testId = extractValue(testDefinition.skGS1pk, "TEST");
    const test = applicantTests.find((item) =>
        item.skGS1pk.includes(`#TESTID:${testId}#QUESTIONID:GLOBAL`)
    );
    return test;
};

export default TestList;
