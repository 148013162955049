import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import { TFunction } from "react-i18next";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Cell,
    ResponsiveContainer
} from "recharts";
import useRandomColor from "customHooks/useRandomColor/useRandomColor";
import { transformDBData } from "functions/util";

interface IModalViewEditTestProps {
    open: boolean;
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined;
    handleClose: () => void;
    t: TFunction;
}

const ModalReportAnswerToResults = (props: IModalViewEditTestProps) => {
    const { test, handleClose, open, t } = props;
    const data = test?.answersToResultScore || [];

    const colorData = useRandomColor({
        objectKeys: data.map((item) => item.resultName),
        luminosity: "dark"
    });

    if (test && open) {
        const mainScore = transformDBData({
            value: Number(test.score || 0),
            t: t,
            language: "pt-BR",
            replaceOption: "-",
            numberDecimalScale: 2
        });
        return (
            <div>
                <Dialog
                    open={test !== null}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                    fullWidth
                    disableBackdropClick
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box
                            display={"flex"}
                            alignContent={"center"}
                            alignItems={"centers"}
                        >
                            <Box display={"flex"}>
                                <span>{test.testName}</span>
                            </Box>
                            <Box flexGrow={1} />
                            <Box display={"flex"}>
                                <strong>
                                    {test.answersToResultsNameToCalculateScore}:{" "}
                                    {mainScore}%
                                </strong>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <ResponsiveContainer
                            minHeight={300}
                            minWidth={300}
                            width="100%"
                            height="100%"
                        >
                            <BarChart
                                data={data}
                                layout="vertical"
                                margin={{
                                    top: 20,
                                    right: 150,
                                    left: 150,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                    type="category"
                                    dataKey="resultName"
                                    width={140}
                                />
                                <Tooltip />
                                <Bar dataKey="score" isAnimationActive={false}>
                                    {data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                colorData.colorsObject[
                                                    entry.resultName
                                                ]
                                            }
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                        <TableContainer
                            component={Paper}
                            style={{ marginTop: 16 }}
                        >
                            <Table aria-label="simple table">
                                <TableHead
                                    style={{ backgroundColor: "lightgrey" }}
                                >
                                    <TableRow>
                                        <TableCell>
                                            <strong>
                                                {t("possibleResults.name")}
                                            </strong>
                                        </TableCell>
                                        <TableCell align="left">
                                            <strong>
                                                {t(
                                                    "possibleResults.description"
                                                )}
                                            </strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>{t("score")}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => {
                                        const resultDetails = test?.possibleResults?.filter(
                                            (item) =>
                                                item.name === row.resultName
                                        )[0];
                                        const scoreToShow = transformDBData({
                                            value: Number(row.score || 0),
                                            t: t,
                                            language: "pt-BR",
                                            replaceOption: "-",
                                            numberDecimalScale: 2
                                        });

                                        return (
                                            <TableRow key={row.resultName}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.resultName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                resultDetails?.description ||
                                                                "-"
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {scoreToShow}%
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            variant="contained"
                        >
                            {t("COMPLEOGENERAL_CLOSE")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return null;
    }
};

export default ModalReportAnswerToResults;
