import { useAuthState } from "_ReactContext/AuthContext";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import { extractValue, uuid } from "functions/util";
import { TFunction } from "i18next";
import { DropResult } from "react-beautiful-dnd";
import { useQueryClient } from "react-query";

export const useGetAssessments = (jobId: string, applicantId?: string) => {
    const { company } = useAuthState();

    const listAddress = `/test/listassessments`;
    const postData = {
        jobId: jobId,
        companyId: company.companyId,
        applicantId: applicantId
    };
    const [query] = useApiCache(listAddress, "post", postData, false);
    const items: CompleoShared.Assessments.IAssessment[] = (
        query.response?.data?.data || []
    ).map((item: any) => ({
        ...item,
        assessmentId: extractDataFromJobSK1AssessmentGroup(
            item.skGS1pk,
            "assessmentId"
        )
    }));

    const itemsAssessments = items.filter((item) =>
        item.skGS1pk.includes("#DEFINITION")
    );
    const itemsTests = items.filter((item) => item.skGS1pk.includes("#TEST"));
    const ready = query.status === "success";
    return { itemsTests, itemsAssessments, ready };
};

export const useGetTestList = () => {
    const { company } = useAuthState();

    const listAddress = `/test/list`;
    const postData = {
        companyId: company.companyId,
        pageSize: 50000
    };
    const apiReturn = useApiCache(listAddress, "post", postData, false);
    return apiReturn;
};

export function extractDataFromJobSK1AssessmentGroup(
    str: string,
    fieldName: "companyId" | "testId" | "assessmentId"
): string | undefined {
    // eslint-disable-next-line max-len
    const regex = /^JOBASSESSMENT:(?<companyId>\d+)#ASSESSMENT:(?<assessmentId>[^#]+)#(?:TEST:(?<testId>[^#]+)|DEFINITION)$/;
    const match = regex.exec(str);
    return match?.groups?.[fieldName];
}

export const getTestsFromAssessment = (
    allItems: CompleoShared.Assessments.IAssessment[],
    skGS1pkGroup: string
) => {
    const filteredAssessments = allItems.filter((itemAssessment) => {
        if (itemAssessment.skGS1pk.includes("#DEFINITION")) return false;

        const groupId = extractDataFromJobSK1AssessmentGroup(
            itemAssessment.skGS1pk,
            "assessmentId"
        );
        const currentGroup = extractDataFromJobSK1AssessmentGroup(
            skGS1pkGroup,
            "assessmentId"
        );
        return groupId === currentGroup;
    });
    return (filteredAssessments as unknown) as CompleoShared.Assessments.ITest[];
};

export const useGetOnDragEndJobAssessments = () => {
    /**
     * Handle drag and drop
     * @param {DropResult} result - the drop result from react beatiful dnd
     */
    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            // if (
            //     (destination.index === source.index &&
            //         destination.droppableId === source.droppableId) ||
            //     destination.droppableId !== source.droppableId
            // ) {
            //     return;
            // }
            // const fields = [...questionsData];
            // const fieldsSource = fields.filter(
            //     (item) => item.id === source.droppableId
            // );
            // const currentAnswer = fieldsSource[0].answers[source.index];
            // fieldsSource[0].answers.splice(source.index, 1);
            // fieldsSource[0].answers.splice(destination.index, 0, currentAnswer);
            // if (setFieldValueQuestions) {
            //     setFieldValueQuestions(fields);
            // }
        }
    };

    return onDragEnd;
};

export const useDeleteAssessmentOrTest = (tLocal: TFunction) => {
    const { company } = useAuthState();
    const showMessageFullControl = useShowMessageFullControl();
    const queryClient = useQueryClient();
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const deleteFN = async (
        test:
            | CompleoShared.Assessments.ITest
            | CompleoShared.Assessments.IAssessment,
        jobId: string,
        deleteGroup: boolean = false
    ) => {
        callDialog({
            title: tLocal("COMPLEOGENERAL_Delete"),
            bodyText: tLocal("msgDelete"),
            agreeButtonText: tLocal("COMPLEOGENERAL_YES"),
            disagreeButtonText: tLocal("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                await handleDeleteMain(test, jobId, deleteGroup);
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    const handleDeleteMain = async (
        testOrAssessment:
            | CompleoShared.Assessments.ITest
            | CompleoShared.Assessments.IAssessment,
        jobId: string,
        deleteGroup: boolean = false
    ) => {
        showMessageFullControl.openMessage({
            message: tLocal("COMPLEOGENERAL_MessageUpdating"),
            variant: "info",
            showLinearProgress: true
        });
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            const values = {
                jobId: jobId,
                assessmentId: extractDataFromJobSK1AssessmentGroup(
                    testOrAssessment.skGS1pk,
                    "assessmentId"
                ),
                testId: !deleteGroup
                    ? extractDataFromJobSK1AssessmentGroup(
                          testOrAssessment.skGS1pk,
                          "testId"
                      )
                    : undefined,
                companyId: company.companyId
            };
            const postAddress = `/test/deleteassessmentortest`;
            await apiDirectCall(postAddress, "post", values);
            queryClient.invalidateQueries(`/test/listassessments`);
        } catch (ex) {
            showMessageFullControl.openMessage({
                message: tLocal("NotIdentifiedProblem"),
                autoHideDuration: 10000,
                variant: "error"
            });
            retorno.status = 500;
            return ex.response;
        }
        showMessageFullControl.openMessage({
            message: tLocal("COMPLEOGENERAL_MessageUpdated"),
            autoHideDuration: 2000,
            variant: "success"
        });
        return retorno;
    };

    return { deleteFN, handleDeleteMain, showMessageFullControl };
};

interface IUseModalAssessmentGroupList {
    jobId: string;
    onlyWithTests?: boolean;
    applicantId?: string;
    fieldName?: string;
    onlyLabelValue?: boolean;
}

export const useModalAssessmentGroupList: (
    params: IUseModalAssessmentGroupList
) => [Compleo.CustomLists.ListDefinitionType[], boolean] = (
    params: IUseModalAssessmentGroupList
) => {
    const {
        jobId,
        onlyWithTests = false,
        applicantId,
        fieldName = "assessment",
        onlyLabelValue = false
    } = params;
    const { ready, itemsAssessments, itemsTests } = useGetAssessments(
        jobId,
        applicantId
    );

    const assessments = itemsAssessments
        .filter((item) => {
            if (onlyWithTests) {
                return (
                    itemsTests.filter((itemTest) => {
                        return (
                            extractDataFromJobSK1AssessmentGroup(
                                itemTest.skGS1pk,
                                "assessmentId"
                            ) ===
                            extractDataFromJobSK1AssessmentGroup(
                                item.skGS1pk,
                                "assessmentId"
                            )
                        );
                    }).length > 0 || (item.tests || []).length > 0
                );
            }
            return true;
        })
        .map((item) => ({
            ...item,
            tests:
                (item.tests || []).length > 0
                    ? item.tests
                    : getTestsFromAssessment(itemsTests, item.skGS1pk)
        }));
    const listDefinition: Compleo.CustomLists.ListDefinitionType[] = [];
    if (ready) {
        const labelName = "name";
        const valueName = "assessmentId";
        const data = onlyLabelValue
            ? assessments.map((item) => {
                  return {
                      [labelName]: item[labelName],
                      [valueName]: item[valueName]
                  };
              })
            : assessments;

        const itemListDefinition: Compleo.CustomLists.ListDefinitionType = {
            data: data,
            fieldName: fieldName,
            labelName: labelName,
            valueName: valueName
        };
        listDefinition.push(itemListDefinition);
    }
    return [listDefinition, ready];
};

export const useButtonAddTestToAssessmentLists = (
    tests: CompleoShared.Assessments.ITest[]
) => {
    const { company } = useAuthState();
    const [testList] = useGetTestList();
    const listReady = testList.status === "success";
    const listData: CompleoShared.Assessments.ITest[] =
        testList.response?.data?.data || [];

    const postTemplateList = `/test/testcompleotemplatelist`;
    const [templateListSearchResult] = useApiCache(
        postTemplateList,
        "post",
        {
            companyId: company.companyId,
            fieldsToReturn: [
                "name",
                "pk",
                "skGS1pk",
                "testType",
                "possibleResults"
            ]
        },
        false,
        1000 * 60 * 5
    );
    const templateList: CompleoShared.Assessments.ITestTemplate[] =
        templateListSearchResult?.response?.data?.data || [];
    const optionsTemplate = templateList
        .filter((item) => {
            const finded = tests.find((item2) => {
                return (
                    item2.compleoModelId ===
                        extractValue(item.skGS1pk, "TESTTEMPLATEID") ||
                    "" ||
                    item2.name === item.name
                );
            });
            return !finded;
        })
        .map((data) => {
            const uuidNew = extractValue(data.skGS1pk, "TESTTEMPLATEID") || "";

            return {
                label: data.name,
                value: `TESTID:${uuidNew}`,
                testType: data.testType,
                possibleResults: data.possibleResults,
                templateUUID: uuidNew
            };
        });

    const options = listData
        .filter((item) => {
            const finded = tests.find((item2) => {
                return item2.name === item.name;
            });
            return !finded;
        })
        .map((data) => {
            return {
                label: data.name,
                value: data.skGS1pk,
                testType: data.testType,
                possibleResults: data.possibleResults,
                templateUUID: undefined
            };
        });

    const ready = listReady && templateListSearchResult.status === "success";
    return { options, optionsTemplate, ready };
};

export const useCheckAssessmentAlreadySent = (
    jobId: string,
    assessmentId: string,
    applicantId?: string
) => {
    let assessmentAlreadySent = false;
    const { company } = useAuthState();

    const [requestSearch] = useApiCache(
        "/test/getAssessmentrequestsbyjob",
        "post",
        {
            companyId: company.companyId,
            jobId: jobId,
            applicantId: applicantId
        },
        false,
        1000 * 60 * 5
    );
    const resultData: CompleoShared.Assessments.IReturnGetAssessmentRequestByJob[] =
        requestSearch.response?.data?.data || [];

    if (
        resultData.filter(
            (item) => item.requestData.assessmentId === assessmentId
        ).length > 0
    ) {
        assessmentAlreadySent = true;
    }
    return { assessmentAlreadySent, ready: requestSearch.status === "success" };
};
