import { Button } from "@material-ui/core";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { TFunction } from "i18next";
import useApplicantViewContext from "../../useApplicantViewContext";

interface ICorrectionButtonProps {
    finishCorrection: () => Promise<void>;
    correctionMode: boolean;
    setCorrectionMode: (enabled: boolean) => void;
    correctionStatus: CompleoShared.Assessments.ICorrectionStatus;
    questionsToCorrect: any[];
    allQuestionsCorrected: boolean;
    t: TFunction;
    closeModal: () => void;
}

const CorrectionButton = (props: ICorrectionButtonProps) => {
    const {
        finishCorrection,
        correctionStatus,
        correctionMode,
        setCorrectionMode,
        questionsToCorrect,
        allQuestionsCorrected,
        closeModal,
        t
    } = props;
    const { callDialog } = useGlobalDialog();
    const [data] = useApplicantViewContext();

    if (
        correctionStatus !== "waiting_manual_correction" ||
        !questionsToCorrect.length ||
        data.readOnly
    ) {
        return null;
    }
    const buttonAction = () => {
        if (correctionStatus === "waiting_manual_correction") {
            if (correctionMode) {
                if (!allQuestionsCorrected) {
                    callDialog({
                        title: t("COMPLEOGENERAL_ERROR"),
                        bodyText: t("correctAllQuestionsRequired"),
                        agreeButtonText: t("COMPLEOGENERAL_CLOSE")
                    });
                    return;
                } else {
                    callDialog({
                        title: t("COMPLEOGENERAL_CONFIRMATION"),
                        bodyText: t("areYouSureFinishCorrection"),
                        agreeButtonText: t("COMPLEOGENERAL_YES"),
                        agreeFunction: async () => {
                            setCorrectionMode(false);
                            await finishCorrection();
                            closeModal();
                        },
                        disagreeButtonText: t("COMPLEOGENERAL_NO")
                    });
                }
            } else {
                setCorrectionMode(true);
            }
        }
    };
    const buttonText = correctionMode
        ? t("finishCorrection")
        : t("correctTest");

    const cancelCorrection = () => {
        callDialog({
            title: t("COMPLEOGENERAL_CONFIRMATION"),
            bodyText: t("areYouSureCancelCorrection"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            agreeFunction: async () => {
                setCorrectionMode(false);
            },
            disagreeButtonText: t("COMPLEOGENERAL_NO")
        });
    };

    return (
        <>
            {correctionMode && (
                <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={() => cancelCorrection()}
                    style={{
                        marginRight: 10
                    }}
                >
                    {t("cancelCorrection")}
                </Button>
            )}
            <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => buttonAction()}
            >
                {buttonText}
            </Button>
        </>
    );
};

export default CorrectionButton;
