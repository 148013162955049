import React, { useContext } from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

interface IButtonAddQuestionProps {
    type?: "add" | "edit";
    values?: CompleoShared.Assessments.IQuestion;
    setModalAddEditQuestionData: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            values?: CompleoShared.Assessments.IQuestion | undefined;
        }>
    >;
}

export const ButtonAddEditQuestion = (props: IButtonAddQuestionProps) => {
    const { type = "add", setModalAddEditQuestionData, values } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const { t } = ContextQuestionFieldsData || {};

    return (
        <>
            {type === "add" ? (
                <Button
                    onClick={() => setModalAddEditQuestionData({ open: true })}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{
                        marginTop: "20px"
                    }}
                >
                    {t("ButtonAddQuestion")}
                </Button>
            ) : (
                <Tooltip title={t("COMPLEOGENERAL_Edit")}>
                    <IconButton
                        onClick={(event) => {
                            setModalAddEditQuestionData({
                                open: true,
                                values: values
                            });
                            event.stopPropagation();
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        aria-label={t("COMPLEOGENERAL_Edit")}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

interface IButtonDeleteQuestionProps {
    values: CompleoShared.Assessments.IQuestion;
}
export const ButtonDeleteQuestion = (props: IButtonDeleteQuestionProps) => {
    const { values } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const { questionsData = [], setFieldValueQuestions } =
        ContextQuestionFieldsData || {};
    const { t } = ContextQuestionFieldsData || {};
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const handleDeleteSection = () => {
        callDialog({
            title: t("COMPLEOGENERAL_Delete"),
            bodyText: t("messageConfirmDeleteSection"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const newQuestionsData = questionsData.filter(
                    (question) => question.id !== values.id
                );
                if (setFieldValueQuestions) {
                    setFieldValueQuestions(newQuestionsData);
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };
    return (
        <Tooltip title={t("COMPLEOGENERAL_Edit")}>
            <IconButton
                onClick={(event) => {
                    handleDeleteSection();
                    event.stopPropagation();
                }}
                onFocus={(event) => event.stopPropagation()}
                aria-label={t("COMPLEOGENERAL_Edit")}
            >
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
};
