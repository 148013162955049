import React, { useCallback } from "react";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import { FormHelperText, Button, Tooltip, Grid } from "@material-ui/core";
import { getToolbarRichText } from "./RichTextHelper";
import { useLanguageState } from "_ReactContext/LanguageContext";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import { plugin_emoji } from "./plugin_emoji";
import SunEditor, { buttonList } from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import Plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import Picker, { IEmojiData } from "emoji-picker-react";
import EmojiDialog from "./components/EmojiDialog";
import { isFileFromDB } from "customHooks/useCompleoReactHookForm/Inputs/File/File";
import { apiDirectCall } from "customHooks/useApi/api";
import { useAuthState } from "_ReactContext/AuthContext";
import { clearAccentuatedAndSpecialCharFromString } from "functions/util";
import { plugin_placeholder } from "./plugin_placeholder";
import { TemplatePlaceholderDialog } from "./RichTextVars";
import { DialogChooseTemplateOrPlaceholderBody } from "./DialogChooseTemplateOrPlaceholder";
import { renderToStaticMarkup } from "react-dom/server";
import { is } from "immutable";
interface IStyleProps {
    height: string;
    minHeight: string;
    maxHeight: string;
}

export interface IRichTextConfig {
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    enableCodeButton?: boolean;
    toolbarType?:
        | "min"
        | "full"
        | "jobDescription"
        | "customCV"
        | "emailTemplate";
}
interface IProps {
    metadata: {
        [x: string]: any;
        richTextConfig?: IRichTextConfig;
    };
    toolbarCustomButtons?: any[];
    mention?: any;
    placeholders?: Compleo.IObject[];
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => {
    const colorText = theme.palette.grey[400];
    const colorError = theme.palette.error.main;

    return createStyles({
        wrapper: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorText} !important`,
            marginTop: theme.spacing(1)
        },
        wrapperError: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorError} !important`,
            marginTop: theme.spacing(1)
        },
        label: {
            fontSize: 12
        },
        editorText: {
            height: (props) => `${props.height} !important`,
            minHeight: (props) => `${props.minHeight} !important`,
            maxHeight: (props) => `${props.maxHeight} !important`
        },
        formControl: {
            marginTop: theme.spacing(2),
            width: "100%"
        }
    });
});

const RichText = (props: IProps & IInputProps) => {
    const {
        label,
        helperTextDefault,
        required,
        metadata,
        toolbarCustomButtons = [],
        t,
        mention,
        placeholders,
        ...other
    } = props;

    const {
        error,
        message,
        // setFieldValue,
        setValue,
        field,
        setError,
        formState
    } = useRhfFieldControlled(metadata.fieldName, helperTextDefault);
    const [openEmoji, setOpenEmoji] = React.useState(false);
    const [openPlaceHolderDialog, setOpenPlaceHolderDialog] = React.useState(
        false
    );
    const [isFirstLoad, setIsFirstLoad] = React.useState(true);
    // formState.
    // console.log("richtext field value", field.value);
    const { company } = useAuthState();
    const editor = React.useRef<SunEditorCore>();

    React.useEffect(() => {
        if (editor.current && field?.value) {
            const newHtml = editor.current.core
                .cleanHTML(editor.current.core.getContents(true))
                .trim();

            const oldHtml = editor.current.core
                .cleanHTML(field.value || "")
                .trim();

            if (newHtml === oldHtml) return;

            if (!isFirstLoad) {
                editor.current.setContents(field.value);
            }
            if (isFirstLoad) {
                setIsFirstLoad(false);
            }
        }
    }, [field.value, isFirstLoad]);

    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };

    const language = useLanguageState();

    const richTextConfig: IRichTextConfig = metadata?.richTextConfig || {};
    const {
        height = "80%",
        minHeight = "10px",
        maxHeight = "2000px",
        enableCodeButton = false
    } = metadata?.richTextConfig || {};

    const toolbarObj = getToolbarRichText(richTextConfig);

    const classes = useStyles({
        height: height,
        maxHeight: maxHeight,
        minHeight: minHeight
    });

    const plugin2 = React.useMemo(
        () => plugin_emoji(() => setOpenEmoji(true), "Emoji", "😀"),
        []
    );

    const pluginPlaceHolder = React.useMemo(
        () =>
            plugin_placeholder(
                () => setOpenPlaceHolderDialog(true),
                t("EMAILDATAFIELD_textChooseOptionModalPlaceholder"),
                renderToStaticMarkup(
                    <Grid container direction="row" alignItems="center">
                        <Grid item style={{ height: "24px" }}>
                            <FindReplaceIcon />
                        </Grid>
                        <Grid item>
                            {t("EMAILDATAFIELD_textChoosePlaceholder")}
                        </Grid>
                    </Grid>
                )
            ),
        []
    );

    const handleChange = (event: FocusEvent, editorContents: string) => {
        if (editor.current) {
            const newHtml = editor.current.core
                .cleanHTML(editor.current.core.getContents(true))
                .trim();

            const oldHtml = editor.current.core
                .cleanHTML(field.value || "")
                .trim();

            if (newHtml === oldHtml) return;

            if (newHtml.trim() === "<p>") {
                setValue(field.name, "", {
                    shouldDirty: true,
                    shouldTouch: true
                });
            } else {
                setValue(field.name, editorContents, {
                    shouldDirty: true,
                    shouldTouch: true
                });
            }
            //field.onBlur();
        }
    };

    const handleChooseAnPlaceholder = async (value: string) => {
        if (editor.current) {
            editor.current.insertHTML(`{{${value}}}`);
            setOpenPlaceHolderDialog(false);
        }
    };

    const onEmojiClick = (
        event: React.MouseEvent<Element, MouseEvent>,
        emojiObject: IEmojiData
    ) => {
        if (editor.current) {
            editor.current.insertHTML(emojiObject.emoji, true, true);
        }
        setOpenEmoji(false);
    };

    const onUploadFiles = useCallback(
        async (files: any[], isPublicBucket = false) => {
            const valueDefinition: Compleo.IObject[] = [];
            const re = /(?:\.([^.]+))?$/;
            const filesAndExtensions: Compleo.IObject = [];

            if (files && files.length) {
                for (const fileDef of files) {
                    const file = fileDef?.FileInfo || fileDef;
                    if (!isFileFromDB(file)) {
                        const extension = (re.exec(file.name) || [])[1];
                        filesAndExtensions.push({
                            extension: extension,
                            originalFileName: clearAccentuatedAndSpecialCharFromString(
                                file.name
                            ),
                            fieldName: file.fieldName,
                            contentType: file.type
                        });
                    }
                }
            }

            const requestUrl = "/general/requestpublics3url";

            if (filesAndExtensions.length) {
                const axPost = await apiDirectCall(requestUrl, "post", {
                    files: filesAndExtensions,
                    companyId: company?.companyId || 0,
                    targetFolder: "customCV"
                });
                const urls = axPost.data;
                if (files && files.length && urls) {
                    for (const fileDef of files) {
                        const file = fileDef?.FileInfo || fileDef;
                        if (!isFileFromDB(file)) {
                            const returnData = urls.filter(
                                (u: any) =>
                                    u.originalFileName ===
                                        clearAccentuatedAndSpecialCharFromString(
                                            file.name
                                        ) && u.fieldName === file.fieldName
                            )[0];
                            const signedUrl = returnData.uploadURL;
                            const filename = returnData.fileName;
                            const options = {
                                headers: {
                                    "Content-Type":
                                        returnData.contentType || file.type,
                                    "x-amz-tagging": `originalFileName=${clearAccentuatedAndSpecialCharFromString(
                                        file.name
                                    )}`
                                }
                            };
                            const axiosReturn = await apiDirectCall(
                                signedUrl,
                                "put",
                                file,
                                options,
                                true,
                                false
                            );
                            const itemToAdd: Compleo.IObject = {
                                key: filename,
                                originalFileName: file.name
                            };
                            if ((fileDef.tags || []).length) {
                                itemToAdd.tags = fileDef.tags;
                            }

                            valueDefinition.push(itemToAdd);
                        } else {
                            valueDefinition.push(file);
                        }
                    }
                }
            }

            return valueDefinition;
        },
        []
    );

    const uploadImage = async (files: any[]) => {
        const returnUploadedFileData: Compleo.IObject[] = await onUploadFiles(
            files,
            true
        );
        const key = returnUploadedFileData[0].key;
        const originalFileName = returnUploadedFileData[0].originalFileName;

        return [key, originalFileName];
    };

    const handleImageUploadBefore = (
        files: File[],
        info: object,
        uploadHandler: Function
    ) => {
        let response = {};

        uploadImage([files[0]]).then((img) => {
            const [key, originalFileName] = img;
            response = {
                result: [
                    {
                        url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${key}`,
                        name: originalFileName || "Imagem",
                        size: files[0].size
                    }
                ]
            };

            uploadHandler(response);
            return true;
        });
        return undefined;
    };

    return (
        <FormControl className={classes.formControl}>
            <FormLabel
                className={classes.label}
                htmlFor={field.name}
                aria-label={label}
                required={required}
                error={error}
            >
                {label}
            </FormLabel>
            <div
                style={{ width: "100%" }}
                tabIndex={0}
                className={error ? classes.wrapperError : undefined}
                ref={field.ref}
            >
                <SunEditor
                    width="auto"
                    setAllPlugins={true}
                    setOptions={{
                        defaultStyle: "font-family: Arial; font-size: 14px;",
                        plugins: { ...Plugins, plugin2, pluginPlaceHolder },
                        buttonList: toolbarObj,
                        value: field.value
                    }}
                    // onChange={handleChange}
                    lang={language === "pt-BR" ? "pt_br" : "en"}
                    getSunEditorInstance={getSunEditorInstance}
                    defaultValue={field.value}
                    onBlur={handleChange}
                    height={height}
                    //@ts-ignore
                    onImageUploadBefore={handleImageUploadBefore}
                />
            </div>
            <FormHelperText error={error} id={`helper_${field.name}`}>
                {message}
            </FormHelperText>
            <EmojiDialog open={openEmoji} onEmojiClick={onEmojiClick} />
            <TemplatePlaceholderDialog
                t={t}
                open={openPlaceHolderDialog}
                onClose={() => setOpenPlaceHolderDialog(false)}
                title={t("EMAILDATAFIELD_textChooseOptionModalPlaceholder")}
            >
                <DialogChooseTemplateOrPlaceholderBody
                    handleChooseAnOption={handleChooseAnPlaceholder}
                    placeholders={placeholders}
                    t={t}
                />
            </TemplatePlaceholderDialog>
        </FormControl>
    );
};

export default RichText;
