import React from "react";
import * as util from "functions/util";
import { ApiStateType } from "customHooks/useApi";
import {
    filtersType,
    useGetAllData,
    SearchTypesText,
    SearchTypesList
} from "./helperCustomSearch";
import { getMetadata } from "customHooks/useCompleoForm/helpers/helper";
import { translateAndOrderArray } from "../ListUtil";
import _ from "lodash";
import useList from "customHooks/useList";
import { useListJob, useListBulkActions } from "./CustomLists";

export type MetadataReturn = {
    id: string;
    metadata: Compleo.IObject[];
    formGroups: Compleo.IObject[];
    fieldName: string;
    lists?: Compleo.ListDefinitionType[];
    t: any;
    filterType: filtersType;
    otherSearchDef?: Compleo.IObject;
};

export const transformTFunction = (t: any, id: string) => {
    const tfunction = (keyValue: string, alternativeTrans?: string) => {
        if (keyValue) {
            const newKey = keyValue.replace(`${id}_`, "");
            return t(newKey, alternativeTrans);
        }
        return alternativeTrans || keyValue;
    };
    return tfunction;
};

interface IAddFilter {
    id: string;
    fieldName: string;
    metadataDB: ApiStateType;
    language: string;
    metadataReturn: MetadataReturn[];
    t: any;
    metadataMainList?: ApiStateType;
    listDefinition?: Compleo.ListDefinitionType[];
    otherSearchDef?: Compleo.IObject;
    filterType: filtersType;
}

const addFilter = (params: IAddFilter) => {
    const {
        metadataDB,
        language,
        metadataReturn,
        t,
        fieldName,
        listDefinition,
        id,
        metadataMainList,
        otherSearchDef,
        filterType
    } = params;
    const metadataDBWithoutRef = _.cloneDeep(metadataDB);

    const lists: Compleo.ListDefinitionType[] = [];

    const metadataParam = getMetadata(metadataDBWithoutRef, language);
    const metadataFields = [...(metadataParam.camposMetadados || [])];
    const metadata: Compleo.IObject[] = metadataFields.map(
        (item: Compleo.IObject) => {
            const newFieldName = `${id}_${item.fieldName}`;
            const validation = item.validation || [];
            for (let index = 0; index < validation.length; index++) {
                if (validation[index].fieldToCheck) {
                    validation[
                        index
                    ].fieldToCheck = `${id}_${validation[index].fieldToCheck}`;
                }
            }

            let requiredCondition = item.requiredCondition;
            if (item.requiredCondition?.conditions?.length) {
                const conditions = item.requiredCondition?.conditions || [];
                for (let index = 0; index < conditions.length; index++) {
                    if (conditions[index].fieldToCheck) {
                        conditions[
                            index
                        ].fieldToCheck = `${id}_${conditions[index].fieldToCheck}`;
                    }
                }
                requiredCondition = {
                    ...(item.requiredCondition || {}),
                    conditions: conditions
                };
            }

            const fieldListDefinition = (metadataMainList?.response?.data
                ?.listas || {})[fieldName];
            const hasListDefinition =
                (listDefinition || []).filter(
                    (item) => item.fieldName === newFieldName
                ).length > 0;
            if (
                fieldListDefinition &&
                !hasListDefinition &&
                item.fieldName === "listData"
            ) {
                lists.push({
                    data: fieldListDefinition,
                    fieldName: newFieldName,
                    labelName: "label",
                    valueName: "value"
                });
            }

            return {
                ...item,
                fieldName: newFieldName,
                validation: validation,
                requiredCondition: requiredCondition
            };
        }
    );

    const localT = transformTFunction(t, id);
    const newMetadata = [...metadataReturn];
    newMetadata.push({
        id: id,
        fieldName: fieldName,
        metadata: metadata,
        t: localT,
        lists: [...(listDefinition || []), ...lists],
        formGroups: metadataParam.formGroups,
        otherSearchDef: otherSearchDef,
        filterType: filterType
    });
    return newMetadata;
};

export type useCustomSearchMetadataReturnType = {
    metadataReturn: MetadataReturn[];
    setMetadataReturn: React.Dispatch<React.SetStateAction<MetadataReturn[]>>;
    addMatchText: (fieldName: string, otherSearchDef?: Compleo.IObject) => void;
    addSimpleText: (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => void;
    addRangeDate: (fieldName: string, otherSearchDef?: Compleo.IObject) => void;
    addRangeNumber: (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => void;
    addList: (
        fieldName: string,
        otherSearchDef?: Compleo.IObject,
        otherLists?: Compleo.ListDefinitionType[]
    ) => void;
    addBoolean: (fieldName: string, otherSearchDef?: Compleo.IObject) => void;
    deleteFilter: (id: string) => void;
    i18n: any;
    ready: boolean;
};

const useCustomSearchMetadata: (
    isOpen: boolean
) => useCustomSearchMetadataReturnType = (isOpen: boolean) => {
    const [metadataReturn, setMetadataReturn] = React.useState<
        MetadataReturn[]
    >([]);
    const [newCustomListToUpdate, setNewCustomListToUpdate] = React.useState<
        Compleo.IObject
    >({});
    const [listData] = useList();
    const { otherFilters } = listData.definitionInfo.elasticDefData?.data || {};
    const allData = useGetAllData(
        listData?.definitionInfo?.elasticDefData?.data?.otherSettings
            ?.metadataToGetLists
    );

    const ListJob = useListJob();
    const ListBulk = useListBulkActions();
    const getListFromListType = (type: string) => {
        switch (type) {
            case "job":
                return ListJob;
            case "bulkActions":
                return ListBulk;
            default:
                break;
        }
    };

    React.useEffect(() => {
        if (
            Object.keys(newCustomListToUpdate).length &&
            metadataReturn.length &&
            isOpen &&
            allData.ready
        ) {
            const newStateCustomList = { ...newCustomListToUpdate };
            const newMetadataReturn = [...metadataReturn];
            for (const keyName of Object.keys(newCustomListToUpdate)) {
                const listType: string = newCustomListToUpdate[keyName].type;
                const ListData = getListFromListType(listType);
                if (ListData && ListData.ready && ListData.data.length) {
                    const data = newMetadataReturn.filter(
                        (item) => item.id === newCustomListToUpdate[keyName].id
                    )[0];
                    if (data) {
                        const newLists = [...(data?.lists || [])];
                        newLists.push({
                            data: ListData.data,
                            fieldName: keyName,
                            labelName: "label",
                            valueName: "value"
                        });
                        data.lists = newLists;
                        newMetadataReturn.filter(
                            (item) => item.id === keyName
                        )[0] = data;
                        delete newStateCustomList[keyName];
                    }
                }
            }
            setNewCustomListToUpdate(newStateCustomList);
        }
    }, [
        Object.keys(newCustomListToUpdate).length,
        ListJob,
        ListBulk,
        metadataReturn.length,
        isOpen,
        allData.ready
    ]);

    if (!allData.ready) {
        return {
            metadataReturn,
            setMetadataReturn,
            addMatchText: () => {},
            addSimpleText: () => {},
            addRangeDate: () => {},
            addRangeNumber: () => {},
            addList: () => {},
            addBoolean: () => {},
            deleteFilter: () => {},
            i18n: allData.i18n,
            ready: false
        };
    }

    const addMatchText = (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => {
        const id = util.uuid();
        const optionsType = translateAndOrderArray(
            SearchTypesText.items,
            allData.data.matchText.t
        );
        const listDefinition: Compleo.ListDefinitionType[] = [];
        listDefinition.push({
            data: optionsType,
            fieldName: `${id}_searchType`,
            valueName: "value",
            labelName: "label"
        });
        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.matchText.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.matchText.t,
            listDefinition: listDefinition,
            otherSearchDef: otherSearchDef,
            filterType: "matchText"
        });

        setMetadataReturn(newMetadata);
    };

    const addSimpleText = (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => {
        const id = util.uuid();
        const optionsType = translateAndOrderArray(
            SearchTypesText.items,
            allData.data.simpleText.t
        );
        const listDefinition: Compleo.ListDefinitionType[] = [];
        listDefinition.push({
            data: optionsType,
            fieldName: `${id}_searchType`,
            valueName: "value",
            labelName: "label"
        });
        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.simpleText.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.simpleText.t,
            listDefinition: listDefinition,
            otherSearchDef: otherSearchDef,
            filterType: "simpleText"
        });

        setMetadataReturn(newMetadata);
    };

    const addRangeDate = (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => {
        const id = util.uuid();
        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.rangeDate.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.rangeDate.t,
            otherSearchDef: otherSearchDef,
            filterType: "rangeDate"
        });

        setMetadataReturn(newMetadata);
    };

    const addRangeNumber = (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => {
        const id = util.uuid();
        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.rangeNumber.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.rangeNumber.t,
            otherSearchDef: otherSearchDef,
            filterType: "rangeNumber"
        });

        setMetadataReturn(newMetadata);
    };

    const addList = async (
        fieldName: string,
        otherSearchDef?: Compleo.IObject,
        otherLists?: Compleo.ListDefinitionType[]
    ) => {
        const id = util.uuid();
        const optionsType = translateAndOrderArray(
            SearchTypesList.items,
            allData.data.list.t
        );
        const listDefinition: Compleo.ListDefinitionType[] = [];

        listDefinition.push({
            data: optionsType,
            fieldName: `${id}_searchType`,
            valueName: "value",
            labelName: "label"
        });
        for (const otherList of otherLists || []) {
            listDefinition.push({ ...otherList, fieldName: `${id}_listData` });
        }

        const filterDef = otherFilters[fieldName] || {};
        if (filterDef?.customList) {
            const ListData = getListFromListType(filterDef?.customList);
            if (ListData) {
                await ListData.run();
                setNewCustomListToUpdate({
                    [`${id}_listData`]: { type: filterDef?.customList, id: id }
                });
            }
        }

        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.list.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.list.t,
            metadataMainList: allData.metadataMainList,
            listDefinition: listDefinition,
            otherSearchDef: otherSearchDef,
            filterType: "list"
        });

        setMetadataReturn(newMetadata);
    };

    const addBoolean = (
        fieldName: string,
        otherSearchDef?: Compleo.IObject
    ) => {
        const id = util.uuid();
        const listDefinition: Compleo.ListDefinitionType[] = [];

        const optionsBoolean = [
            { label: allData.data.boolean.t("booleanTrue"), value: true },
            { label: allData.data.boolean.t("booleanFalse"), value: false }
        ];
        listDefinition.push({
            data: optionsBoolean,
            fieldName: `${id}_listData`,
            valueName: "value",
            labelName: "label"
        });

        const newMetadata = addFilter({
            fieldName: fieldName,
            id: id,
            language: allData.language,
            metadataDB: allData.data.boolean.metadata,
            metadataReturn: metadataReturn,
            t: allData.data.boolean.t,
            listDefinition: listDefinition,
            otherSearchDef: otherSearchDef,
            filterType: "boolean"
        });

        setMetadataReturn(newMetadata);
    };

    const deleteFilter = (id: string) => {
        const newMetadata = [...metadataReturn].filter(
            (item) => item.id !== id
        );
        setMetadataReturn(newMetadata);
    };

    return {
        metadataReturn,
        setMetadataReturn,
        addMatchText,
        addRangeDate,
        addRangeNumber,
        addList,
        addBoolean,
        addSimpleText,
        deleteFilter,
        i18n: allData.i18n,
        ready: allData.ready
    };
};

export type useGetAllDataCustomSearch = {
    [K in filtersType]: {
        metadata: ApiStateType;
        t: any;
    };
};

export default useCustomSearchMetadata;
