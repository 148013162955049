import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import ApplicantView from "./ApplicantView";
import theme from "theme/Theme";
import { useMediaQuery } from "@material-ui/core";
import { useHotkeys } from "react-hotkeys-hook";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CloseIcon from "@material-ui/icons/Close";
import {
    ApplicantViewJobDataInsideJob,
    ApplicantViewModulesType
} from "./useApplicantViewContext/ApplicantViewTypes";
import { apiDirectCall } from "customHooks/useApi/api";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperDialogProps: {
            backgroundColor: theme.palette.backgroundGrey.main,
            minHeight: "95vh",
            maxHeight: "95vh"
        },
        dialogActions: {
            backgroundColor: "white",
            borderTopColor: theme.palette.backgroundGrey.main,
            borderTopWidth: 2,
            borderTopStyle: "solid"
        }
    })
);

export interface IApplicantViewModalContainerNavigationInfoKanban {
    index: number;
    fieldArray: Compleo.IObject[];
    reactWindowRef: any;
}

interface IPropsItemFilter {
    open: boolean;
    onClose: () => void;
    navigationInfo: IApplicantViewModalContainerNavigationInfoKanban;
    jobDataInsideJob: ApplicantViewJobDataInsideJob;
    module: ApplicantViewModulesType;
}

const getApplicantId = (
    navigationInfo: IApplicantViewModalContainerNavigationInfoKanban,
    currentIndex?: number
) => {
    if (
        currentIndex !== undefined &&
        navigationInfo.fieldArray[currentIndex]?.pk
    ) {
        return navigationInfo.fieldArray[currentIndex]?.pk.split(":")[1];
    } else if (navigationInfo.fieldArray[navigationInfo.index]?.pk) {
        return navigationInfo.fieldArray[navigationInfo.index]?.pk.split(
            ":"
        )[1];
    } else {
        return undefined;
    }
};

const ApplicantViewModalContainerKanban = (props: IPropsItemFilter) => {
    const { onClose, open, navigationInfo, jobDataInsideJob, module } = props;
    const classes = useStyles();
    const moduleName = module;

    const [currentIndex, setCurrentIndex] = React.useState(
        navigationInfo?.index !== undefined ? navigationInfo?.index : -1
    );
    const [
        externalDataLoadFinished,
        setExternalDataLoadFinished
    ] = React.useState(false);
    const [customCVsData, setCustomCVsData] = React.useState<Compleo.IObject[]>(
        []
    );
    const totalItems = (navigationInfo?.fieldArray || []).length;
    const localApplicantId = getApplicantId(navigationInfo, currentIndex);
    // const companyId = getCompanyId(navigationInfo, currentIndex);
    const companyId = jobDataInsideJob?.jobData?.data?.companyId;

    React.useEffect(() => {
        if (open) {
            setCurrentIndex(
                navigationInfo?.index !== undefined ? navigationInfo?.index : -1
            );
        }
    }, [open]);

    React.useEffect(() => {
        if (open && !localApplicantId) {
            onClose();
        }
    }, [open, localApplicantId]);

    React.useEffect(() => {
        apiDirectCall("/customcv/getcustomcv", "post", {
            companyId,
            jobId: jobDataInsideJob?.jobId
        }).then((result: any) => {
            setCustomCVsData(result.data || []);
            setExternalDataLoadFinished(true);
        });
    }, [companyId, jobDataInsideJob]);

    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const isXSScreen = useMediaQuery(theme.breakpoints.only("xs"));
    const classPaperProps = !isXSScreen ? classes.paperDialogProps : "";

    const previousApplicant = (currentIndexParam: number) => {
        if (currentIndexParam > 0) {
            setCurrentIndex(currentIndexParam - 1);
            const reactWindowCurrent: any =
                navigationInfo.reactWindowRef?.current;
            if (reactWindowCurrent) {
                reactWindowCurrent.scrollToItem(currentIndexParam - 1);
            }
        }
    };

    const nextApplicant = (
        currentIndexParam: number,
        totalItemsParams: number
    ) => {
        if (currentIndexParam >= totalItemsParams - 1) {
        } else {
            setCurrentIndex(currentIndexParam + 1);
            const reactWindowCurrent: any =
                navigationInfo.reactWindowRef?.current;
            if (reactWindowCurrent) {
                reactWindowCurrent.scrollToItem(currentIndexParam + 1);
            }
        }
    };
    useHotkeys(
        "ctrl+q,right,left",
        (event: any, handler: any) => {
            event.preventDefault();
            switch (handler.key) {
                case "ctrl+q":
                    onClose();
                    break;
                case "right":
                    nextApplicant(currentIndex, totalItems);
                    break;
                case "left":
                    previousApplicant(currentIndex);
                    break;
                default:
                    break;
            }
        },
        {},
        [currentIndex, totalItems]
    );

    return (
        <Dialog
            fullWidth
            fullScreen={isXSScreen}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xl"
            aria-labelledby={t("ModalSortColumn_Title")}
            open={open}
            PaperProps={{ className: classPaperProps }}
        >
            <DialogContent style={{ padding: 0, margin: 0 }}>
                {open &&
                    localApplicantId &&
                    readyTranslation &&
                    externalDataLoadFinished && (
                        <ApplicantView
                            applicantId={localApplicantId}
                            containerType="modal"
                            index={currentIndex}
                            jobDataInsideJob={jobDataInsideJob}
                            isInsideKanban={true}
                            module={module}
                            customCVsData={customCVsData}
                        />
                    )}
            </DialogContent>
            {open && localApplicantId && readyTranslation && (
                <DialogActions className={classes.dialogActions}>
                    {currentIndex > 0 && (
                        <Button
                            onClick={() => {
                                previousApplicant(currentIndex);
                            }}
                            color="primary"
                            variant="outlined"
                            startIcon={<NavigateBeforeIcon />}
                        >
                            {t("COMPLEOGENERAL_PREVIOUS")}
                        </Button>
                    )}
                    {totalItems > 0 && currentIndex < totalItems - 1 && (
                        <Button
                            onClick={() => {
                                nextApplicant(currentIndex, totalItems);
                            }}
                            color="primary"
                            variant="outlined"
                            endIcon={<NavigateNextIcon />}
                        >
                            {t("COMPLEOGENERAL_NEXT")}
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        color="secondary"
                        variant="contained"
                        startIcon={<CloseIcon />}
                    >
                        {t("COMPLEOGENERAL_CLOSE")}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ApplicantViewModalContainerKanban;
