import React from "react";
import Page from "Pages/_Layouts/MainInternalPageJobView";
import { JobViewProvider } from "./useJobContext";
import JobMain from "./JobMain";
import useJobAccess from "customHooks/useAccess/useJobAccess";

const JobView = (props: any) => {
    const jobAccess = useJobAccess();
    return (
        <JobViewProvider jobId={props.id} readonly={!jobAccess.allowEdit}>
            <Page maxWidth={false}>
                <JobMain pageToRender={props.pageToRender} />
            </Page>
        </JobViewProvider>
    );
};

export default JobView;
