import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { useQueryClient } from "react-query";
import { useHandleAssessmentsLists } from "./useHandleAssessmentsList";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface IPropsModal {
    open: boolean;
    onClose: () => void;
    applicantId: string;
    jobData?: {
        value: string;
        label: string;
    };
    applicantName: string;
}

const JobKanbanRequestAssessmentModal = (props: IPropsModal) => {
    const { onClose, open, applicantId, jobData, applicantName } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const queryClient = useQueryClient();
    const module = "APPLICANTVIEWSENDASSESSMENTMODAL";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });
    const {
        lists,
        ready: readyLists,
        noAssessmentsToSent
    } = useHandleAssessmentsLists({
        applicantId,
        t
    });

    const [initialValues] = useValuesFromSource(metadata, true, {
        id: applicantId,
        jobId: jobData?.value,
        jobTitle: jobData?.label
    });

    const ready =
        readyTranslation && metadata.status === "success" && readyLists;

    const handleSubmit = async (values: any) => {
        setIsUpdating(true);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        // console.log("values", JSON.stringify(values, null, 4));
        // return retorno;
        const postAddress = `/test/sendassessmentapplicantindividual`;
        try {
            const dataAPI = await apiDirectCall(postAddress, "post", values);
            if (dataAPI.status === 200) {
                queryClient.invalidateQueries(`/applicant/searchfulldata`);
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        customLists: lists
    });

    const handleSave = () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }
    const noAssessments = ready && noAssessmentsToSent;

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modalTitle")}
            open={open}
        >
            <DialogTitle>
                {!ready
                    ? null
                    : `${t("modalTitle")} ${
                          applicantName ? ` - ${applicantName}` : ""
                      } `}
            </DialogTitle>
            <DialogContent dividers style={{ padding: 4 }}>
                {noAssessments ? (
                    <Alert severity="info" style={{ margin: 24 }}>
                        <Typography>{t("noAssessmentToBeSent")}</Typography>
                    </Alert>
                ) : (
                    <>{FormReturn}</>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("COMPLEOGENERAL_CLOSE", "")}
                </Button>
                {!noAssessments && (
                    <Button
                        onClick={() => handleSave()}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE", "")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default JobKanbanRequestAssessmentModal;
