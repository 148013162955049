import { IQuestionListProps } from "../Question";
import VideoPlayer from "Components/HlsJS";
import { useApiCache } from "customHooks/useApi";

const AnswerVideo = (props: IQuestionListProps) => {
    const { question } = props;
    const videoData = question?.answer?.[0]?.videoS3;

    const postAddressSearch = `/legacy/getsignerurlvideomultiplefilesprivate`;

    const [getVideosResult] = useApiCache(
        postAddressSearch,
        "post",
        {
            companyId: question.companyId,
            videoList: [
                {
                    Name: videoData?.s3Key,
                    Id: 1
                }
            ]
        },
        false,
        1000 * 60 * 1
    );
    const source =
        getVideosResult?.response?.data?.videoList?.[0]?.processedFilePath;
    const queryString =
        getVideosResult?.response?.data?.videoList?.[0]?.queryString;
    const finalPath = `${source}`;

    if (finalPath) {
        return <VideoPlayer src={source} queryString={queryString} />;
    } else {
        return null;
    }
};

export default AnswerVideo;
