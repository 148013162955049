import QuestionsMain from "./Questions/QuestionsMain";

const CustomComponents = [
    {
        name: "sections",
        component: QuestionsMain
    }
];

export default CustomComponents;
