import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    ListItemSecondaryAction,
    Radio,
    RadioGroup
} from "@material-ui/core";
import { IQuestionListProps } from "./Question";
import { Rating } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { useStyles } from "./QuestionManualCorrectionStyles";
import * as questionStyle from "./QuestionStyle";
import { ChangeEvent } from "react";

const QuestionManualCorrection = (props: IQuestionListProps) => {
    const { question, questionsToCorrect, correctionMode } = props;

    if (!(questionsToCorrect || []).find((q) => q.id === question.id)) {
        return null;
    }

    return (
        <ListItemSecondaryAction>
            {correctionMode ? (
                <CorrectionComponent {...props} />
            ) : (
                <CorrectionView {...props} />
            )}
        </ListItemSecondaryAction>
    );
};

const CorrectionComponent = (props: IQuestionListProps) => {
    const { question, setScore, questionScore, correctionMode, t } = props;
    const classes = useStyles();

    if (!correctionMode) return null;
    const questionData: CompleoShared.Assessments.IQuestion | undefined =
        question.question;

    const score = questionScore.find((q) => q.questionId === questionData?.id)
        ?.score;
    const value = score === undefined ? -1 : score;

    const setValue = (newValue: number) => {
        if (questionData?.id) {
            setScore(questionData?.id, newValue);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number((event.target as HTMLInputElement).value));
    };

    switch (questionData?.correctionType?.value) {
        case "correctIncorrect":
            return (
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="correction"
                        name={`correctOrIncorrect_${questionData?.id}`}
                        value={value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleChange(event)
                        }
                        row
                    >
                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={t("incorrectAnswer")}
                        />
                        <FormControlLabel
                            value={100}
                            control={<Radio />}
                            label={t("correctAnswer")}
                        />
                    </RadioGroup>
                    {value === -1 && (
                        <FormLabel component="legend" error>
                            {t("requiredField")}
                        </FormLabel>
                    )}
                </FormControl>
            );
        case "starRating":
            return (
                <>
                    {value === -1 && (
                        <Box display={"block"} mb={1}>
                            <FormLabel component="legend" error>
                                {t("requiredField")}
                            </FormLabel>
                        </Box>
                    )}
                    <Box
                        display={"flex"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        justifyItems={"center"}
                    >
                        <ButtonIconTooltipCompleo
                            label="0%"
                            onClick={() => setValue(0)}
                            className={classes.zeroStartTooltip}
                        >
                            <ClearIcon
                                color={value === 0 ? "secondary" : "disabled"}
                            />
                        </ButtonIconTooltipCompleo>
                        <Rating
                            name={`simple-controlled${questionData?.id}`}
                            value={value / 20}
                            onChange={(event, newValue) => {
                                setValue((newValue || 0) * 20);
                            }}
                            getLabelText={(value) => `${value / 20}%`}
                        />
                        {value !== -1 && <Box ml={2}>{value} %</Box>}
                    </Box>
                </>
            );
        default:
            return null;
    }
};

const CorrectionView = (props: IQuestionListProps) => {
    const { question, questionScore, t } = props;
    const classes = questionStyle.useStyles();

    const questionData: CompleoShared.Assessments.IQuestion | undefined =
        question.question;
    const score = questionScore.find((q) => q.questionId === questionData?.id)
        ?.score;
    const value = score === undefined ? -1 : score;

    if (value === -1) return null;

    switch (questionData?.correctionType?.value) {
        case "correctIncorrect":
            return (
                <Chip
                    className={classes.chipCardAnswerCorrect}
                    label={
                        value === 0 ? t("incorrectAnswer") : t("correctAnswer")
                    }
                />
            );
        case "starRating":
            return (
                <>
                    <Box
                        display={"flex"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        justifyItems={"center"}
                    >
                        <Rating
                            name="simple-controlled"
                            value={value / 20}
                            getLabelText={(value) => `${value / 20}%`}
                            readOnly
                        />
                        {value !== -1 && <Box ml={2}>{value} %</Box>}
                    </Box>
                </>
            );
        default:
            return null;
    }
};

export default QuestionManualCorrection;
