import React, { useContext, useState } from "react";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Droppable,
    DroppableProvided,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import { Box } from "@material-ui/core";
import { ButtonAddEditQuestion } from "./QuestionsHelper";
import QuestionAddEditModal from "./QuestionAddEditModal";
import Question from "./Question";

interface IQuestionsProps {
    sectionData: CompleoShared.Assessments.ISection;
}
const Questions = (props: IQuestionsProps) => {
    const { sectionData } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const [modalAddEditQuestionData, setModalAddEditQuestionData] = useState<{
        open: boolean;
        values?: CompleoShared.Assessments.IQuestion;
    }>({ open: false });

    const questionsData: CompleoShared.Assessments.IQuestion[] = (
        ContextQuestionFieldsData?.questionsData || []
    ).filter((question) => question.sectionId === sectionData.id);

    const { ready = false } = ContextQuestionFieldsData || {};

    if (ready) {
        return (
            <Box width="100%">
                <Box>
                    <Droppable
                        droppableId={sectionData.id}
                        direction="vertical"
                        type="questions"
                    >
                        {(provided: DroppableProvided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {questionsData.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                    >
                                        {(
                                            provided: DraggableProvided,
                                            snapshot: DraggableStateSnapshot
                                        ) => (
                                            <Question
                                                key={item.id}
                                                question={item}
                                                provided={provided}
                                                setModalAddEditQuestionData={
                                                    setModalAddEditQuestionData
                                                }
                                                index={index}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Box>
                <Box>
                    <ButtonAddEditQuestion
                        setModalAddEditQuestionData={
                            setModalAddEditQuestionData
                        }
                    />
                    {modalAddEditQuestionData.open && (
                        <QuestionAddEditModal
                            onClose={() =>
                                setModalAddEditQuestionData({
                                    open: false,
                                    values: undefined
                                })
                            }
                            open={modalAddEditQuestionData.open}
                            values={modalAddEditQuestionData.values}
                            sectionId={sectionData.id}
                        />
                    )}
                </Box>
            </Box>
        );
    } else {
        return <Loading />;
    }
};

export default Questions;
