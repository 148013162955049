import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import CustomComponents from "./components/CustomComponents";
import { useApi } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryStringRedirectAfter } from "customHooks/useQueryString/useQueryString";
import useFieldsToHideTests from "./helpers/useFieldsToHideTests";

interface ITestEditProps {
    uuid: string;
    jobid?: string;
    assessmentid?: string;
}
const TestEdit = (props: ITestEditProps) => {
    const { jobid, uuid, assessmentid } = props;
    const redirectAfter = useQueryStringRedirectAfter(props);

    const redirectAddress = redirectAfter || "/test/list";
    const cancelFunction = useCancelButtonForm(redirectAddress);
    const { company } = useAuthState();
    const moduleName = "TESTEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/test/edit`;

    const [editReturn, edit] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);

    const postAddressSearch = `/test/search`;
    const [searchResult] = useApi(
        postAddressSearch,
        "post",
        {
            uuid: uuid,
            companyId: company.companyId,
            jobId: jobid, //only used whend editing a test from a job
            assessmentId: assessmentid //only used whend editing a test from a job
        },
        false
    );
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        searchResult,
        ["data", "data"]
    );
    valuesFromSource.uuid = uuid;
    valuesFromSource.jobId = props.jobid;
    valuesFromSource.assessmentId = assessmentid;
    valuesFromSource.deleted = false;

    const ready = readyTranslation && searchResult.status === "success";

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });

    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const results = watch("results") || [];
    const fieldsToHide = useFieldsToHideTests({
        watch,
        type: valuesFromSource.type
    });

    const customList: Compleo.CustomLists.ListDefinitionType[] = [
        {
            fieldName: "rankingToResults.result",
            data: results,
            labelName: "name",
            valueName: "name"
        }
    ];

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: ready,
        i18nV: i18n,
        postMethod: edit,
        postReturn: editReturn,
        redirectAddress: redirectAddress,
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        CustomComponents: CustomComponents,
        secondaryButtonFunction: cancelFunction,
        reactHookFormMethods: reactHookFormMethods,
        customLists: customList,
        invalidateQueryClientItems: ["/test/listassessments"],
        fieldsToHide: fieldsToHide
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default TestEdit;
