import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TFunction } from "react-i18next";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio
} from "@material-ui/core";
import { IHandleAddTestToAssessment } from "./AddTestToAssessment";

interface IAddTestToAssessmentChooseResultModalProps {
    open: boolean;
    resultsList: CompleoShared.Assessments.IPossibleResults[];
    testId: string;
    templateUUID?: string;
    handleClose: () => void;
    handleAddTestToAssessment: (
        params: IHandleAddTestToAssessment
    ) => Promise<any>;
    t: TFunction;
}

const AddTestToAssessmentChooseResultModal = (
    props: IAddTestToAssessmentChooseResultModalProps
) => {
    const {
        open,
        resultsList,
        handleClose,
        handleAddTestToAssessment,
        t,
        testId,
        templateUUID
    } = props;
    const [selectedValue, setSelectedValue] = React.useState(
        resultsList[0].name
    );
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleSave = async () => {
        await handleAddTestToAssessment({
            testId: testId,
            resultName: selectedValue,
            templateUUID: templateUUID
        });
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown
                disableBackdropClick
            >
                <DialogTitle id="form-dialog-title">
                    {t("AddTestToAssessmentChooseResultModal_ModalTitle", {
                        ns: "TESTNEW"
                    })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(
                            "AddTestToAssessmentChooseResultModal_ModalInstructions",
                            {
                                ns: "TESTNEW"
                            }
                        )}
                    </DialogContentText>
                    <List>
                        {resultsList.map((result) => {
                            return (
                                <ListItem
                                    key={result.name}
                                    button
                                    onClick={() =>
                                        setSelectedValue(result.name)
                                    }
                                >
                                    <ListItemIcon>
                                        <Radio
                                            checked={
                                                selectedValue === result.name
                                            }
                                            onChange={handleChange}
                                            value={result.name}
                                            name="radio-button-result"
                                            inputProps={{
                                                "aria-label": result.name
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={result.name}
                                        secondary={
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        result.description || ""
                                                }}
                                            />
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddTestToAssessmentChooseResultModal;
