import { ApiStateType, apiDirectCall } from "customHooks/useApi";
import useGetMetadata from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useJobContext from "../../useJobContext";
import { ListDefinitionType } from "Pages/Users/components/useCustomLists";
import { useQueryClient } from "react-query";
import { extractValue } from "functions/util";

interface IModalJobAssessmentsGroupProps {
    open: boolean;
    onClose: () => void;
    editData?: CompleoShared.Assessments.IAssessment;
}
const ModalJobAssessmentsGroup = (props: IModalJobAssessmentsGroupProps) => {
    const { onClose, open, editData } = props;
    const queryClient = useQueryClient();

    const [data] = useJobContext();
    const allStages = (data?.LocalStateData?.allStages || []).map((item) => ({
        value: item.id,
        label: item.name
    }));
    const lists: ListDefinitionType[] = [
        {
            fieldName: "resultsToStageRule.targetStage",
            data: allStages,
            labelName: "label",
            valueName: "value"
        }
    ];
    const module = "JOBVIEWASSESSMENTSMODAL";
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, true, {
        ...editData,
        jobId: data?.JobId
    });

    const ready = readyTranslation && metadata.status === "success";

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            if (!editData) {
                const postAddress = `/test/newassessmenttojob`;
                await apiDirectCall(postAddress, "post", values);
            } else {
                const editValues: Compleo.IObject = { ...values };
                delete editValues.skGS1pk;
                delete editValues.pk;
                editValues.assessmentId =
                    extractValue(editData.skGS1pk, "ASSESSMENT") || "";

                const postAddress = `/test/editassessmenttojob`;
                await apiDirectCall(postAddress, "post", editValues);
            }
            queryClient.invalidateQueries(`/test/listassessments`);
            onClose();
        } catch (ex) {
            return ex.response;
        }
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        secondaryButtonFunction: onClose,
        customLists: lists
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">{t("modalTitle")}</DialogTitle>
            <DialogContent>{FormReturn}</DialogContent>
        </Dialog>
    );
};

export default ModalJobAssessmentsGroup;
