import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { handleSubmitType } from "customHooks/useCompleoReactHookForm";
import React from "react";
import { UseFormWatch } from "react-hook-form";
import { useCompanyAIStatus } from "../CompanyAICreditStatus/useCompanyAIStatus";

interface IModalConfirmChangeAISettings {
    open: boolean;
    onClose: () => void;
    t: any;
    handleSubmit: handleSubmitType;
    watch: UseFormWatch<Compleo.IObject>;
    initialValues: Compleo.IObject;
}
const ModalConfirmChangeAISettings = (props: IModalConfirmChangeAISettings) => {
    const { open, onClose, t, handleSubmit, watch, initialValues } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);

    const { currentCredits, ready } = useCompanyAIStatus();
    const pageReady = ready && initialValues;

    let hasCreditAllModels = true;
    Object.keys(watch("mainSettings")).map((item) => {
        const itemValue = watch("mainSettings") || "disabled";
        Object.keys(itemValue).map((key) => {
            if (currentCredits === 0 && itemValue[key] !== "disabled") {
                hasCreditAllModels = false;
            }
        });
    });

    const handleSave = async () => {
        setIsUpdating(true);
        await handleSubmit();
        onClose();
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t("modelWarning_modalTitleWarning")}
            open={open}
        >
            <DialogTitle>{t("modelWarning_modalTitleWarning")} </DialogTitle>
            <DialogContent dividers>
                {pageReady ? t("confirmationMessage") : <Loading />}
                <Alert severity="warning" style={{ marginTop: 16 }}>
                    {t("warningAIBeta")}
                </Alert>
                {!hasCreditAllModels && (
                    <Alert severity="error" style={{ marginTop: 16 }}>
                        {t("noCreditsEnableModelWarning")}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    disabled={isUpdating || !pageReady}
                >
                    {t("COMPLEOGENERAL_CLOSE")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    color="primary"
                    disabled={isUpdating || !pageReady}
                >
                    {t("COMPLEOGENERAL_SAVE")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalConfirmChangeAISettings;
