import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const TestsBetaWarning = () => {
    const [t, i18n, readyTranslation] = useTranslation(
        "ASSESSMENTSBETAWARNING",
        {
            useSuspense: false
        }
    );
    if (!readyTranslation) {
        return null;
    }
    return (
        <Alert severity="warning" style={{ width: "100%" }}>
            <AlertTitle>{t("title")}</AlertTitle>
            {t("message")}
        </Alert>
    );
};

export default TestsBetaWarning;
