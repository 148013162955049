import { useAuthorization } from "_ReactContext/AuthContext";
import React from "react";

const useJobFieldsToHide = (languages: any) => {
    const { isFeatureAuthorized } = useAuthorization();
    const aiEnabled = isFeatureAuthorized("ManageAISettings");
    const defaultValues = !aiEnabled ? ["enableMatchApplicants"] : [];

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultValues
    );

    React.useEffect(() => {
        if (languages && languages.length <= 1) {
            const fName = "translatedData";
            if (!fieldsToHide.find((f) => f === fName)) {
                const newFieldsToHide = [...fieldsToHide].filter(
                    (f) => f !== "translatedData"
                );
                newFieldsToHide.push("translatedData");
                setFieldsToHide([...newFieldsToHide]);
            }
        }
    }, [languages]);

    return { fieldsToHide, setFieldsToHide };
};
export default useJobFieldsToHide;
