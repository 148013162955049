import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import ApplicantMatchJobData from "./ApplicantMatchJobData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "90%"
        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            backgroundColor: theme.palette.backgroundGrey.main,
            overflow: "auto"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {},
        refreshButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        }
    })
);

const ApplicantJobMatchAI = (props: any) => {
    const classes = useStyles();
    const [tLocal, i18n, readyTranslation] = useTranslation(
        "APPLICANTVIEWJOBMATCH",
        {
            useSuspense: false
        }
    );

    const [data] = useApplicantViewContext();
    const { applicantData } = data;

    const { t } = data;
    const ready = readyTranslation;
    const jobsWithMatch = (applicantData.Jobs || []).filter(
        (itemJM: Compleo.IObject) => {
            const jobMatch = applicantData?.JobMatch?.filter(
                (item: Compleo.IObject) => itemJM.JobId === item.jobId
            );
            return (jobMatch || []).length > 0;
        }
    );
    const hasJobs = jobsWithMatch.length > 0;

    if (ready) {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {t("a_StepForm_applicantJobMatch")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    {jobsWithMatch.map((jobData: Compleo.IObject) => {
                        return (
                            <ApplicantMatchJobData
                                jobData={jobData}
                                key={jobData?.pk}
                                tLocal={tLocal}
                                language={i18n.language}
                            />
                        );
                    })}
                    {!hasJobs && (
                        <Box p={2}>
                            <Alert severity="info">
                                {tLocal("noJobsWithMatch")}
                            </Alert>
                        </Box>
                    )}
                </Box>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default ApplicantJobMatchAI;
