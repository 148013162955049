import {
    Avatar,
    Box,
    Grid,
    Icon,
    Paper,
    Tooltip,
    Typography
} from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {
    getIconByHistoryType,
    IApplicantHistoryProps,
    getColorByHistoryType,
    getSizeByHistoryType
} from "./ApplicantHistoryHelper";
import ApplicantHistoryChangeStage from "./Items/ApplicantHistoryChangeStage";
import ApplicantHistoryChangeStageAssessmentResult from "./Items/ApplicantHistoryChangeStageAssessmentResult";
import ApplicantHistoryComment from "./Items/ApplicantHistoryComment";
import ApplicantHistoryAddToJob from "./Items/ApplicantHistoryAddToJob";
import ApplicantHistoryAppliedToJob from "./Items/ApplicantHistoryAppliedToJob";
import ApplicantHistoryRemoveFromJob from "./Items/ApplicantHistoryRemoveFromJob";
import ApplicantHistoryAddTagsAutomation from "./Items/ApplicantHistoryAddTagsAutomation";
import ApplicantHistorySendEmailAutomation from "./Items/ApplicantHistorySendEmailAutomation";
import ApplicantHistorySendEmailAutomationLater from "./Items/ApplicantHistorySendEmailAutomationLater";
import ApplicantHistorySendEmailToUsersApplicantContext from "./Items/ApplicantHistorySendEmailToUsersApplicantContext";
import ApplicantHistorySendEmailToUsersApplicantContextLater from "./Items/ApplicantHistorySendEmailToUsersApplicantContextLater";
import ApplicantHistoryRequestEvaluationAutomation from "./Items/ApplicantHistoryRequestEvaluationAutomation";
import ApplicantHistoryRequestEvaluation from "./Items/ApplicantHistoryRequestEvaluation";
import ApplicantHistoryBulkEmail from "./Items/ApplicantHistoryBulkEmail";
import { TimelineOppositeContent } from "@material-ui/lab";
import { transformDBData } from "functions/util";
import useApplicantViewContext from "../useApplicantViewContext";
import ApplicantHistoryTemplate from "./Items/ApplicantHistoryTemplate";
import ApplicantHistoryOpinionOnTheApplicant from "./Items/ApplicantHistoryOpinionOnTheApplicant";
import ApplicantHistorySendAssessment from "./Items/ApplicantHistorySendAssessment";
import ApplicantHistoryJobMatch from "./Items/ApplicantHistoryJobMatch";
import { useTranslation } from "react-i18next";
import ApplicantHistoryChangeJobMatchResult from "./Items/ApplicantHistoryChangeJobMatchResult";

const useStyles = (dotColor: string, heightRoot: number) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: { margin: theme.spacing(2) },
            paper: { padding: theme.spacing(2) },
            timeDot: {
                borderColor: dotColor,
                backgroundColor: dotColor
            },
            icon: {
                color: "white"
            },
            content: { height: heightRoot }
        })
    );
};

const ApplicantHistoryTimelineItem = (props: IApplicantHistoryProps) => {
    const { historyItem } = props;
    const color = getColorByHistoryType(historyItem.type);
    const [tLocal, i18n, readyTranslation] = useTranslation(
        "APPLICANTVIEWHISTORY",
        {
            useSuspense: false
        }
    );
    const [data] = useApplicantViewContext();

    const { t, language } = data;
    const heightItem = getSizeByHistoryType(historyItem?.type);
    const classes = useStyles(color, heightItem)();
    const toolTipText: string = tLocal(
        `ApplicantHistoryType_${historyItem.type}`,
        ""
    );
    return (
        <TimelineItem className={classes.root}>
            <TimelineOppositeContent style={{ flex: 0.2 }}>
                {transformDBData({
                    value: historyItem.operationDate || historyItem.createdAt,
                    t,
                    language,
                    replaceOption: "-",
                    customDateFormat: "dateRelative"
                })}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <Tooltip title={toolTipText}>
                    <TimelineDot variant="default" className={classes.timeDot}>
                        <Icon className={classes.icon}>
                            {getIconByHistoryType(historyItem.type)}
                        </Icon>
                    </TimelineDot>
                </Tooltip>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <ApplicantHistorySwitch {...props} />
            </TimelineContent>
        </TimelineItem>
    );
};

export const ApplicantHistorySwitch = (props: IApplicantHistoryProps) => {
    const { historyItem } = props;
    const type: CompleoShared.Common.ApplicantHistoryType = historyItem.type;
    // "addToJob", "changeJobStage", "addTagsAutomation"
    switch (type) {
        case "changeJobStage":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryChangeStage {...props} />
                </ApplicantHistoryTemplate>
            );
        case "addToJob":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryAddToJob {...props} />
                </ApplicantHistoryTemplate>
            );
        case "appliedForTheJob":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryAppliedToJob {...props} />
                </ApplicantHistoryTemplate>
            );
        case "addComment":
        case "phoneCall":
        case "interview":
        case "textMessage":
            return <ApplicantHistoryComment {...props} />;
        case "removedFromJob":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryRemoveFromJob {...props} />
                </ApplicantHistoryTemplate>
            );
        case "addTagsAutomation":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryAddTagsAutomation {...props} />
                </ApplicantHistoryTemplate>
            );
        case "sendEmailAutomation": {
            return <ApplicantHistorySendEmailAutomation {...props} />;
        }
        case "sendEmailToUsersAutomationApplicantContext":
            return (
                <ApplicantHistorySendEmailToUsersApplicantContext {...props} />
            );
        case "sendEmailAutomation_later": {
            return <ApplicantHistorySendEmailAutomationLater {...props} />;
        }
        case "sendEmailToUsersAutomationApplicantContext_later": {
            return (
                <ApplicantHistorySendEmailToUsersApplicantContextLater
                    {...props}
                />
            );
        }
        case "sendEmailBulk":
            return <ApplicantHistoryBulkEmail {...props} />;
        case "requestEvaluationAutomation":
            return <ApplicantHistoryRequestEvaluationAutomation {...props} />;
        case "requestEvaluation":
            return <ApplicantHistoryRequestEvaluation {...props} />;
        case "newOrUpdateApplicantOpinionJob":
        case "newOrUpdateApplicantOpinion":
            return <ApplicantHistoryOpinionOnTheApplicant {...props} />;
        case "changeJobStageAssessmentResult":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryChangeStageAssessmentResult {...props} />
                </ApplicantHistoryTemplate>
            );
        case "sendAssessment":
        case "sendAssessmentAutomation":
        case "sendAssessmentAutomation_emailAgain":
        case "sendAssessment_emailAgain":
        case "sendAssessmentAutomation_failedApplicantAssessmentCompleted":
        case "sendAssessment_failedApplicantAssessmentCompleted":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistorySendAssessment
                        {...props}
                        historyType={type}
                    />
                </ApplicantHistoryTemplate>
            );
        case "jobApplicantMatch":
        case "jobApplicantMatchAutomation":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryJobMatch {...props} />
                </ApplicantHistoryTemplate>
            );
        case "changeJobApplicantMatchResult":
            return (
                <ApplicantHistoryTemplate {...props} type={type}>
                    <ApplicantHistoryChangeJobMatchResult {...props} />
                </ApplicantHistoryTemplate>
            );

        default:
            return null;
    }
};

export default ApplicantHistoryTimelineItem;
