import React from "react";
import useList from "../..";
import { IListBulk, getT } from "./helperBulkActions";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { useGetMetadataMerge } from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import useJobContext from "Pages/Job/JobView/useJobContext";
import useRefreshKanban from "Pages/Job/JobView/JobKanban/useRefreshKanban";
import { ListDefinitionType } from "Pages/Users/components/useCustomLists";
import isEqual from "lodash/isEqual";
import { useCompanyAIFeatureStatus } from "Pages/ai/CompanyAICreditStatus/useCompanyAIStatus";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    otherGenericParamsListQuery?: Compleo.IObject;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkActionsStep3ChangeStage = (props: IProps) => {
    const queryClient = useQueryClient();
    const { company } = useAuthState();
    const [data] = useJobContext();
    const {
        formikRef,
        listBulk,
        setStepBulk,
        otherGenericParamsListQuery,
        setLoadingButtons
    } = props;
    const [listData] = useList();
    const { t } = listData.tempInfo;
    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKJOBAIMATCHMODAL";
    let defaultFieldsToHide: string[] = [];
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);
    useCheckCreditsTotalApplicants({
        listBulk: listBulk,
        t: tMerge,
        setStepBulk: setStepBulk,
        ready: readyTranslation
    });

    const [initialValues] = useValuesFromSource(metadata, true, {
        jobId: `JOB:${data.JobId}`
    });
    const updateAllInfo = useRefreshKanban();

    const ready = readyTranslation && metadata.status === "success";
    const currentStageId = otherGenericParamsListQuery?.filterStageId;

    const allStages = (data?.LocalStateData?.allStages || [])
        .filter((item) => item.id !== currentStageId)
        .map((item) => ({
            value: item.id,
            label: item.name
        }));

    const lists: ListDefinitionType[] = [
        {
            fieldName: "resultsToStageRule.targetStage",
            data: allStages,
            labelName: "label",
            valueName: "value"
        }
    ];

    const handleSubmit = async (values: any) => {
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);

        const dataPost: Compleo.IObject = {
            ...values,
            companyId: company.companyId,
            ids: ids,
            job: { value: `JOB:${data.JobId}` },
            name: values.name,
            oldStageId: currentStageId,
            type: "applicantjob_aiMatching"
        };
        // console.log("dataPost", JSON.stringify(dataPost, null, 4));
        // const retorno: Compleo.IObject = {};
        // retorno.status = 200;
        // return retorno;

        try {
            const dataAPI = await apiDirectCall(
                "/bulkactions/baadd",
                "post",
                dataPost
            );
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        await updateAllInfo();
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        setLoadingButtons(false);

        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;

    const resultsToStageRuleField = watch("resultsToStageRule");
    React.useEffect(() => {
        if ((resultsToStageRuleField || []).length > 0) {
            let fieldsToHideFN = defaultFieldsToHide;
            for (let i = 0; i <= resultsToStageRuleField.length; i++) {
                const fieldData = resultsToStageRuleField[i];
                if (fieldData) {
                    const newStageDetails = (
                        data?.LocalStateData?.allStages || []
                    ).find((item) => item.id === fieldData?.targetStage?.value);
                    if (newStageDetails?.type !== "rejected") {
                        const fieldToHide = `resultsToStageRule.${i}.disqualifyReason`;
                        fieldsToHideFN = [...fieldsToHideFN, fieldToHide];
                    }
                }
            }
            if (!isEqual(fieldsToHide, fieldsToHideFN)) {
                setFieldsToHide(fieldsToHideFN);
            }
        }
    }, [JSON.stringify(resultsToStageRuleField || [])]);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formRef: formikRef,
        reactHookFormMethods: reactHookFormMethods,
        customLists: lists,
        fieldsToHide: fieldsToHide
    });

    let FormReturn: JSX.Element = <Loading />;

    if (ready && finished) {
        FormReturn = formCompleo;
    }

    return FormReturn;
};

export default BulkActionsStep3ChangeStage;

interface IUseCheckCreditsTotalApplicants {
    listBulk: IListBulk[];
    t: any;
    ready: boolean;
    setStepBulk: (value: React.SetStateAction<number>) => void;
}
function useCheckCreditsTotalApplicants(
    props: IUseCheckCreditsTotalApplicants
) {
    const { listBulk, t, setStepBulk, ready } = props;
    const total = listBulk.filter((item) => item.checked).length;
    const {
        canUse,
        currentCredits,
        totalCreditsRequired
    } = useCompanyAIFeatureStatus("applicantJobMatch", total);
    const { callDialog } = useGlobalDialog();

    React.useEffect(() => {
        if (!canUse && ready) {
            callDialog({
                title: t("messageCreditsBulkTitle"),
                bodyText: t("messageCreditsBulk", {
                    totalCredits: totalCreditsRequired,
                    availableCredits: currentCredits,
                    totalApplicants: total
                }),
                agreeButtonText: t("COMPLEOGENERAL_PREVIOUS"),
                agreeFunction: async () => {
                    setStepBulk(2);
                }
            });
        }
    }, [canUse, ready]);
}
