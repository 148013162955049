import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import React from "react";

// CV Parsing will be free for all users
const featuresToRemoveFromList: CompleoShared.AI.compleoFeaturesAI[] = [
    "cvParsing"
];
interface IProps {
    t: any;
    compleoFeaturesAI: CompleoShared.AI.compleoFeaturesAI[];
}
const MainSettings = (props: IProps & IInputProps) => {
    const { t, compleoFeaturesAI } = props;
    const filteredList = compleoFeaturesAI.filter(
        (feature) => !featuresToRemoveFromList.includes(feature)
    );

    return (
        <Box display={"flex"} flexDirection={"column"} mt={4}>
            {filteredList.map((feature) => {
                return (
                    <MainSettingsModelConfig
                        key={feature}
                        {...props}
                        feature={feature}
                        t={t}
                    />
                );
            })}
        </Box>
    );
};

interface MainSettingsModelConfigProps extends IProps {
    feature: CompleoShared.AI.compleoFeaturesAI;
}
const MainSettingsModelConfig = (props: MainSettingsModelConfigProps) => {
    const { feature, t } = props;
    const localFieldName = `mainSettings.${feature}`;
    const { watch, setFieldValue } = useRhfFieldWithoutControllerOrRegister(
        localFieldName
    );
    const value = watch(localFieldName);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue((event.target as HTMLInputElement).value);
    };
    if (value === undefined) {
        return null;
    }
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Typography style={{ marginBottom: 8, fontWeight: 700 }}>
                {t(`aiFeatures_${feature}`)}
            </Typography>
            <FormControl component="fieldset" fullWidth required>
                <FormLabel component="legend">{t("chooseAModel")}</FormLabel>
                <RadioGroup
                    aria-label="Features"
                    name={localFieldName}
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="disabled"
                        control={<Radio />}
                        label={t("COMPLEOGENERAL_disabled")}
                    />
                    <FormControlLabel
                        value="enabled"
                        control={<Radio />}
                        label={t("COMPLEOGENERAL_enabled")}
                    />
                </RadioGroup>
            </FormControl>
            <Divider style={{ marginBottom: 16 }} />
        </Box>
    );
};

export default MainSettings;
