import AnswerToResultCustomField from "./AnswerToResultCustomField";

const CustomComponents = [
    {
        name: "answers.answerToResults",
        component: AnswerToResultCustomField
    }
];

export default CustomComponents;
