import ContextQuestionsFieldsData from "./components/ContextQuestionsFieldsData";
import { DropResult } from "react-beautiful-dnd";
import { useContext } from "react";

/**
 * Hooks that returns onDragEnd function (React beautiful dnd)
 * @return onDragEnd function
 */
export const useGetOnDragEnd = () => {
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const sectionsData: CompleoShared.Assessments.ISection[] =
        ContextQuestionFieldsData?.sectionsData || [];
    const questionsData: CompleoShared.Assessments.IQuestion[] =
        ContextQuestionFieldsData?.questionsData || [];
    const { setFieldValueSections, setFieldValueQuestions } =
        ContextQuestionFieldsData || {};

    /**
     * Handle drag and drop
     * @param {DropResult} result - the drop result from react beatiful dnd
     */
    const onDragEnd = (result: DropResult) => {
        console.log("result:", JSON.stringify(result, null, 4));
        const { destination, source, draggableId, type } = result;
        switch (type) {
            case "sections":
                sectionOnDragEnd(result);
                break;
            case "questions":
                questionOnDragEnd(result);
                break;
            case "answers":
                answersOnDragEnd(result);
                break;
            default:
                break;
        }
    };

    const sectionOnDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            const currentItem = sectionsData.find(
                (item) => item.id === draggableId
            );
            if (currentItem) {
                sectionsData.splice(source.index, 1);
                sectionsData.splice(destination.index, 0, currentItem);
                const reorderedSections = sectionsData.map(
                    (
                        item: CompleoShared.Assessments.ISection,
                        index: number
                    ) => {
                        return {
                            ...item,
                            order: index
                        };
                    }
                );
                if (setFieldValueSections) {
                    setFieldValueSections(reorderedSections);
                }
            }
        }
    };

    const questionOnDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (
                destination.index === source.index &&
                destination.droppableId === source.droppableId
            ) {
                return;
            }
            const fields = [...questionsData];
            const fieldsSource = fields.filter(
                (item) => item.sectionId === source.droppableId
            );
            const fieldsDestination = fields.filter(
                (item) => item.sectionId === destination.droppableId
            );
            const item = fields.filter((i) => i.id === draggableId)[0];

            const newFields = fields.filter(
                (item) =>
                    item.sectionId !== source.droppableId &&
                    item.sectionId !== destination.droppableId
            );

            fieldsSource.splice(source.index, 1);
            if (destination.droppableId !== source.droppableId) {
                fieldsDestination.splice(destination.index, 0, item);
                fieldsDestination.map((field) =>
                    newFields.push({
                        ...field,
                        sectionId: destination.droppableId
                    })
                );
            } else {
                fieldsSource.splice(destination.index, 0, item);
            }
            fieldsSource.map((field) => newFields.push(field));
            const reorderedFields = newFields.map(
                (item: any, index: number) => {
                    return { ...item, order: index + 1 };
                }
            );
            if (setFieldValueQuestions) {
                setFieldValueQuestions(reorderedFields);
            }
        }
    };

    const answersOnDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;
        if (destination) {
            if (
                (destination.index === source.index &&
                    destination.droppableId === source.droppableId) ||
                destination.droppableId !== source.droppableId
            ) {
                return;
            }
            const fields = [...questionsData];
            const fieldsSource = fields.filter(
                (item) => item.id === source.droppableId
            );
            const currentAnswer = fieldsSource[0].answers[source.index];

            fieldsSource[0].answers.splice(source.index, 1);
            fieldsSource[0].answers.splice(destination.index, 0, currentAnswer);
            if (setFieldValueQuestions) {
                setFieldValueQuestions(fields);
            }
        }
    };

    return onDragEnd;
};
