import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useApi, useApiCache } from "customHooks/useApi";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useAuthState } from "_ReactContext/AuthContext";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCustomLists from "./components/useCustomLists";
import useHandleInitialValuesJobRequestNew from "./components/useHandleInitialValuesJobRequestNew";
import CustomComponents from "./components/CustomComponents";
import { requestFormikReturnFN } from "./components/helper";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";

const JobRequestNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/job/listrequest");
    const [t, i18n, readyTranslation] = useTranslation("JOBREQUESTNEW", {
        useSuspense: false
    });
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);

    const { company } = useAuthState();

    const jobInitialValuesPost = "/job/jobinitialvalues";
    const [jobInitialValuesData] = useApiCache(
        jobInitialValuesPost,
        "post",
        {
            companyId: company.companyId,
            cloneFromJobId: props.id,
            isJobRequest: true
        },
        false
    );
    const jobInitialValuesDBData = jobInitialValuesData.response?.data;

    const [listDefinition, readyLists] = useCustomLists(
        jobInitialValuesDBData?.lists,
        t
    );

    const postAddress = `/jobrequest/newrequest`;
    const [addJobReturn, addJob] = useApi(postAddress, "post");

    const [metadata] = useGetMetadata("JOBREQUESTNEW");
    const [valuesFromSource] = useValuesFromSource(metadata, true);
    valuesFromSource.scorecard = {};
    valuesFromSource.visibility = {
        "label-en-US": "Private",
        value: "CP2",
        "label-pt-BR": "Privada",
        label: "Privada"
    };

    const {
        initialValues,
        ready: readyInitialValues
    } = useHandleInitialValuesJobRequestNew(
        valuesFromSource,
        jobInitialValuesDBData || {},
        listDefinition,
        readyLists
    );
    const finalValues: Compleo.IObject = {
        ...initialValues,
        ...(jobInitialValuesDBData?.cloneData || {}),
        openingDate: initialValues.openingDate
    };

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: finalValues
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const openingReason = watch("openingReason");

    React.useEffect(() => {
        requestFormikReturnFN(setValue, watch, fieldsToHide, setFieldsToHide);
    }, [openingReason, fieldsToHide]);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        postMethod: addJob,
        postReturn: addJobReturn,
        redirectAddress: "/job/listrequest",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: finalValues,
        customLists: listDefinition,
        CustomComponents: CustomComponents,
        fieldsToHide: fieldsToHide,
        reactHookFormMethods: reactHookFormMethods,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const ready = readyLists && finished && readyTranslation; //&& addPropsAndFormMenu.ready;

    if (ready) {
        compRetorno = formCompleo;
        return (
            <>
                <Page
                    featureName={t("a_FeatureName")}
                    featureMainDescription={t("a_FeatureMainDescription")}
                    maxWidth="lg"
                >
                    {compRetorno}
                </Page>
            </>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default JobRequestNew;
