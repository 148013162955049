import { useModalAssessmentGroupList } from "Pages/Job/JobView/JobAssessments/AssessmentsGroupsList/helper";
import useJobContext from "Pages/Job/JobView/useJobContext";
const listAssessmentFieldName = "assessment";

type useHandleAssessmentsListsParams = {
    applicantId: string;
    t: any;
};
export function useHandleAssessmentsLists(
    params: useHandleAssessmentsListsParams
): {
    lists: Compleo.CustomLists.ListDefinitionType[];
    ready: boolean;
    noAssessmentsToSent: boolean;
} {
    const { applicantId, t } = params;
    const [data] = useJobContext();

    const [lists, readyLists] = useModalAssessmentGroupList({
        jobId: data.JobId,
        onlyWithTests: true,
        applicantId: applicantId
    });
    const assessmentList = lists.find(
        (i) => i.fieldName === listAssessmentFieldName
    );
    const ready = readyLists;

    const newLists = lists.filter(
        (i) => i.fieldName !== listAssessmentFieldName
    );
    console.log("assessmentList.data", assessmentList?.data);

    if (assessmentList) {
        assessmentList.data = assessmentList.data
            .map(
                (
                    i: CompleoShared.Assessments.IAssessmentApplicantSentStatus
                ) => {
                    if (i.statusApplicant === "alreadySent") {
                        return {
                            assessmentId: i.assessmentId,
                            name: `${i.name} (${t("alreadySent")})`,
                            assessmentName: i.name
                        };
                    } else if (i.statusApplicant === "notSent") {
                        return {
                            assessmentId: i.assessmentId,
                            name: i.name,
                            assessmentName: i.name
                        };
                    } else {
                        return {
                            assessmentId: i.assessmentId,
                            name: "",
                            assessmentName: i.name
                        };
                    }
                }
            )
            .filter((i) => i.name);
        newLists.push(assessmentList);

        return {
            lists: newLists,
            ready: ready,
            noAssessmentsToSent: assessmentList.data?.length === 0
        };
    } else {
        return { lists: newLists, ready: ready, noAssessmentsToSent: true };
    }
}
