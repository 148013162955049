interface IGetTimeFixedDateParam {
    hours?: number;
    minutes?: number;
    seconds?: number;
    useUTC?: boolean;
}

export const getTimeFixedDate = (params?: IGetTimeFixedDateParam) => {
    const { hours = 0, minutes = 0, seconds = 0, useUTC = false } =
        params || {};
    const currentDate = new Date();
    if (!useUTC) {
        currentDate.setHours(hours);
        currentDate.setMinutes(minutes);
        currentDate.setSeconds(seconds);
    } else {
        currentDate.setUTCHours(hours);
        currentDate.setUTCMinutes(minutes);
        currentDate.setUTCSeconds(seconds);
    }
    return currentDate;
};
