import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { ApiStateType, useApi } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useAuthState } from "_ReactContext/AuthContext";

interface IJobBoardsModalProps {
    open: boolean;
    handleModalClose: any;
    updateData: (values: Compleo.IObject) => Promise<void>;
    initialValues?: Compleo.IObject;
    location: Compleo.IObject;
}

const JobBoardsModal = (props: IJobBoardsModalProps) => {
    const {
        open,
        handleModalClose,
        updateData,
        initialValues = {},
        location
    } = props;
    const fieldsToHideLinkedIn = useVerifyLinkedInJobBoardFieldsToHide();

    const locationDefined =
        location?.city?.label &&
        location?.provinceOrState?.label &&
        (location?.country?.iso2 ||
            location?.country?.label.includes("Brasil") ||
            location?.country?.label.includes("Brazil"));

    const [t, i18n, readyTranslation] = useTranslation("JOBBOARDSMODULE", {
        useSuspense: false
    });
    const [metadata] = useGetMetadata("JOBBOARDSMODULE");
    const [valuesFromSource] = useValuesFromSource(metadata, false, {
        ...initialValues
    });

    const handlePost = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };
        await updateData(values);
        handleModalClose();
        return retorno;
    };

    const handlePostReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: handlePost,
        postReturn: handlePostReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource,
        secondaryButtonFunction: handleModalClose,
        formGroupPaperElevation: 0,
        fieldsToHide: fieldsToHideLinkedIn
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const readyAll = finished && readyTranslation;

    if (readyAll) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            open={open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("a_ModalTitle")}
            aria-describedby={t("a_ModalTitle")}
            // PaperProps={{
            //     className: classes.dialog
            // }}
            scroll="body"
        >
            <DialogTitle id="alert-dialog-title">
                {t("a_ModalTitle")}
            </DialogTitle>
            <DialogContent>
                {!locationDefined && (
                    <Alert severity="warning">
                        <AlertTitle>
                            {t("alertJobBoardLocationErrorTitle")}
                        </AlertTitle>
                        {t("alertJobBoardLocationErrorMessage")}
                    </Alert>
                )}
                {compRetorno}
            </DialogContent>
        </Dialog>
    );
};

export const useVerifyLinkedInJobBoardFieldsToHide = () => {
    const { company } = useAuthState();

    if (company?.jobBoardLinkedInCompanyPage) {
        return [];
    } else {
        return ["linkedinEnabled", "linkedinCategory"];
    }
};

export default JobBoardsModal;
