import { FormGroup, Grid, Switch } from "@material-ui/core";
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import { getSortLabelText } from "customHooks/useList/ListHelpers/SortMenuSelect";
import useJobContext from "Pages/Job/JobView/useJobContext";
import { IJobViewStages } from "Pages/Job/JobView/useJobContext/JobViewTypes";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { priorityOrderName } from "./helperSortColumn";

const getItems = (sortDef: any, localT: any, isLocationAvailable: boolean) => {
    const items: {
        name: string;
        id: string;
        type: "asc" | "desc";
    }[] = Object.keys(sortDef)
        .filter((item) => item !== priorityOrderName)
        .map((item) => {
            return { name: localT(item), id: item, type: "asc" };
        });

    Object.keys(sortDef)
        .filter((item) => item !== priorityOrderName)
        .map((item) => {
            items.push({ name: localT(item), id: item, type: "desc" });
        });

    items.sort((a, b) => a.name.localeCompare(b.name));
    items.splice(0, 0, {
        name: localT(priorityOrderName),
        id: priorityOrderName,
        type: "asc"
    });

    if (!isLocationAvailable) {
        const itemsWithoutDistance = items.filter((item) => {
            const defItem = sortDef[item.id];
            if (defItem?.type === "distance") {
                return false;
            }
            return true;
        });
        return itemsWithoutDistance;
    } else {
        return items;
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        },
        captionFilters: {
            fontWeight: 600
        },
        captionNoDataFound: {
            color: theme.palette.secondary.main
        },
        switchPriority: {
            marginBottom: theme.spacing(2)
        }
    })
);

interface IProps {
    handleClose: any;
    stageData: IJobViewStages;
    localValue: {
        name: string;
        sortType: "asc" | "desc";
    };
    setLocalValue: React.Dispatch<
        React.SetStateAction<{
            name: string;
            sortType: "asc" | "desc";
        }>
    >;
    replacePriority: boolean;
    setReplacePriority: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadFilterModalList = (props: IProps) => {
    const classes = useStyles();
    const [data, dispatchJobContext] = useJobContext();

    const { t } = data;
    const moduleName = "JOBAPPLICANTLISTFILTERKANBAN";
    const [localT, i18n, readyTranslation] = useTranslation(
        [moduleName, "ELASTICLISTSETTINGS"],
        {
            useSuspense: false
        }
    );
    const [metadata] = useGetMetadata(moduleName);
    const {
        localValue,
        setLocalValue,
        replacePriority,
        setReplacePriority
    } = props;
    const isLocationAvailableFromJob =
        data.fullFilter?.geoLocation?.latitude !== undefined &&
        data.fullFilter?.geoLocation?.longitude !== undefined;

    const sortDef =
        metadata.response.data?.ElasticSearchListDefinition?.sortOptions
            ?.fields || {};
    const items = getItems(sortDef, localT, isLocationAvailableFromJob);

    const value = localValue || { name: priorityOrderName, sortType: "asc" };
    const handleChange = (event: any) => {
        const data = JSON.parse((event.target as HTMLInputElement).value);
        setLocalValue({ name: data.name, sortType: data.sortType });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormGroup>
                    {!data.readonly && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={replacePriority}
                                    onChange={() => {
                                        setReplacePriority(!replacePriority);
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={t("ModalKanbanSort_LabelReplaceSort")}
                            className={classes.switchPriority}
                        />
                    )}
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            {t("ModalKanbanSort_PriorityField")}
                        </FormLabel>
                        <RadioGroup
                            aria-label="Ordenação"
                            value={JSON.stringify(value)}
                            onChange={handleChange}
                        >
                            {items.map((item, index) => (
                                <FormControlLabel
                                    key={`${item.id}${item.type}`}
                                    value={JSON.stringify({
                                        name: item.id,
                                        sortType: item.type
                                    })}
                                    control={<Radio />}
                                    label={getSortLabelText(
                                        item.id,
                                        localT,
                                        sortDef[item.id],
                                        item.type
                                    )}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default LoadFilterModalList;
