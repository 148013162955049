import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import useApplicantViewContext from "../useApplicantViewContext";
import { useApiCache } from "customHooks/useApi";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Collapse,
    ListItemIcon,
    ListItemSecondaryAction,
    Paper
} from "@material-ui/core";
import _ from "lodash";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { getFirstNWordsFromText, transformDBData } from "functions/util";
import useApplicantQuestionnairesHelper, {
    IQuestionnaireAnswer,
    IQuestionnaireJobs,
    IQuestionnaires
} from "./useApplicantQuestionnairesHelper";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { FieldFile } from "customHooks/useCompleoView/Fields/FieldsType";
import ModalSaveEmailAttachments from "../ApplicantEmail/ModalSaveEmailAttachments";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        nested2: {
            paddingLeft: theme.spacing(8)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);

function createMarkup(value: string) {
    return { __html: value };
}

interface IProps {
    t: any;
    language: string;
}
const ApplicantQuestionnairesList = (props: IProps) => {
    const { t, language } = props;
    const questionData = useApplicantQuestionnairesHelper();

    return <JobList data={questionData} t={t} language={language} />;
};

interface IJobList {
    data: IQuestionnaireJobs[];
    t: any;
    language: string;
}

const JobList = (props: IJobList) => {
    const { data, t, language } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paperContainer}>
            <List className={classes.root}>
                {data.map((jobData) => {
                    return (
                        <React.Fragment key={jobData.jobId}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Typography variant="body1">
                                            <strong style={{ marginRight: 8 }}>
                                                {t("job")}:
                                            </strong>
                                            {jobData.jobTitle}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <QuestionnaireList
                                data={jobData.Questionnaires}
                                t={t}
                                language={language}
                            />
                        </React.Fragment>
                    );
                })}
            </List>
        </Paper>
    );
};

interface IQuestionnaireList {
    data: IQuestionnaires[];
    t: any;
    language: string;
}

const QuestionnaireList = (props: IQuestionnaireList) => {
    const { data, t, language } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            {data.map((qData) => {
                return (
                    <React.Fragment key={qData.QuestionnaireId}>
                        <ListItem className={classes.nested}>
                            <ListItemText
                                primary={
                                    <Typography variant="body2">
                                        <strong>
                                            {t(
                                                qData.QuestionnaireName.toLowerCase(),
                                                qData.QuestionnaireName
                                            )}
                                        </strong>
                                    </Typography>
                                }
                                secondary={
                                    <>
                                        <Typography variant="body2">
                                            <strong>{t("startDate")}</strong>:{" "}
                                            {transformDBData({
                                                value: qData.startDate,
                                                t: t,
                                                language: language,
                                                customDateFormat: "dateWithTime"
                                            })}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>{t("endDate")}</strong>:{" "}
                                            {transformDBData({
                                                value: qData.endDate,
                                                t: t,
                                                language: language,
                                                customDateFormat: "dateWithTime"
                                            })}
                                        </Typography>
                                        {qData.result && (
                                            <Typography variant="body2">
                                                <strong>{t("result")}</strong>:{" "}
                                                {transformDBData({
                                                    value: qData.result,
                                                    t: t,
                                                    language: language
                                                })}
                                            </Typography>
                                        )}
                                        {qData.enableRanking && (
                                            <Typography variant="body2">
                                                <strong>{t("ranking")}</strong>:{" "}
                                                {transformDBData({
                                                    value: qData.ranking,
                                                    t: t,
                                                    language: language
                                                })}{" "}
                                                %
                                            </Typography>
                                        )}
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>
                                <ButtonIconTooltipCompleo
                                    label={open ? t("seeLess") : t("seeMore")}
                                    onClick={() => handleClick()}
                                >
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ButtonIconTooltipCompleo>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <AnswerList
                                data={qData.Answers}
                                t={t}
                                language={language}
                            />
                        </Collapse>
                    </React.Fragment>
                );
            })}
        </>
    );
};

interface IAnswerList {
    data: IQuestionnaireAnswer[];
    t: any;
    language: string;
}

const AnswerList = (props: IAnswerList) => {
    const { data, t, language } = props;
    const [
        modalSaveAttachmentsOpen,
        setModalSaveAttachmentsOpen
    ] = React.useState(false);
    const classes = useStyles();
    const [dataApplicant] = useApplicantViewContext();

    return (
        <>
            {data.map((answerData) => {
                const fileTypes: string[] = [
                    "CPQUESTIONSINGLEFILE",
                    "CPQUESTIONMULTIPLEFILES"
                ];
                let fileData: Compleo.IObject = {};
                const isFile = fileTypes.includes(
                    answerData?.question?.questiontype?.value
                );
                if (isFile) {
                    fileData.file = answerData.answer.map((a) => a.value);
                }

                return (
                    <React.Fragment key={answerData.question.id}>
                        <ListItem className={classes.nested2}>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                        >
                                            <strong>
                                                {answerData.question.name}
                                            </strong>
                                        </Typography>
                                        {!dataApplicant.readOnly && isFile && (
                                            <ButtonIconTooltipCompleo
                                                label={t("saveAttachments")}
                                                color="secondary"
                                                onClick={() =>
                                                    setModalSaveAttachmentsOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                <SaveIcon />
                                            </ButtonIconTooltipCompleo>
                                        )}
                                    </>
                                }
                                secondary={
                                    !isFile ? (
                                        <Typography variant="body2">
                                            {(answerData.answer || []).map(
                                                (answer, index) => {
                                                    const isLast =
                                                        (
                                                            answerData.answer ||
                                                            []
                                                        ).length -
                                                            1 ===
                                                        index;
                                                    const suffix = isLast
                                                        ? ""
                                                        : " | ";

                                                    return `${answer?.value?.stringValue}${suffix}`;
                                                }
                                            )}
                                        </Typography>
                                    ) : (
                                        <>
                                            <FieldFile
                                                fieldData={fileData}
                                                fieldName="file"
                                                t={t}
                                                language={language}
                                                fieldDef={{}}
                                                propsToAdd={{}}
                                                disableSearch
                                            />
                                            {modalSaveAttachmentsOpen && (
                                                <ModalSaveEmailAttachments
                                                    open={
                                                        modalSaveAttachmentsOpen
                                                    }
                                                    handleClose={() =>
                                                        setModalSaveAttachmentsOpen(
                                                            false
                                                        )
                                                    }
                                                    files={fileData.file}
                                                />
                                            )}
                                        </>
                                    )
                                }
                            />
                        </ListItem>
                        {!isFile && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ApplicantQuestionnairesList;
