import { apiDirectCall } from "customHooks/useApi/api";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import { navigate } from "@gatsbyjs/reach-router";

const useNextPageFunctionsNewJob = (companyId: number, t: any) => {
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const verifyExternalCode = async (nextPageFunction: any, values: any) => {
        if (!values.externalCode) {
            nextPageFunction();
        } else {
            const params = {
                fieldValueToValidate: values.externalCode,
                companyId,
                validationType: "jobExternalCodeExists"
            };
            const url = "/general/genericvalidationlogged";
            const validation = await apiDirectCall(url, "post", params);
            const valid = validation.data.validationResult;

            if (!valid) {
                callDialog({
                    title: t("externalCodeExists_ModalTitle"),
                    bodyText: t("externalCodeExists_Message"),
                    agreeButtonText: t("externalCodeExists_Yes"),
                    disagreeButtonText: t("externalCodeExists_No"),
                    agreeFunction: async () => {
                        const searchJobUrl = "/job/search";
                        const jobData = await apiDirectCall(
                            searchJobUrl,
                            "post",
                            {
                                externalCode: values.externalCode,
                                companyId: companyId
                            }
                        );

                        if (jobData.data.data.Items.length > 0) {
                            const jobId: string = jobData.data.data.Items[0].pk.split(
                                ":"
                            )[1];
                            navigate(`/job/edit/${jobId}`);
                        }
                    },
                    disagreeFunction: () => {}
                });
            } else {
                nextPageFunction();
            }
        }
    };

    const nextPageFunctions = [{ page: 1, fn: verifyExternalCode }];

    return nextPageFunctions;
};

export default useNextPageFunctionsNewJob;
