import { Avatar, Box, Grid, Icon, Paper, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import useJobContext from "../useJobContext";
import {
    getIconByHistoryType,
    IJobHistoryProps,
    getColorByHistoryType,
    getSizeByHistoryType
} from "./JobHistoryHelper";
import JobHistoryChangeStatus from "./Items/JobHistoryChangeStatus";
import JobHistoryComment from "./Items/JobHistoryComment";
import { TimelineOppositeContent } from "@material-ui/lab";
import { transformDBData } from "functions/util";

const useStyles = (dotColor: string, heightRoot: number) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            root: { margin: theme.spacing(2) },
            paper: { padding: theme.spacing(2) },
            timeDot: {
                borderColor: dotColor,
                backgroundColor: dotColor
            },
            icon: {
                color: "white"
            },
            content: { height: heightRoot }
        })
    );
};

const JobHistoryTimelineItem = (props: IJobHistoryProps) => {
    const { historyItem } = props;
    const color = getColorByHistoryType(historyItem.type);

    const [data] = useJobContext();
    const { t, JobId, language } = data;
    const heightItem = getSizeByHistoryType(
        historyItem?.type,
        historyItem?.comment !== undefined
    );
    const classes = useStyles(color, heightItem)();

    return (
        <TimelineItem className={classes.root}>
            <TimelineOppositeContent style={{ flex: 0.2 }}>
                {transformDBData({
                    value: historyItem.createdAt,
                    t,
                    language,
                    replaceOption: "-",
                    customDateFormat: "dateRelative"
                })}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot variant="default" className={classes.timeDot}>
                    <Icon className={classes.icon}>
                        {getIconByHistoryType(historyItem.type)}
                    </Icon>
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>
                <JobHistorySwitch {...props} />
            </TimelineContent>
        </TimelineItem>
    );
};

const JobHistorySwitch = (props: IJobHistoryProps) => {
    const { historyItem } = props;
    const type: CompleoShared.Common.JobHistoryType = historyItem.type;
    switch (type) {
        case "changeStatus":
            return <JobHistoryChangeStatus {...props} />;
        case "addComment":
            return <JobHistoryComment {...props} />;
        default:
            return null;
    }
};

export default JobHistoryTimelineItem;
