import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { RouteComponentProps } from "@gatsbyjs/reach-router";
import useGetMetadata from "customHooks/useGetMetadata";
import {
    useCompleoSimpleList,
    actionsTableListType
} from "customHooks/useCompleoSimpleList";
import ApplicantViewModalContainer from "Pages/Applicant/ApplicantView/ApplicantViewModalContainer";
import { ApplicantViewModulesType } from "Pages/Applicant/ApplicantView/useApplicantViewContext/ApplicantViewTypes";
import { Box, Button } from "@material-ui/core";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { Alert } from "@material-ui/lab";
import ModalFinishEvaluation, {
    IModalFinishEvaluation
} from "./ModalFinishEvaluation";
import { useQueryClient } from "react-query";

interface IEvaluationStatus {
    applicantsEvaluated: number;
    isFinishedByUser: boolean;
    totalApplicants: number;
}

export interface IEvaluationRequestApplicantListProps
    extends RouteComponentProps {
    companyId: string | number;
    accessType:
        | CompleoShared.EvaluationRequest.IGetUserEvaluationTypeReturn
        | undefined;
    evaluationId: string;
    external: boolean;
}

const EvaluationRequestApplicantList = (
    props: IEvaluationRequestApplicantListProps
) => {
    const { accessType, companyId, evaluationId, external } = props;
    const queryClient = useQueryClient();

    const moduleName = "EVALUATIONREQUESTAPPLICANTLIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const showMessage = useShowMessage();

    const [
        modalFinishEvaluationData,
        setModalFinishEvaluationData
    ] = React.useState<IModalFinishEvaluation>({
        open: false,
        handleClose: () => {},
        t: t,
        finishEvaluation: async () => {}
    });
    const defaultModalStateValue: IModalFinishEvaluation = {
        open: false,
        handleClose: () =>
            setModalFinishEvaluationData({
                ...modalFinishEvaluationData,
                open: false
            }),
        t: t,
        finishEvaluation: async () => {}
    };

    const postAddressSearchEvaluationStatus = `/applicant/getevaluationstatus`;
    const [getEvaluationStatusReturn, getEvaluationStatus] = useApiCache(
        postAddressSearchEvaluationStatus,
        "post",
        { id: evaluationId },
        false
    );
    const evaluationStatus: IEvaluationStatus | undefined =
        getEvaluationStatusReturn.response?.data;

    const [openModalApplicantView, setOpenModalApplicantView] = React.useState<{
        open: boolean;
        applicandId: string;
        evaluationId: string;
        jobId: string;
        jobData: Compleo.IObject;
        openRatingOnStart: boolean;
        cvToShare?: Compleo.IObject;
        customCVDetail?: Compleo.IObject;
        assessmentsTestsToShare: Compleo.IObject[];
    }>({
        open: false,
        applicandId: "",
        jobId: "",
        jobData: {},
        evaluationId: "",
        openRatingOnStart: false,
        assessmentsTestsToShare: []
    });

    const EndEvaluation = () => {
        return (
            <Button
                size="small"
                color="primary"
                variant="outlined"
                // onClick={callDialogFinishEvaluation}
            >
                {t("btnFinishEvaluation")}
            </Button>
        );
    };

    const actionsTable: actionsTableListType = [
        {
            icon: EndEvaluation,
            tooltip: "Add User",
            isFreeAction: true,
            onClick: () => {
                setModalFinishEvaluationData({
                    ...defaultModalStateValue,
                    open: true,
                    isCalledAfterLastApplicant: false
                });
            }
        }
    ];

    const [metadata] = useGetMetadata(moduleName);
    const listAddress = "/applicant/evaluationrequestexternalgetlist";

    const [renderTable, finished, refreshTable] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        {
            id: evaluationId,
            page: 1,
            pageSize: 10,
            orderBy: "createdAt",
            orderDirection: "desc"
        },
        actionsTable,
        undefined,
        {
            evaluateApplicant: async (rowData: any) => {
                const applicantId = rowData.pk.split(":")[1];

                setOpenModalApplicantView({
                    open: true,
                    applicandId: applicantId,
                    jobId: rowData?.otherRequestData?.jobId,
                    jobData: rowData.jobDetail,
                    evaluationId: evaluationId,
                    openRatingOnStart: true,
                    cvToShare: rowData?.otherRequestData?.displayCV
                        ? rowData?.otherRequestData?.cvToShare
                            ? rowData?.otherRequestData?.cvToShare
                            : { value: "cv", label: "" }
                        : undefined,
                    customCVDetail: rowData?.otherRequestData?.customCVDetail,
                    assessmentsTestsToShare:
                        rowData?.otherRequestData?.assessmentsTestsToShare || []
                });
            },
            viewApplicant: async (rowData: any) => {
                const applicantId = rowData.pk.split(":")[1];

                setOpenModalApplicantView({
                    open: true,
                    applicandId: applicantId,
                    jobId: rowData?.otherRequestData?.jobId,
                    jobData: rowData.jobDetail,
                    evaluationId: evaluationId,
                    openRatingOnStart: false,
                    cvToShare: rowData?.otherRequestData?.displayCV
                        ? rowData?.otherRequestData?.cvToShare
                            ? rowData?.otherRequestData?.cvToShare
                            : { value: "cv", label: "" }
                        : undefined,
                    customCVDetail: rowData?.otherRequestData?.customCVDetail,
                    assessmentsTestsToShare:
                        rowData?.otherRequestData?.assessmentsTestsToShare || []
                });
            }
        }
    );

    const postAddress = `/applicant/saveevaluationstatus`;
    const finishEvaluation = async () => {
        const result = await apiDirectCall(postAddress, "post", {
            id: evaluationId,
            finished: true
        });
        if (result.status === 200) {
            setModalFinishEvaluationData({
                ...defaultModalStateValue
            });
            showMessage(t("finishEvaluation_successMessage"), "success");
            queryClient.invalidateQueries(postAddressSearchEvaluationStatus);
        }
    };
    React.useEffect(() => {
        if (
            !openModalApplicantView.open &&
            !evaluationStatus?.isFinishedByUser
        ) {
            queryClient.invalidateQueries(postAddressSearchEvaluationStatus);
            refreshTable();
        }
    }, [openModalApplicantView.open, evaluationStatus?.isFinishedByUser]);

    React.useEffect(() => {
        // use storage to verify if last modal is not called recently
        const keyStorageName = `lastAskSaveEvaluationRequest_${evaluationId}`;
        const storageLastAskDate = localStorage.getItem(keyStorageName);
        const minutesDifferenceToAsk = 15;
        const diffMinutes = storageLastAskDate
            ? getDateDiffMinutes(storageLastAskDate, new Date().toISOString())
            : 1000000;

        if (
            diffMinutes > minutesDifferenceToAsk &&
            !openModalApplicantView.open &&
            (evaluationStatus?.totalApplicants || 0) > 0 &&
            evaluationStatus?.totalApplicants ===
                evaluationStatus?.applicantsEvaluated
        ) {
            localStorage.setItem(keyStorageName, new Date().toISOString());
            setModalFinishEvaluationData({
                ...defaultModalStateValue,
                open: true,
                isCalledAfterLastApplicant: true
            });
        }
    }, [
        openModalApplicantView.open,
        evaluationStatus?.totalApplicants,
        evaluationStatus?.applicantsEvaluated
    ]);

    const allReady =
        finished &&
        readyTranslation &&
        accessType &&
        getEvaluationStatusReturn.status === "success";

    let compRetorno: null | JSX.Element = <Loading />;
    if (allReady) {
        compRetorno = renderTable();
        const module = getModule(accessType);
        if (!evaluationStatus?.isFinishedByUser) {
            return (
                <>
                    {compRetorno}
                    <ApplicantViewModalContainer
                        onClose={() =>
                            setOpenModalApplicantView((modalData) => {
                                return { ...modalData, open: false };
                            })
                        }
                        open={openModalApplicantView.open}
                        loadingData={readyTranslation && finished}
                        ApplicantId={openModalApplicantView.applicandId}
                        module={module}
                        externalData={
                            external
                                ? {
                                      companyId: companyId.toString(),
                                      evaluationId:
                                          openModalApplicantView.evaluationId,
                                      cvToShare:
                                          openModalApplicantView.cvToShare,
                                      assessmentsTestsToShare:
                                          openModalApplicantView.assessmentsTestsToShare
                                  }
                                : undefined
                        }
                        jobDataInsideJob={{
                            jobId: openModalApplicantView.jobId,
                            jobData: openModalApplicantView.jobData
                        }}
                        openRatingOnStart={
                            openModalApplicantView.openRatingOnStart
                        }
                        customCVsData={
                            openModalApplicantView.customCVDetail
                                ? [openModalApplicantView.customCVDetail]
                                : undefined
                        }
                        evaluationId={openModalApplicantView.evaluationId}
                        cvToShare={openModalApplicantView.cvToShare}
                    />
                    <ModalFinishEvaluation
                        {...modalFinishEvaluationData}
                        applicantsEvaluated={
                            evaluationStatus?.applicantsEvaluated
                        }
                        totalApplicants={evaluationStatus?.totalApplicants}
                        finishEvaluation={finishEvaluation}
                    />
                </>
            );
        } else {
            return (
                <Box padding={2}>
                    <Alert severity="info">{t("msgFinishedEvaluations")}</Alert>
                </Box>
            );
        }
    } else {
        return <Loading />;
    }
};

type getModuleType = (
    accessType?: CompleoShared.EvaluationRequest.IGetUserEvaluationTypeReturn
) => ApplicantViewModulesType;

const getModule: getModuleType = (
    accessType?: CompleoShared.EvaluationRequest.IGetUserEvaluationTypeReturn
) => {
    switch (accessType?.userAccess) {
        case "recruiter":
            return "APPLICANTVIEW";
        case "contact":
            return "APPLICANTVIEWCONTACT";
        case "requester":
            return "APPLICANTVIEWREQUESTER";
        default:
            return "APPLICANTVIEWEXTERNALOROTHERS";
    }
};

const getDateDiffMinutes = (startISODate: string, endISODate: string) => {
    const start = new Date(startISODate).getTime();
    const end = new Date(endISODate).getTime();
    const milliseconds = Math.abs(end - start);
    const seconds = parseInt((milliseconds / 1000).toString());
    const minutes = parseInt((seconds / 60).toString());
    return minutes;
};

export default EvaluationRequestApplicantList;
