import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { Divider, ListItemSecondaryAction, Switch } from "@material-ui/core";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { FieldOptionsType } from "./FormData";
import { fieldsRules } from "./helper";

interface IButtonGroupFieldProps {
    handleFieldOption: any;
    t: any;
    fieldName: string;
    currentValue: FieldOptionsType;
}

const ButtonGroupField = (props: IButtonGroupFieldProps) => {
    const { handleFieldOption, t, fieldName, currentValue } = props;

    const handleClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
        handleFieldOption(event.target.checked ? "show" : "hidden", fieldName);
    };
    return (
        <Switch
            checked={currentValue === "show"}
            onChange={handleClick}
            name="checkedB"
            color="primary"
        />
    );
};

interface IPropsStageItems {
    listFields: Compleo.IObject[];
    formId: string;
    classError: any;
    t: any;
    tapplicant: any;
    handleEditStage: any;
    handleDeleteStage: any;
    fieldValue: any;
    setFieldValue: any;
}
const Fields = (props: IPropsStageItems) => {
    const {
        listFields,
        formId,
        classError,
        t,
        tapplicant,
        handleEditStage,
        handleDeleteStage,
        fieldValue,
        setFieldValue
    } = props;
    const newList: Compleo.IObject[] = listFields.filter(
        (field: any) => field.formGroupId === formId
    );

    const handleFieldOption = (value: FieldOptionsType, fieldName: string) => {
        const newValue = { ...fieldValue };
        const fields = [...newValue.fields];
        fields.filter(
            (item: any) => item.fieldName === fieldName
        )[0].fieldOptions = value;
        setFieldValue({ ...newValue, fields: fields });
        // currentField.fieldOptions =
    };

    // blocked fields can't be dragged (we need to keep it always in the same group)
    const blockedFields = fieldsRules.blockedFields;

    return (
        <>
            {newList.map((field: any, index: number) => {
                const isDragDisabled = blockedFields.includes(field.fieldName);
                return (
                    <Draggable
                        key={field.fieldName}
                        draggableId={field.fieldName}
                        index={index}
                        isDragDisabled={isDragDisabled}
                    >
                        {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                        ) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <ListItem
                                    alignItems="center"
                                    style={{
                                        margin: 4
                                    }}
                                >
                                    <ListItemIcon>
                                        <Tooltip title="">
                                            <Icon color="action">
                                                {isDragDisabled
                                                    ? "block"
                                                    : "drag_indicator"}
                                            </Icon>
                                        </Tooltip>
                                    </ListItemIcon>

                                    <ListItemText
                                        className={classError}
                                        primary={tapplicant(field.fieldName)}
                                    />
                                    <ListItemSecondaryAction>
                                        <ButtonGroupField
                                            fieldName={field.fieldName}
                                            handleFieldOption={
                                                handleFieldOption
                                            }
                                            currentValue={field.fieldOptions}
                                            t={t}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </div>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};

export default Fields;
