import React, { Component } from "react";
import Page from "Pages/_Layouts/DefaultInternal";
// import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoReactHookForm/components/useFormStyles";
import CustomComponents from "./components/PipelineCustomComponents";
// import { useSelector } from "react-redux";
import { AuthProvider, useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import { getHandlePipelineSubmit } from "./pipelinePostHelper";
import useCompleoReactHookForm from "customHooks/useCompleoReactHookForm";

const PipelineNew = (props: any) => {
    const cancelFunction = useCancelButtonForm("/pipeline/list");
    const classes = useFormStyles();
    const { company } = useAuthState();
    const moduleName = "PIPELINEEDIT";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/pipeline/edit`;
    const [editPipelineReturn, editPipeline] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);

    const postAddressSearch = `/pipeline/search`;
    const [getPipelineSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { uuid: props.uuid, companyId: company.companyId },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getPipelineSearchResult,
        ["data", "data", "Items"]
    );
    valuesFromSource.uuid = props.uuid;
    valuesFromSource.deleted = false;
    const ready =
        readyTranslation &&
        getPipelineSearchResult.status === "success" &&
        metadata.status === "success";

    const handleSubmit = getHandlePipelineSubmit(editPipeline);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t: t,
        ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: editPipelineReturn,
        redirectAddress: "/pipeline/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource,
        CustomComponents,
        secondaryButtonFunction: cancelFunction
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && readyTranslation) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

// UserNew.whyDidYouRender = true;
export default PipelineNew;
