import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AssistantIcon from "@material-ui/icons/Assistant";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extendedIcon: {
            color: "white"
        },
        fab: {
            position: "absolute",
            top: theme.spacing(10),
            right: theme.spacing(4),
            background: "linear-gradient(135deg, #322241, #9c4f96, #322241)",
            backgroundSize: "200% 200%"
        }
    })
);

interface IAIMainButtonProps {
    onClick: () => void;
    isVisible?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;
}
export default function AIMainButton(pros: IAIMainButtonProps) {
    const { onClick, isVisible = true, buttonRef } = pros;
    const classes = useStyles();
    if (!isVisible) return null;
    return (
        <Fab
            variant="extended"
            className={classes.fab}
            onClick={onClick}
            ref={buttonRef ? buttonRef : undefined}
        >
            <AssistantIcon className={classes.extendedIcon} />
        </Fab>
    );
}
