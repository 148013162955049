import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList from "../..";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import {
    BulkActionsType,
    getBulkActionsList,
    IListBulk,
    stepValidations,
    useBulkLoadList,
    IBulkActions,
    getBulkChangeStageActionName
} from "./helperBulkActions";
import BulkActionsStep1 from "./BulkActionsStep1";
import BulkActionsStep2 from "./BulkActionsStep2";
import BulkActionsStep3AddToJob from "./BulkActionsStep3AddToJob";
import BulkActionsStep3ApplicantAddTags from "./BulkActionsStep3ApplicantAddTags";
import BulkActionsStep3AddToStageOrDisqualify from "./BulkActionsStep3ChangeStage";
import BulkActionsStep3SendEmail from "./BulkActionsStep3SendEmail";
import BulkActionsStep3RequestEvaluation from "./BulkActionsStep3RequestEvaluation";
import BulkActionsStep3DeleteApplicant from "./BulkActionsStep3DeleteApplicant";
import BulkActionsStep3SendAssessment from "./BulkActionsStep3SendAssessment";
import BulkActionsStep3JobAIMatch from "./BulkActionsStep3JobAIMatch";
import BulkActionsStepConfirmation from "./BulkActionsStepConfirmation";
import BulkActionsList from "./BulkActionsList";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import BulkActionsStep3JobExport from "./BulkActionsStep3JobExport";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: (value?: string) => void;
    otherGenericParamsListQuery?: Compleo.IObject;
    BulkActionsRemoveFromList?: BulkActionsType[];
    fromJobViewJobId?: string;
}

const BulkActionsModal = (props: IPropsItemFilter) => {
    const {
        onClose,
        open,
        otherGenericParamsListQuery = {},
        BulkActionsRemoveFromList = [],
        fromJobViewJobId
    } = props;
    const formikRef = React.useRef(null);
    const classes = useStyles();
    const [loadingButtons, setLoadingButtons] = React.useState(false);
    const [stepBulk, setStepBulk] = React.useState<number>(1);
    const [listBulk, setListBulk] = React.useState<IListBulk[]>([]);
    const [listData] = useList();
    const [submitCalled, setSubmitCalled] = React.useState(false);

    React.useEffect(() => {
        // post está dentro do effect por causa dos botões do modal
        // para ficarem como loading... (Necessário rerender para atualizar)

        if (submitCalled) {
            const formikValue: any = formikRef.current;
            if (formikValue) {
                try {
                    formikValue.submitForm();
                } catch (ex) {
                    formikValue?.dispatchEvent(
                        new Event("submit", { cancelable: true, bubbles: true })
                    );
                }
                setLoadingButtons(false);
            }
            setSubmitCalled(false);
        }
    }, [submitCalled]);

    const { t } = listData.tempInfo;
    const bulkActionsList = getBulkActionsList(
        listData.tempInfo.moduleName,
        "",
        BulkActionsRemoveFromList
    );
    const [bulkType, setBulkType] = React.useState<IBulkActions>(
        bulkActionsList[0]
    );

    const { callDialog } = useGlobalDialog();
    const showMessage = useShowMessage();
    const listReady = useBulkLoadList(
        listBulk,
        setListBulk,
        open,
        otherGenericParamsListQuery
    );

    const nextStep = async () => {
        switch (stepBulk) {
            case 1:
            case 2:
                const bulkActionsSettings =
                    listData.definitionInfo?.elasticDefData?.data?.otherSettings
                        ?.bulkActionsSettings || {};

                // const currentFilterTotal =
                //     listData.listReturnInfo?.totalFiltered?.value || 0;

                const currentFilterTotal = listBulk.length;
                const validationStatus = stepValidations(
                    callDialog,
                    t,
                    stepBulk,
                    bulkType,
                    bulkActionsSettings,
                    currentFilterTotal,
                    listBulk
                );
                if (validationStatus) {
                    setStepBulk(stepBulk + 1);
                }
                break;
            case 3:
                setLoadingButtons(true);
                setSubmitCalled(true);

                break;
            default:
                break;
        }
    };
    const previousStep = () => {
        setStepBulk(stepBulk - 1);
    };
    React.useEffect(() => {
        if (open) {
            setStepBulk(1);
        }
    }, [open]);

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby={t("ELASTICLISTSETTINGS:ModalBulkActions_Title")}
            open={open}
        >
            <DialogTitle>
                {`${t("ELASTICLISTSETTINGS:ModalBulkActions_Title")} - ${t(
                    `ELASTICLISTSETTINGS:BulkActions_${bulkType?.name}`
                )}`}
            </DialogTitle>
            <DialogContent dividers>
                {listReady ? (
                    <MainContent
                        stepBulk={stepBulk}
                        bulkType={bulkType}
                        setBulkType={setBulkType}
                        listBulk={listBulk}
                        setListBulk={setListBulk}
                        openDialog={open}
                        formikRef={formikRef}
                        setStepBulk={setStepBulk}
                        otherGenericParamsListQuery={
                            otherGenericParamsListQuery
                        }
                        BulkActionsRemoveFromList={BulkActionsRemoveFromList}
                        setLoadingButtons={setLoadingButtons}
                        fromJobViewJobId={fromJobViewJobId}
                    />
                ) : (
                    <Loading />
                )}
            </DialogContent>
            <DialogActionsModal
                nextStep={nextStep}
                onClose={onClose}
                previousStep={previousStep}
                stepBulk={stepBulk}
                t={t}
                loadingButtons={loadingButtons}
            />
        </Dialog>
    );
};

interface IPropsMainContent {
    stepBulk: number;
    bulkType: IBulkActions | null;
    setBulkType: any;
    listBulk: IListBulk[];
    setListBulk: React.Dispatch<React.SetStateAction<IListBulk[]>>;
    openDialog: boolean;
    formikRef: React.MutableRefObject<any>;
    setStepBulk: (value: React.SetStateAction<number>) => void;
    otherGenericParamsListQuery?: Compleo.IObject;
    BulkActionsRemoveFromList: BulkActionsType[];
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
    fromJobViewJobId?: string;
}

const MainContent = (props: IPropsMainContent) => {
    const {
        stepBulk,
        bulkType,
        setBulkType,
        listBulk,
        setListBulk,
        openDialog,
        formikRef,
        setStepBulk,
        otherGenericParamsListQuery,
        BulkActionsRemoveFromList,
        setLoadingButtons,
        fromJobViewJobId
    } = props;

    const setAction = (type: IBulkActions) => {
        setBulkType(type);
    };

    switch (stepBulk) {
        case -1:
            return <Loading />;
        case 1:
            return (
                <BulkActionsStep1
                    setAction={setAction}
                    action={bulkType}
                    BulkActionsRemoveFromList={BulkActionsRemoveFromList}
                />
            );
        case 2:
            return (
                <BulkActionsStep2
                    BulkActionsListLocal={
                        <BulkActionsList
                            listBulk={listBulk}
                            openDialog={openDialog}
                            setListBulk={setListBulk}
                        />
                    }
                />
            );
        case 3:
            switch (bulkType?.name) {
                case "applicant_addToJob":
                    return (
                        <BulkActionsStep3AddToJob
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "applicantjob_addToStage":
                case "applicantjob_disqualify":
                case "applicantjob_requalify":
                case "applicantjob_changeRejectedStage":
                    const action = getBulkChangeStageActionName(bulkType?.name);
                    if (action) {
                        return (
                            <BulkActionsStep3AddToStageOrDisqualify
                                listBulk={listBulk}
                                formikRef={formikRef}
                                setStepBulk={setStepBulk}
                                otherGenericParamsListQuery={
                                    otherGenericParamsListQuery
                                }
                                action={action}
                                setLoadingButtons={setLoadingButtons}
                            />
                        );
                    } else {
                        return null;
                    }
                case "applicant_sendEmail":
                    return (
                        <BulkActionsStep3SendEmail
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                            fromJobViewJobId={fromJobViewJobId}
                        />
                    );
                case "applicantjob_requestEvaluation":
                    return (
                        <BulkActionsStep3RequestEvaluation
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "applicant_Delete":
                    return (
                        <BulkActionsStep3DeleteApplicant
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "job_exportToFile":
                    return (
                        <BulkActionsStep3JobExport
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "applicant_addTag":
                    return (
                        <BulkActionsStep3ApplicantAddTags
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "applicantjob_requestAssessment":
                    return (
                        <BulkActionsStep3SendAssessment
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                        />
                    );
                case "applicantjob_aiMatching":
                    return (
                        <BulkActionsStep3JobAIMatch
                            listBulk={listBulk}
                            formikRef={formikRef}
                            setStepBulk={setStepBulk}
                            setLoadingButtons={setLoadingButtons}
                            otherGenericParamsListQuery={
                                otherGenericParamsListQuery
                            }
                        />
                    );
                default:
                    return null;
            }
        case 99:
            return <BulkActionsStepConfirmation bulkAction={bulkType} />;
        default:
            return null;
    }
};

interface IDialogActionsModal {
    stepBulk: number;
    nextStep: () => void;
    previousStep: () => void;
    onClose: (value?: string) => void;
    t: any;
    loadingButtons: boolean;
}
export const DialogActionsModal = (props: IDialogActionsModal) => {
    const {
        stepBulk,
        nextStep,
        previousStep,
        onClose,
        t,
        loadingButtons
    } = props;

    if (stepBulk === -1) {
        return (
            <DialogActions>
                <Loading size={20} />
            </DialogActions>
        );
    }
    switch (stepBulk) {
        case 99:
            // Confirmation
            return (
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={loadingButtons}
                    >
                        {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                    </Button>
                </DialogActions>
            );
        default:
            return (
                <DialogActions>
                    <Button
                        onClick={() => onClose()}
                        color="secondary"
                        disabled={loadingButtons}
                    >
                        {t("ELASTICLISTSETTINGS:GeneralModalCancel")}
                    </Button>
                    {stepBulk > 1 && (
                        <Button
                            onClick={previousStep}
                            color="primary"
                            disabled={loadingButtons}
                        >
                            {t(
                                "ELASTICLISTSETTINGS:ModalBulkActions_PreviousButton"
                            )}
                        </Button>
                    )}
                    <Button
                        onClick={nextStep}
                        color="primary"
                        variant="contained"
                        disabled={loadingButtons}
                    >
                        {stepBulk === 3
                            ? t(
                                  "ELASTICLISTSETTINGS:ModalBulkActions_FinishButton"
                              )
                            : t(
                                  "ELASTICLISTSETTINGS:ModalBulkActions_NextButton"
                              )}
                    </Button>
                </DialogActions>
            );
    }
};

export default BulkActionsModal;
