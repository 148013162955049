import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";

interface Opts {
    locale?: string;
}

export class MomentUTCAdapter extends MomentUtils {
    constructor(options?: Opts) {
        moment.tz.setDefault("UTC");

        super({
            locale: options?.locale,
            instance: moment
        });
    }
}
