import React, { useEffect } from "react";
import { UseFormWatch } from "react-hook-form";

interface IFieldsToHideTests {
    watch: UseFormWatch<Compleo.IObject>;
    type?: CompleoShared.Assessments.testType;
}
const useFieldsToHideTests = (props: IFieldsToHideTests) => {
    const { watch, type } = props;
    const fieldsToHideDefault =
        type !== "answersToResults" ? ["possibleResults"] : [];
    const [fieldsToHide, setFieldsToHide] = React.useState(fieldsToHideDefault);
    const enableMaxDuration = watch("enableMaxDuration");

    useEffect(() => {
        const fieldsToHideLocal = [...fieldsToHide];
        const index = fieldsToHideLocal.indexOf("maxDuration");
        if (!enableMaxDuration) {
            if (index === -1) {
                setFieldsToHide([...fieldsToHideLocal, "maxDuration"]);
            }
        } else {
            if (index > -1) {
                fieldsToHideLocal.splice(index, 1);
                setFieldsToHide(fieldsToHideLocal);
            }
        }
    }, [enableMaxDuration]);
    return fieldsToHide;
};

export default useFieldsToHideTests;
