import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";

interface IApplicantHistorySendAssessmentProps extends IApplicantHistoryProps {
    historyType: CompleoShared.Common.ApplicantHistoryType;
}
const ApplicantHistorySendAssessment = (
    props: IApplicantHistorySendAssessmentProps
) => {
    const { historyItem, tLocal, language, historyType } = props;
    const typesWithoutEmail: CompleoShared.Common.ApplicantHistoryType[] = [
        "sendAssessmentAutomation_failedApplicantAssessmentCompleted",
        "sendAssessment_failedApplicantAssessmentCompleted"
    ];
    const isWithoutEmail = typesWithoutEmail.includes(historyType);
    console.log("isWithoutEmail", isWithoutEmail);
    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("assessmentName")}
                transformDBDataParam={{
                    value: historyItem?.operationInfo?.assessmentName,
                    language: language,
                    t: tLocal
                }}
            />
            {!isWithoutEmail && (
                <ApplicantHistoryItemDetail
                    title={tLocal("dateLimit")}
                    transformDBDataParam={{
                        value: historyItem?.operationInfo?.dateLimit,
                        language: language,
                        t: tLocal
                    }}
                />
            )}
        </>
    );
};

export default ApplicantHistorySendAssessment;
