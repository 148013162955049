import Stages from "Pages/Pipeline/components/Stages";
import { Grid } from "@material-ui/core";
import { IInputProps } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

interface IProps {
    stageIdsWithApplicants?: string[];
    jobId: string;
}

const Pipeline = (props: IProps & IInputProps) => {
    const { stageIdsWithApplicants, jobId } = props;
    const metadata = { fieldName: "pipeline.stages" };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Stages
                    {...props}
                    metadata={metadata}
                    stageIdsWithApplicants={stageIdsWithApplicants}
                    label="Stages"
                    name={metadata.fieldName}
                    jobId={jobId}
                />
            </Grid>
        </Grid>
    );
};

export default Pipeline;
