import { createTheme } from "@material-ui/core/styles";

const CompleoTheme = createTheme({
    palette: {
        primary: { main: "#322241" },
        neutral: {
            main: "#5c6ac4"
        },
        backgroundGrey: { main: "#f4f6f8" }
        // background: { default: "white", paper: "white" }
    },
    typography: {
        // fontFamily: "'Nunito', sans-serif"
        fontFamily: "'Poppins', sans-serif"
        // fontFamily: "'Nunito Sans', sans-serif"
        // fontFamily: "'Helvetica', sans-serif"
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1.1em"
            }
        }
    }
});

export default CompleoTheme;
