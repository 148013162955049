import { useAuthState } from "_ReactContext/AuthContext";
import { useApiCache } from "customHooks/useApi";

export function useCompanyAIStatus() {
    const { company } = useAuthState();

    const addressGetAIFeatures = `/ai/getaifeatures`;
    const [dataReturnGetAiFeatures] = useApiCache(
        addressGetAIFeatures,
        "post",
        {
            companyId: company.companyId
        },
        false
    );

    const addressGetAICompanyCredits = `/ai/getaicompanycredits`;
    const [dataReturnCompanyCredits] = useApiCache(
        addressGetAICompanyCredits,
        "post",
        {
            companyId: company.companyId
        },
        false
    );

    const addressGetAiSettings = `/ai/getaicompanysettings`;
    const [dataCompanyAiSettings] = useApiCache(
        addressGetAiSettings,
        "post",
        {
            companyId: company.companyId
        },
        false
    );

    const monthlyCredits =
        dataReturnCompanyCredits.response?.data?.monthlyCredits || {};

    const compleoAIFeatures: CompleoShared.AI.compleoFeaturesAI[] =
        dataReturnGetAiFeatures.response?.data?.compleoFeaturesAI || [];

    const packages = dataReturnCompanyCredits.response?.data?.packages || [];
    const currentCredits = getCreditInfo(monthlyCredits, packages);
    const companySettings =
        dataCompanyAiSettings.response?.data?.data?.mainSettings || {};
    console.log("companySettings", companySettings);
    return {
        monthlyCredits,
        compleoAIFeatures,
        packages,
        dataCompanyAiSettings,
        companySettings,
        dataReturnGetAiModels: dataReturnGetAiFeatures,
        currentCredits,
        ready:
            dataReturnGetAiFeatures.status === "success" &&
            dataReturnCompanyCredits.status === "success" &&
            dataCompanyAiSettings.status === "success"
    };
}

export function useCompanyAIFeatureStatus(
    feature: CompleoShared.AI.compleoFeaturesAI,
    amount: number = 1
) {
    const { company } = useAuthState();
    const { companySettings, currentCredits } = useCompanyAIStatus();
    const enabled = companySettings?.[feature] === "enabled";

    const addressGetAIFeatures = `/ai/getaifeatures`;
    const [dataReturnGetAiFeatures] = useApiCache(
        addressGetAIFeatures,
        "post",
        {
            companyId: company.companyId,
            feature: feature
        },
        false
    );
    const unitCreditsRequired =
        dataReturnGetAiFeatures.response?.data?.unitCreditRequiredForFeature ||
        -1;

    const totalCreditsRequired = unitCreditsRequired * amount;
    const canUse = currentCredits >= totalCreditsRequired && enabled;

    return {
        enabled,
        totalCreditsRequired,
        currentCredits,
        feature,
        canUse
    };
}

const getCreditInfo = (
    monthlyCredit: Compleo.IObject,
    packages: Compleo.IObject[]
) => {
    let currentCredits: number = 0;
    currentCredits =
        (monthlyCredit?.creditDetails?.credits || 0) -
        (monthlyCredit?.creditDetails?.creditsUsed || 0);
    for (const packageItem of packages) {
        if (packageItem) {
            currentCredits +=
                (packageItem?.creditDetails?.credits || 0) -
                (packageItem?.creditDetails?.creditsUsed || 0);
        }
    }
    return currentCredits;
};
