import {
    Button,
    ButtonGroup,
    Divider,
    Menu,
    MenuItem
} from "@material-ui/core";
import { TFunction } from "i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import {
    useButtonAddTestToAssessmentLists,
    useCheckAssessmentAlreadySent
} from "./helper";
import { apiDirectCall } from "customHooks/useApi";
import { useQueryClient } from "react-query";
import useJobContext from "../../useJobContext";
import { useAuthState } from "_ReactContext/AuthContext";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import AddTestToAssessmentChooseResultModal from "./AddTestToAssessmentChooseResultModal";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

export interface IHandleAddTestToAssessment {
    testId: string;
    resultName?: string;
    templateUUID?: string;
}

interface IAddAssessmentToGroup {
    tLocal: TFunction;
    assessmentId: string;
    tests: CompleoShared.Assessments.ITest[];
}
const AddTestToAssessment = (props: IAddAssessmentToGroup) => {
    const queryClient = useQueryClient();
    const { tLocal, assessmentId, tests } = props;
    const [jobData] = useJobContext();
    const { company } = useAuthState();
    const showMessageFullControl = useShowMessageFullControl();
    const { callDialog } = useGlobalDialog();
    const {
        ready: readyCheckAssessmentSent,
        assessmentAlreadySent
    } = useCheckAssessmentAlreadySent(jobData.JobId, assessmentId);

    interface IModalData {
        testId: string;
        resultsList: CompleoShared.Assessments.IPossibleResults[];
        templateUUID?: string;
    }
    const [modalData, setModalData] = React.useState<null | IModalData>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);
    const {
        options,
        optionsTemplate,
        ready
    } = useButtonAddTestToAssessmentLists(tests);

    const handleMenuItemClick = async (
        event: React.MouseEvent<HTMLElement>,
        itemOption: string,
        testType: CompleoShared.Assessments.testType,
        resultValues?: CompleoShared.Assessments.IPossibleResults[],
        templateUUID?: string
    ) => {
        event.stopPropagation();

        const mainHandleFunction = async () => {
            const testId = itemOption.split(":")[1];
            if (testType === "answersToResults") {
                setModalData({
                    testId: testId,
                    resultsList: resultValues || [],
                    templateUUID: templateUUID
                });
                setAnchorEl(null);
                return;
            } else {
                const retorno = await handleAddTestToAssessment({
                    testId: testId,
                    templateUUID: templateUUID
                });
                return retorno;
            }
        };

        const mainFinalFunction = async () => {
            if (!templateUUID) {
                await mainHandleFunction();
            } else {
                callDialog({
                    title: tLocal("COMPLEOGENERAL_HELP"),
                    bodyText: tLocal("testsWarningMessage"),
                    agreeButtonText: tLocal("COMPLEOGENERAL_NEXT"),
                    agreeFunction: async () => {
                        await mainHandleFunction();
                    }
                });
            }
        };
        if (assessmentAlreadySent) {
            callDialog({
                title: tLocal("COMPLEOGENERAL_HELP"),
                bodyText: tLocal("msgAddTestWarning"),
                agreeButtonText: tLocal("COMPLEOGENERAL_YES"),
                agreeFunction: async () => {
                    await mainFinalFunction();
                },
                disagreeButtonText: tLocal("COMPLEOGENERAL_CANCEL"),
                disagreeFunction: () => {}
            });
        } else {
            await mainFinalFunction();
        }
    };

    const handleAddTestToAssessment = async (
        params: IHandleAddTestToAssessment
    ) => {
        const { testId, resultName, templateUUID } = params;
        showMessageFullControl.openMessage({
            message: tLocal("COMPLEOGENERAL_MessageUpdating"),
            variant: "info",
            showLinearProgress: true
        });
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            const values: Compleo.IObject = {
                jobId: jobData.JobId,
                assessmentId: assessmentId,
                testId: testId,
                templateUUID: templateUUID,
                companyId: company.companyId
            };
            if (resultName) {
                values.answersToResultsNameToCalculateScore = resultName;
            }
            const postAddress = `/test/addtesttoassessment`;
            await apiDirectCall(postAddress, "post", values);
            queryClient.invalidateQueries(`/test/listassessments`);
        } catch (ex) {
            showMessageFullControl.openMessage({
                message: tLocal("NotIdentifiedProblem"),
                autoHideDuration: 10000,
                variant: "error"
            });
            retorno.status = 500;
            setAnchorEl(null);
            return ex.response;
        }
        setAnchorEl(null);
        showMessageFullControl.openMessage({
            message: tLocal("COMPLEOGENERAL_MessageUpdated"),
            autoHideDuration: 2000,
            variant: "success"
        });
        return retorno;
    };

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(divRef.current);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const pageReady = readyCheckAssessmentSent && ready;

    if (pageReady) {
        return (
            <div>
                <ButtonGroup
                    variant="outlined"
                    color="primary"
                    aria-label="split button"
                    ref={divRef}
                    style={{ marginRight: 8 }}
                >
                    <Button onClick={handleClickListItem}>
                        {tLocal("addTest")}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label={tLocal("addTest")}
                        aria-haspopup="menu"
                        onClick={handleClickListItem}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <MenuItem
                        disabled
                        button={false}
                        style={{
                            color: options.length > 0 ? undefined : "red"
                        }}
                    >
                        {options.length
                            ? tLocal("chooseATest")
                            : tLocal("noMoreTests")}
                    </MenuItem>
                    {options.length > 0 && <Divider />}
                    {options.map((option, index) => {
                        return (
                            <MenuItem
                                key={option.value}
                                onClick={(event) =>
                                    handleMenuItemClick(
                                        event,
                                        option.value,
                                        option.testType,
                                        option.possibleResults
                                    )
                                }
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                    <Divider />
                    <MenuItem
                        disabled
                        button={false}
                        style={{
                            color: options.length > 0 ? undefined : "red"
                        }}
                    >
                        {optionsTemplate.length
                            ? tLocal("chooseATemplate")
                            : tLocal("noMoreTemplates")}
                    </MenuItem>
                    {optionsTemplate.length > 0 && <Divider />}
                    {optionsTemplate.map((option, index) => {
                        return (
                            <MenuItem
                                key={option.value}
                                onClick={(event) =>
                                    handleMenuItemClick(
                                        event,
                                        option.value,
                                        option.testType,
                                        option.possibleResults,
                                        option.templateUUID
                                    )
                                }
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
                {showMessageFullControl.MessageElement}
                {modalData && (
                    <AddTestToAssessmentChooseResultModal
                        t={tLocal}
                        open={modalData !== null}
                        handleClose={() => setModalData(null)}
                        handleAddTestToAssessment={handleAddTestToAssessment}
                        testId={modalData.testId}
                        resultsList={modalData.resultsList}
                        templateUUID={modalData.templateUUID}
                    />
                )}
            </div>
        );
    } else {
        return null;
    }
};

export default AddTestToAssessment;
