import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useCompleoSimpleList } from "customHooks/useCompleoSimpleList";
import { useAuthState } from "_ReactContext/AuthContext";
import RightAIUsageComponent from "./Components/RightAIUsageComponent";
import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useApi, useApiCache } from "customHooks/useApi";
import { Alert } from "@material-ui/lab";

const AIUsageReport = (props: any) => {
    const moduleName = "AIUSAGELIST";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    const year = props.year || new Date().getFullYear();
    const month = props.month || new Date().getMonth() + 1;

    const [metadata] = useGetMetadata(moduleName);
    const listAddress = `/ai/reportaiusage`;
    const { company } = useAuthState();
    const bodyPost = {
        companyId: company.companyId,
        year: year,
        month: month
    };
    const [searchResult, callSearch, invalidateQ] = useApiCache(
        listAddress,
        "post",
        bodyPost,
        true
    );
    const totalUsedCredits = (searchResult.response?.data?.data || [])
        .map((i: any) => i.creditsUsed || 1)
        .reduce((a: number, b: number) => a + b, 0);

    const [renderTable, finished, , callFinalSearch] = useCompleoSimpleList(
        t,
        metadata,
        listAddress,
        "post",
        bodyPost,
        []
    );

    useEffect(() => {
        callFinalSearch(bodyPost);
        callSearch(bodyPost);
    }, [year, month]);

    let compRetorno: null | JSX.Element = <Loading />;
    if (readyTranslation && finished) {
        compRetorno = renderTable();

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={getDescription(
                    t,
                    year,
                    month,
                    i18n.language
                )}
                maxWidth="lg"
                RightButtonComponent={
                    <RightAIUsageComponent
                        year={Number(year)}
                        month={Number(month)}
                    />
                }
            >
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                    <Box display={"flex"} width={"100%"}>
                        <Alert style={{ width: "100%" }}>
                            {getTotalCreditsMessage(t, totalUsedCredits)}
                        </Alert>
                    </Box>
                    <Box display={"flex"} width={"100%"}>
                        {compRetorno}
                    </Box>
                </Box>
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

const getTotalCreditsMessage = (t: any, totalUsedCredits: number) => {
    if (totalUsedCredits === 0) {
        return t("totalUsedCredits0");
    } else if (totalUsedCredits === 1) {
        return t("totalUsedCredits1", {
            totalUsedCredits: totalUsedCredits
        });
    } else {
        return t("totalUsedCredits", {
            totalUsedCredits: totalUsedCredits
        });
    }
};

const getDescription = (
    t: any,
    year: number,
    month: number,
    language: string
) => {
    const monthName = new Date(year, month - 1, 1).toLocaleString(language, {
        month: "long"
    });

    const featureNameDescription = `${t(
        "a_FeatureMainDescription"
    )} (${monthName}/${year})`;
    return featureNameDescription;
};

export default AIUsageReport;
