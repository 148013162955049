import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useApplicantAssessmentsHelper from "./useApplicantAssessmentsHelper";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import { useStyles } from "./AssessmentsListStyle";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import TestList from "./TestList";
import { transformDBData } from "functions/util";
import { Alert } from "@material-ui/lab";

interface IAssessmentsListProps {
    jobId: string;
}
const AssessmentsList = (props: IAssessmentsListProps) => {
    const { jobId } = props;
    const [t, i18n, readyTranslation] = useTranslation(
        "APPLICANTVIEWASSESSMENTS",
        {
            useSuspense: false
        }
    );
    const language = i18n.language;
    const { assessmentList, ready } = useApplicantAssessmentsHelper(jobId);
    const classes = useStyles();
    const [indexOpen, setIndexOpen] = React.useState(-1);
    const handleOpen = (index: number) => {
        if (indexOpen === index) {
            setIndexOpen(-1);
        } else {
            setIndexOpen(index);
        }
    };

    if (!assessmentList.length)
        return (
            <Box mt={1} p={1}>
                <Alert severity="info">{t("noAssessments")}</Alert>
            </Box>
        );
    if (ready && readyTranslation) {
        return (
            <>
                {assessmentList.map((assessment, index) => (
                    <List
                        component="nav"
                        className={classes.root}
                        key={assessment.assessment.name}
                        aria-label="assessments list"
                    >
                        <ListItem button onClick={() => handleOpen(index)}>
                            <ListItemText
                                primary={assessment.assessment.name}
                                secondary={
                                    <AssessmentListDetails
                                        assessment={assessment}
                                        language={language}
                                        t={t}
                                    />
                                }
                            />
                            <ListItemSecondaryAction>
                                <ButtonIconTooltipCompleo
                                    label={t("showMessagesExpand")}
                                    onClick={() => handleOpen(index)}
                                >
                                    {indexOpen === index ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ButtonIconTooltipCompleo>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse
                            in={indexOpen === index}
                            timeout="auto"
                            unmountOnExit
                        >
                            <TestList
                                tests={assessment.assessmentAndTestsData}
                                assessment={assessment.assessment}
                                t={t}
                            />
                        </Collapse>
                    </List>
                ))}
            </>
        );
    } else {
        return <Loading />;
    }
};

interface IAssessmentListDetailsProps {
    assessment: CompleoShared.Assessments.IReturnGetAssessmentByApplicant;
    language: string;
    t: TFunction<"APPLICANTVIEWASSESSMENTS">;
}

const AssessmentListDetails = (props: IAssessmentListDetailsProps) => {
    const { assessment, language, t } = props;

    const dateLimit = transformDBData({
        language: language,
        t: t,
        value: assessment.requestData.dateLimit
    });
    const requestDate = transformDBData({
        language: language,
        t: t,
        value: assessment.requestData.createdAt
    });
    const assessmentApplicant = assessment.assessmentAndTestsData.filter(
        (item) => item.skGS1pk.includes("#DEFINITION:JOB")
    )[0];
    const isCompleted = assessmentApplicant?.status === "global_completed";
    const status = assessmentApplicant?.status || "global_pending";
    const assessmentScore = transformDBData({
        value: Number(assessmentApplicant?.score || 0),
        t: t,
        language: "pt-BR",
        replaceOption: "-",
        numberDecimalScale: 2
    });

    return (
        <Typography variant="body2">
            {/* <strong>{t("job")}</strong>: {assessment.requestData.assessmentData?.assessment.}
            {" - "} */}
            <strong>{t("requestDate")}</strong>: {requestDate}
            {" - "}
            <strong>{t("dateLimit")}</strong>: {dateLimit}
            {" - "}
            <strong>{t("status")}</strong>: {t(`assessmentStatus_${status}`)}
            {isCompleted && (
                <>
                    {" - "}
                    <strong>{t("score")}</strong>: {assessmentScore}%
                </>
            )}
        </Typography>
    );
};

export default AssessmentsList;
