import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import ModalJobAssessmentsGroup from "./ModalJobAssessmentsGroup/Index";
import React from "react";
import AssessmentsGroupsList from "./AssessmentsGroupsList/Index";
import TestsBetaWarning from "Pages/Test/components/TestsBetaWarning";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        paperContainerBody: {
            margin: theme.spacing(2),
            padding: theme.spacing(2),
            height: "100%"
        }
    })
);

const JobAssessmentsMain = (props: any) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [tLocal, i18n, readyTranslation] = useTranslation(
        ["JOBVIEWASSESSMENTS", "TESTNEW"],
        {
            useSuspense: false
        }
    );

    if (readyTranslation) {
        return (
            <Box className={classes.container}>
                <Paper className={classes.paperContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">
                                {tLocal("title")}
                            </Typography>
                        </Grid>
                        <Grid item xs sm container justify="flex-end">
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => setModalOpen(true)}
                            >
                                {tLocal("buttonNew")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TestsBetaWarning />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paperContainerBody}>
                    <AssessmentsGroupsList tLocal={tLocal} />
                </Paper>
                <ModalJobAssessmentsGroup
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                />
            </Box>
        );
    } else {
        return <Loading />;
    }
};

export default JobAssessmentsMain;
