import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import JobKanbanSortColumnModal from "./SortColumn/JobKanbanSortColumnModal";
import BulkActionsModal from "customHooks/useList/ListHelpers/BulkActions/BulkActionsModal";
import useJobContext from "../../useJobContext";
import { getItemsToExcludeFromBulk } from "./helperJobKanbanColumnMenu";
import { useAuthorization } from "_ReactContext/AuthContext";
import useFeatureUpgradePlan, {
    ModalUpgradePlan
} from "customHooks/useFeatureUpgradePlan/useFeatureUpgradePlan";
import { useCompanyAIFeatureStatus } from "Pages/ai/CompanyAICreditStatus/useCompanyAIStatus";

type ActionsMenuList = "sort" | "bulkActions" | "bulkActionsUpgrade";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    stageId: string;
}

const JobKanbanColumnMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t, stageId } = props;
    const [modalSortColumnOpen, setModalSortColumnOpen] = React.useState(false);
    const [bulkActionsOpen, setBulkActionsOpen] = React.useState(false);
    const { isFeatureAuthorized } = useAuthorization();
    const bulkUpgradePlan = isFeatureAuthorized("BulkActions_Upgrade");
    const featuresUpgradePlan = useFeatureUpgradePlan("bulkActionsATS");

    const [data] = useJobContext();
    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === stageId
    )[0];
    const { canUse } = useCompanyAIFeatureStatus("applicantJobMatch", 1);
    const itemsToExcludeFromBulk = getItemsToExcludeFromBulk(
        stageData,
        data.LocalStateData.allStages
    );
    if (!canUse) {
        itemsToExcludeFromBulk.push("applicantjob_aiMatching");
    }

    const allMenuOptions: ActionsMenuList[] = data.readonly
        ? ["sort"]
        : bulkUpgradePlan
        ? ["sort", "bulkActionsUpgrade"]
        : ["sort", "bulkActions"];

    const menuOptions = allMenuOptions;

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickMenuItem = (action: ActionsMenuList) => {
        switch (action) {
            case "sort":
                setModalSortColumnOpen(true);
                break;
            case "bulkActions":
                setBulkActionsOpen(true);
                break;
            case "bulkActionsUpgrade":
                featuresUpgradePlan.setOpen(true);
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <div>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
            <JobKanbanSortColumnModal
                onClose={() => setModalSortColumnOpen(false)}
                open={modalSortColumnOpen}
                stageId={stageId}
            />
            {bulkActionsOpen && (
                <BulkActionsModal
                    onClose={() => setBulkActionsOpen(false)}
                    open={bulkActionsOpen}
                    otherGenericParamsListQuery={{ filterStageId: stageId }}
                    BulkActionsRemoveFromList={itemsToExcludeFromBulk}
                    fromJobViewJobId={data.JobId}
                />
            )}
            {featuresUpgradePlan.open && (
                <ModalUpgradePlan {...featuresUpgradePlan} />
            )}
        </div>
    );
};

const orderMenuOptions = (menuItems: ActionsMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ModalKanbaColumnMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default JobKanbanColumnMenu;
