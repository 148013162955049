import React from "react";
import JobFunnel from "Pages/Job/JobView/JobReports/Funnel/JobFunnel";
import JobActivities from "Pages/Job/JobView/JobReports/JobActivities/JobActivities";
import JobActivitiesRecruiter from "Pages/Job/JobView/JobReports/JobActivities/JobActivitiesRecruiter";
import { IEvalutionReportsDBReturn } from "Pages/EvaluationRequestExternal/EvaluationRequestMain";
import JobApplicantsDashboardExternal from "../Dashboard/JobApplicantsDashboardExternal";
// import JobViewDashboardMain from "../JobViewDashboard/JobViewDashboardMain";

interface IJobReportsSwitch {
    jobReportToShow: CompleoShared.Reports.IJobReportsToRender;
    allowedReports: CompleoShared.Reports.IJobReportsToRender[];
    externalEvaluationId: string;
    jobId: string;
    jobTitle: string;
    dashboardId?: string;
    companyId: number;
}

const JobReportsSwitch = (props: IJobReportsSwitch) => {
    const {
        jobReportToShow,
        externalEvaluationId,
        jobId,
        jobTitle,
        dashboardId,
        companyId
    } = props;

    switch (jobReportToShow) {
        case "funnel":
            return (
                <JobFunnel
                    externalEvaluationId={externalEvaluationId}
                    jobId={jobId}
                    jobTitle={jobTitle}
                />
            );
        // case "dashboard":
        //     return <JobViewDashboardMain />;
        case "activities":
            return (
                <JobActivities
                    externalEvaluationId={externalEvaluationId}
                    jobId={jobId}
                    jobTitle={jobTitle}
                />
            );
        case "activitiesRecruiter":
            return (
                <JobActivitiesRecruiter
                    externalEvaluationId={externalEvaluationId}
                    jobId={jobId}
                    jobTitle={jobTitle}
                />
            );
        case "dashboard":
            if (dashboardId) {
                return (
                    <JobApplicantsDashboardExternal
                        dashboardId={dashboardId}
                        evaluationId={externalEvaluationId}
                        companyId={companyId}
                    />
                );
            } else {
                return null;
            }
        default:
            return null;
    }
};

export default JobReportsSwitch;
