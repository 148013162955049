import React, { useContext } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import * as util from "functions/util";
import CustomComponents from "./components/CustomComponents";
import { getTimeFixedDate } from "Pages/Test/helpers/testUtil";

export interface IGenericQuestionActionsModal {
    open: boolean;
    onClose: () => void;
    values?: CompleoShared.Assessments.IQuestion;
    sectionId: string;
}

const QuestionAddEditModal = (props: IGenericQuestionActionsModal) => {
    const { open, onClose, values, sectionId } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const questionsData: CompleoShared.Assessments.IQuestion[] =
        ContextQuestionFieldsData?.questionsData || [];

    const [maxDurationDefault] = React.useState(
        getTimeFixedDate({ hours: 0, minutes: 1, useUTC: true })
    );

    const {
        setFieldValueQuestions,
        testType = "regular",
        possibleResults = []
    } = ContextQuestionFieldsData || {};

    const module = "TESTQUESTIONMAIN";
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, false, {
        questionWithAnswers: false,
        videoMaxDuration: new Date(maxDurationDefault),
        ...values,
        questiontype:
            testType === "answersToResults"
                ? {
                      "label-en-US": "Single choice",
                      value: "singleChoice",
                      "label-pt-BR": "Escolha única",
                      label: "Escolha única"
                  }
                : values?.questiontype
    });

    const ready = readyTranslation && metadata.status === "success";

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);
    const [answersCurrentValues, setAnswersCurrentValues] = React.useState<
        Compleo.IObject[]
    >([]);

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const questionTypeObj = watch("questiontype");

    const answers: CompleoShared.Assessments.IAnswer[] = watch("answers") || [];
    const correctAnswers = answers.filter((item) => item.correct).length;
    const questionWithAnswersList = ["singleChoice", "multipleChoice"];
    const questionType: CompleoShared.Assessments.questionType | undefined =
        questionTypeObj?.value;
    const bottomRef = React.useRef(null);

    React.useEffect(() => {
        // let's scroll to the bottom of the modal when a new answer is added
        const divRef: any = bottomRef.current;
        if (divRef) {
            divRef.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers.length]);

    React.useEffect(() => {
        if (!questionWithAnswersList.includes(questionType || "")) {
            const newFieldsToHide = ["answers", "partialAccept"];
            if (!questionType) {
                newFieldsToHide.push("correctionType");
            }
            setFieldsToHide(
                handleFieldsToHideVideos(newFieldsToHide, questionType)
            );
            setValue("questionWithAnswers", false);
        } else {
            const newFieldsToHide: string[] = ["correctionType"];
            if (testType === "weightAnswers") {
                newFieldsToHide.push("answers.correct");
                newFieldsToHide.push("answers.answerToResults");
            } else if (testType === "answersToResults") {
                newFieldsToHide.push("weight");
                newFieldsToHide.push("answers.correct");
                newFieldsToHide.push("answers.weight");
                newFieldsToHide.push("questiontype");
                setValue("questionWithAnswers", true);
                setValue("questionType", {
                    "label-en-US": "Single choice",
                    value: "singleChoice",
                    "label-pt-BR": "Escolha única",
                    label: "Escolha única"
                });
            } else {
                newFieldsToHide.push("answers.weight");
                newFieldsToHide.push("answers.answerToResults");
            }
            if (
                questionType === "singleChoice" ||
                testType === "weightAnswers"
            ) {
                newFieldsToHide.push("partialAccept");
            }

            setFieldsToHide(
                handleFieldsToHideVideos(newFieldsToHide, questionType)
            );
            setValue("questionWithAnswers", true);
        }
    }, [testType, questionType]);

    React.useEffect(() => {
        const questionType = questionTypeObj?.value;
        if (questionType === "singleChoice") {
            // only allows 1 correct answer if single choice
            if (correctAnswers > 1) {
                const newAnswers = answers.map((item, index) => {
                    if (answersCurrentValues[index]?.correct) {
                        return {
                            ...item,
                            correct: false
                        };
                    } else {
                        return item;
                    }
                });
                setValue("answers", [...newAnswers]);
                setAnswersCurrentValues(_.cloneDeep(newAnswers));
            } else {
                setAnswersCurrentValues(_.cloneDeep(answers));
            }
        }
    }, [correctAnswers, answers, questionTypeObj]);

    const handleSubmit = async (values: any) => {
        debugger;
        if (
            questionWithAnswersList.includes(questionType || "") &&
            testType === "regular"
        ) {
            if (correctAnswers === 0) {
                return {
                    status: 400,
                    data: {
                        message: [
                            {
                                dataPath: "answers",
                                message: t("msgAtLeastOneCorrectAnswer")
                            }
                        ]
                    }
                };
            }
        }

        const retorno: Compleo.IObject = {};
        setIsUpdating(true);
        retorno.status = 200;
        try {
            if (setFieldValueQuestions) {
                if (values.id) {
                    // Edit

                    const answers = values.answers || [];
                    const reorderedAnswers = values?.questionWithAnswers
                        ? answers.map((item: any, index: any) => {
                              const answerId = util.uuid();
                              return {
                                  ...item,
                                  order: index,
                                  id: answerId
                              };
                          })
                        : [];
                    const newValue: CompleoShared.Assessments.IQuestion = {
                        name: values.name,
                        order: values.order,
                        id: values.id,
                        expanded: values.expanded,
                        answers: reorderedAnswers,
                        questiontype: values.questiontype,
                        sectionId: sectionId,
                        weight: values.weight,
                        correctionType: values.correctionType,
                        partialAccept: values.partialAccept,
                        videoCanRecordAgain: values.videoCanRecordAgain,
                        videoStartRecordAuto: values.videoStartRecordAuto,
                        videoMaxDuration: values.videoMaxDuration,
                        videoStartRecordAutoSeconds:
                            values.videoStartRecordAutoSeconds
                    };
                    const sectionsDataNew = questionsData.map((item) => {
                        if (item.id === values.id) {
                            return newValue;
                        }
                        return item;
                    });
                    setFieldValueQuestions(sectionsDataNew);
                } else {
                    // Add
                    const uuidNew = util.uuid();

                    const answers = values.answers || [];
                    const reorderedAnswers = answers.map(
                        (item: any, index: any) => {
                            const answerId = util.uuid();
                            return {
                                ...item,
                                order: index,
                                id: answerId
                            };
                        }
                    );

                    const newValue: CompleoShared.Assessments.IQuestion = {
                        name: values.name,
                        order: questionsData.length,
                        id: uuidNew,
                        expanded: true,
                        answers: reorderedAnswers,
                        questiontype: values.questiontype,
                        sectionId: sectionId,
                        weight: values.weight,
                        correctionType: values.correctionType,
                        partialAccept: values.partialAccept,
                        videoCanRecordAgain: values.videoCanRecordAgain,
                        videoStartRecordAuto: values.videoStartRecordAuto,
                        videoMaxDuration: values.videoMaxDuration,
                        videoStartRecordAutoSeconds:
                            values.videoStartRecordAutoSeconds
                    };
                    setFieldValueQuestions([...questionsData, newValue]);
                }
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "answers.answerToResults",
            props: {
                possibleResults: possibleResults
            }
        }
    ];

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide: fieldsToHide,
        CustomComponents: CustomComponents,
        additionalFieldProperties: addProps
    });

    const handleSave = async () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_ModalTitle")}
                open={open}
                scroll="body"
            >
                <DialogTitle>{t("a_ModalTitle")}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>

                <DialogActions>
                    <Button
                        onClick={(event) => {
                            onClose();
                        }}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={(event) => {
                            handleSave();
                        }}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
                <div ref={bottomRef} style={{ height: "1px" }} />
            </Dialog>
        );
    } else {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_FeatureMainDescription")}
                open={open}
            >
                <DialogContent dividers>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

const handleFieldsToHideVideos = (
    fieldsToHide: string[],
    questionType?: CompleoShared.Assessments.questionType
) => {
    const newFieldsToHide = [...fieldsToHide];
    const videoFields: string[] = [
        "videoCanRecordAgain",
        "videoStartRecordAuto",
        "videoMaxDuration",
        "videoStartRecordAutoSeconds"
    ];
    if (!questionType || questionType !== "video") {
        // add video fields if not exist in fields to hide
        videoFields.forEach((field) => {
            if (!newFieldsToHide.includes(field)) {
                newFieldsToHide.push(field);
            }
        });
    } else {
        // remove video fields if exists in fields to hide
        videoFields.forEach((field) => {
            const index = newFieldsToHide.indexOf(field);
            if (index !== -1) {
                newFieldsToHide.splice(index, 1);
            }
        });
    }
    return newFieldsToHide;
};

export default QuestionAddEditModal;
