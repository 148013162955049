import Page from "Pages/_Layouts/DefaultInternal";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import FormClassic from "customHooks/useCompleoForm/components/FormClassic";
import useFormStyles from "customHooks/useCompleoForm/components/useFormStyles";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApi, useApiCache } from "customHooks/useApi";

import useValuesFromSource from "customHooks/useValuesFromSource";
import _ from "lodash";
import FormData from "./components/FormData";
import useHandleInitialValues from "./components/useHandleInitialValues";

const ApplicantDataViewSettings = (props: any) => {
    const { company } = useAuthState();
    const moduleName = "APPLICANTDATAVIEWSETTINGS";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const postAddress = `/applicant/applicantdataviewsettingsedit`;

    const [addDataReturn, addData] = useApi(postAddress, "post");
    const [metadata] = useGetMetadata(moduleName);
    const postAddressSearch = `/applicant/applicantdataviewsettingssearch`;

    const [getSearch] = useApi(
        postAddressSearch,
        "post",
        { companyId: company.companyId },
        false
    );

    const [valuesFromSource] = useValuesFromSource(
        metadata,
        true,
        {},
        getSearch,
        ["data", "data"]
    );

    const { initialValues, readyInitialValues } = useHandleInitialValues(
        valuesFromSource,
        getSearch.status === "success"
    );

    const CustomComponents = [
        {
            name: "requesterSettings",
            component: FormData
        },
        {
            name: "contactSettings",
            component: FormData
        },
        {
            name: "externalAndOthersSettings",
            component: FormData
        }
    ];

    const [formCompleo, finished] = useCompleoFormNew({
        t,
        ready: readyTranslation && readyInitialValues,
        i18nV: i18n,
        postMethod: addData,
        postReturn: addDataReturn,
        // redirectAddress: "/scheduletemplate/list",
        FormType: FormClassic,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        // secondaryButtonFunction: cancelFunction,
        formikEnableReinitialize: false,
        CustomComponents: CustomComponents,
        sizeToChangeStepHorizontal: "lg",
        enableStepNavigation: true,
        IsMultiStepLateralParam: false
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    const ready =
        finished && readyTranslation && getSearch.status === "success";

    if (ready) {
        compRetorno = formCompleo;
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default ApplicantDataViewSettings;
