// IMPORTANT: KEEP THIS FILE IN SYNC (BACKEND/FRONTEND)

export const getTestResult: CompleoShared.Assessments.getTestResult = (
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined
) => {
    if (!test) return null;
    const { questionsApplicant } = test;
    if (!(questionsApplicant || []).length) return null;
    if (
        (test.questionsRandomFilled || []).length !==
        (test.questionsApplicant || []).length
    ) {
        // test not completed
        return null;
    }
    if (test.testType === "answersToResults") {
        return getTestResultAnswersToResults(test);
    }

    let finalScore = 0;
    let maxScore = 0;

    questionsApplicant?.map((question) => {
        const { question: questionDetails } = question;
        const mainQuestionData: CompleoShared.Assessments.IQuestion = questionDetails;
        if (test.testType === "regular") {
            let questionMaxScore = 1;
            if (mainQuestionData.weight > 0) {
                questionMaxScore = questionMaxScore * mainQuestionData.weight;
            }
            maxScore = maxScore + questionMaxScore;

            const automaticQuestionTypes: CompleoShared.Assessments.questionType[] = [
                "multipleChoice",
                "singleChoice"
            ];
            if (
                automaticQuestionTypes.includes(
                    mainQuestionData.questiontype.value
                )
            ) {
                // automatic correction
                if (mainQuestionData.questiontype.value === "singleChoice") {
                    const answer = question.answer?.find(
                        (answer: CompleoShared.Assessments.IAnswer) =>
                            answer.correct
                    );
                    if (answer) {
                        finalScore = finalScore + questionMaxScore;
                    }
                } else {
                    const answersCorrectApplicant = question.answer?.filter(
                        (answer: CompleoShared.Assessments.IAnswer) =>
                            answer.correct
                    ).length;
                    const answersIncorrectApplicant = question.answer?.filter(
                        (answer: CompleoShared.Assessments.IAnswer) =>
                            answer.correct !== true
                    ).length;

                    const totalCorrect = mainQuestionData.answers.filter(
                        (answer: CompleoShared.Assessments.IAnswer) =>
                            answer.correct
                    ).length;
                    if (answersIncorrectApplicant) {
                        return 0;
                    }
                    if (answersCorrectApplicant === totalCorrect) {
                        finalScore = finalScore + questionMaxScore;
                    } else if (mainQuestionData.partialAccept) {
                        finalScore =
                            finalScore +
                            (answersCorrectApplicant / totalCorrect) *
                                questionMaxScore;
                    }
                }
            } else {
                // manual correction
                const correctionTypeList: CompleoShared.Assessments.correctionType[] = [
                    "correctIncorrect",
                    "starRating"
                ];
                if (
                    correctionTypeList.includes(
                        mainQuestionData?.correctionType?.value
                    )
                ) {
                    const manualCorrectionScore =
                        mainQuestionData.manualCorrectionScore || 0;

                    finalScore =
                        finalScore +
                        (questionMaxScore * manualCorrectionScore) / 100;
                }
            }
        } else if (test.testType === "weightAnswers") {
            const { answerScore, questionMaxScore } = getAnswerWeightDetails(
                question.answer,
                mainQuestionData
            );
            maxScore = maxScore + questionMaxScore;
            finalScore = finalScore + answerScore;
        }
    });
    return { score: (finalScore / maxScore) * 100 };
};

export const getTestResultAnswersToResults: CompleoShared.Assessments.getTestResult = (
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined
) => {
    if (!test) return null;
    const { questionsApplicant } = test;
    if (!(questionsApplicant || []).length) return null;

    const possibleResults = test?.possibleResults || [];
    const finalResult: CompleoShared.Assessments.IAnswersToResultScore[] = [];

    for (const result of possibleResults) {
        let finalScore = 0;
        let maxScore = 0;

        // eslint-disable-next-line no-loop-func
        questionsApplicant?.map((question) => {
            const { question: questionDetails } = question;
            const mainQuestionData: CompleoShared.Assessments.IQuestion = questionDetails;

            const itemMaxScoreList = mainQuestionData.answers.map((answer) => {
                const resultCurrent = answer.answerToResults?.find(
                    (i) => i.result === result.name
                );
                return resultCurrent?.resultValue || 0;
            });

            maxScore = maxScore + Math.max(...itemMaxScoreList);
            const answersApplicant: CompleoShared.Assessments.IAnswer = (question.answer ||
                [])[0];

            const answer = answersApplicant.answerToResults?.find(
                (i) => i.result === result.name
            );

            if (answer) {
                finalScore = finalScore + answer.resultValue;
            }
        });
        finalResult.push({
            resultName: result.name,
            score: (finalScore / maxScore) * 100
        });
    }

    const finalScore = finalResult.find(
        (i) => i.resultName === test?.answersToResultsNameToCalculateScore
    )?.score;
    return { score: finalScore || 0, answersToResultScore: finalResult };
};

export const getQuestionMaxScore: CompleoShared.Assessments.getQuestionMaxScore = (
    question: CompleoShared.Assessments.IQuestion
) => {
    let maxScore = 0;
    switch (question.questiontype?.value) {
        case "multipleChoice":
            question.answers.map((answer) => {
                if (
                    answer?.weight?.value &&
                    Number(answer?.weight?.value) > 0
                ) {
                    maxScore = maxScore + Number(answer?.weight?.value);
                }
            });
            break;
        case "singleChoice":
            {
                const weights = question.answers.map((answer) => {
                    if (
                        answer?.weight?.value &&
                        Number(answer?.weight?.value) > 0
                    ) {
                        return Number(answer?.weight?.value);
                    } else {
                        return 0;
                    }
                });
                const maxValue = Math.max(...weights);
                maxScore = maxScore + maxValue;
            }
            break;
        default:
            break;
    }
    return maxScore;
};
const answerTypesWithWeight: CompleoShared.Assessments.questionType[] = [
    "multipleChoice",
    "singleChoice"
];

export const getAnswerWeightDetails: CompleoShared.Assessments.getAnswerWeightDetails = (
    answers: CompleoShared.Assessments.IAnswer[],
    questionData: CompleoShared.Assessments.IQuestion
) => {
    let answerScore = 0;
    let questionMaxScore = 0;
    if (answerTypesWithWeight.includes(questionData?.questiontype?.value)) {
        questionMaxScore = questionMaxScore + getQuestionMaxScore(questionData);

        if (questionData) {
            answers.map((item) => {
                const answerData = questionData.answers.filter(
                    (a) => a.id === item.id
                )[0];
                if (answerData) {
                    if (answerData.weight?.value) {
                        const weight = Number(answerData.weight?.value);
                        answerScore = answerScore + weight;
                    }
                }
            });
        }
    }
    return { answerScore, questionMaxScore };
};
