import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";

import { Grid, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const RightAIUsageComponent = (props: any) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation("AIUSAGELIST");
    const { company } = useAuthState();

    const year = props.year || new Date().getFullYear();
    const month = props.month || new Date().getMonth() + 1;

    const months = getMonths(i18n.language);

    const handleChange = (event: any, newValue: any) => {
        navigate(`/reports/aiusage/${newValue.year}/${newValue.month}`);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Autocomplete
                    id="combo-box-demo"
                    options={months}
                    getOptionLabel={(option: any) => option.label}
                    getOptionSelected={(option: any, value: any) =>
                        option.month === value.month &&
                        option.year === value.year
                    }
                    value={months.find(
                        (m: any) => m.month === month && m.year === year
                    )}
                    onChange={handleChange}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Month" />
                    )}
                    disableClearable={true}
                />
            </Grid>
        </Grid>
    );
};

const getMonths = (language: string) => {
    const months = [];
    const today = new Date();
    for (let i = 0; i < 12; i++) {
        let month = today.getMonth() - i;
        let year = today.getFullYear();
        if (month < 0) {
            month += 12;
            year -= 1;
        }
        months.push({
            month: month + 1,
            year,
            label: `${getMonthName(month, language)} ${year}`
        });
    }
    return months;
};

const getMonthName = (month: number, language: string) => {
    const monthName = new Date(2020, month, 1).toLocaleString(language, {
        month: "long"
    });
    return monthName;
};

export default RightAIUsageComponent;
