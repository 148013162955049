import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import { useApiCache } from "customHooks/useApi";
import useJobContext from "../useJobContext";
import { useAuthState } from "_ReactContext/AuthContext";
import InfiniteLoader from "react-window-infinite-loader";
import { useTranslation } from "react-i18next";
import JobHistoryNewCommentModal from "./JobHistoryNewCommentModal";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import JobHistoryRow from "./JobHistoryRow";
import {
    getSizeByHistoryType,
    HISTORYCOMMENTMAINHEIGHT
} from "./JobHistoryHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: theme.palette.backgroundGrey.main
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {
            // maxWidth: 1000
        }
    })
);

const pageSize = 10;

const initialState: Compleo.IObject[] = [];

const reducer = (
    state: Compleo.IObject[],
    action: {
        indexToStart: number;
        data: Compleo.IObject[];
        reset?: boolean;
    }
) => {
    const { reset = false, indexToStart, data } = action;
    const pageSize = data.length;

    const lastIndex = indexToStart + pageSize;
    let normalIndex = 0;
    const currentState = reset ? [] : [...state];

    for (let index = indexToStart; index < lastIndex; index++) {
        currentState[index] = action.data[normalIndex];
        normalIndex = normalIndex + 1;
    }
    return currentState;
};

const JobHistoryMain = (props: any) => {
    const classes = useStyles();
    const [data] = useJobContext();
    const { company } = useAuthState();
    const { t, JobId, language } = data;
    const [modalCommentOpen, setModalCommentOpen] = React.useState(false);
    const [totalItems, setTotalItems] = React.useState(0);
    const [firstLoadFinished, setFirstLoadFinished] = React.useState(false);

    // const [historyData, setHistoryData] = React.useState<Compleo.IObject[]>([]);
    const [historyData, setHistoryData] = React.useReducer(
        reducer,
        initialState
    );

    const [tLocal, i18n, readyTranslation] = useTranslation("JOBVIEWHISTORY", {
        useSuspense: false
    });
    const listAddress = `/job/jobhistorylist`;
    const postData = {
        jobId: JobId,
        companyId: company.companyId,
        pageSize: pageSize,
        page: 1,
        sizeProcessLocal: 0,
        startArray: 0
    };
    const [listHistory, executeListHistory] = useApiCache(
        listAddress,
        "post",
        postData,
        false
    );
    const infiniteLoaderRef = React.useRef(null);
    const reactWindowRef = React.useRef(null);
    const hasMountedRef = React.useRef(false);

    const dbHistoryData: Compleo.IObject[] =
        listHistory.response?.data?.data || [];
    const total = listHistory.response?.data?.totalCount || 0;
    const currentPage: number = listHistory.response?.data?.page || 0;
    const startArray: number = listHistory.response?.data?.startArray || 0;
    const ready = listHistory.status === "success";

    React.useEffect(() => {
        if (ready && !firstLoadFinished) {
            setFirstLoadFinished(true);
        }
    }, [ready]);

    React.useEffect(() => {
        if (dbHistoryData.length) {
            if (total > 0 && total !== totalItems) {
                setHistoryData({
                    data: dbHistoryData,
                    reset: true,
                    indexToStart: startArray
                });
                executeListHistory({ ...postData });
                setTotalItems(total);
            } else {
                setHistoryData({
                    data: dbHistoryData,
                    indexToStart: startArray
                });
            }
        }
    }, [dbHistoryData, currentPage, total, totalItems, startArray]);

    React.useEffect(() => {
        if (total > 0 && totalItems > 0 && total !== totalItems) {
            if (hasMountedRef.current) {
                const currentInfiteLoader: any = infiniteLoaderRef.current;
                if (currentInfiteLoader) {
                    currentInfiteLoader.resetloadMoreItemsCache();
                }
                setTotalItems(0);
            }
        }
        hasMountedRef.current = true;
    }, [total, totalItems]);

    React.useEffect(() => {
        if (totalItems) {
            const currentReactWindowRef: any = reactWindowRef.current;
            if (currentReactWindowRef) {
                currentReactWindowRef.resetAfterIndex(startArray);
            }
        }
    }, [startArray, totalItems]);

    const getItemSize = (index: number) => {
        const historyItem = historyData[index];
        return getSizeByHistoryType(
            historyItem?.type,
            historyItem?.comment !== undefined
        );
    };
    const isItemLoaded = (index: number) => historyData[index] !== undefined;

    const loadMoreItems = (startIndex: number, stopIndex: number) => {
        executeListHistory({
            ...postData,
            startArray: startIndex,
            pageSize: stopIndex + 1 - startIndex
        }).then(() => {
            return null;
        });
        return null;
    };

    if (readyTranslation && firstLoadFinished) {
        return (
            <Box className={classes.container}>
                <Paper className={classes.paperContainer}>
                    <Grid container>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1">
                                {tLocal("title")}
                            </Typography>
                        </Grid>
                        <Grid item xs sm container justify="flex-end">
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => setModalCommentOpen(true)}
                            >
                                {tLocal("buttonNewComment")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Timeline align="left" className={classes.timeline}>
                    <InfiniteLoader
                        ref={infiniteLoaderRef}
                        isItemLoaded={isItemLoaded}
                        itemCount={totalItems}
                        //@ts-ignore
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={10}
                        threshold={15}
                    >
                        {({ onItemsRendered, ref }) => (
                            <AutoSizer>
                                {({ height, width }: any) => (
                                    <VariableSizeList
                                        height={height}
                                        itemCount={totalItems}
                                        itemSize={getItemSize}
                                        width={width}
                                        itemData={{
                                            historyData: historyData,
                                            tLocal: tLocal
                                        }}
                                        onItemsRendered={onItemsRendered}
                                        ref={(list) => {
                                            // @ts-ignore
                                            ref(list);

                                            const currentRef: any = reactWindowRef;
                                            currentRef.current = list;
                                        }}
                                        estimatedItemSize={
                                            HISTORYCOMMENTMAINHEIGHT
                                        }
                                    >
                                        {JobHistoryRow}
                                    </VariableSizeList>
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </Timeline>

                <JobHistoryNewCommentModal
                    open={modalCommentOpen}
                    onClose={() => setModalCommentOpen(false)}
                />
            </Box>
        );
    } else {
        return <Loading />;
    }
};

export default JobHistoryMain;
