import React from "react";
import HelperLoading from "Components/Loading/HelperLoading";
import { apiDirectCall } from "customHooks/useApi/api";
import { Auth, API } from "aws-amplify";
import { TFunction } from "i18next";
import useQueryCompanyData from "customHooks/useQuery/useQueryCompanyData";
import { isNull } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import _ from "lodash";
import { LOCALSTORAGEINACTIVITYNAME } from "auth/useIdleTimer";

export const LOGOFFISINACTIVITYSTORAGENAME = "logoffIsInactivity";
interface ICompany {
    companyId: number;
    plan: null | string;
    companyName: null | string;
    companyFancyName: null | string;
    featuresAccess: null | string[];
    defaultCurrency: null | string;
    branchAccessControl: boolean;
    scoreType: Compleo.scoreType;
    maxUsers: number;
    maxJobs: number;
    maxApplicants: number;
    jobBoardLinkedInCompanyPage?: string;
    jobTitleType?: CompleoShared.Company.jobTitleType;
    allowJobAccessOnlyTeam: boolean;
    parserCompany: boolean;
    parserCompanyAcceptExternalSender: boolean;
    parserAutomaticProcess: boolean;
    uniqueName: string;
    otherData: {
        URL_CAREERS_ROOT: string;
        URL_MAIN_ROOT: string;
        DOMAIN_EMAIL: string;
    };
    featureFlags: string[];
    inactivityTimeoutMinutes: number;
}

export interface IAuthState {
    status: "pending" | "success" | "reloading" | "error";
    user: null | string;
    error: null | Compleo.IObject;
    companyLoaded: boolean;
    company: ICompany;
    allCompanies: Compleo.IObject[];
    featuresAccess: Compleo.IObject[];
    routesAccess: Compleo.IObject[];
    login: (email: string, password: string) => Promise<any>;
    logout: (isInactivity?: boolean) => Promise<void>;
    register: TFunction;
    changeCompany: (companyId: number) => Promise<void>;
    reloadCompanyAndPermissionData: () => void;
    timeZone: null | string;
    userName: null | string;
}

const defaultState: IAuthState = {
    status: "pending",
    user: null,
    error: null,
    companyLoaded: false,
    company: {
        companyId: 0,
        plan: null,
        companyName: null,
        companyFancyName: null,
        featuresAccess: null,
        defaultCurrency: null,
        branchAccessControl: false,
        scoreType: "stars",
        maxApplicants: 0,
        maxJobs: 0,
        maxUsers: 0,
        jobBoardLinkedInCompanyPage: undefined,
        jobTitleType: "default",
        allowJobAccessOnlyTeam: false,
        parserCompany: false,
        parserCompanyAcceptExternalSender: false,
        parserAutomaticProcess: false,
        uniqueName: "",
        otherData: {
            URL_CAREERS_ROOT: "",
            URL_MAIN_ROOT: "",
            DOMAIN_EMAIL: ""
        },
        featureFlags: [],
        inactivityTimeoutMinutes: 60 * 4
    },
    allCompanies: [],
    featuresAccess: [],
    routesAccess: [],
    login: async () => {},
    logout: async () => {},
    register: () => null,
    changeCompany: async (companyId: number) => {},
    reloadCompanyAndPermissionData: () => {},
    timeZone: null,
    userName: null
};

const AuthContext = React.createContext(defaultState);

const getUserFromCognito = () =>
    Auth.currentAuthenticatedUser({
        bypassCache: false
    })
        .then((userFromCognito: any) => ({ userName: userFromCognito }))
        .catch((err: any) => null);

const getStateFromLocalStorage = (key: string) => {
    let hasValue = false;
    const stateFromLocalStorageString = localStorage.getItem(key);
    let state = {};
    if (stateFromLocalStorageString !== null) {
        hasValue = true;
        state = JSON.parse(stateFromLocalStorageString);
    }
    return { state, hasValue };
};

function AuthProvider(props: any) {
    const stateLocalStorage = getStateFromLocalStorage(
        "CompleoContext_companyUserData"
    );
    const initialState = { ...defaultState, ...stateLocalStorage.state };
    const [state, setState] = React.useState(initialState);
    const queryCompanyData: any = useQueryCompanyData(
        state.user || "",
        state.company.companyId
    );
    const queryClient = useQueryClient();

    const loadCompanyDataFromQuery = (newState: IAuthState = { ...state }) => {
        newState.status = "success";
        const data = queryCompanyData.data;
        if ((data.allCompanies || []).length === 0) {
            Auth.signOut(); // clear the token in localStorage and the user data
            const newStateReturn = { ...defaultState };
            newStateReturn.status = "success";
            setState(newStateReturn);
        } else {
            const returnCompany = {
                company: {
                    companyFancyName: data.company.CompanyFancyName,
                    companyName: data.company.CompanyName,
                    plan: data.company.Plan,
                    companyId: data.company.pk.split(":")[1],
                    featuresAccess: data.company.featuresAccessCompany,
                    defaultCurrency: data.company.defaultCurrency,
                    scoreType: data.company.scoreType || "stars",
                    branchAccessControl:
                        data.company.branchAccessControl !== undefined
                            ? data.company.branchAccessControl
                            : false,
                    maxUsers: data.company.maxUsers || 0,
                    maxJobs: data.company.maxJobs || 0,
                    maxApplicants: data.company.maxApplicants || 0,
                    jobBoardLinkedInCompanyPage:
                        data.company.jobBoardLinkedInCompanyPage,
                    jobTitleType: data.company.jobTitleType,
                    allowJobAccessOnlyTeam:
                        data.company.allowJobAccessOnlyTeam === true,
                    parserCompany: data.company.parserCompany === true,
                    parserCompanyAcceptExternalSender:
                        data.company.parserCompanyAcceptExternalSender === true,
                    parserAutomaticProcess:
                        data.company.parserAutomaticProcess === true,
                    uniqueName: data.company.GS1sk.split(":")[1],
                    otherData: {
                        URL_CAREERS_ROOT:
                            data.company?.otherData?.URL_CAREERS_ROOT,
                        URL_MAIN_ROOT: data.company?.otherData?.URL_MAIN_ROOT,
                        DOMAIN_EMAIL: data.company?.otherData?.DOMAIN_EMAIL
                    },
                    featureFlags: data.company?.featureFlags || [],
                    inactivityTimeoutMinutes:
                        data.company.inactivityTimeoutMinutes || 60 * 4
                },
                companyLoaded: true,
                allCompanies: data.allCompanies,
                featuresAccess: data.dataAccessAuth.featuresName,
                routesAccess: data.dataAccessAuth.routesFeatures,
                timeZone: data.timeZone
            };
            const stateToUpdate = { ...newState, ...returnCompany };
            localStorage.setItem(
                "CompleoContext_companyUserData",
                JSON.stringify(stateToUpdate)
            );
            setState(stateToUpdate);
        }
    };

    React.useEffect(() => {
        getUserFromCognito().then(
            (user: any) => {
                let userEmail = null;
                let userName = null;
                const newState: Compleo.IObject = {};
                if (user) {
                    userEmail = user.userName.attributes.email;
                    userName = user.userName.attributes.name;
                }
                newState.status = "success";
                newState.user = userEmail;
                newState.userName = userName;
                setState({ ...state, ...newState });
            },
            (error: any) => {
                setState({
                    ...defaultState,
                    status: "error",
                    error,
                    user: null
                });
            }
        );
    }, []);

    React.useEffect(() => {
        if (
            state.user !== null &&
            state.user !== undefined &&
            state.user !== "" &&
            queryCompanyData.status === "success"
        ) {
            loadCompanyDataFromQuery({ ...state });
        }
    }, [
        state.company.companyId,
        state.user,
        state.timeZone,
        state.userName,
        queryCompanyData.status,
        JSON.stringify(
            ((queryCompanyData.data || {}).company || {})
                .featuresAccessCompany || []
        )
    ]);

    // debugger;
    // reloading para situações em que apenas a empresa está sendo alterada
    if (state.status === "pending" || state.status === "reloading") {
        return <HelperLoading />;
    }

    const login = async (email: string, password: string) => {
        try {
            const user = await Auth.signIn({
                username: email,
                password: password
            });
            if (user) {
                const currentUser = await Auth.currentAuthenticatedUser({
                    bypassCache: false
                });
                const userEmail = currentUser.attributes.email;
                localChangeCompany(userEmail, 0);
                return user;
            }
        } catch (ex) {
            const newStatus = { ...defaultState };
            //@ts-ignore
            newStatus.error = ex;
            newStatus.user = null;
            newStatus.timeZone = null;
            newStatus.status = "error";
            setState(newStatus);
            console.error("Login Error", JSON.stringify(ex));
            throw ex;
        }
    };

    const changeCompany = async (companyId: number) => {
        setState({ ...state, status: "reloading" });
        const currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: false
        });

        const userEmail = currentUser.attributes.email;
        localChangeCompany(userEmail, companyId);
    };

    const reloadCompanyAndPermissionData = () => {
        queryCompanyData.refetch();
        // loadCompanyDataFromQuery();
        // localChangeCompany(state.user || "", state.company.companyId);
    };

    const localChangeCompany = (userEmail: string, companyId: number) => {
        const newState = { ...defaultState };
        newState.status = "success";
        newState.user = userEmail;
        newState.company.companyId = companyId;
        localStorage.setItem(
            "CompleoContext_companyUserData",
            JSON.stringify(newState)
        );
        setState(newState);
    };

    // make a login request
    const register = () => {}; // register the user
    const logout = async (isInactivity = false) => {
        if (isInactivity) {
            localStorage.setItem(LOGOFFISINACTIVITYSTORAGENAME, "true");
        }
        if (localStorage.getItem(LOCALSTORAGEINACTIVITYNAME)) {
            localStorage.removeItem(LOCALSTORAGEINACTIVITYNAME);
        }

        queryClient.cancelQueries();
        await Auth.signOut(); // clear the token in localStorage and the user data
        const newStateReturn = { ...defaultState };
        newStateReturn.status = "success";
        setState(newStateReturn);
    };

    // note, I'm not bothering to optimize this `value` with React.useMemo here
    // because this is the top-most component rendered in our app and it will very
    // rarely re-render/cause a performance problem.

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                changeCompany,
                reloadCompanyAndPermissionData
            }}
            {...props}
        />
    );
}

// const useAuth = () => React.useContext(AuthContext);

interface IPropsReturn {
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
    isAuthenticated: boolean;
}

interface IUseAuthStateReturn extends IPropsReturn, IAuthState {}
export type IUseAuthState = () => IUseAuthStateReturn;

const useAuthState: IUseAuthState = () => {
    const contextState = React.useContext(AuthContext);
    const isPending = contextState.status === "pending";
    const isError = contextState.status === "error";
    const isSuccess = contextState.status === "success";
    const isAuthenticated = contextState.user !== null && isSuccess;

    return {
        ...contextState,
        isPending,
        isError,
        isSuccess,
        isAuthenticated
    };
};

function useAuthorization() {
    const contextState = React.useContext(AuthContext);
    const isSuccess = contextState.status === "success";
    const isAuthenticated = contextState.user !== null && isSuccess;

    const isRouteAuthorized = (routeUniqueName: string) => {
        if (!isAuthenticated || routeUniqueName === undefined) {
            return false;
        }
        const featuresGroupsUserHasAccess =
            contextState.company.featuresAccess || [];
        const routesAccess = contextState.routesAccess;

        const routeToAccess = routesAccess.filter(
            (r: any) => r.route === routeUniqueName
        );
        if (routeToAccess === undefined) {
            return false;
        }
        // const intersectArrays = routeToAccess.groupsAccess.filter((r: any) =>
        //     featuresGroupsUserHasAccess.includes(r)
        // );
        const allGroupsAccess = _.flattenDeep(
            routeToAccess.map((r: any) => r.groupsAccess)
        );

        const intersectArrays = allGroupsAccess.filter((r: any) =>
            featuresGroupsUserHasAccess.includes(r)
        );
        if (intersectArrays.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const isFeatureAuthorized = (featureName: string) => {
        if (!isAuthenticated) {
            return false;
        }
        const featuresGroupsUserHasAccess =
            contextState.company.featuresAccess || [];
        const featuresAccess = contextState.featuresAccess;
        const featureToAccess = featuresAccess.filter(
            (f: any) => f.feature === featureName
        )[0];
        if (featureToAccess === undefined) {
            return false;
        }
        const intersectArrays = featureToAccess.groupsAccess.filter((f: any) =>
            featuresGroupsUserHasAccess.includes(f)
        );
        if (intersectArrays.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    return {
        isRouteAuthorized,
        isFeatureAuthorized
    };
}

export { AuthProvider, useAuthState, useAuthorization };
