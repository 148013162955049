import {
    Box,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { TFunction } from "i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided
} from "react-beautiful-dnd";
import { useGetOnDragEndJobAssessments } from "./helper";
import useJobContext from "../../useJobContext";
import { HandleDeleteButton, HandleEditButton } from "./ActionsButtons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    nested: {
        // paddingLeft: theme.spacing(4),
        marginBottom: 12,
        marginTop: 4
    },
    table: {}
}));

interface IAssessmentDetails {
    tests: CompleoShared.Assessments.ITest[];
    tLocal: TFunction;
}

const AssessmentTestList = (props: IAssessmentDetails) => {
    const { tests, tLocal } = props;
    const [data] = useJobContext();

    const classes = useStyles();
    const onDragEnd = useGetOnDragEndJobAssessments();

    if (tests.length > 0) {
        return (
            <Box marginTop={4}>
                <Typography
                    variant="body1"
                    style={{
                        fontStyle: "italic",
                        marginBottom: 8,
                        fontWeight: 700
                    }}
                >
                    {tLocal("associatedTests")}
                </Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                    <TableContainer className={classes.nested}>
                        <Table
                            className={classes.table}
                            aria-label="assessments table"
                            size="small"
                        >
                            <TableHead
                                style={{
                                    backgroundColor: "#614F71"
                                }}
                            >
                                <TableRow>
                                    <TableCell
                                        style={{
                                            fontWeight: 600,
                                            color: "white"
                                        }}
                                    >
                                        {tLocal("name", { ns: "TESTNEW" })}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: 600,
                                            color: "white"
                                        }}
                                    >
                                        {tLocal(`testType`, { ns: "TESTNEW" })}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: 600,
                                            color: "white"
                                        }}
                                        align="right"
                                    >
                                        {tLocal("actions")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <Droppable
                                droppableId={"group"}
                                direction="vertical"
                                type="answers"
                            >
                                {(provided: DroppableProvided) => (
                                    <TableBody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {tests.map((test, index) => (
                                            <Draggable
                                                key={test.skGS1pk}
                                                draggableId={test.name}
                                                index={index}
                                                isDragDisabled={true} //if in the future we want to enable drag and drop
                                            >
                                                {(
                                                    provided: DraggableProvided,
                                                    snapshot: DraggableStateSnapshot
                                                ) => {
                                                    return (
                                                        <TableRow
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                                backgroundColor: snapshot.isDragging
                                                                    ? "#e8eaf6"
                                                                    : "#FFF6FF",
                                                                display: snapshot.isDragging
                                                                    ? "table"
                                                                    : undefined
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {getTestName(
                                                                    test,
                                                                    tLocal
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {tLocal(
                                                                    `typeTest_${test.testType}`,
                                                                    {
                                                                        ns:
                                                                            "TESTNEW"
                                                                    }
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <HandleEditButton
                                                                    jobId={
                                                                        data.JobId
                                                                    }
                                                                    t={tLocal}
                                                                    test={test}
                                                                />
                                                                <HandleDeleteButton
                                                                    jobId={
                                                                        data.JobId
                                                                    }
                                                                    t={tLocal}
                                                                    test={test}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </TableContainer>
                </DragDropContext>
            </Box>
        );
    } else {
        return <NoAssociatedTests tLocal={tLocal} />;
    }
};

const getTestName = (test: CompleoShared.Assessments.ITest, t: TFunction) => {
    if (test.testType === "answersToResults") {
        return (
            test.name +
            " (" +
            t(`answersToResultsNameToCalculateScore`, {
                ns: "TESTNEW"
            }) +
            ": " +
            test.answersToResultsNameToCalculateScore +
            ")"
        );
    }
    return test.name;
};

interface INoAssociatedTests {
    tLocal: TFunction;
}
const NoAssociatedTests = (props: INoAssociatedTests) => {
    const { tLocal } = props;
    const classes = useStyles();

    return (
        <ListItem button className={classes.nested}>
            <ListItemText
                primary={
                    <Typography color="secondary">
                        {tLocal("noAssociatedTests")}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default AssessmentTestList;
