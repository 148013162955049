import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsModalCardTableFields from "./JobKanban/JobKanbanCardFields/SettingsModalCardKanbanFields";
import StatusActions from "customHooks/useList/components/_componentsJob/StatusActions";
import useJobContext from "./useJobContext";
import { useAuthorization } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import { useEmailParser } from "customHooks/useEmailParser";
import { CopyToClipboardEmailParser } from "Components/CopyToClipboard/CopyToClipboardEmailParser";
import { Divider } from "@material-ui/core";
import JobPrintModal from "./JobPrintModal";

type MainMenuList = "cardKanbanFields" | "editJob" | "printJob";

interface IProps {
    anchorEl: any;
    setAnchorEl: any;
    t: any;
    jobId: string;
}

const JobKanbanColumnMenu = (props: IProps) => {
    const { anchorEl, setAnchorEl, t, jobId } = props;
    const [modalCardKanbanFields, setModalCardKanbanFields] = React.useState(
        false
    );
    const [showPrintModal, setShowPrintModal] = React.useState(false);
    const { isFeatureAuthorized } = useAuthorization();
    const emailParser = useEmailParser();

    const [data] = useJobContext();

    const allMenuOptions: MainMenuList[] = ["cardKanbanFields"];
    const isJobRequest = data.module === "JOBREQUESTVIEW";
    if (isFeatureAuthorized("EditJob")) {
        allMenuOptions.push("editJob");
    }
    const statusAllowedEditJobRequest: CompleoShared.Common.JobStatus[] = [
        "requested",
        "awaitingCorrectionRequest"
    ];
    if (isJobRequest && statusAllowedEditJobRequest.includes(data.jobStatus)) {
        if (!allMenuOptions.includes("editJob")) {
            allMenuOptions.push("editJob");
        }
    }
    if (!isJobRequest) {
        allMenuOptions.push("printJob");
    }

    const menuOptions = allMenuOptions;

    const options = orderMenuOptions(menuOptions, t);
    const handleClose = () => {
        setAnchorEl(null);
    };
    // afdsafsd

    const clickMenuItem = (action: MainMenuList) => {
        switch (action) {
            case "cardKanbanFields":
                setModalCardKanbanFields(true);
                break;
            case "editJob": {
                if (data.module !== "JOBREQUESTVIEW") {
                    navigate(
                        `/job/edit/${jobId}?redirectAfter=/job/view/${jobId}`
                    );
                } else {
                    navigate(
                        `/job/editrequest/${jobId}?redirectAfter=/job/viewrequest/${jobId}`
                    );
                }

                break;
            }
            case "printJob":
                setShowPrintModal(true);
                break;
            default:
                break;
        }
        handleClose();
    };
    const statusAllowedShowEmailParser: CompleoShared.Common.JobStatus[] = [
        "awaitingCustomer",
        "open"
    ];
    const hideStatusAction =
        isJobRequest && data.jobStatus !== "awaitingCorrectionRequest";

    return (
        <div>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {options.map((option: any) => {
                    return (
                        <MenuItem
                            key={option.value}
                            onClick={() => clickMenuItem(option.value)}
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
                {!hideStatusAction && (
                    <StatusActions
                        fieldData={data.Job?.data || {}}
                        isInsideJobView
                        showInDialog
                        t={data.t}
                        useApiKey={data.apiKeyJob}
                        showCloneJob={false}
                        handleCloseParentMenu={handleClose}
                        isJobRequestList={isJobRequest}
                    />
                )}
                {emailParser.isEnabled &&
                    statusAllowedShowEmailParser.includes(data.jobStatus) && (
                        <>
                            <Divider />
                            <MenuItem disabled button={false}>
                                {t("jobEmailParser")}
                            </MenuItem>
                            <MenuItem>
                                <CopyToClipboardEmailParser
                                    ml={0.7}
                                    jobId={data.JobId}
                                />
                            </MenuItem>
                        </>
                    )}
            </Menu>
            <SettingsModalCardTableFields
                onClose={() => setModalCardKanbanFields(false)}
                open={modalCardKanbanFields}
            />
            <JobPrintModal
                jobId={jobId}
                open={showPrintModal}
                onClose={() => setShowPrintModal(false)}
            />
        </div>
    );
};

const orderMenuOptions = (menuItems: MainMenuList[], t: any) => {
    const translatedNames = menuItems.map((item) => {
        return {
            label: t(`ModalMainMenu_${item}`),
            value: item
        };
    });
    translatedNames.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return translatedNames;
};

export default JobKanbanColumnMenu;
