import { useContext } from "react";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    Droppable,
    DroppableProvided,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import {
    Box,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import { createMarkup } from "functions/util";

interface IAnswersProps {
    questionData: CompleoShared.Assessments.IQuestion;
}
const Answers = (props: IAnswersProps) => {
    const { questionData } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const answerData: CompleoShared.Assessments.IAnswer[] = (
        ContextQuestionFieldsData?.questionsData || []
    ).filter((question) => question.id === questionData.id)[0]?.answers;

    const { ready = false, testType = "regular", t } =
        ContextQuestionFieldsData || {};

    const getTableHead = () => {
        switch (testType) {
            case "regular":
                return "correct";
            case "weightAnswers":
                return "weight";
            case "answersToResults":
                return "rankingToResults";
            default:
                return "";
        }
    };
    // const isWeightAnswers = testType === "weightAnswers";

    if (ready) {
        return (
            <Box width="100%">
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="simple table" size="small">
                        <TableHead
                            style={{
                                backgroundColor: "#614F71"
                            }}
                        >
                            <TableRow>
                                <TableCell
                                    style={{ fontWeight: 600, color: "white" }}
                                >
                                    {t("answer")}
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontWeight: 600,
                                        color: "white"
                                    }}
                                >
                                    {t(getTableHead())}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <Droppable
                            droppableId={questionData.id}
                            direction="vertical"
                            type="answers"
                        >
                            {(provided: DroppableProvided) => (
                                <TableBody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {answerData.map((item, index) => (
                                        <Draggable
                                            key={item.name}
                                            draggableId={item.name}
                                            index={index}
                                        >
                                            {(
                                                provided: DraggableProvided,
                                                snapshot: DraggableStateSnapshot
                                            ) => (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided
                                                            .draggableProps
                                                            .style,
                                                        backgroundColor: snapshot.isDragging
                                                            ? "#e8eaf6" // Dragging color
                                                            : index % 2 === 0
                                                            ? "#F8F8F8" // Even row color
                                                            : "#FFF6FF", // Odd row color
                                                        display: snapshot.isDragging
                                                            ? "table"
                                                            : undefined
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                            width: "80%"
                                                        }}
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            item.name
                                                        )}
                                                    />
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                            width: "20%"
                                                        }}
                                                    >
                                                        <ColumnDataByTestType
                                                            item={item}
                                                            testType={testType}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </TableContainer>
            </Box>
        );
    } else {
        return <Loading />;
    }
};

interface IColumDataByTestTypeProps {
    item: CompleoShared.Assessments.IAnswer;
    testType: CompleoShared.Assessments.testType;
}

const ColumnDataByTestType = (props: IColumDataByTestTypeProps) => {
    const { item, testType } = props;
    switch (testType) {
        case "regular":
            return <>{item.correct ? "correct" : "incorrect"}</>;
        case "weightAnswers":
            return <>{item.weight?.value || 0}</>;
        case "answersToResults":
            return (
                <>
                    {item.answerToResults?.map((answerToResult) => {
                        return (
                            <Box key={answerToResult.result}>
                                <Box>
                                    {answerToResult.result}
                                    {": "}
                                    {answerToResult.resultValue}%
                                </Box>
                                <Divider />
                            </Box>
                        );
                    })}
                </>
            );
        default:
            return null;
    }
};
export default Answers;
