import React from "react";
import { ApiStateType, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useCompleoViewEditFormGroupName } from "customHooks/useCompleoView";

const useGetMetadata: (
    compleoModule: string,
    companyIdExternal?: string
) => [ApiStateType] = (compleoModule: string, companyIdExternal?: string) => {
    const { company } = useAuthState();
    const companyId = companyIdExternal || company.companyId || "0";

    const urlMetadados = `/general/metadata/${companyId}/0/${compleoModule}`;

    const [metadadosRetorno, callMetadados] = useApiCache(
        urlMetadados,
        "get",
        {},
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        callMetadados();
    }, [compleoModule]);

    return [metadadosRetorno];
};

/**
 * This functions merge only the metadata properties of 2 metadata.
 * For the other properties, module2 will overwrite module1 data
 * @param compleoModule1 Module name 1
 * @param compleoModule2 Module name 2
 */
export const useGetMetadataMerge: (
    compleoModule1: string,
    compleoModule2: string,
    companyIdExternal?: string
) => [ApiStateType] = (
    compleoModule1: string,
    compleoModule2: string,
    companyIdExternal?: string
) => {
    const { company } = useAuthState();
    const plan = company.plan || "noplan";
    const companyId = company.companyId || companyIdExternal || "0";

    const urlMetadados1 = `/general/metadata/${companyId}/0/${compleoModule1}`;
    const [metadadosRetorno1, callMetadados1] = useApiCache(
        urlMetadados1,
        "get",
        {},
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        callMetadados1();
    }, [compleoModule1]);

    const urlMetadados2 = `/general/metadata/${companyId}/0/${compleoModule2}`;
    const [metadadosRetorno2, callMetadados2] = useApiCache(
        urlMetadados2,
        "get",
        {},
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        callMetadados2();
    }, [compleoModule2]);

    let status: "initial" | "fetching" | "success" | "error" = "initial";
    if (
        metadadosRetorno1.status === "success" &&
        metadadosRetorno2.status === "success"
    ) {
        status = "success";
    }
    if (
        metadadosRetorno1.status === "fetching" ||
        metadadosRetorno2.status === "fetching"
    ) {
        status = "fetching";
    }
    if (
        metadadosRetorno1.status === "initial" ||
        metadadosRetorno2.status === "initial"
    ) {
        status = "initial";
    }
    if (
        metadadosRetorno1.status === "error" ||
        metadadosRetorno2.status === "error"
    ) {
        status = "error";
    }

    const metadadoList1 = metadadosRetorno1.response?.data?.metadado || [];
    const metadadoList2 = metadadosRetorno2.response?.data?.metadado || [];
    const response1 = metadadosRetorno1.response?.data || {};
    const response2 = metadadosRetorno2.response?.data || {};

    const returnData: ApiStateType = {
        exception: null,
        status: status,
        response: {
            data: {
                ...response1,
                ...response2,
                metadado: [...metadadoList1, ...metadadoList2]
            }
        }
    };
    return [returnData];
};

/**
 * This functions return a filtered metadata with a formId and when the field is allowed to edit
 * @param compleoModule Module name
 * @param formId The formId to edit data
 */
export const useGetMetadataViewEdit: (
    compleoModule: string,
    formId: string,
    companyIdExternal?: string
) => [ApiStateType] = (
    compleoModule: string,
    formId: string,
    companyIdExternal?: string
) => {
    const { company } = useAuthState();
    const companyId = company.companyId || companyIdExternal || "0";

    const urlMetadados1 = `/general/metadata/${companyId}/0/${compleoModule}`;
    const [metadadosRetorno1, callMetadados1] = useApiCache(
        urlMetadados1,
        "get",
        {},
        false,
        1000 * 60 * 2
    );

    React.useEffect(() => {
        callMetadados1();
    }, [compleoModule]);

    const editFormGroupName = useCompleoViewEditFormGroupName;

    const metadadoList1 = (metadadosRetorno1.response?.data?.metadado || [])
        .filter(
            (item: Compleo.IObject) =>
                (item.formGroupId === formId &&
                    item.viewOptionAllowEdit === true) ||
                item.complexFieldDetail === true
        )
        .map((item: Compleo.IObject) => {
            return {
                ...item,
                columnSize: item.editColumnSize || {
                    xs: 12
                },
                formGroupId: editFormGroupName
            };
        });
    const response1 = metadadosRetorno1.response?.data || {};

    const returnData: ApiStateType = {
        exception: null,
        status: metadadosRetorno1.status,
        response: {
            data: {
                ...response1,
                metadado: [...metadadoList1],
                IsMultiStepForm: false,
                MultiStepForm: [],
                formGroups: [
                    {
                        id: editFormGroupName,
                        order: 1,
                        viewOptionColumnSize: {
                            xs: 12
                        }
                    }
                ]
            }
        }
    };
    return [returnData];
};
export default useGetMetadata;
