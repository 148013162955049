import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useList, { IFilters } from "../..";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Slide } from "@material-ui/core";
import { useCompleoFormNew } from "customHooks/useCompleoForm";
import { ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoForm/components/FormModal";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import _ from "lodash";
import useCustomSearchMetadata from "./useCustomSearchMetadata";
import { addSearchType, handleInitialValues } from "./helperCustomSearch";
import useValuesFromSource from "customHooks/useValuesFromSource";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

interface IPropsItemFilter {
    open: boolean;
    filterName: string;
    title: string;
    onClose: (value?: string) => void;
}

const clearIdFromValues = (values: any, id: string) => {
    const newValues: Compleo.IObject = {};
    Object.keys(values).map((name) => {
        const newKeyName = name.replace(`${id}_`, "");
        newValues[newKeyName] = values[name];
    });
    return newValues;
};

const addIdToValues = (values: any, id?: string) => {
    if (id && Object.keys(values).length) {
        const newValues: Compleo.IObject = {};
        Object.keys(values).map((name) => {
            const newKeyName = `${id}_${name}`;
            newValues[newKeyName] = values[name];
        });
        return newValues;
    }
    return values;
};

const CustomSearchModal = (props: IPropsItemFilter) => {
    const formik = React.useRef(null);
    const [listData, listDispatch] = useList();
    const { t } = listData.tempInfo;
    const { filters } = listData.filtersInfo;
    const { setFilters } = listDispatch;
    const { onClose, open, title, filterName, ...other } = props;
    const [firstLoad, setFirstLoad] = React.useState(false);
    const customSearchMetadata = useCustomSearchMetadata(open);
    const currentFilterDataValues = filters.customSearch[filterName] || {};
    const metadataReturnInfo = customSearchMetadata.metadataReturn.filter(
        (item) => item.fieldName === filterName
    )[0];
    console.log(
        "customSearchMetadata.metadataReturn",
        customSearchMetadata.metadataReturn
    );
    const filterInfo = (listData.definitionInfo.elasticDefData?.data
        ?.otherFilters || {})[filterName];

    React.useEffect(() => {
        if (firstLoad && customSearchMetadata.ready) {
            addSearchType(filterInfo?.type, filterName, customSearchMetadata);
            setFirstLoad(false);
        }
    }, [filterInfo?.type, firstLoad, customSearchMetadata.ready]);

    React.useEffect(() => {
        if (!firstLoad) {
            setFirstLoad(true);
        }
    }, [open]);

    const metadataState = metadataReturnInfo?.metadata ? "success" : "initial";
    const metadata: ApiStateType = {
        status: metadataState,
        response: {
            data: {
                metadado: metadataReturnInfo?.metadata,
                formGroups: metadataReturnInfo?.formGroups
            }
        },
        exception: null
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const handleOk = () => {
        onClose();
    };

    const handleSubmit = async (values: any) => {
        // metadataReturnInfo.id
        const retorno: Compleo.IObject = {};
        retorno.status = 200;

        const newState: Compleo.IObject = {};
        const newValues = clearIdFromValues(values, metadataReturnInfo.id);
        newState[filterName] = handleValuesData(newValues);

        setFilters({
            ...filters,
            customSearch: {
                ...(filters?.customSearch || {}),
                ...newState
            },
            filterUpdated: true
        });
        // setLocalFilterValues({});
        onClose();
        return retorno;
    };

    const handleSave = async () => {
        const formikValue: any = formik.current;
        if (formikValue) {
            formikValue.submitForm();
        }
    };

    const currentValues =
        metadataReturnInfo?.id && currentFilterDataValues
            ? addIdToValues(currentFilterDataValues, metadataReturnInfo?.id)
            : {};
    const newValues = handleInitialValues(
        currentValues,
        filterInfo?.type,
        metadataReturnInfo?.id
    );
    const [initialValues] = useValuesFromSource(metadata, true, newValues);
    const ready = metadataReturnInfo?.metadata !== undefined;
    const [formCompleo, finished] = useCompleoFormNew({
        t: metadataReturnInfo?.t,
        ready: ready,
        i18nV: customSearchMetadata.i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formikReturn: formik,
        customLists: metadataReturnInfo?.lists
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && ready) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby={title}
            open={open}
            TransitionComponent={Transition}
            TransitionProps={{ timeout: { enter: 500, appear: 500 } }}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>{compRetorno}</DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="secondary">
                    {t("ELASTICLISTSETTINGS:closeModalCustomSearch")}
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    {t("ELASTICLISTSETTINGS:saveModalCustomSearch")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const handleValuesData = (values: any) => {
    if (typeof values === "object") {
        for (const keyName of Object.keys(values)) {
            if (_.isDate(values[keyName])) {
                if (!isNaN(values[keyName].getTime())) {
                    values[keyName] = values[keyName].toISOString();
                } else {
                    values[keyName] = null;
                }
            }
        }
    }
    return values;
};

export default CustomSearchModal;
