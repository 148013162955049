import React, { useContext, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { DraggableProvided } from "react-beautiful-dnd";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import { Button, Chip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Questions from "../02_Questions/Questions";
import { ButtonAddEditSection, ButtonDeleteSection } from "./SectionsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                // margin: theme.spacing(0.5),
                mt: 40
            }
        },
        heading: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600
        },
        grow: {
            flexGrow: 1
        },
        chip: {
            margin: theme.spacing(0),
            marginRight: 8,
            backgroundColor: "#1976d2",
            color: "#fff",
            borderRadius: 0
        },
        accordionsummary: {
            alignItems: "center"
        }
    })
);
interface ISectionProps {
    section: CompleoShared.Assessments.ISection;
    provided: DraggableProvided;
    setModalAddEditSectionData: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            values?: CompleoShared.Assessments.ISection | undefined;
        }>
    >;
}

const Section = (props: ISectionProps) => {
    const classes = useStyles();
    const { section, provided, setModalAddEditSectionData } = props;

    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const sectionsData: CompleoShared.Assessments.ISection[] =
        ContextQuestionFieldsData?.sectionsData || [];
    const { setFieldValueSections, tSection } = ContextQuestionFieldsData || {};

    /**
     * Expand or collapse section
     */
    const handleExpanded = () => {
        const newSectionsData = sectionsData.map((item) => {
            if (item.id === section.id) {
                return {
                    ...item,
                    expanded: !item.expanded
                };
            }
            return item;
        });
        if (setFieldValueSections) {
            setFieldValueSections(newSectionsData);
        }
    };

    return (
        <Accordion
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            expanded={section.expanded}
            onChange={handleExpanded}
            className={classes.root}
            elevation={4}
            style={{
                ...provided.draggableProps.style,
                marginTop: 20
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ content: classes.accordionsummary }}
            >
                <DragIndicatorIcon style={{ marginRight: 6 }} />
                <Typography className={classes.heading}>
                    {section.name}
                </Typography>
                <div className={classes.grow} />

                {section.showAllQuestions ? (
                    <Chip
                        className={classes.chip}
                        label={tSection("showAllQuestionsInfo", {
                            ns: "TESTSECTIONMAIN"
                        })}
                    />
                ) : (
                    <Chip
                        className={classes.chip}
                        label={tSection("numberOfQuestionsToShowInfo", {
                            max: section.numberOfQuestionsToShow || 0
                        })}
                    />
                )}
                <ButtonAddEditSection
                    type="edit"
                    values={section}
                    setModalAddEditSectionData={setModalAddEditSectionData}
                />
                <ButtonDeleteSection values={section} />
            </AccordionSummary>
            <Divider />

            <AccordionDetails>
                <Questions sectionData={section} />
            </AccordionDetails>
        </Accordion>
    );
};

export default Section;
