import { IconButton } from "@material-ui/core";
import { TFunction } from "react-i18next";
import { Edit, Delete } from "@material-ui/icons";
import { navigate } from "@gatsbyjs/reach-router";
import { extractValue } from "functions/util";
import {
    useCheckAssessmentAlreadySent,
    useDeleteAssessmentOrTest
} from "./helper";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

interface IHandleButtons {
    test: CompleoShared.Assessments.ITest;
    jobId: string;
    t: TFunction;
}
export const HandleEditButton = (props: IHandleButtons) => {
    const { test, jobId, t } = props;
    const testId = extractValue(test.skGS1pk, "TEST");
    const assessmentId = extractValue(test.skGS1pk, "ASSESSMENT") || "";

    const { callDialog } = useGlobalDialog();
    const { assessmentAlreadySent, ready } = useCheckAssessmentAlreadySent(
        jobId,
        assessmentId
    );

    const handleEdit = async () => {
        const messageWarning = assessmentAlreadySent
            ? t("msgEditTest")
            : t("msgEditTestWithoutRequest");

        callDialog({
            title: t("COMPLEOGENERAL_Edit"),
            bodyText: messageWarning,
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const queryString = `redirectAfter=/job/view/${jobId}`;
                if (!testId || !assessmentId) return;

                navigate(
                    `/test/edit/${testId}/${assessmentId}/${jobId}?${queryString}`
                );
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    if (!ready) return null;
    return (
        <IconButton
            aria-label="edit"
            style={{
                margin: 0,
                padding: 0,
                marginRight: 10
            }}
            onClick={handleEdit}
        >
            <Edit />
        </IconButton>
    );
};

export const HandleDeleteButton = (props: IHandleButtons) => {
    const { test, jobId, t } = props;
    const testId = extractValue(test.skGS1pk, "TEST");
    const assessmentId = extractValue(test.skGS1pk, "ASSESSMENT") || "";

    const { callDialog } = useGlobalDialog();
    const { assessmentAlreadySent, ready } = useCheckAssessmentAlreadySent(
        jobId,
        assessmentId
    );
    const {
        handleDeleteMain,
        showMessageFullControl
    } = useDeleteAssessmentOrTest(t);

    const handleDelete = async () => {
        const messageWarning = assessmentAlreadySent
            ? t("msgDeleteTest")
            : t("msgDelete");

        callDialog({
            title: t("COMPLEOGENERAL_Delete"),
            bodyText: messageWarning,
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                await handleDeleteMain(test, jobId);
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };

    if (!ready) return null;
    return (
        <>
            <IconButton
                aria-label="edit"
                style={{
                    margin: 0,
                    padding: 0,
                    marginRight: 10
                }}
                onClick={async () => await handleDelete()}
            >
                <Delete />
            </IconButton>
            {showMessageFullControl.MessageElement}
        </>
    );
};
