import React from "react";

import useJobContext from "./useJobContext";
import { Tab, Tabs } from "@material-ui/core";
import { IMainState, PageToRenderList } from "./useJobContext/JobViewTypes";
import useApplicantAccess from "customHooks/useAccess/useApplicantAccess";
import { useAuthorization } from "_ReactContext/AuthContext";

const JobTabs = (props: any) => {
    const [data, dispatch] = useJobContext();
    const { dispatchGeneralPageInfo } = dispatch;
    const { isFeatureAuthorized } = useAuthorization();

    const { t } = data;
    const { pageToRender } = data.LocalStateData;
    const applicantAccess = useApplicantAccess();
    const isJobRequestWithoutApplicantAccess = isFeatureAuthorized(
        "JobRequestWithoutApplicantsAccess"
    );

    const PageToRenderLocalList = getTabsList(
        applicantAccess,
        data,
        isJobRequestWithoutApplicantAccess
    );
    const currentValue = PageToRenderLocalList.indexOf(pageToRender);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const newPageToRender = PageToRenderLocalList[newValue];
        dispatchGeneralPageInfo({
            type: "update",
            payload: { pageToRender: newPageToRender }
        });
    };

    return (
        <Tabs
            value={currentValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            variant="scrollable"
        >
            {PageToRenderLocalList.map((tab, index) => (
                <Tab key={tab} label={t(`TabPage_${tab}`)} />
            ))}
        </Tabs>
    );
};

const getTabsList = (
    applicantAccess: {
        allowAdd: boolean;
        allowEdit: boolean;
        allowView: boolean;
        allowDelete: boolean;
    },
    data: IMainState,
    isJobRequestWithoutApplicantAccess: boolean
) => {
    const removeApplicantsTabs =
        (!applicantAccess.allowEdit && data.module !== "JOBREQUESTVIEW") ||
        (isJobRequestWithoutApplicantAccess &&
            data.module === "JOBREQUESTVIEW");

    const showReports = data.module !== "JOBREQUESTVIEW";

    const filteredPageToRenderList = removeApplicantsTabs
        ? PageToRenderList.filter(
              (i) => !["pipeline", "applicants", "assessment"].includes(i)
          )
        : PageToRenderList;

    const filtered2 = showReports
        ? filteredPageToRenderList
        : filteredPageToRenderList.filter((i) => i !== "reports");

    return filtered2;
};

export default JobTabs;
