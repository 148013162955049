//@ts-nocheck

import { useEffect, useRef } from "react";
import Hls from "hls.js";

class CustomLoader extends Hls.DefaultConfig.loader {
    constructor(config) {
        super(config);
        this.queryString = config.queryString || "";
    }

    load(context, config, callbacks) {
        // Append the query string to .ts file requests
        if (context.url.endsWith(".ts") && this.queryString) {
            context.url += this.queryString;
        }
        super.load(context, config, callbacks);
    }
}
const VideoPlayer = ({ src, queryString }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (src) {
            const video = videoRef.current;
            if (!video) return;

            if (src.endsWith(".mp4") && video.canPlayType("video/mp4")) {
                video.src = src;
            } else if (Hls.isSupported()) {
                const hls = new Hls({
                    loader: CustomLoader,
                    queryString: queryString
                });
                hls.loadSource(src);
                hls.attachMedia(video);
                return () => {
                    hls.destroy();
                };
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
                video.src = src;
            }
        }
    }, [src, queryString]);

    return <video ref={videoRef} controls />;
};

export default VideoPlayer;
