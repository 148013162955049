import React from "react";
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided
} from "react-beautiful-dnd";
import CardContent from "@material-ui/core/CardContent";
import ComboAutoComplete from "customHooks/useCompleoReactHookForm/Inputs/ComboAutoComplete";
import Table from "@material-ui/core/Table";
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";

interface IProps {
    questionsFieldName: string;
    answers: Compleo.IObject[];
    enableActions: boolean;
    t: any;
    index: number;
    questionType: string;
    options: {
        label: any;
        value: any;
        group: string;
    }[];
    enableRanking: boolean;
}

const CardQuestionsAnswers = (props: IProps) => {
    const {
        answers,
        t,
        index,
        options,
        enableActions,
        questionType,
        questionsFieldName,
        enableRanking
    } = props;
    const enableActionsForAnswerOption =
        questionType === "CPQUESTIONSINGLECHOICE";

    if ((answers || []).length > 0) {
        return (
            <CardContent>
                <TableContainer>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 600 }}>
                                    {t("labelAnswer")}
                                </TableCell>
                                {enableRanking && (
                                    <TableCell style={{ fontWeight: 600 }}>
                                        {t("answers.weight")}
                                    </TableCell>
                                )}
                                {enableActions &&
                                    enableActionsForAnswerOption && (
                                        <TableCell style={{ fontWeight: 600 }}>
                                            {t("labelAction")}
                                        </TableCell>
                                    )}
                            </TableRow>
                        </TableHead>

                        <Droppable
                            droppableId={`question_${index}`}
                            direction="vertical"
                            type={`answer${index}`}
                        >
                            {(provided: DroppableProvided) => (
                                <TableBody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {answers.map(
                                        (answer: any, indexLocal: number) => (
                                            <AnswerItem
                                                key={answer.id}
                                                answer={answer}
                                                index={index}
                                                indexLocal={indexLocal}
                                                options={options}
                                                t={t}
                                                enableActions={enableActions}
                                                enableActionsForAnswerOption={
                                                    enableActionsForAnswerOption
                                                }
                                                questionsFieldName={
                                                    questionsFieldName
                                                }
                                                enableRanking={enableRanking}
                                            />
                                        )
                                    )}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </TableContainer>
            </CardContent>
        );
    } else {
        return null;
    }
};

export default CardQuestionsAnswers;

interface IPropsAnswer {
    questionsFieldName: string;
    enableActions: boolean;
    answer: any;
    index: number;
    indexLocal: number;
    enableActionsForAnswerOption: boolean;
    options: { label: any; value: any; group: string }[];
    t: any;
    enableRanking: boolean;
}
const AnswerItem = (props: IPropsAnswer): JSX.Element => {
    const {
        questionsFieldName,
        answer,
        index,
        indexLocal,
        options,
        t,
        enableActions,
        enableActionsForAnswerOption,
        enableRanking
    } = props;
    const actions = enableActions && enableActionsForAnswerOption;
    const columnSize = actions
        ? enableRanking
            ? "33%"
            : "50%"
        : enableRanking
        ? "50%"
        : "100%";

    return (
        <Draggable key={answer.id} draggableId={answer.id} index={indexLocal}>
            {(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot
            ) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        ...provided.draggableProps.style,
                        backgroundColor: snapshot.isDragging
                            ? "lightgrey"
                            : "white",
                        display: snapshot.isDragging ? "table" : undefined
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        style={{
                            width: columnSize
                        }}
                    >
                        {answer.name}
                    </TableCell>
                    {enableRanking && (
                        <TableCell
                            component="th"
                            scope="row"
                            style={{
                                width: columnSize
                            }}
                        >
                            {answer?.weight?.value || 0}
                        </TableCell>
                    )}
                    {enableActions && enableActionsForAnswerOption && (
                        <TableCell
                            style={{
                                width: columnSize
                            }}
                        >
                            <ComboAutoComplete
                                required={false}
                                name={`${questionsFieldName}.${index}.answers.${indexLocal}.action`}
                                label={t("dropdownActionAnswer")}
                                options={options}
                                groupByField="group"
                                campoMetadata={{}}
                                language={"pt-BR"}
                            />
                        </TableCell>
                    )}
                </TableRow>
            )}
        </Draggable>
    );
};
