import React, { useContext } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import { ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import * as util from "functions/util";

export interface IGenericStageActionsModal {
    open: boolean;
    onClose: () => void;
    values?: CompleoShared.Assessments.ISection;
    scrollToBottom: () => void;
}

const SectionAddEditModal = (props: IGenericStageActionsModal) => {
    const { open, onClose, values = {}, scrollToBottom } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const sectionsData: CompleoShared.Assessments.ISection[] =
        ContextQuestionFieldsData?.sectionsData || [];

    const { setFieldValueSections } = ContextQuestionFieldsData || {};

    const module = "TESTSECTIONMAIN";
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [metadata] = useGetMetadata(module);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const [initialValues] = useValuesFromSource(metadata, false, values);

    const ready = readyTranslation && metadata.status === "success";

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;

    const showAllQuestions = watch("showAllQuestions");
    React.useEffect(() => {
        if (showAllQuestions) {
            setFieldsToHide([
                "numberOfQuestionsToShow",
                "sectionQuestionsWeight"
            ]);
        } else {
            setFieldsToHide([]);
        }
    }, [showAllQuestions]);

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        setIsUpdating(true);
        retorno.status = 200;
        try {
            if (setFieldValueSections) {
                if (values.id) {
                    // Edit
                    const newValue: CompleoShared.Assessments.ISection = {
                        name: values.name,
                        order: values.order,
                        id: values.id,
                        expanded: values.expanded,
                        showAllQuestions: values.showAllQuestions === true,
                        numberOfQuestionsToShow: values?.numberOfQuestionsToShow
                    };
                    const sectionsDataNew = sectionsData.map((item) => {
                        if (item.id === values.id) {
                            return newValue;
                        }
                        return item;
                    });
                    setFieldValueSections(sectionsDataNew);
                } else {
                    // Add
                    const uuidNew = util.uuid();
                    const newValue: CompleoShared.Assessments.ISection = {
                        name: values.name,
                        order: sectionsData.length,
                        id: uuidNew,
                        expanded: true,
                        showAllQuestions: values.showAllQuestions === true,
                        numberOfQuestionsToShow: values?.numberOfQuestionsToShow
                    };
                    setFieldValueSections([...sectionsData, newValue]);
                    scrollToBottom();
                }
            }
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        setIsUpdating(false);

        onClose();
        return retorno;
    };

    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished, handleRHFSubmit] = useCompleoReactHookForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide: fieldsToHide
    });

    const handleSave = async () => {
        handleRHFSubmit();
    };

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    if (ready) {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_ModalTitle")}
                open={open}
            >
                <DialogTitle>{t("a_ModalTitle")}</DialogTitle>
                <DialogContent dividers>{FormReturn}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={(event) => {
                            onClose();
                        }}
                        color="secondary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_CANCEL")}
                    </Button>
                    <Button
                        onClick={(event) => {
                            handleSave();
                        }}
                        variant="contained"
                        color="primary"
                        disabled={isUpdating}
                    >
                        {t("COMPLEOGENERAL_SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <Dialog
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby={t("a_FeatureMainDescription")}
                open={open}
            >
                <DialogContent dividers>
                    <Loading />
                </DialogContent>
            </Dialog>
        );
    }
};

export default SectionAddEditModal;
