import React, { useCallback } from "react";
import {
    IInputProps,
    useRhfFieldControlled
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import SunEditor, { buttonList } from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import Plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { getToolbarRichText } from "./RichTextHelper";
import { useLanguageState } from "_ReactContext/LanguageContext";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormHelperText } from "@material-ui/core";
import {
    isFileFromDB,
    getUrlFromS3
} from "customHooks/useCompleoReactHookForm/Inputs/File/File";
import { apiDirectCall } from "customHooks/useApi/api";
import { useAuthState } from "_ReactContext/AuthContext";
import Picker, { IEmojiData } from "emoji-picker-react";
import EmojiDialog from "./components/EmojiDialog";
import useVariableList, {
    richTextVariablesType
} from "customHooks/useCustomCVVariableList";
import Loading from "Components/Loading/HelperLoading";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { plugin_emoji } from "./plugin_emoji";

export type IToolbarType =
    | "min"
    | "full"
    | "jobDescription"
    | "customCV"
    | "main";

interface IStyleProps {
    height: string;
    minHeight: string;
    maxHeight: string;
}
export interface IRichTextConfig {
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    enableCodeButton?: boolean;
    toolbarType?: IToolbarType;
    variablesType?: richTextVariablesType;
    publicImagesFolder?: string;
}
interface IProps {
    metadata: {
        [x: string]: any;
        richTextConfig?: IRichTextConfig;
    };
    toolbarCustomButtons?: any[];
    mention?: any;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => {
    const colorText = theme.palette.grey[400];
    const colorError = theme.palette.error.main;

    return createStyles({
        wrapper: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorText} !important`,
            marginTop: theme.spacing(1)
        },
        wrapperError: {
            border: "1px solid !important",
            borderRadius: "1px !important",
            borderColor: `${colorError} !important`,
            marginTop: theme.spacing(1)
        },
        label: {
            fontSize: 12
        },
        editorText: {
            height: (props) => `${props.height} !important`,
            minHeight: (props) => `${props.minHeight} !important`,
            maxHeight: (props) => `${props.maxHeight} !important`
        },
        formControl: {
            marginTop: theme.spacing(2),
            width: "100%"
        }
    });
});

const RichTextV3 = (props: IProps & IInputProps) => {
    const {
        label,
        helperTextDefault,
        required,
        metadata,
        toolbarCustomButtons = [],
        t,
        mention,
        ...other
    } = props;

    const {
        error,
        message,
        setFieldValue,
        //setValue,
        field,
        setError,
        formState
    } = useRhfFieldControlled(metadata.fieldName, helperTextDefault);

    const { company } = useAuthState();
    const language = useLanguageState();
    const richTextConfig: IRichTextConfig = metadata?.richTextConfig || {};
    const {
        height = "80%",
        minHeight = "10px",
        maxHeight = "2000px",
        variablesType = "EMPTY",
        publicImagesFolder = "customCV"
    } = richTextConfig || {};

    const [variableList, ready] = useVariableList(
        variablesType,
        language || "pt-BR",
        t
    );

    const [
        showErrorRequiredPosition,
        setShowErrorRequiredPosition
    ] = React.useState(false);

    const editor = React.useRef<SunEditorCore>();

    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };

    const [openEmoji, setOpenEmoji] = React.useState(false);

    const plugin2 = React.useMemo(
        () => plugin_emoji(() => setOpenEmoji(true), "Emoji", "😀"),
        []
    );

    const toolbarObj = getToolbarRichText(richTextConfig);

    const classes = useStyles({
        height: height,
        maxHeight: maxHeight,
        minHeight: minHeight
    });

    const onEmojiClick = (
        event: React.MouseEvent<Element, MouseEvent>,
        emojiObject: IEmojiData
    ) => {
        if (editor.current) {
            editor.current.insertHTML(emojiObject.emoji, true, true);
        }
        setOpenEmoji(false);
    };

    const handleCustomCVVariableClick = (data: any) => {
        if (editor.current) {
            const label = data?.label ? `[${data.label}]` : "";
            editor.current.insertHTML(label);
            setShowErrorRequiredPosition(false);
        }
    };

    const handleBlur = (focusEvent: any, editorContents: string) => {
        if (editor.current) {
            const newHtml = editor.current.core
                .cleanHTML(editor.current.core.getContents(true))
                .trim();

            const oldHtml = editor.current.core
                .cleanHTML(field.value || "")
                .trim();

            if (newHtml === oldHtml) return;
            if (newHtml.trim() === "<p>") {
                setFieldValue("");
            } else {
                setFieldValue(editorContents);
            }
        }
    };

    const onUploadFiles = useCallback(
        async (files: any[], isPublicBucket = false) => {
            const valueDefinition: Compleo.IObject[] = [];
            const re = /(?:\.([^.]+))?$/;
            const filesAndExtensions: Compleo.IObject = [];

            if (files && files.length) {
                for (const fileDef of files) {
                    const file = fileDef?.FileInfo || fileDef;
                    if (!isFileFromDB(file)) {
                        const extension = (re.exec(file.name) || [])[1];
                        filesAndExtensions.push({
                            extension: extension,
                            originalFileName: clearAccentuatedAndSpecialCharFromString(
                                file.name
                            ),
                            fieldName: file.fieldName,
                            contentType: file.type
                        });
                    }
                }
            }

            const requestUrl = "/general/requestpublics3url";

            if (filesAndExtensions.length) {
                const axPost = await apiDirectCall(requestUrl, "post", {
                    files: filesAndExtensions,
                    companyId: company?.companyId || 0,
                    targetFolder: publicImagesFolder
                });
                const urls = axPost.data;
                if (files && files.length && urls) {
                    for (const fileDef of files) {
                        const file = fileDef?.FileInfo || fileDef;
                        if (!isFileFromDB(file)) {
                            const returnData = urls.filter(
                                (u: any) =>
                                    u.originalFileName ===
                                        clearAccentuatedAndSpecialCharFromString(
                                            file.name
                                        ) && u.fieldName === file.fieldName
                            )[0];
                            const signedUrl = returnData.uploadURL;
                            const filename = returnData.fileName;
                            const options = {
                                headers: {
                                    "Content-Type":
                                        returnData.contentType || file.type,
                                    "x-amz-tagging": `originalFileName=${clearAccentuatedAndSpecialCharFromString(
                                        file.name
                                    )}`
                                }
                            };
                            const axiosReturn = await apiDirectCall(
                                signedUrl,
                                "put",
                                file,
                                options,
                                true,
                                false
                            );
                            const itemToAdd: Compleo.IObject = {
                                key: filename,
                                originalFileName: file.name
                            };
                            if ((fileDef.tags || []).length) {
                                itemToAdd.tags = fileDef.tags;
                            }

                            valueDefinition.push(itemToAdd);
                        } else {
                            valueDefinition.push(file);
                        }
                    }
                }
            }

            return valueDefinition;
        },
        []
    );
    console.log("publicImagesFolder", publicImagesFolder);
    const uploadImage = async (files: any[]) => {
        const returnUploadedFileData: Compleo.IObject[] = await onUploadFiles(
            files,
            true
        );
        const key = returnUploadedFileData[0].key;
        const originalFileName = returnUploadedFileData[0].originalFileName;

        return [key, originalFileName];
    };

    const handleImageUploadBefore = (
        files: File[],
        info: object,
        uploadHandler: Function
    ) => {
        let response = {};

        uploadImage([files[0]]).then((img) => {
            const [key, originalFileName] = img;
            response = {
                result: [
                    {
                        url: `${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${key}`,
                        name: originalFileName || "Imagem",
                        size: files[0].size
                    }
                ]
            };

            uploadHandler(response);
            return true;
        });
        return undefined;
    };

    if (!ready) return <Loading />;

    return (
        <FormControl className={classes.formControl}>
            <FormLabel
                className={classes.label}
                htmlFor={field.name}
                aria-label={label}
                required={required}
                error={error}
            >
                {label}
            </FormLabel>
            <div style={{ width: "100%", margin: "15px 0 15px 0" }}>
                <Grid container justifyContent="flex-start" spacing={1}>
                    {variableList
                        .sort(function (a, b) {
                            var textA = a.label.toUpperCase();
                            var textB = b.label.toUpperCase();

                            return textA.localeCompare(textB);
                        })
                        .map((variable: Compleo.IObject) => (
                            <Grid item key={variable.value}>
                                <>
                                    <Tooltip
                                        title={variable.label}
                                        aria-label={variable.label}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                handleCustomCVVariableClick(
                                                    variable
                                                )
                                            }
                                            size="small"
                                        >
                                            {variable.label.length > 15
                                                ? `${variable.label.substring(
                                                      0,
                                                      14
                                                  )}...`
                                                : variable.label}
                                        </Button>
                                    </Tooltip>
                                </>
                            </Grid>
                        ))}
                </Grid>
            </div>
            {showErrorRequiredPosition && (
                <FormHelperText error={true}>
                    {"Clique na posição em que deseja inserir a variável"}
                </FormHelperText>
            )}
            <div
                style={{ width: "100%" }}
                tabIndex={0}
                className={error ? classes.wrapperError : undefined}
                ref={field.ref}
                key={`SunEditorContainer_${field.name}`}
            >
                <SunEditor
                    name={metadata.fieldName}
                    key={`suneditor_${metadata.fieldName}`}
                    width="auto"
                    setAllPlugins={true}
                    setOptions={{
                        defaultStyle: "font-family: Arial; font-size: 14px;",
                        plugins: { ...Plugins, plugin2 },
                        buttonList: toolbarObj,
                        value: field.value,
                        attributesWhitelist: {
                            all: "style"
                        }
                    }}
                    lang={language === "pt-BR" ? "pt_br" : "en"}
                    getSunEditorInstance={getSunEditorInstance}
                    defaultValue={field.value}
                    onBlur={handleBlur}
                    height={height}
                    //@ts-ignore
                    onImageUploadBefore={handleImageUploadBefore}
                />
            </div>
            <FormHelperText error={error} id={`helper_${field.name}`}>
                {message}
            </FormHelperText>
            <EmojiDialog open={openEmoji} onEmojiClick={onEmojiClick} />
        </FormControl>
    );
};

export const clearAccentuatedAndSpecialCharFromString = (value: string) => {
    if (typeof value === "string") {
        return value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[()$@%#\/&*!:]/g, "");
    } else {
        return value;
    }
};

export default RichTextV3;
