import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";

const ApplicantHistoryJobMatch = (props: IApplicantHistoryProps) => {
    const { historyItem, tLocal, language } = props;
    const reasoning =
        language === "pt-BR"
            ? historyItem?.operationInfo?.matchResult?.reasoningPtBr
            : historyItem?.operationInfo?.matchResult?.reasoning;
    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />

            <ApplicantHistoryItemDetail
                title={tLocal("match_score")}
                transformDBDataParam={{
                    value: `${historyItem?.operationInfo?.matchResult?.match_score}%`,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("reasoning")}
                transformDBDataParam={{
                    value: `${reasoning}`,
                    language: language,
                    t: tLocal
                }}
                tooltipAlternative={reasoning}
            />
        </>
    );
};

export default ApplicantHistoryJobMatch;
