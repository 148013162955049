import React from "react";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { TFunction } from "i18next";
import { useLanguageState } from "_ReactContext/LanguageContext";
import FormMainDetail from "./FormMainDetail";

import {
    FieldValues,
    UseFormGetValues,
    UseFormReturn,
    FormProvider
} from "react-hook-form";
import { clearArrayInfo } from "functions/util";
import { useBackdrop } from "_ReactContext/Backdrop";

export type PropsParent = {
    camposMetadados: any;
    formGroups: any;
    t: TFunction;
    listas: any;
    updating: boolean;
    loading: boolean;
    valuesFromSource: any;
    CustomComponents: any;
    IsMultiStepForm: boolean;
    MultiStepForm: any;
    nextPageFunctions?: Compleo.IObject[];
    secondaryButtonFunction?: any | null;
    tertiaryButtonFunction?: any | null;
    customLists?: Compleo.IObject[];
    fieldsToHide?: string[];
    reactHookFormMethods: UseFormReturn<FieldValues, object>;
    FormMenuActions?: any;
    additionalFieldProperties?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    sizeToChangeStepHorizontal?: "md" | "xs" | "sm" | "lg" | "xl";
    formGroupPaperElevation?: number;
    enableStepNavigation?: boolean;
    IsMultiStepLateralParam?: boolean;
    pageForm: number;
    setPageForm: React.Dispatch<React.SetStateAction<number>>;
    // setForceSubmitFinal: React.Dispatch<React.SetStateAction<boolean>>;
    // setNonLinearPageNumber: React.Dispatch<React.SetStateAction<number>>;
    submit: (values: any) => Promise<void>;
    submitFinal: (values: any, alternativePostFunction?: any) => Promise<void>;
    handleSubmit: (
        e?: React.BaseSyntheticEvent<object, any, any> | undefined
    ) => Promise<void>;
    formRef?: any;
    redirectAddress: string | null;
};

export type typeForm = "INTERNAL" | "EXTERNAL";

type PropsMain = {
    classes: any;
    formType: typeForm;
    parentProps: PropsParent;
};

const FormMain = (props: PropsMain) => {
    const { classes, formType } = props;
    const {
        camposMetadados,
        formGroups,
        t,
        listas,
        updating,
        loading,
        valuesFromSource,
        CustomComponents,
        IsMultiStepForm,
        MultiStepForm,
        secondaryButtonFunction,
        tertiaryButtonFunction,
        customLists,
        fieldsToHide,
        reactHookFormMethods,
        FormMenuActions,
        additionalFieldProperties,
        sizeToChangeStepHorizontal,
        formGroupPaperElevation,
        enableStepNavigation,
        IsMultiStepLateralParam,
        pageForm,
        setPageForm,
        // setForceSubmitFinal,
        // setNonLinearPageNumber,
        submit,
        submitFinal,
        handleSubmit,
        formRef,
        redirectAddress
    } = props.parentProps;
    const totalPages = MultiStepForm.length;
    const lastPage = pageForm === totalPages;

    const filteredCamposMetadados = filterMetaDataFromPage(
        IsMultiStepForm,
        camposMetadados,
        MultiStepForm,
        formGroups,
        pageForm
    );

    const hookFormMethods = reactHookFormMethods;

    let buttonTertiaryText = t("buttonTertiaryText");
    let buttonSecondaryText = t("buttonSecondaryText");
    let buttonMainText = t("buttonMainText");
    const renderCancelButton =
        secondaryButtonFunction !== null &&
        buttonSecondaryText !== "[disabled]";
    let renderBackButton = false;

    const steps: string[] = [];
    if (IsMultiStepForm) {
        if (!lastPage) {
            const pageStepTranslationName = `buttonNextStep_Page_${pageForm}`;
            buttonMainText = t(pageStepTranslationName);
            if (buttonMainText === pageStepTranslationName) {
                const nextPageText = t("buttonNextStep");
                buttonMainText = nextPageText;
            }
        }
        if (pageForm > 1) {
            const pageStepTranslationName = `buttonPreviousStep_Page_${pageForm}`;
            buttonSecondaryText = t(pageStepTranslationName);
            if (buttonSecondaryText === pageStepTranslationName) {
                const previousPageText = t(`buttonPreviousStep`);
                buttonSecondaryText = previousPageText;
            }
            renderBackButton = true;
        }
        MultiStepForm.map((stepForm: any) => {
            steps.push(t(`a_StepForm_${stepForm.id}`));
        });
    }
    const renderSecondaryButton = renderBackButton || renderCancelButton;

    const renderTertiaryButton =
        tertiaryButtonFunction !== null &&
        tertiaryButtonFunction !== undefined &&
        (lastPage || !IsMultiStepForm);

    const language = useLanguageState();

    const filteredFormGroups = filterFormGroups(
        formGroups,
        filteredCamposMetadados,
        fieldsToHide
    );

    const tertiaryAction = async (formValues: any) => {
        if (lastPage || !IsMultiStepForm) {
            formValues = removeEmptyOrNull(formValues);

            return await submitFinal(formValues, tertiaryButtonFunction);
        }
    };

    const secondaryAction = () => {
        if (IsMultiStepForm && pageForm > 1) {
            setPageForm(pageForm - 1);
        }
        if (!IsMultiStepForm || pageForm === 1) {
            secondaryButtonFunction();
        }
    };

    const {
        formState: { errors, isSubmitting },
        getValues
    } = hookFormMethods;

    return (
        <FormProvider {...hookFormMethods}>
            <form
                translate="yes"
                className={classes.formcontainer}
                noValidate
                autoComplete="off"
                onSubmit={stopPropagate(handleSubmit)}
                ref={formRef}
            >
                {loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <CircularProgress size={24} />
                        </Grid>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <FormMainDetail
                            filteredFormGroups={filteredFormGroups}
                            classes={classes}
                            camposMetadados={filteredCamposMetadados}
                            t={t}
                            listas={listas}
                            valuesFromSource={valuesFromSource}
                            CustomComponents={CustomComponents}
                            language={language}
                            pageForm={pageForm}
                            IsMultiStepForm={IsMultiStepForm}
                            steps={steps}
                            customLists={customLists}
                            fieldsToHide={fieldsToHide}
                            // formik={formik}
                            hookFormMethods={hookFormMethods}
                            FormMenuActions={FormMenuActions}
                            additionalFieldProperties={
                                additionalFieldProperties
                            }
                            sizeToChangeStepHorizontal={
                                sizeToChangeStepHorizontal
                            }
                            formGroupPaperElevation={formGroupPaperElevation}
                            enableStepNavigation={enableStepNavigation}
                            IsMultiStepLateralParam={IsMultiStepLateralParam}
                            submit={submit}
                        />
                        <RenderButtons
                            buttonSecondaryText={buttonSecondaryText}
                            buttonMainText={buttonMainText}
                            updating={updating}
                            isSubmitting={isSubmitting}
                            classes={classes}
                            t={t}
                            formType={formType}
                            secondaryActionHandler={secondaryAction}
                            renderSecondaryButton={renderSecondaryButton}
                            buttonTertiaryText={buttonTertiaryText}
                            tertiaryActionHandler={tertiaryAction}
                            renderTertiaryButton={renderTertiaryButton}
                            getValues={getValues}
                            redirectAddress={redirectAddress}
                        />{" "}
                    </React.Fragment>
                )}
            </form>
            {/* <DevTool control={hookFormMethods.control}  /> */}
        </FormProvider>
    );
};

export const filterFormGroups = (
    formGroups: any,
    camposMetadados: any,
    fieldsToHide: string[] = []
) => {
    if (Array.isArray(fieldsToHide) && fieldsToHide.length > 0) {
        camposMetadados = camposMetadados.filter(
            (m: any) =>
                !fieldsToHide.includes(clearArrayInfo(m.fieldName)) &&
                !m.complexFieldDetail
        );
    }

    let formGroupsId = camposMetadados
        .filter((c: any) => c.elementType !== "hidden")
        .map((f: any) => f.formGroupId)
        .filter((f: any) => f !== undefined);
    formGroupsId = [...new Set(formGroupsId)];
    const filteredFormGroups = formGroups.filter((f: any) =>
        formGroupsId.includes(f.id)
    );

    return filteredFormGroups;
};

interface IRenderButtonsProps {
    buttonSecondaryText: string;
    buttonMainText: string;
    updating: boolean;
    classes: any;
    t: TFunction;
    formType: string;
    secondaryActionHandler: any;
    renderSecondaryButton: boolean;
    buttonTertiaryText?: string;
    tertiaryActionHandler?: any;
    renderTertiaryButton?: boolean;
    // formValues?: string;
    getValues: UseFormGetValues<FieldValues>;
    setFieldValueFunction?: any;
    formSubmitHandler?: any;
    isSubmitting: boolean;
    redirectAddress: string | null;
    // formikData: any;
}

interface IRenderButtonsWraper {
    children: any;
    formType: string;
}
const RenderButtonsWraper = (props: IRenderButtonsWraper) => {
    const { children, formType } = props;

    if (formType === "INTERNAL") {
        return (
            <Grid container alignItems="flex-start" justify="flex-start">
                {children}
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12}>
                {children}
            </Grid>
        );
    }
};

const RenderButtons = (props: IRenderButtonsProps) => {
    let fullWidth = false;
    const loading = props.isSubmitting || props.updating;
    if (props.formType === "EXTERNAL") {
        fullWidth = true;
    }
    const { backdropOpen, setBackdropOpen } = useBackdrop();
    const showBackdrop = loading && props.redirectAddress !== null;

    React.useEffect(() => {
        if (loading && props.redirectAddress !== null) {
            setBackdropOpen(true);
        } else {
            setBackdropOpen(false);
        }
    }, [loading, props.redirectAddress, setBackdropOpen]);

    return (
        <RenderButtonsWraper
            formType={props.formType}
            // backDropOpen={showBackdrop}
        >
            {props.buttonMainText !== "[disabled]" && (
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth={fullWidth}
                    color="primary"
                    disabled={loading}
                    className={props.classes.button}
                    data-testid="buttonMainText"
                >
                    {props.buttonMainText}
                </Button>
            )}
            {props.renderSecondaryButton ? (
                <Button
                    //variant="outlined"
                    onClick={props.secondaryActionHandler}
                    color="secondary"
                    fullWidth={fullWidth}
                    disabled={loading}
                    className={props.classes.button}
                    data-testid="buttonSecondaryText"
                >
                    {props.buttonSecondaryText}
                </Button>
            ) : (
                ""
            )}
            {props.renderTertiaryButton ? (
                <Button
                    //variant="outlined"
                    onClick={() => {
                        // props.setFieldValueFunction("isDraft", true, false);
                        // props.formSubmitHandler();
                        props.tertiaryActionHandler(props.getValues());
                    }}
                    // type="submit"
                    color="default"
                    variant="contained"
                    fullWidth={fullWidth}
                    disabled={props.updating}
                    className={props.classes.button}
                    data-testid="buttonTertiaryText"
                >
                    {props.buttonTertiaryText}
                </Button>
            ) : (
                ""
            )}

            {loading && (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress size={24} />
                </Grid>
            )}
        </RenderButtonsWraper>
    );
};

const filterMetaDataFromPage = (
    IsMultiStepForm: boolean,
    camposMetadados: any,
    MultiStepForm: any,
    formGroups: any,
    currentPage: number = 0
) => {
    if (!IsMultiStepForm) {
        return camposMetadados;
    } else {
        let currentStep = MultiStepForm.filter(
            (m: any) => m.order === currentPage
        );

        // se não achar volta a primeira página
        if (currentStep.length === 0) {
            currentStep = MultiStepForm.filter((m: any) => m.order === 1);
        }

        let formGroupsPageIds = formGroups
            .filter((f: any) => f.stepId === currentStep[0].id)
            .map((f: any) => f.id);

        let filteredCamposMetadados = camposMetadados.filter((c: any) =>
            formGroupsPageIds.includes(c.formGroupId)
        );

        return filteredCamposMetadados;
    }
};

const removeEmptyOrNull = (obj: any) => {
    Object.keys(obj).forEach((k) => {
        if (!k.includes("_filesDefinition")) {
            if (obj[k] === null || obj[k] === undefined) {
                delete obj[k];
            } else if (typeof obj[k] === "string" && obj[k].trim() === "") {
                delete obj[k];
            } else if (Array.isArray(obj[k])) {
                for (const item of obj[k]) {
                    if (typeof item === "object") {
                        removeEmptyOrNull(item);
                    }
                }
            } else if (typeof obj[k] === "object") {
                removeEmptyOrNull(obj[k]);
            }
        }
    });

    return obj;
};

/**
 * This function avoids nested forms (like modals) to invoke parent form submit
 * https://github.com/react-hook-form/react-hook-form/issues/1005
 * @param callback
 * @returns
 */
export function stopPropagate(callback: () => void) {
    return (e: { stopPropagation: () => void; preventDefault: () => void }) => {
        e.preventDefault();
        callback();
        e.stopPropagation();
    };
}

export default FormMain;
