import { Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { IApplicantHistoryProps } from "../ApplicantHistoryHelper";
import ApplicantHistoryItemDetail from "./ApplicantHistoryItemDetail";
import { transformDBData } from "functions/util";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { padding: theme.spacing(2), height: "100%" }
    })
);

const ApplicantHistoryChangeJobMatchResult = (
    props: IApplicantHistoryProps
) => {
    const { historyItem, tLocal, language } = props;
    console.log("historyItem", historyItem);

    return (
        <>
            <ApplicantHistoryItemDetail
                title={tLocal("JobTitle")}
                transformDBDataParam={{
                    value: historyItem?.jobData?.title,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("NewStage")}
                transformDBDataParam={{
                    value: historyItem?.stage?.name,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("OldStage")}
                transformDBDataParam={{
                    value: historyItem?.oldStage?.name,
                    language: language,
                    t: tLocal
                }}
            />
            <ApplicantHistoryItemDetail
                title={tLocal("match_score")}
                transformDBDataParam={{
                    value: `${historyItem?.operationInfo?.matchResult?.match_score}%`,
                    language: language,
                    t: tLocal
                }}
            />
        </>
    );
};

export default ApplicantHistoryChangeJobMatchResult;
