import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { useStyles } from "./QuestionStyle";
import Answer from "./Answer";
import QuestionManualCorrection from "./QuestionManualCorrection";
import { IQuestionScore } from "./useCorrectTest";
import { TFunction } from "react-i18next";

export interface IQuestionListProps {
    question: CompleoShared.Assessments.IAssessmentApplicant;
    setScore: (questionId: string, score: number) => void;
    questionScore: IQuestionScore[];
    questionsToCorrect: any[] | undefined;
    correctionMode: boolean;
    index: number;
    t: TFunction;
}
const Question = (props: IQuestionListProps) => {
    const { question, index, t } = props;
    const classes = useStyles();

    return (
        <List
            component="nav"
            className={classes.root}
            aria-label="questions list"
        >
            <ListItem onClick={() => {}} className={classes.rootQuestion}>
                <ListItemText
                    primary={
                        <div
                            className={classes.responsiveImage}
                            dangerouslySetInnerHTML={{
                                __html: `<strong>${t("question")} ${
                                    index + 1
                                }:</strong> ${question?.question?.name || ""}`
                            }}
                        />
                    }
                />
                <QuestionManualCorrection {...props} />
            </ListItem>
            <List component="div" disablePadding dense>
                <Answer {...props} />
            </List>
        </List>
    );
};

export default Question;
