import { ApiCallType, ApiStateType } from "customHooks/useApi";
import { IFilters } from "customHooks/useList";
import { PipelineActionTypeDefinition } from "Pages/Pipeline/components/helperPipeline";
import { UseTranslationResponse } from "react-i18next";

export interface IOrderData {
    applicantPK: string;
    order: number;
    stageId: string;
}

type Job = {
    data: Compleo.IObject;
    ready: boolean;
};

type Applicants = {
    Aggregations: Compleo.IObject;
    ready: boolean;
};

export type PageToRenderType =
    | "pipeline"
    | "applicants"
    | "activity"
    | "viewData"
    | "reports"
    | "assessment";

export const PageToRenderList: PageToRenderType[] = [
    "viewData",
    "pipeline",
    "applicants",
    "activity",
    "reports",
    "assessment"
];

export const PageToRenderListReadOnlyMode: PageToRenderType[] = [
    "viewData",
    "pipeline",
    "activity"
];

export type PipelineViewType =
    | "InProgress_Approveds"
    | "News_InProgress_Approveds"
    | "Approveds"
    | "Disqualifieds";

export const PipelineViewList: PipelineViewType[] = [
    "News_InProgress_Approveds",
    "InProgress_Approveds",
    "Approveds",
    "Disqualifieds"
];

export type ModuleViewType = "JOBREQUESTVIEW" | "JOBVIEW";

export interface IApplicantsData {
    [x: string]: {
        data: {
            pageSize: number;
            fromValue: number;
            totalFiltered?: {
                value?: number;
            };
        };
        applicants: Compleo.IObject[];
    };
}
interface IPagination {
    pagination: { pageSize: number; currentPage: number; fromValue: number };
}
export interface IMoveCard {
    sourceStage?: string;
    sourceIndex?: number;
}

export type IJobViewStagesActionDefinition = {
    [K in PipelineActionTypeDefinition]?: Compleo.IObject[];
};

export interface IJobViewStages {
    id: string;
    name: string;
    fixed: boolean;
    type: string;
    order: number;
    maxDaysInStage?: number;
    maxItensKanban?: number;
    actions?: IJobViewStagesActionDefinition;
}

export interface IStagesConfig {
    [x: string]: {
        currentSort?: {
            name: string;
            sortType: "asc" | "desc";
        };
    };
}

export interface IStagesOnlyTotal {
    id: string;
    total: number;
    name: string;
}

export type LocalStateDataType = {
    pageToRender: PageToRenderType;
    pipelineView: PipelineViewType;
    filter: IFilters;
    ApplicantsData: IApplicantsData;
    ApplicantsPagination: IPagination;
    minimumPageSize: number;
    orderData: IOrderData[];
    filterUpdated: boolean;
    filterApplied: boolean;
    moveCardProps?: IMoveCard;
    allStages: IJobViewStages[];
    stagesConfig: IStagesConfig;
    sortChangedStages?: string[];
    stagesOnlyTotal: IStagesOnlyTotal[];
    firstRenderFinished: boolean;
    lastUpdateApplicantJobStage: string;
    modalJobBoardsStatusOpen: boolean;
    modalJobShareOpen: boolean;
};

export type PayloadActionType = {
    pageToRender?: PageToRenderType;
    pipelineView?: PipelineViewType;
    filter?: IFilters;
    ApplicantsData?: IApplicantsData;
    ApplicantsPagination?: IPagination;
    orderData?: IOrderData[];
    filterUpdated?: boolean;
    moveCardProps?: IMoveCard;
    filterApplied?: boolean;
    allStages?: IJobViewStages[];
    stagesConfig?: IStagesConfig;
    sortChangedStages?: string[];
    stagesOnlyTotal?: IStagesOnlyTotal[];
    firstRenderFinished?: boolean;
    lastUpdateApplicantJobStage?: string;
    modalJobBoardsStatusOpen?: boolean;
    modalJobShareOpen?: boolean;
};

export interface IMainState {
    Job: Job;
    Applicants: Applicants;
    JobId: string;
    apiKeyApplicant: string;
    apiKeyJob: string;
    apiKeyOrderData: string;
    LocalStateData: LocalStateDataType;
    t: any;
    language: string;
    fullFilter: IFilters;
    orderDataFilter: Compleo.IObject;
    apiKanbanListAddress: string;
    apiKanbanOrderDataAddress: string;
    kanbanApplicantListMetadata: ApiStateType;
    tResponseListFilterKanban: UseTranslationResponse<string[]>;
    EditJobListDefinition: {
        listDefinition: Compleo.ListDefinitionType[];
        ready: boolean;
    };
    readonly: boolean;
    module: ModuleViewType;
    jobStatus: CompleoShared.Common.JobStatus;
    customCVsData?: Compleo.IObject[];
}

export interface IMainStateAction {
    type: "update";
    payload: PayloadActionType;
}

export interface IJobViewContextDispatch {
    dispatchGeneralPageInfo: React.Dispatch<IMainStateAction>;
}
