import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useList from "../..";
import { IListBulk, getT } from "./helperBulkActions";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";

import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import useGetMetadata, {
    useGetMetadataMerge
} from "customHooks/useGetMetadata";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useTranslation } from "react-i18next";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { useQueryClient } from "react-query";
import useJobContext from "Pages/Job/JobView/useJobContext";
import { useModalAssessmentGroupList } from "Pages/Job/JobView/JobAssessments/AssessmentsGroupsList/helper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginBottom: theme.spacing(3)
        }
    })
);

interface IProps {
    formikRef: React.MutableRefObject<any>;
    listBulk: IListBulk[];
    setStepBulk: (value: React.SetStateAction<number>) => void;
    setLoadingButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkActionsStep3RequestEvaluation = (props: IProps) => {
    const queryClient = useQueryClient();
    const classes = useStyles();
    const { company } = useAuthState();
    const [data, dispatchData] = useJobContext();
    let defaultFieldsToHide: string[] = ["cvToShare"];
    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>(
        defaultFieldsToHide
    );

    const { formikRef, listBulk, setStepBulk, setLoadingButtons } = props;

    const [listData] = useList();
    const customerFilterIdList: string[] = [];
    if (data.Job.data?.customer?.value) {
        customerFilterIdList.push(data.Job.data?.customer?.value);
    }

    const module1 = "ELASTICLISTSETTINGSBULKMERGE";
    const module2 = "ELASTICLISTSETTINGSBULKSENDASSESSMENT";
    const [metadata] = useGetMetadataMerge(module1, module2);

    const [tLocal, i18n, readyTranslation] = useTranslation(
        [module2, module1],
        {
            useSuspense: false
        }
    );
    const tMerge = getT(tLocal, module1);
    const [lists, readyLists] = useModalAssessmentGroupList({
        jobId: data.JobId,
        onlyWithTests: true
    });

    const [initialValues] = useValuesFromSource(metadata, true, {});
    const ready =
        readyTranslation && metadata.status === "success" && readyLists;

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });

    const handleSubmit = async (values: any) => {
        // const type = values.filterType.value;
        setLoadingButtons(true);
        const ids = listBulk
            .filter((item) => item.checked)
            .map((item) => item.value);
        try {
            const paramsPost: Compleo.IObject = {
                companyId: company.companyId,
                ids: ids,
                ...values,
                type: "applicantjob_requestAssessment",
                job: {
                    value: data.JobId
                },
                assessmentId: values?.assessment?.value,
                assessmentName: values?.assessment?.label
            };
            await apiDirectCall("/bulkactions/baadd", "post", paramsPost);
        } catch (ex) {
            setLoadingButtons(false);
            return ex.response;
        }
        // listData.tempInfo.useApiKey
        queryClient.invalidateQueries(listData.tempInfo.useApiKey);
        queryClient.invalidateQueries("/test/getAssessmentrequestsbyjob");
        setLoadingButtons(false);
        setStepBulk(99);
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    //console.log("fieldsToHide", fieldsToHide);
    const [formCompleo, finished] = useCompleoReactHookForm({
        t: tMerge,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        formRef: formikRef,
        //formikReturn,
        customLists: lists,
        reactHookFormMethods: reactHookFormMethods,
        fieldsToHide
    });

    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return FormReturn;
};

export default BulkActionsStep3RequestEvaluation;
