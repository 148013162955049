import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { i18n, TFunction } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { ModalDataType } from "./helper";
import * as util from "functions/util";
import { useRhfFieldWithoutControllerOrRegister } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            backgroundColor: "#f4f6f8"
        }
    })
);

interface IProps {
    modalData: ModalDataType;
    handleModalClose: any;
    fieldName: string;
    setFieldValue: (value: any) => void;
    metadata: ApiStateType;
    i18n: i18n;
    readyTranslation: boolean;
    t: any;
}

const QuestionsModal = (props: IProps) => {
    const {
        handleModalClose,
        modalData,
        t,
        setFieldValue,
        fieldName,
        metadata,
        i18n,
        readyTranslation
    } = props;
    const classes = useStyles();
    const { watch: watchParent } = useRhfFieldWithoutControllerOrRegister(
        fieldName
    );

    const initialValues = {
        ...(modalData.section || {}),
        questionWithAnswers: modalData.questionWithAnswers
    };
    const [valuesFromSource] = useValuesFromSource(
        metadata,
        false,
        initialValues
    );

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([]);

    const closeModal = () => {
        handleModalClose();
    };

    let addSectionReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource
    });
    const { watch, setValue } = reactHookFormMethods.reactHookFormMethods;
    const fieldValue = watchParent(fieldName);

    const addSection = async (values: any) => {
        let retorno: { [k: string]: any } = {};
        retorno = { status: 200 };

        const currentField = [...(fieldValue || [])];

        if (modalData.new) {
            const newId = util.uuid();
            const answers = values.answers || [];
            const reorderedAnswers = answers.map((item: any, index: any) => {
                const answerId = util.uuid();
                return {
                    ...item,
                    order: index,
                    id: answerId
                };
            });

            currentField.push({
                ...values,
                answers: reorderedAnswers,
                enableActions: false,
                id: newId
            });
            const reorderedQuestions = currentField.map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        order: index
                    };
                }
            );
            setFieldValue(reorderedQuestions);
        } else {
            const answers = values.answers || [];
            const reorderedAnswers = values?.questionWithAnswers
                ? answers.map((item: any, index: any) => {
                      const answerId = util.uuid();
                      return {
                          ...item,
                          order: index,
                          id: answerId
                      };
                  })
                : [];

            currentField[modalData.index] = {
                ...currentField[modalData.index],
                ...values,
                answers: reorderedAnswers
            };
            setFieldValue(currentField);
        }

        handleModalClose();
        return retorno;
    };

    const questiontype = watch("questiontype");

    React.useEffect(() => {
        const questionWithAnswersList = [
            "CPQUESTIONSINGLECHOICE",
            "CPQUESTIONMULTIPLECHOICE"
        ];
        const questionType = questiontype?.value;
        if (!questionWithAnswersList.includes(questionType)) {
            if (!fieldsToHide.find((f) => f === "answers")) {
                const newFieldsToHide = [...fieldsToHide];
                newFieldsToHide.push("answers");
                setFieldsToHide(newFieldsToHide);
                setValue("questionWithAnswers", false);
            }
        } else {
            let newFieldsToHide = [...fieldsToHide].filter(
                (f) => f !== "answers"
            );
            if (modalData.enableRanking) {
                newFieldsToHide = newFieldsToHide.filter(
                    (f) => f !== "answers.weight"
                );
            } else {
                newFieldsToHide.push("answers.weight");
            }

            setFieldsToHide(newFieldsToHide);
            setValue("questionWithAnswers", true);
        }
    }, [questiontype, modalData.enableRanking]);

    const [formCompleo, finished] = useCompleoReactHookForm({
        t,
        ready: readyTranslation,
        i18nV: i18n,
        postMethod: addSection,
        postReturn: addSectionReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource,
        secondaryButtonFunction: closeModal,
        fieldsToHide: fieldsToHide,
        reactHookFormMethods: reactHookFormMethods
    });

    let compRetorno: boolean | JSX.Element = <Loading />;
    if (finished && readyTranslation) {
        compRetorno = formCompleo;
    }

    return (
        <Dialog
            open={modalData.open}
            onClose={handleModalClose}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby={t("a_ModalTitle")}
            aria-describedby={t("a_ModalTitle")}
            PaperProps={{
                className: classes.dialog
            }}
            scroll="body"
        >
            <DialogTitle id="alert-dialog-title">
                {t("a_ModalTitle")}
            </DialogTitle>
            <DialogContent>{compRetorno}</DialogContent>
        </Dialog>
    );
};

export default QuestionsModal;
