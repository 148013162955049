import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";
import { useTranslation } from "react-i18next";
import AssessmentsList from "./AssessmentsList";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "90%"
        },
        container: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            backgroundColor: theme.palette.backgroundGrey.main,
            overflow: "auto"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        timeline: {},
        refreshButton: {
            padding: theme.spacing(0.2),
            margin: theme.spacing(0.2),
            marginRight: theme.spacing(2)
        }
    })
);

const ApplicantRating = (props: any) => {
    const classes = useStyles();
    const [tLocal, , readyTranslation] = useTranslation(
        "APPLICANTVIEWASSESSMENTS",
        {
            useSuspense: false
        }
    );

    const [data] = useApplicantViewContext();
    const { applicantData, applicantIndex } = data;
    const { t } = data;
    const ready = readyTranslation;
    const hasJobs = applicantData.Jobs?.length > 0;

    if (ready) {
        return (
            <Grid container item className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1">
                                    {t("a_StepForm_applicantAssessments")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    {(applicantData.Jobs || []).map(
                        (jobData: Compleo.IObject) => {
                            const jobTitle =
                                jobData?._updatedJobData?.title ||
                                jobData.JobTitle;

                            return (
                                <Paper
                                    className={classes.paperContainer}
                                    key={jobData.JobId}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <strong>{jobTitle}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AssessmentsList
                                            jobId={jobData.JobId}
                                        />
                                    </Grid>
                                </Paper>
                            );
                        }
                    )}
                    {!hasJobs && (
                        <Box p={2}>
                            <Alert severity="info">
                                {tLocal("noAssessments")}
                            </Alert>
                        </Box>
                    )}
                </Box>
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default ApplicantRating;
