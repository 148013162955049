import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";

import FormHelperText from "@material-ui/core/FormHelperText";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";
import StageActionsModal from "./StageActionsModal";
import useGetMetadata from "customHooks/useGetMetadata";
import * as util from "functions/util";
import StagesItems from "./StageItems";
import AddStageButtom from "./AddStageButtom";
import { PipelineActionTypeDefinition, ModalDataType } from "./helperPipeline";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";
import {
    IInputProps,
    useRhfFieldControlled,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ol: {
            paddingLeft: theme.spacing(1)
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            marginTop: theme.spacing(1)
        },
        nested: {
            paddingLeft: theme.spacing(6)
        },
        inline: {
            display: "inline"
        },
        listCheck: {
            minWidth: 36
        },
        colorError: {
            color: theme.palette.error.main
        },
        color: {}
    })
);

const getTypeList = (items: Compleo.IObject[]) => {
    const itemsToExclude = [
        "newApplied",
        "newSourced",
        "newReferred",
        "hired",
        "rejected"
    ];
    return items.filter(
        (item: Compleo.IObject) => !itemsToExclude.includes(item.value)
    );
};

type PipelinePatternsType = {
    actions: { name: PipelineActionTypeDefinition; enabled: boolean }[];
    defaultPipeline: { stages: Compleo.IObject[] };
    typesOfStage: { items: Compleo.IObject[] };
};
interface IProps {
    stageIdsWithApplicants?: string[];
    jobId: string;
}

const Stages = (props: IProps & IInputProps) => {
    const classes = useStyles();
    const { stageIdsWithApplicants = [], jobId } = props;
    // const [field, meta, helpers] = useField(props.metadata.fieldName);
    const fieldName = props.metadata.fieldName;
    const {
        error,
        message,
        // field,
        setFieldValue,
        watch
    } = useRhfFieldWithoutControllerOrRegister(
        fieldName,
        props.helperTextDefault
    );

    const { readyForm, callDialog, agree } = useGlobalDialog();

    const [
        pipelinePatterns,
        setPipelinePatterns
    ] = React.useState<PipelinePatternsType | null>(null);

    const { company } = useAuthState();
    const [modalData, setModalData] = useState<ModalDataType>({
        open: false,
        stage: {},
        index: 0,
        name: fieldName,
        typeList: [],
        new: false
    });

    const modalModuleName = "PIPELINESTAGEMAIN";
    const [t, i18n, readyTranslation] = useTranslation(modalModuleName, {
        useSuspense: false
    });
    const language = i18n.languages[0];
    const [modalMetadata] = useGetMetadata(modalModuleName);

    const [getPipelinePatterns] = useApiCache(
        "/pipeline/getpipelinepatterns",
        "post",
        { companyId: company.companyId },
        false
    );

    React.useEffect(() => {
        if (
            getPipelinePatterns.status === "success" &&
            pipelinePatterns === null
        ) {
            const data = getPipelinePatterns.response.data;
            const typesOfStage = data.typesOfStage.items.map((item: any) => {
                return { ...item, label: item[`label-${language}`] };
            });
            setPipelinePatterns({
                ...data,
                typesOfStage: { items: typesOfStage }
            });
        }
    }, [getPipelinePatterns.status, pipelinePatterns]);

    const stageData = watch(fieldName);
    // const stageData =field.value;

    React.useEffect(() => {
        if (
            pipelinePatterns?.defaultPipeline?.stages &&
            readyTranslation &&
            (!stageData || stageData?.length === 0)
        ) {
            const stagesDefault = pipelinePatterns.defaultPipeline.stages.map(
                (stage: any) => {
                    const uuidNew = util.uuid();
                    return {
                        ...stage,
                        id: uuidNew,
                        name: t(`PIPELINE_${stage.name}`, stage.name)
                    };
                }
            );
            setFieldValue(stagesDefault);
        }
    }, [pipelinePatterns, readyTranslation]);

    const handleModalClose = () => {
        setModalData({ ...modalData, open: false });
    };

    const handleEditStage = (stage: any, index: number) => {
        if (pipelinePatterns?.typesOfStage.items) {
            setModalData({
                ...modalData,
                stage: stage,
                open: true,
                index: index,
                typeList: getTypeList(pipelinePatterns?.typesOfStage.items),
                new: false
            });
        }
    };

    const handleAddStage = (index: number, type: string) => {
        const newStage = {
            name: "",
            fixed: false,
            type: type,
            order: index + 1
        };
        if (pipelinePatterns?.typesOfStage.items) {
            setModalData({
                ...modalData,
                stage: newStage,
                open: true,
                typeList: getTypeList(pipelinePatterns?.typesOfStage.items),
                index: index,
                new: true
            });
        }
    };
    console.log("stageData", stageData);

    const handleDeleteStage = (stage: any) => {
        const {
            hasJobAIMatchRule,
            nameListString
        } = checkStageWithJobAIMatchRule(stageData, stage);
        if (hasJobAIMatchRule) {
            callDialog({
                title: t("stageWithJobAIMatchRuleDeleteTitle"),
                bodyText: t("stageWithJobAIMatchRuleDelete") + nameListString,
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        } else if (stageIdsWithApplicants.includes(stage.id)) {
            callDialog({
                title: t("stageWithApplicantsDeleteTitle"),
                bodyText: t("stageWithApplicantsDelete"),
                agreeButtonText: t("COMPLEOGENERAL_CLOSE"),
                agreeFunction: async () => {
                    return false;
                }
            });
        } else {
            const newValue = [...stageData].filter(
                (item: any) => item.id !== stage.id
            );
            setFieldValue(newValue);
        }
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result;

        if (destination) {
            if (destination.index === source.index) {
                return;
            }
            const stagesDestination = [...stageData];
            const currentItem = stageData.filter(
                (item: any) => item.name === draggableId
            )[0];
            const mainListCurrentItem = stageData.findIndex(
                (item: any) => item.name === draggableId
            );
            const indexDifference = mainListCurrentItem - source.index;
            stagesDestination.splice(mainListCurrentItem, 1);
            stagesDestination.splice(
                destination.index + indexDifference,
                0,
                currentItem
            );
            const reorderedStages = stagesDestination.map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        order: index
                    };
                }
            );
            setFieldValue(reorderedStages);
        }
    };

    // const hasError = false;
    let classError = classes.color;
    if (error) {
        classError = classes.colorError;
    }
    // const mensagemErro = "temp";

    let listStages: any = stageData || [];
    let listGroups: any = ["new", "InProgress", "hired", "rejected"];

    if (readyTranslation && listStages.length > 0 && pipelinePatterns) {
        return (
            <React.Fragment>
                <StageActionsModal
                    modalData={modalData}
                    handleModalClose={handleModalClose}
                    t={t}
                    mainFieldName={fieldName}
                    setFieldValue={setFieldValue}
                    metadata={modalMetadata}
                    i18n={i18n}
                    readyTranslation={readyTranslation}
                    stageIdsWithApplicants={stageIdsWithApplicants}
                    jobId={jobId}
                />
                <List component="nav" className={classes.root}>
                    {error && (
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <FormHelperText
                                            id="component-helper-text"
                                            error
                                        >
                                            {message}
                                        </FormHelperText>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                    {listGroups.map((group: any) => (
                        <React.Fragment key={group}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    className={classError}
                                    primary={t(`PIPELINEGROUP_${group}`)}
                                />
                            </ListItem>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                    droppableId={group}
                                    direction="vertical"
                                    type={group}
                                >
                                    {(provided: DroppableProvided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <StagesItems
                                                classError={classError}
                                                group={group}
                                                listStages={listStages}
                                                t={t}
                                                handleEditStage={
                                                    handleEditStage
                                                }
                                                handleDeleteStage={
                                                    handleDeleteStage
                                                }
                                                listTypesOfStage={
                                                    pipelinePatterns
                                                        ?.typesOfStage.items ||
                                                    []
                                                }
                                                stageIdsWithApplicants={
                                                    stageIdsWithApplicants
                                                }
                                            ></StagesItems>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {group !== "new" && (
                                <AddStageButtom
                                    handleAddStage={handleAddStage}
                                    group={group}
                                    listStages={listStages}
                                    t={t}
                                />
                            )}
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>
        );
    } else {
        return <Loading />;
    }
};

function checkStageWithJobAIMatchRule(
    stageData: Compleo.IObject[],
    stageToDelete: Compleo.IObject
) {
    const allStagesIdsJobAIMatchRule: string[] = [];
    const stagesRelatedToStageToDelete: Compleo.IObject[] = [];

    stageData.map((i: Compleo.IObject) => {
        const resultsToStageRuleField: Compleo.IObject[] =
            i?.actionApplicantJobMatch?.[0]?.resultsToStageRule || [];

        if (resultsToStageRuleField.length > 0) {
            resultsToStageRuleField.map((item) => {
                allStagesIdsJobAIMatchRule.push(item.targetStage?.value);
                if (item.targetStage?.value === stageToDelete.id) {
                    stagesRelatedToStageToDelete.push(i);
                }
            });
        }
    });
    const stagesWithJobAIMatchRule: Compleo.IObject[] = stageData.filter(
        (item: Compleo.IObject) => allStagesIdsJobAIMatchRule.includes(item.id)
    );
    const hasJobAIMatchRule = allStagesIdsJobAIMatchRule.includes(
        stageToDelete.id
    );
    const nameListString = stagesRelatedToStageToDelete
        .map((item) => `- <strong>${item.name}<strong>`)
        .join("<br />");

    return {
        hasJobAIMatchRule,
        nameListString,
        allStagesJobAIMatchRule: stagesWithJobAIMatchRule,
        stagesRelatedToStageToDelete
    };
}

export default Stages;
