import Loading from "customHooks/useCompleoForm/helpers/Loading";
import React from "react";
import useJobContext from "./useJobContext";
import JobKanbanMain from "./JobKanban/JobKanbanMain";
import JobReportsMain from "./JobReports/JobReportsMain";
import JobToolbar from "./JobToolbar";
import JobDefaultExamplePage from "./JobDefaultExamplePage";
import JobApplicantList from "./JobApplicantList";
import JobViewDataMain from "./JobViewData/JobViewDataMain";
import JobHistoryMain from "./JobHistory/JobHistoryMain";
import JobAssessmentsMain from "./JobAssessments/JobAssessmentsMain";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { PageToRenderType } from "./useJobContext/JobViewTypes";
import { setLastPageToRender } from "./JobViewMainHelper";
import useApplicantAccess from "customHooks/useAccess/useApplicantAccess";
import JobBoardsStatusModal from "./JobShare/JobBoards/JobBoardsStatusModal";
import JobShareModal from "./JobShare/JobShareModal/JobShareModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        container: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column"
            // backgroundColor: "yellow"
        }
    })
);

interface IJobMain {
    pageToRender?: PageToRenderType;
}

const JobMain = (props: IJobMain) => {
    const { pageToRender } = props;
    const classes = useStyles();
    const [data, dispatch] = useJobContext();

    React.useEffect(() => {
        if (pageToRender) {
            dispatch.dispatchGeneralPageInfo({
                type: "update",
                payload: { pageToRender: pageToRender }
            });
        }
    }, [pageToRender]);

    if (data.Job.ready) {
        return (
            // <Box className={classes.mainContainer}>
            <Box className={classes.container}>
                <JobToolbar />
                <MainPageToRender />
                {data.LocalStateData?.modalJobBoardsStatusOpen && (
                    <JobBoardsStatusModal />
                )}
                {data.LocalStateData?.modalJobShareOpen && <JobShareModal />}
            </Box>
            // </Box>
        );
    } else {
        return <Loading />;
    }
};

const MainPageToRender = (props: any) => {
    const [data] = useJobContext();
    const pageToRender = data.LocalStateData.pageToRender;
    setLastPageToRender(pageToRender);
    const applicantAccess = useApplicantAccess();

    if (
        data.module !== "JOBREQUESTVIEW" &&
        (pageToRender === "pipeline" ||
            pageToRender === "applicants" ||
            pageToRender === "assessment")
    ) {
        if (!applicantAccess.allowEdit) {
            return null;
        }
    }

    switch (pageToRender) {
        case "pipeline":
            return <JobKanbanMain />;
        case "applicants":
            return <JobApplicantList />;
        case "viewData":
            return <JobViewDataMain />;
        case "activity":
            return <JobHistoryMain />;
        case "reports":
            return <JobReportsMain />;
        case "assessment":
            return <JobAssessmentsMain />;
        default:
            return <JobDefaultExamplePage />;
    }
};

export default JobMain;
