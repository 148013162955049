import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import useGetMetadata from "customHooks/useGetMetadata";
import useCompleoReactHookForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import FormClassic from "customHooks/useCompleoReactHookForm/components/FormClassic";
import { useApi } from "customHooks/useApi";
import useValuesFromSource from "customHooks/useValuesFromSource";
import { useCancelButtonForm } from "customHooks/useCancelButtonForm";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import CustomComponents from "./components/CustomComponents";
import { getTimeFixedDate } from "./helpers/testUtil";
import React from "react";
import useTestTemplate from "./helpers/useTestTemplate";
import {} from "./helpers/testUtil";
import useFieldsToHideTests from "./helpers/useFieldsToHideTests";

interface ITestNewProps {
    type?: CompleoShared.Assessments.testType;
    templateuuid?: string;
}
const TestNew = (props: ITestNewProps) => {
    const { type = "regular", templateuuid } = props;

    const { ready: readyTemplate, templateData } = useTestTemplate(
        templateuuid
    );
    // const fieldsToHide = type !== "answersToResults" ? ["possibleResults"] : [];
    const moduleName = "TESTNEW";
    const cancelFunction = useCancelButtonForm("/test/list");
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });
    const [metadata] = useGetMetadata(moduleName);
    const postAddress = `/test/new`;
    const [maxDuration] = React.useState(
        getTimeFixedDate({ hours: 0, minutes: 30 })
    );

    const [addBranchReturn, addBranch] = useApi(postAddress, "post");

    const [valuesFromSource] = useValuesFromSource(metadata, true, {
        sections: [],
        testType: type,
        maxDuration: maxDuration,
        defaultLanguage: "pt-BR",
        deleted: false,
        ...(templateData || {})
    });
    const ready = readyTemplate && readyTranslation;
    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: valuesFromSource
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const results = watch("results") || [];
    const fieldsToHide = useFieldsToHideTests({ watch, type });

    const customList: Compleo.CustomLists.ListDefinitionType[] = [
        {
            fieldName: "rankingToResults.result",
            data: results,
            labelName: "name",
            valueName: "name"
        }
    ];

    const [formCompleo, finished] = useCompleoReactHookForm({
        FormType: FormClassic,
        i18nV: i18n,
        metadadosRetorno: metadata,
        postMethod: addBranch,
        postReturn: addBranchReturn,
        ready: ready,
        valuesFromSource: valuesFromSource,
        t: t,
        secondaryButtonFunction: cancelFunction,
        redirectAddress: "/test/list",
        reactHookFormMethods: reactHookFormMethods,
        CustomComponents: CustomComponents,
        fieldsToHide: fieldsToHide,
        customLists: customList
    });

    let compRetorno: boolean | JSX.Element;
    compRetorno = <Loading />;

    if (finished && ready) {
        compRetorno = formCompleo;
        const featureMainDescription = templateData?.modelName
            ? `${t("a_FeatureMainDescription")} (${t("testTemplate")}: ${
                  templateData?.modelName
              })`
            : t("a_FeatureMainDescription");

        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={featureMainDescription}
                maxWidth="lg"
            >
                {compRetorno}
            </Page>
        );
    } else {
        return <Page>{compRetorno}</Page>;
    }
};

export default TestNew;
