import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Box, Slide } from "@material-ui/core";
import { useCustomSearchMetadataReturnType } from "./useCustomSearchMetadata";
import AdvancedSearchFormAddFilter, {
    listFieldsType
} from "./AdvancedSearchFormAddFilter";
import { getOrderedObjectNames } from "../ListUtil";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import useAdvancedSearchCustomFields from "./useAdvancedSearchCustomFields";
import useAdvancedSearchQuestionnaires from "./useAdvancedSearchQuestionnaires";
import useAdvancedSearchAssessments from "./useAdvancedSearchAssessments";
import useList from "../..";
import {
    filtersType,
    getTypeFromCustomFieldType,
    addSearchType
} from "./helperCustomSearch";
import { stripHTMLTags } from "functions/util";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        dialogPaper: {}
    })
);

interface IPropsItemFilter {
    open: boolean;
    onClose: () => void;
    t: any;
    advancedMetadata: useCustomSearchMetadataReturnType;
}

const AdvancedSearchFormAddFilterModal = (props: IPropsItemFilter) => {
    const classes = useStyles();
    const { company } = useAuthState();
    const [value, setValue] = React.useState<listFieldsType | null>(null);

    const { open, onClose, t, advancedMetadata } = props;
    const [listData, listDispatch] = useList();
    const { otherFilters, otherFiltersGroup, otherSettings } =
        listData.definitionInfo.elasticDefData?.data || {};
    const { t: tlist } = listData.tempInfo;
    const options: listFieldsType[] = [];
    const customFieldsGroup = "__customFields__";
    otherFiltersGroup[customFieldsGroup] = { order: 9999, icon: "tune" };
    const customFieldsReturn = useAdvancedSearchCustomFields(
        listData.tempInfo.moduleName,
        company.companyId
    );
    const questionFieldsReturn = useAdvancedSearchQuestionnaires(
        listData.tempInfo.moduleName,
        company.companyId
    );
    const assessmentsFieldsReturn = useAdvancedSearchAssessments(
        listData.tempInfo.moduleName,
        company.companyId
    );

    const groups: string[] = getOrderedObjectNames(otherFiltersGroup);
    const items: string[] = getOrderedObjectNames(otherFilters);

    groups.map((groupName) => {
        items.map((itemName) => {
            const itemDetail = otherFilters[itemName];
            if (itemDetail.group === groupName) {
                options.push({
                    group: groupName,
                    label: t(
                        `otherFilter_${itemName}`,
                        t(itemName, `otherFilter_${itemName}`)
                    ),
                    value: itemName,
                    fieldType: "regular"
                });
            }
        });
    });

    const customFieldsDef: Compleo.IObject[] = customFieldsReturn.customFields;
    if (customFieldsReturn.readyCustomFields) {
        customFieldsDef.map((field: Compleo.IObject) => {
            options.push({
                group: "__customFields__",
                label: t(field.customFieldName),
                value: field.customFieldName,
                customFieldType: field.fieldType,
                fieldType: "custom"
            });
        });
    }

    // eslint-disable-next-line max-len
    const questionFields = questionFieldsReturn.fieldsDef as CompleoShared.useList.IQuestionnaireSearchData[];
    if (questionFieldsReturn.readyCustomFields) {
        questionFields.map(
            (field: CompleoShared.useList.IQuestionnaireSearchData) => {
                options.push({
                    group: "__questionFields__",
                    label: `${field.jobTitle} - ${tlist(
                        `ELASTICLISTSETTINGS:${field.QuestionnaireId.toLowerCase()}`,
                        field.QuestionnaireName
                    )} - ${field.QuestionName}`,
                    value: `${field.jobId}#${field.QuestionnaireId}#${field.QuestionId}`,
                    listData: field.ListData,
                    fieldType: "questionnaire"
                });
            }
        );
    }

    // eslint-disable-next-line max-len
    const assessmentsFields = assessmentsFieldsReturn.fieldsDef as CompleoShared.useList.IAssessmentSearchData[];
    if (assessmentsFieldsReturn.readyCustomFields) {
        assessmentsFields.map((field) => {
            options.push({
                group: "__assessmentFields__",
                label: `${field.jobTitle} - ${tlist(
                    `ELASTICLISTSETTINGS:${field.testId.toLowerCase()}`,
                    field.testName
                )} - ${field.questionName}`,
                value: `${field.jobId}#${field.testId}#${field.questionId}`,
                listData: field.ListData?.map((item) => {
                    return {
                        value: item.value,
                        label: stripHTMLTags(item.label)
                    };
                }),
                fieldType: "assessment"
            });
        });
    }

    const addItem = (filterName: string, listFields: listFieldsType) => {
        const customFieldType = listFields?.customFieldType;
        const filterDef = otherFilters[filterName];
        let type: filtersType = getTypeFromCustomFieldType(
            filterDef?.type,
            customFieldType
        );
        let customDef: Compleo.IObject = {};
        if (listFields.fieldType === "custom") {
            customDef = customFieldsDef.filter(
                (item) => item.customFieldName === filterName
            )[0];
            customDef.type = "customFields";
        }
        const customList: Compleo.ListDefinitionType[] = [];

        if (listFields.fieldType === "questionnaire") {
            if (listFields.listData) {
                type = "list";
                customList.push({
                    data: listFields.listData,
                    valueName: "value",
                    labelName: "label",
                    fieldName: filterName
                });
            }
            const valueData = listFields.value.split("#");
            const [jobId, questionnaireId, questionId] = valueData;
            const questionDef = questionFields.filter(
                (q) =>
                    q.jobId === jobId &&
                    q.QuestionnaireId === questionnaireId &&
                    q.QuestionId === questionId
            )[0];
            customDef = {
                ...(questionDef || {}),
                type: "questionnaire",
                listData: null,
                fieldType: type
            };
        }
        if (listFields.fieldType === "assessment") {
            if (listFields.listData) {
                type = "list";
                customList.push({
                    data: listFields.listData,
                    valueName: "value",
                    labelName: "label",
                    fieldName: filterName
                });
            }
            const valueData = listFields.value.split("#");
            const [jobId, testId, questionId] = valueData;
            const assessmentDef = assessmentsFields.filter(
                (q) =>
                    q.jobId === jobId &&
                    q.testId === testId &&
                    q.questionId === questionId
            )[0];
            customDef = {
                ...(assessmentDef || {}),
                type: "assessment",
                listData: null,
                fieldType: type
            };
        }

        addSearchType(
            type,
            filterName,
            advancedMetadata,
            customDef,
            customList
        );
        onClose();
    };

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby={t(`ELASTICLISTSETTINGS:advancedFilters`)}
            open={open}
            TransitionComponent={Transition}
            TransitionProps={{ timeout: { enter: 500, appear: 500 } }}
            scroll="body"
            // classes={{
            //     paper: classes.dialogPaper
            // }}
        >
            <DialogTitle>
                {t(`ELASTICLISTSETTINGS:advancedFilters`)}
            </DialogTitle>
            <DialogContent dividers>
                <AdvancedSearchFormAddFilter
                    options={options}
                    value={value}
                    setValue={setValue}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {t("ELASTICLISTSETTINGS:closeModalCustomSearch")}
                </Button>
                {value?.value && (
                    <Button
                        variant="contained"
                        onClick={() => addItem(value?.value, value)}
                        color="primary"
                    >
                        {t("ELASTICLISTSETTINGS:AdvancedFilter_AddField")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AdvancedSearchFormAddFilterModal;
