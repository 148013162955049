import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
    const nestedBorder = {
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        borderWidth: "1px",
        borderStyle: "solid"
    };
    const chipCard = {
        borderRadius: 6,
        marginRight: theme.spacing(1),
        fontWeight: 700
    };

    return createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        },
        rootQuestion: {
            width: "100%",
            borderLeft: "3px solid",
            paddingLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.grey[200]
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        dividerNested: {
            marginLeft: theme.spacing(4)
        },
        nestedBorderGrey: {
            ...nestedBorder,
            borderColor: theme.palette.grey[500]
        },
        nestedBorderBlue: {
            ...nestedBorder,
            borderColor: theme.palette.info.main,
            borderWidth: "2px"
        },
        chipCardTestStatus: {
            ...chipCard
        },
        chipCardAnswerCorrect: {
            ...chipCard,
            backgroundColor: theme.palette.success.main,
            color: "white"
        },
        chipCardWeightInfo: {
            ...chipCard,
            backgroundColor: theme.palette.info.main,
            color: "white"
        },
        chipCardChoosenAnswer: {
            ...chipCard,
            marginRight: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: "white"
        },
        responsiveImage: {
            "& img": {
                maxWidth: "100%",
                height: "auto"
            }
        }
    });
});
