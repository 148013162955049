import AIMainButton from "Components/AIMainButton";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider, Menu } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ModalAIFormHelper from "./ModalAIFormHelper";
import { useCompanyAIFeatureStatus } from "Pages/ai/CompanyAICreditStatus/useCompanyAIStatus";

interface IAIFormHelperProps {
    filteredFormGroups: Compleo.IObject[];
    camposMetadados: Compleo.IObject[];
}
const AIFormHelper = (props: IAIFormHelperProps) => {
    const { filteredFormGroups, camposMetadados } = props;
    const { canUse } = useCompanyAIFeatureStatus("jobDescriptionGeneration", 1);
    const aiButtonRef: React.RefObject<HTMLButtonElement> = React.useRef<
        HTMLButtonElement
    >(null);
    const [t, , readyTranslation] = useTranslation("AIACTIONSMAIN", {
        useSuspense: false
    });
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [
        modalData,
        setModalData
    ] = React.useState<CompleoShared.AI.compleoFeaturesAI | null>(null);

    const formGroupIds = filteredFormGroups.map((i: any) => i.id);
    const camposFiltered = camposMetadados.filter((i: any) =>
        formGroupIds.includes(i.formGroupId)
    );
    const isOk = camposFiltered.find(
        (i: Compleo.IObject) => i.fieldName === "description"
    );
    if (!canUse) {
        return null;
    }

    return (
        <>
            <AIMainButton
                onClick={() => {
                    setMenuOpen(true);
                }}
                isVisible={isOk !== undefined && readyTranslation}
                buttonRef={aiButtonRef}
            />
            <Menu
                id="lock-menu"
                anchorEl={aiButtonRef.current}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem disabled button={false}>
                    {t("aiMenuDescription")}
                </MenuItem>
                <Divider />
                {["Gerar descrição da vaga"].map((option, index) => (
                    <MenuItem
                        key={option}
                        onClick={() => {
                            setModalData("jobDescriptionGeneration");
                            setMenuOpen(false);
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            {modalData && (
                <ModalAIFormHelper
                    aiFeature={modalData}
                    handleClose={() => setModalData(null)}
                    open={modalData !== null}
                />
            )}
        </>
    );
};

export default AIFormHelper;
