import React from "react";
import { getMetadata, getPageWithErrosMultiStepFrom } from "./helpers/helper";
import Loading from "./helpers/Loading";
import { TFunction, i18n } from "i18next";
import { ApiStateType } from "customHooks/useApi";
import { MenuOptionTypeReturn } from "./components/useMenuFormGroup";
import { useForm, UseFormReturn, ValidationMode } from "react-hook-form";
import { PropsParent } from "./components/FormMain";
import { getSchema } from "customHooks/useCompleoReactHookForm/helpers/helper";
import Yup from "customHooks/useCompleoReactHookForm/helpers/yupHelper";
import { useAuthState } from "_ReactContext/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    processEmailFields,
    processUploadFileFields
} from "./components/FormMainHelper";
import useShowMessage from "customHooks/useShowMessage/useShowMessage";
import { navigate } from "@gatsbyjs/reach-router";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { handleLocationCountryLegacy } from "functions/util";
import { replaceAll } from "functions/util";
import { sendErrorToDB, getErrorObject } from "ErrorBoundary/helper";
import { useBackdrop } from "_ReactContext/Backdrop";

export type ComplexFieldsToHide = {
    complexFieldName: string;
    fieldName: string;
    arrayIndex: number;
};

export interface IUseCompleoReactHookFormParams {
    t: TFunction;
    ready: boolean;
    i18nV: i18n;
    postMethod: any;
    postReturn: any;
    redirectAddress?: null | string;
    FormType: any;
    metadadosRetorno: ApiStateType;
    valuesFromSource: Compleo.IObject;
    CustomComponents?: any;
    timeZone?: null | string;
    nextPageFunctions?: Compleo.IObject[];
    secondaryButtonFunction?: any;
    tertiaryButtonFunction?: any;
    customLists?: Compleo.CustomLists.ListDefinitionType[];
    fieldsToHide?: string[];
    reactHookFormMethods?: IUseCompleoReactHookFormGetMainDataReturn;
    FormMenuActions?: {
        formGroupId: string;
        menuDefinition: MenuOptionTypeReturn;
    }[];
    additionalFieldProperties?: Compleo.useCompleoForm.AdditionalPropertiesField[];
    sizeToChangeStepHorizontal?: "md" | "xs" | "sm" | "lg" | "xl";
    // formikEnableReinitialize?: boolean;
    formGroupPaperElevation?: number;
    enableStepNavigation?: boolean;
    IsMultiStepLateralParam?: boolean;
    invalidateQueryClientItems?: string[];
    removeQueryClientItems?: string[];
    validationMode?: keyof ValidationMode;
    formRef?: any;
    forceBackdrop?: boolean;
}

export type handleSubmitType = (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
) => Promise<void>;

type useCompleoReactHookFormType = (
    params: IUseCompleoReactHookFormParams
) => [
    JSX.Element,
    boolean,
    handleSubmitType,
    UseFormReturn<Compleo.IObject, object>
];

const useCompleoReactHookForm: useCompleoReactHookFormType = (
    params: IUseCompleoReactHookFormParams
) => {
    const {
        t,
        ready,
        i18nV,
        postMethod,
        postReturn,
        redirectAddress,
        FormType,
        metadadosRetorno,
        valuesFromSource,
        CustomComponents,
        timeZone,
        nextPageFunctions,
        secondaryButtonFunction,
        tertiaryButtonFunction,
        customLists,
        fieldsToHide,
        reactHookFormMethods,
        FormMenuActions,
        additionalFieldProperties,
        sizeToChangeStepHorizontal,
        // formikEnableReinitialize,
        formGroupPaperElevation,
        enableStepNavigation,
        IsMultiStepLateralParam,
        invalidateQueryClientItems,
        removeQueryClientItems,
        validationMode = "onBlur",
        formRef,
        forceBackdrop = false
    } = params;

    const showMessage = useShowMessage();
    const queryClient = useQueryClient();
    const allAuthData = useAuthState();
    const { setBackdropOpen } = useBackdrop();

    let finished = false;
    let metaData: Compleo.IObject = {};
    const localUseForm = useCompleoReactHookFormGetMainData({
        i18nV: i18nV,
        metadadosRetorno: metadadosRetorno,
        ready: ready,
        t: t,
        valuesFromSource: valuesFromSource,
        timeZone: timeZone,
        validationMode: validationMode
    });
    const reactHookFormMethodsParam =
        reactHookFormMethods !== undefined
            ? reactHookFormMethods
            : localUseForm;

    const language = i18nV.languages[0];
    if (metadadosRetorno.status === "success" && ready) {
        finished = true;
        metaData = getMetadata(metadadosRetorno, language, timeZone);
    }

    let formRetorno = <Loading />;
    const Form: (props: PropsParent) => JSX.Element = FormType;
    const updating = postReturn.status === "fetching";
    const initialValues = valuesFromSource; // getInitialValues(camposMetadados, valuesFromSource);
    const newRedirectAddress =
        redirectAddress === undefined ? null : redirectAddress;
    const hookFormMethods = reactHookFormMethodsParam.reactHookFormMethods;
    const IsMultiStepForm = metaData.IsMultiStepForm;
    const MultiStepForm = metaData?.MultiStepForm || [];
    const totalPages = MultiStepForm.length;
    const lastPage = reactHookFormMethodsParam.pageForm === totalPages;

    const submitFinal = async (values: any, alternativePostFunction?: any) => {
        try {
            if (forceBackdrop || newRedirectAddress) {
                setBackdropOpen(true);
            }
            values = removeEmptyOrNull(values);
            const ignoreUploadEmailFields =
                values?._ignoreUploadEmailFields === true;
            const ignoreUploadFileFields =
                values?._ignoreUploadFileFields === true;

            if (!ignoreUploadFileFields) {
                values = await processUploadFileFields(values);
            }
            const newValues = !ignoreUploadEmailFields
                ? await processEmailFields(values)
                : values;

            const finalPostFunction =
                alternativePostFunction !== undefined
                    ? alternativePostFunction
                    : postMethod;

            try {
                const retorno = await finalPostFunction(newValues);

                if (retorno.status === 200) {
                    if (
                        invalidateQueryClientItems &&
                        invalidateQueryClientItems?.length > 0
                    ) {
                        for (const item of invalidateQueryClientItems) {
                            queryClient.invalidateQueries(item);
                            console.log("invalidated query: " + item);
                        }
                    }
                    if (
                        removeQueryClientItems &&
                        removeQueryClientItems?.length > 0
                    ) {
                        for (const item of removeQueryClientItems) {
                            queryClient.removeQueries(item);
                            // queryClient.cancelQueries(item);
                            // console.log("remove query: " + item);
                        }
                        // queryClient.clear();
                    }
                    values = { ...initialValues };
                    // setSubmitting(false);
                    if (
                        t("successMessage") !== "[noMessage]" &&
                        t("successMessage") !== "successMessage"
                    ) {
                        showMessage(t("successMessage"), "success");
                    }
                    if (newRedirectAddress) {
                        setBackdropOpen(false);

                        navigate(newRedirectAddress);
                    }
                } else if (retorno.status === 400) {
                    if (retorno.data.message) {
                        debugger;
                        const errors = retorno.data.message.map((item: any) => {
                            const campoErro = item.dataPath.replace("/", "");
                            const missingProperty = (item?.params?.errors ||
                                [])[0]?.params?.missingProperty;

                            const messageErro = item.message;

                            return {
                                id: campoErro || missingProperty,
                                mensagem: messageErro
                            };
                        });

                        let objErros: { [key: string]: any };
                        objErros = {};
                        errors
                            .map((value: any) => value.id)
                            .sort()
                            .filter(
                                (item: any, pos: any, ary: any) =>
                                    !pos || item !== ary[pos - 1]
                            )
                            .map((item: any) => {
                                let mensagemUnificadoPorId = "";
                                errors.filter((value: any) => {
                                    if (value.id === item) {
                                        if (mensagemUnificadoPorId === "") {
                                            mensagemUnificadoPorId +=
                                                value.mensagem;
                                        } else {
                                            mensagemUnificadoPorId +=
                                                " - " + value.mensagem;
                                        }
                                    }
                                });
                                objErros[item] = mensagemUnificadoPorId;
                                const clearedItem = replaceAll(item, "/", ".");
                                hookFormMethods.setError(
                                    clearedItem,
                                    { message: mensagemUnificadoPorId },
                                    {
                                        shouldFocus: true
                                    }
                                );
                                return {
                                    id: item,
                                    mensagem: mensagemUnificadoPorId
                                };
                            });
                        // setSubmitting(false);
                        // setStatus(objErros);
                        const pageToNavigateWithErros = getPageWithErrosMultiStepFrom(
                            errors,
                            metaData,
                            reactHookFormMethods?.pageForm || 0
                        );
                        if (pageToNavigateWithErros > 0) {
                            reactHookFormMethodsParam.setPageForm(
                                pageToNavigateWithErros
                            );
                        }
                        console.log(
                            "ex",
                            JSON.stringify(retorno?.data, null, 4)
                        );
                    }
                } else if (retorno.status === 999) {
                    // erro definido na mão para não exibir nenhuma mensagem
                    // setSubmitting(false);
                } else {
                    console.log(
                        "Error submitting form",
                        JSON.stringify(retorno?.data, null, 4)
                    );
                    const error = new Error(
                        `Error submitting form: ${JSON.stringify(
                            retorno,
                            null,
                            4
                        )}`
                    );

                    logError(
                        error,
                        error.message,
                        allAuthData?.company?.companyId,
                        allAuthData?.company?.companyName || "",
                        allAuthData?.user || ""
                    ).then((errorId) => {
                        console.log("errorId", errorId);
                    });
                    showMessage(t("NotIdentifiedProblem"), "error");
                    // setSubmitting(false);
                }
            } catch (ex: any) {
                console.log("Error submitting form", ex);
                const errorMessage = `Error submitting form: ${
                    ex?.message ?? JSON.stringify(ex, null, 4)
                }`;
                logError(
                    ex,
                    errorMessage,
                    allAuthData?.company?.companyId,
                    allAuthData?.company?.companyName || "",
                    allAuthData?.user || ""
                ).then((errorId) => {
                    console.log("errorId", errorId);
                });
                showMessage(t("NotIdentifiedProblem"), "error");
            }
        } catch (ex) {
            console.log("Error submitting form", ex);
            const errorMessage = `Error submitting form: ${
                ex?.message ?? JSON.stringify(ex, null, 4)
            }`;
            logError(
                ex,
                errorMessage,
                allAuthData?.company?.companyId,
                allAuthData?.company?.companyName || "",
                allAuthData?.user || ""
            ).then((errorId) => {
                console.log("errorId", errorId);
            });
            showMessage(t("NotIdentifiedProblem"), "error");
            // setSubmitting(false);
        }
        setBackdropOpen(false);
    };

    const goToPageAfterSubmit = async (setPage: any, pageNumber: number) => {
        setPage(pageNumber);
    };

    const submit = async (
        values: any,
        nonLinearPageNumberLocal = 0,
        forceSubmitFinalLocal = false
    ) => {
        if (IsMultiStepForm && nonLinearPageNumberLocal > 0) {
            const successFN = async (keepSamePage = false) => {
                if (!keepSamePage) {
                    return await goToPageAfterSubmit(
                        reactHookFormMethodsParam.setPageForm,
                        nonLinearPageNumberLocal
                    );
                }
            };
            // Traz função personalizada específica para esta página
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === reactHookFormMethodsParam.pageForm
            );

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                await currentPageCustomFunction[0].fn(successFN, values);
            } else {
                await successFN();
            }
            // setNonLinearPageNumber(0);
        } else if (lastPage || !IsMultiStepForm || forceSubmitFinalLocal) {
            // Traz função personalizada específica para esta página
            // Se for a última página (Submit final, o parâmetro deve ser 0)
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === 0
            );

            const successFN = async (keepSamePage = false) => {
                if (!keepSamePage) {
                    return await submitFinal(values);
                }
            };

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                await currentPageCustomFunction[0].fn(successFN, values);
            } else {
                await successFN();
            }
        } else {
            // Função padrão de submit para a próxima página
            const successFN = async (keepSamePage = false) => {
                if (!keepSamePage) {
                    return await goToPageAfterSubmit(
                        // setSubmitting,
                        // setTouched,
                        reactHookFormMethodsParam.setPageForm,
                        reactHookFormMethodsParam.pageForm + 1
                    );
                } else {
                    // return clearSubmiting(setSubmitting);
                }
            };

            // Traz função personalizada específica para esta página
            const currentPageCustomFunction = (nextPageFunctions || []).filter(
                (n: any) => n.page === reactHookFormMethodsParam.pageForm
            );

            // Se a função existir, chama e envia os parâmetros
            if (currentPageCustomFunction.length > 0) {
                await currentPageCustomFunction[0].fn(successFN, values);
            } else {
                await successFN();
            }
        }
    };

    const defaultSubmit = (values: any) => {
        return submit(values, 0, false);
    };
    const handleSubmit = reactHookFormMethodsParam.reactHookFormMethods.handleSubmit(
        defaultSubmit
    );

    const listas = handleListsSort(metaData, language);

    if (finished) {
        formRetorno = (
            <Form
                t={t}
                camposMetadados={metaData.camposMetadados}
                formGroups={metaData.formGroups}
                listas={listas}
                updating={updating}
                loading={!finished}
                valuesFromSource={valuesFromSource}
                CustomComponents={CustomComponents}
                IsMultiStepForm={IsMultiStepForm}
                MultiStepForm={MultiStepForm}
                nextPageFunctions={nextPageFunctions}
                secondaryButtonFunction={secondaryButtonFunction || null}
                tertiaryButtonFunction={tertiaryButtonFunction || null}
                customLists={customLists}
                fieldsToHide={fieldsToHide}
                reactHookFormMethods={
                    reactHookFormMethodsParam.reactHookFormMethods
                }
                FormMenuActions={FormMenuActions}
                additionalFieldProperties={additionalFieldProperties}
                sizeToChangeStepHorizontal={sizeToChangeStepHorizontal}
                formGroupPaperElevation={formGroupPaperElevation}
                enableStepNavigation={enableStepNavigation}
                IsMultiStepLateralParam={IsMultiStepLateralParam}
                pageForm={reactHookFormMethodsParam.pageForm}
                setPageForm={reactHookFormMethodsParam.setPageForm}
                submit={submit}
                submitFinal={submitFinal}
                handleSubmit={handleSubmit}
                formRef={formRef}
                redirectAddress={newRedirectAddress}
            />
        );
    }

    return [formRetorno, finished, handleSubmit, hookFormMethods];
};

export interface IUseCompleoReactHookFormGetMainDataParams {
    t: TFunction;
    ready: boolean;
    i18nV: i18n;
    metadadosRetorno: ApiStateType;
    valuesFromSource: Compleo.IObject;
    timeZone?: null | string;
    validationMode?: keyof ValidationMode;
}

export interface IUseCompleoReactHookFormGetMainDataReturn {
    reactHookFormMethods: UseFormReturn<Compleo.IObject, object>;
    finished: boolean;
    pageForm: number;
    setPageForm: React.Dispatch<React.SetStateAction<number>>;
}

export const useCompleoReactHookFormGetMainData = (
    params: IUseCompleoReactHookFormGetMainDataParams
) => {
    const {
        t,
        ready,
        i18nV,
        metadadosRetorno,
        valuesFromSource,
        timeZone,
        validationMode = "onBlur"
    } = params;

    const localValuesFromSource = handleLocationCountryLegacy(valuesFromSource);
    const { company } = useAuthState();
    const [localInitialValues, setLocalInitialValues] = React.useState<
        Compleo.IObject
    >({});

    let finished = false;
    let metaData: Compleo.IObject = {};

    if (metadadosRetorno.status === "success" && ready) {
        const language = i18nV.languages[0];
        finished = true;
        metaData = getMetadata(metadadosRetorno, language, timeZone);
    }

    const initialValues = localValuesFromSource; // getInitialValues(camposMetadados, valuesFromSource);
    const MultiStepForm = metaData?.MultiStepForm || [];
    const IsMultiStepForm = metaData.IsMultiStepForm === true;
    const totalPages = MultiStepForm.length;
    const initialPage = totalPages > 0 ? 1 : 0;
    const [pageForm, setPageForm] = React.useState(initialPage);

    React.useEffect(() => {
        setPageForm(initialPage);
    }, [initialPage]);

    const camposMetadados = metaData.camposMetadados;
    const formGroups = metaData.formGroups;

    const filteredCamposMetadados = filterMetaDataFromPage(
        IsMultiStepForm,
        camposMetadados,
        MultiStepForm,
        formGroups,
        pageForm
    );
    const schema = getSchema(
        Yup,
        filteredCamposMetadados,
        t,
        company.companyId
    );
    const ObjectSchema = Yup.object().shape(schema);
    // console.log("yupResolver(ObjectSchema)", ObjectSchema);

    const localUseForm = useForm({
        mode: validationMode,
        resolver: yupResolver(ObjectSchema),
        reValidateMode: "onBlur"
    });
    const { reset } = localUseForm;

    React.useEffect(() => {
        const isEqualLocalValues = _.isEqual(localInitialValues, initialValues);

        if (!isEqualLocalValues && ready) {
            reset(initialValues);
            setLocalInitialValues(initialValues);
        }
    }, [ready, reset, localInitialValues, initialValues]);

    const returnData: IUseCompleoReactHookFormGetMainDataReturn = {
        reactHookFormMethods: localUseForm,
        finished,
        pageForm,
        setPageForm
    };
    return returnData;
};

export default useCompleoReactHookForm;

const filterMetaDataFromPage = (
    IsMultiStepForm: boolean,
    camposMetadados: any,
    MultiStepForm: any,
    formGroups: any,
    currentPage: number = 0
) => {
    if (!IsMultiStepForm) {
        return camposMetadados;
    } else {
        let currentStep = MultiStepForm.filter(
            (m: any) => m.order === currentPage
        );

        // se não achar volta a primeira página
        if (currentStep.length === 0) {
            currentStep = MultiStepForm.filter((m: any) => m.order === 1);
        }

        let formGroupsPageIds = formGroups
            .filter((f: any) => f.stepId === currentStep[0].id)
            .map((f: any) => f.id);

        let filteredCamposMetadados = camposMetadados.filter((c: any) =>
            formGroupsPageIds.includes(c.formGroupId)
        );

        return filteredCamposMetadados;
    }
};

const logError = async (
    error: any,
    errorMessage: string,
    companyId: number,
    companyName: string,
    userEmail?: string
) => {
    const errorObject = getErrorObject(
        "Custom",
        error?.stack,
        undefined,
        undefined,
        undefined,
        undefined,
        errorMessage,
        companyId,
        companyName ?? "",
        userEmail ?? ""
    );
    const errorId = await sendErrorToDB(errorObject);
    return errorId;
    // .then((errorId) => {
    //     const message = `${t(
    //         "NotIdentifiedProblem"
    //     )} Informe o seguinte código de erro para o suporte: ${errorId}`;
    //     showMessage(message, "error", 30 * 1000);
    // });
};

/**
 * Esta função foi removida pois estava gerando erro em telas de edição.
 * Por exemplo: Um campo era apagado e seu valor modificando para string vazia, null
 * ou undefined. Neste caso, a propriedade era removida do objeto e não era enviada para
 * a função de update. A função de update hoje só atualiza os campos que foram enviados no body.
 * Neste cenário, o campo continuava com o valor antigo
 * @param obj Objecto que será feito o post
 * @returns
 */
const removeEmptyOrNull = (obj: any) => {
    return obj;
    // Object.keys(obj).forEach((k) => {
    //     if (!k.includes("_filesDefinition")) {
    //         if (obj[k] === null || obj[k] === undefined) {
    //             delete obj[k];
    //         } else if (typeof obj[k] === "string" && obj[k].trim() === "") {
    //             console.log("obj[k]", obj[k]);
    //             delete obj[k];
    //         } else if (Array.isArray(obj[k])) {
    //             for (const item of obj[k]) {
    //                 if (typeof item === "object") {
    //                     removeEmptyOrNull(item);
    //                 }
    //             }
    //         } else if (typeof obj[k] === "object") {
    //             removeEmptyOrNull(obj[k]);
    //         }
    //     }
    // });

    // return obj;
};

interface IListType {
    [x: string]: Compleo.IObject[];
}
const handleListsSort = (
    metadata?: Compleo.IObject,
    language: string = "pt-BR"
) => {
    const lists: IListType | undefined = metadata?.listas;
    const listsRetun: IListType = {};
    const fieldsDef = metadata?.camposMetadados || [];
    if (!lists || fieldsDef.length === 0) {
        return lists;
    }

    Object.keys(lists || {}).map((keyName) => {
        const fieldDef = fieldsDef.filter(
            (field: Compleo.IObject) => field.fieldName === keyName
        )[0];
        listsRetun[keyName] = lists[keyName];
        const sort = fieldDef?.listDefinition?.sort;

        const fieldToSort = `label-${language}`;

        switch (sort) {
            case "asc":
            case "desc":
                listsRetun[keyName].sort((a, b) => {
                    if (sort === "asc") {
                        if (a[fieldToSort]) {
                            return a[fieldToSort].localeCompare(b[fieldToSort]);
                        } else {
                            return a.label.localeCompare(b.label);
                        }
                    } else {
                        if (b[fieldToSort]) {
                            return b[fieldToSort].localeCompare(a[fieldToSort]);
                        } else {
                            return b.label.localeCompare(a.label);
                        }
                    }
                });
                break;
            case "ascNumber":
            case "descNumber":
                listsRetun[keyName].sort((a, b) => {
                    if (sort === "ascNumber") {
                        if (a[fieldToSort]) {
                            return a[fieldToSort] - b[fieldToSort];
                        } else {
                            return a.label - b.label;
                        }
                    } else {
                        if (b[fieldToSort]) {
                            return b[fieldToSort] - a[fieldToSort];
                        } else {
                            return b.label - a.label;
                        }
                    }
                });
                break;
            default:
                break;
        }
    });
    return listsRetun;
};
