import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
// import { useField } from "formik";
// import languagesAvailable from "functions/getAllLanguagesSuported";
import { Icon, Menu } from "@material-ui/core";
import TooltipCompleo from "Components/Tooltip";
import { EmailDataDefaultValues } from "customHooks/useValuesFromSource";
import { apiDirectCall, useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { getTemplatesFromEmailDataDB } from "./EmailDataHelper";
import { createLocalUrlFromS3 } from "../File/File";
import useShowMessageFullControl from "customHooks/useShowMessageFullControl";
import { useFormContext } from "react-hook-form";

interface IButtonLoadTemplate {
    fieldValue: any;
    setFieldValue: (p: any) => void;
    fieldName: string;
    t: any;
    type?: string;
    arrayPlaceHolderBody: string[];
    arrayPlaceHolderSubject: string[];
    buttonPositionEnd?: boolean;
    splitTemplatesByLanguage: boolean;
}

const ButtonLoadTemplate = (props: IButtonLoadTemplate) => {
    const {
        t,
        fieldValue,
        setFieldValue,
        fieldName,
        type,
        buttonPositionEnd = true,
        arrayPlaceHolderSubject = [],
        arrayPlaceHolderBody = [],
        splitTemplatesByLanguage
    } = props;
    // const [field, meta, helpers] = useField(mainFieldName);
    const { company } = useAuthState();
    const showMessageFullControl = useShowMessageFullControl();
    const { reset, getValues } = useFormContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const postAddressSearch = `/emailtemplate/list`;

    const [
        getScheduleTemplateResult,
        executeScheduleTemplateData
    ] = useApiCache(
        postAddressSearch,
        "post",
        { companyId: company.companyId, pageSize: 5000 },
        false
    );
    const templateList = getTemplatesFromEmailDataDB(
        getScheduleTemplateResult,
        splitTemplatesByLanguage,
        t,
        type
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = async (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        id: string,
        language?: string
    ) => {
        showMessageFullControl.openMessage({
            message: t("COMPLEOGENERAL_MessageUpdating"),
            variant: "info",
            showLinearProgress: true
        });
        handleClose();
        const templateData = templateList.filter(
            (item) =>
                item.id === id &&
                (!language ||
                    item.items.filter((i) => i.language === language).length >
                        0)
        )[0];

        const templateLanguages = templateData.items.map(
            (item) => item.language
        );

        const currentValue = [...fieldValue];
        const currentFieldLanguages = currentValue.map((item) => item.language);

        for (let index = 0; index < currentValue.length; index++) {
            const element = currentValue[index];
            const languageData = templateData.items.filter((item) => {
                if (!language) {
                    return item.language === element.language;
                } else {
                    return true;
                }
            })[0];

            if (languageData) {
                const {
                    body,
                    subject,
                    attachments
                } = await getDataFromTemplate(
                    languageData.id,
                    company.companyId
                );

                currentValue[index]._tempEmailData = {
                    ...(currentValue[index]._tempEmailData || {}),
                    body: body,
                    subject: subject,
                    attachments: attachments
                };
                if (language) {
                    break;
                }
            }
        }
        if (!language) {
            const languagesToInclude = templateLanguages.filter(
                (item) => !currentFieldLanguages.includes(item)
            );
            for (const item of languagesToInclude) {
                const languageData = templateData.items.filter(
                    (itemLanguage) => itemLanguage.language === item
                )[0];
                if (languageData) {
                    const {
                        body,
                        subject,
                        attachments
                    } = await getDataFromTemplate(
                        languageData.id,
                        company.companyId
                    );

                    currentValue.push({
                        ...EmailDataDefaultValues,
                        default: false,
                        _tempEmailData: {
                            ...EmailDataDefaultValues._tempEmailData,
                            body: body,
                            subject: subject,
                            attachments: attachments,
                            arrayPlaceHolderBody: arrayPlaceHolderBody,
                            arrayPlaceHolderSubject: arrayPlaceHolderSubject
                        },
                        language: languageData.language
                    });
                }
            }
        }

        // setFieldValue(currentValue);
        reset({ ...getValues(), [fieldName]: currentValue });
        showMessageFullControl.closeMessage();
    };

    if (templateList.length) {
        return (
            <Grid
                container
                item
                alignItems={buttonPositionEnd ? "flex-end" : "flex-start"}
                justify={buttonPositionEnd ? "flex-end" : "flex-start"}
                alignContent="center"
            >
                <Grid item>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClick}
                        endIcon={<Icon>arrow_drop_down</Icon>}
                    >
                        {t("SCHEDULEDATAFIELD_textChooseTemplate")}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {templateList.map((option, index) => {
                            const langParam = splitTemplatesByLanguage
                                ? option.items[0].language
                                : undefined;
                            return (
                                <MenuItem
                                    key={`${option.id}${langParam || ""}`}
                                    onClick={(event) =>
                                        handleMenuItemClick(
                                            event,
                                            option.id,
                                            langParam
                                        )
                                    }
                                >
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Grid>
                {showMessageFullControl.MessageElement}
            </Grid>
        );
    } else {
        return null;
    }
};

const getDataFromTemplate = async (id: string, companyId: number) => {
    const dataReturn = await apiDirectCall("/email/getemail", "post", {
        id: id,
        companyId: companyId
    });
    const mainData = dataReturn?.data?.data;
    const body = mainData?.body;
    const subject = mainData?.subject;
    const attachments = mainData?.attachments || [];

    const attachmentsData: Compleo.IObject[] = [];
    for (const attachment of attachments) {
        const fileData = await createLocalUrlFromS3(attachment.key);
        const file = new File([fileData.blob], attachment.originalFileName);

        attachmentsData.push({
            tags: [],
            FileInfo: file,
            originalFileName: file.name
        });
    }
    return { body, subject, attachments: attachmentsData };
};

export default ButtonLoadTemplate;
