import React from "react";
import Translations from "Pages/ApplicationForm/components/TranslationComponent/Translations";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Divider, Grid } from "@material-ui/core";

interface IProps {
    messages: Compleo.IObject[];
    translations: Compleo.IObject;
    languages: Compleo.IObject[];
    defaultLanguage: string;
    t: any;
}

const ApplicationFormTranslations = (props: IProps) => {
    const { translations, messages, languages, defaultLanguage, t } = props;
    const [
        enableCustomTranslation,
        setEnableCustomTranslation
    ] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnableCustomTranslation(event.target.checked);
    };
    const metadataTranslations = { fieldName: "applicationForm.translations" };
    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableCustomTranslation}
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={t("keepDefaulTranslationCheck")}
                />
            </Grid>
            <Divider />
            {enableCustomTranslation && (
                <Grid item xs={12}>
                    <Translations
                        translations={translations}
                        messages={messages}
                        languages={languages}
                        defaultLanguage={defaultLanguage}
                        metadata={metadataTranslations}
                        formDataFieldName={"applicationForm.formData"}
                    />
                </Grid>
            )}
        </Grid>
    );
};

// ApplicationFormTranslations.whyDidYouRender = true;

export default ApplicationFormTranslations;
