import { Box, Chip, ListItem, ListItemText } from "@material-ui/core";
import { useStyles } from "../QuestionStyle";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { IQuestionListProps } from "../Question";
import { Alert } from "@material-ui/lab";

const AnswerChoices = (props: IQuestionListProps) => {
    const { question, t } = props;
    const classes = useStyles();
    const testType = question?.testType;

    return (
        <>
            {(question?.question?.answers || []).map(
                (answer: CompleoShared.Assessments.IAnswer, index) => {
                    const choosenAnswer =
                        question?.answerIds?.find(
                            (a: string) => a === answer.id
                        ) !== undefined;

                    return (
                        <ListItem
                            className={
                                choosenAnswer
                                    ? classes.nestedBorderBlue
                                    : classes.nestedBorderGrey
                            }
                            onClick={() => {}}
                            key={index}
                        >
                            <ListItemText
                                primary={
                                    <Box display={"flex"}>
                                        <Box
                                            className={classes.responsiveImage}
                                            dangerouslySetInnerHTML={{
                                                __html: answer.name || ""
                                            }}
                                        />
                                        <Box flexGrow={1} />
                                        {testType === "answersToResults" && (
                                            <Alert
                                                variant="outlined"
                                                severity="info"
                                            >
                                                {answer.answerToResults?.map(
                                                    (answerToResult) => {
                                                        return (
                                                            <Box
                                                                key={
                                                                    answerToResult.result
                                                                }
                                                            >
                                                                {
                                                                    answerToResult.result
                                                                }
                                                                {": "}
                                                                {
                                                                    answerToResult.resultValue
                                                                }
                                                                %
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            </Alert>
                                        )}
                                    </Box>
                                }
                                secondary={
                                    <SecondaryComponent
                                        answer={answer}
                                        testType={testType}
                                        classes={classes}
                                        choosenAnswer={choosenAnswer}
                                        t={t}
                                    />
                                }
                            />
                        </ListItem>
                    );
                }
            )}
        </>
    );
};

// Define the new component
interface ISecondaryProps {
    answer: CompleoShared.Assessments.IAnswer;
    testType: CompleoShared.Assessments.testType;
    choosenAnswer: boolean;
    classes: any;
    t: any;
}

const SecondaryComponent = (props: ISecondaryProps) => {
    const { answer, classes, testType, choosenAnswer, t } = props;
    const correctAnswer = testType === "regular" && answer.correct === true;
    const weightText =
        testType === "weightAnswers"
            ? `${t("weight")}: ${answer?.weight?.value}`
            : "";

    return (
        <>
            {choosenAnswer && (
                <Chip
                    label={t("choosedAnswerByApplicant")}
                    size="small"
                    className={classes.chipCardChoosenAnswer}
                    icon={
                        <IconChooseAnswer
                            correctAnswer={correctAnswer}
                            choosenAnswer={choosenAnswer}
                            weightText={weightText}
                        />
                    }
                />
            )}
            {correctAnswer && (
                <Chip
                    label={t("correctAnswer")}
                    size="small"
                    className={classes.chipCardAnswerCorrect}
                />
            )}
            {weightText && (
                <Chip
                    label={weightText}
                    size="small"
                    className={classes.chipCardWeightInfo}
                />
            )}
        </>
    );
};

interface IIconChooseAnswerProps {
    correctAnswer: boolean;
    choosenAnswer: boolean;
    weightText?: string;
}
const IconChooseAnswer = (props: IIconChooseAnswerProps) => {
    const { correctAnswer, choosenAnswer, weightText } = props;
    if (correctAnswer && choosenAnswer) {
        return <DoneIcon style={{ color: "white" }} />;
    } else if (weightText) {
        return null;
    } else {
        return <CloseIcon style={{ color: "red" }} />;
    }
};

export default AnswerChoices;
