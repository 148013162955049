import React from "react";
import { UseFormWatch } from "react-hook-form";
import isEqual from "lodash/isEqual";

interface IParams {
    watch: UseFormWatch<Compleo.IObject>;
    mainPipelineData?: Compleo.IObject[];
}

export function useGenericStageActionsModalHandleFieldsToHide(params: IParams) {
    const { watch, mainPipelineData } = params;

    const [fieldsToHide, setFieldsToHide] = React.useState<string[]>([
        "cvToShare"
    ]);

    const displayCVField = watch("displayCV");
    const resultsToStageRuleField = watch("resultsToStageRule");

    React.useEffect(() => {
        let fieldsToHideFN = [...fieldsToHide];

        if (displayCVField) {
            fieldsToHideFN = fieldsToHideFN.filter(
                (item) => item !== "cvToShare"
            );
        } else {
            fieldsToHideFN = [...fieldsToHide, "cvToShare"];
        }
        if ((resultsToStageRuleField || []).length > 0) {
            debugger;
            for (let i = 0; i <= resultsToStageRuleField.length; i++) {
                const fieldData = resultsToStageRuleField[i];
                if (fieldData) {
                    const newStageDetails = (mainPipelineData || []).find(
                        (item) => item.id === fieldData?.targetStage?.value
                    );
                    if (newStageDetails?.type?.value !== "rejected") {
                        const fieldToHide = `resultsToStageRule.${i}.disqualifyReason`;
                        fieldsToHideFN = [...fieldsToHideFN, fieldToHide];
                    } else {
                        const fieldToHide = `resultsToStageRule.${i}.disqualifyReason`;
                        fieldsToHideFN = fieldsToHideFN.filter(
                            (item) => item !== fieldToHide
                        );
                    }
                }
            }
        }
        // remove duplicates
        fieldsToHideFN = Array.from(new Set(fieldsToHideFN));
        // order fields alphabetically
        fieldsToHideFN.sort();

        if (!isEqual(fieldsToHide, fieldsToHideFN)) {
            setFieldsToHide(fieldsToHideFN);
        }
    }, [displayCVField, JSON.stringify(resultsToStageRuleField || [])]);

    return fieldsToHide;
}
