import { ListItem, ListItemText, Typography } from "@material-ui/core";
import { useStyles } from "../../AssessmentsListStyle";
import { IQuestionListProps } from "../Question";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import SaveIcon from "@material-ui/icons/Save";
import { FieldFile } from "customHooks/useCompleoView/Fields/FieldsType";
import { useState } from "react";
import ModalSaveEmailAttachments from "Pages/Applicant/ApplicantView/ApplicantEmail/ModalSaveEmailAttachments";

const AnswerFiles = (props: IQuestionListProps) => {
    const { question, t } = props;
    const [modalSaveAttachmentsOpen, setModalSaveAttachmentsOpen] = useState(
        false
    );
    const fieldData = question.answer?.[0];
    return (
        <ListItem onClick={() => {}}>
            {/* {question.answer?.[0]?.filesS3Keys?.map((file) => ( */}
            <ListItemText
                // key={file.key}
                primary={
                    <>
                        <ButtonIconTooltipCompleo
                            label={t("saveAttachments")}
                            color="secondary"
                            onClick={() => setModalSaveAttachmentsOpen(true)}
                        >
                            <SaveIcon />
                        </ButtonIconTooltipCompleo>
                    </>
                }
                secondary={
                    <>
                        <FieldFile
                            fieldData={fieldData || {}}
                            fieldName="filesS3Keys"
                            t={t}
                            language={"pt-BR"}
                            fieldDef={{}}
                            propsToAdd={{}}
                            disableSearch
                        />
                        {modalSaveAttachmentsOpen && (
                            <ModalSaveEmailAttachments
                                open={modalSaveAttachmentsOpen}
                                handleClose={() =>
                                    setModalSaveAttachmentsOpen(false)
                                }
                                files={fieldData?.filesS3Keys || []}
                            />
                        )}
                    </>
                }
            />
            {/* ))} */}
        </ListItem>
    );
};

export default AnswerFiles;
