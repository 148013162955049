import React from "react";
import { useApiCache, ApiStateType } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { useGetAssessments } from "Pages/Job/JobView/JobAssessments/AssessmentsGroupsList/helper";
import { ModalDataType } from "./helperPipeline";
import { ListDefinitionType } from "Pages/Users/components/useCustomLists";

interface ICustomLists {
    t: any;
    readyTranslation: boolean;
    jobId: string;
    mainPipelineData?: Compleo.IObject[];
    modalData?: ModalDataType;
}

const useCustomLists: (
    params: ICustomLists
) => [Compleo.ListDefinitionType[], boolean] = (params: ICustomLists) => {
    const { t, readyTranslation, jobId, modalData, mainPipelineData } = params;
    const { company } = useAuthState();
    const companyId = company.companyId || "0";

    const [ready, setReady] = React.useState<boolean>(false);
    const [listDefinition, setListDefinition] = React.useState<
        Compleo.ListDefinitionType[]
    >([]);

    const [customCVListResult, getCustomCVList] = useApiCache(
        "/customcv/getcustomcv",
        "post",
        {
            companyId
        },
        false,
        60 * 1000 * 10
    );

    if (
        customCVListResult.status === "success" &&
        !listFilled(listDefinition, "cvToShare") &&
        readyTranslation
    ) {
        const cvList = customCVListResult?.response?.data?.map(
            (cv: Compleo.IObject) => ({
                label: cv.name,
                value: cv.skGS1pk.split(":")[1]
            })
        );

        cvList?.sort((a: Compleo.IObject, b: Compleo.IObject) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

        cvList?.unshift({
            label: t("applicantOriginalCVType"),
            value: "cv"
        });

        listDefinition.push({
            fieldName: "cvToShare",
            data: cvList,
            valueName: "value",
            labelName: "label"
        });
    }

    const [recruiters, callRecruiters] = useApiCache(
        "/user/dropdownlist",
        "post",
        { companyId: companyId },
        false,
        1000 * 60 * 2
    );
    if (
        recruiters.status === "success" &&
        !listFilled(listDefinition, "recipients") &&
        !listFilled(listDefinition, "evaluators")
    ) {
        listDefinition.push({
            fieldName: "recipients",
            data: recruiters.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
        listDefinition.push({
            fieldName: "evaluators",
            data: recruiters.response.data,
            valueName: "itemValue",
            labelName: "itemText"
        });
    }

    const { ready: readyAssessment, itemsAssessments } = useGetAssessments(
        jobId
    );
    if (
        (readyAssessment || !jobId) &&
        !listFilled(listDefinition, "assessment")
    ) {
        if (jobId) {
            listDefinition.push({
                fieldName: "assessment",
                data: itemsAssessments,
                valueName: "assessmentId",
                labelName: "name"
            });
            listDefinition.push({
                fieldName: "assessmentsTestsToShare",
                data: itemsAssessments,
                valueName: "assessmentId",
                labelName: "name"
            });
        } else {
            listDefinition.push({
                fieldName: "assessment",
                data: [],
                valueName: "assessmentId",
                labelName: "name"
            });
            listDefinition.push({
                fieldName: "assessmentsTestsToShare",
                data: [],
                valueName: "assessmentId",
                labelName: "name"
            });
        }
    }
    if (!listFilled(listDefinition, "resultsToStageRule.targetStage")) {
        const currentStageId = modalData?.stage?.id;
        const allStages = (mainPipelineData || [])
            .filter((item) => item.id !== currentStageId)
            .map((item) => ({
                value: item.id,
                label: item.name
            }));

        const stagesList: ListDefinitionType = {
            fieldName: "resultsToStageRule.targetStage",
            data: allStages,
            labelName: "label",
            valueName: "value"
        };
        listDefinition.push(stagesList);
    }

    if (!ready) {
        const readyModules = verifyReady(
            [
                "recipients",
                "evaluators",
                "cvToShare",
                "assessment",
                "resultsToStageRule.targetStage"
            ],
            listDefinition
        );
        if (readyModules) {
            setReady(true);
        }
    }
    return [listDefinition, ready];
};

const verifyReady = (
    fieldList: string[],
    state: Compleo.CustomLists.ListDefinitionType[]
) => {
    const currentFieldsLoaded = state.map((item) => item.fieldName);
    for (const field of fieldList) {
        if (!currentFieldsLoaded.includes(field)) {
            return false;
        }
    }
    return true;
};

const listFilled = (
    listDefinition: Compleo.ListDefinitionType[],
    fieldName: string
) => {
    return (
        listDefinition.filter((l: any) => l.fieldName === fieldName).length > 0
    );
};

export default useCustomLists;
