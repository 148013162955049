import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { TFunction } from "i18next";
import AddTestToAssessment from "./AddTestToAssessment";
import {
    extractDataFromJobSK1AssessmentGroup,
    useDeleteAssessmentOrTest
} from "./helper";
import AssessmentTestList from "./AssessmentTestList";
import StageRulesCard from "./StageRulesCard";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import useJobContext from "../../useJobContext";
import ModalJobAssessmentsGroup from "../ModalJobAssessmentsGroup/Index";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end"
    },
    expandIcon: {
        marginLeft: "auto"
    }
}));

interface IAssessmentGroup {
    item: CompleoShared.Assessments.IAssessment;
    tests: CompleoShared.Assessments.ITest[];
    tLocal: TFunction;
}
const Assessment = (props: IAssessmentGroup) => {
    const { item, tLocal, tests } = props;
    const [data] = useJobContext();
    const [modalOpen, setModalOpen] = React.useState(false);

    const classes = useStyles();
    const assessmentId = extractDataFromJobSK1AssessmentGroup(
        item.skGS1pk,
        "assessmentId"
    );
    const {
        deleteFN: handleDelete,
        showMessageFullControl
    } = useDeleteAssessmentOrTest(tLocal);

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h2"
                        gutterBottom
                        style={{ fontWeight: 700 }}
                    >
                        {item.name}
                    </Typography>
                    <Box flexGrow={1} />
                    <ButtonIconTooltipCompleo
                        label="Delete"
                        onClick={() => setModalOpen(true)}
                    >
                        <Edit />
                    </ButtonIconTooltipCompleo>
                    {tests.length === 0 && (
                        <ButtonIconTooltipCompleo
                            label="Delete"
                            onClick={async () =>
                                await handleDelete(item, data?.JobId, true)
                            }
                        >
                            <Delete color="secondary" />
                        </ButtonIconTooltipCompleo>
                    )}
                    <AddTestToAssessment
                        tLocal={tLocal}
                        assessmentId={assessmentId || ""}
                        tests={tests}
                    />
                </Box>
                <StageRulesCard
                    resultsToStageRule={item.resultsToStageRule}
                    tLocal={tLocal}
                />
                <AssessmentTestList tLocal={tLocal} tests={tests} />
                {showMessageFullControl.MessageElement}
            </CardContent>
            {modalOpen && (
                <ModalJobAssessmentsGroup
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    editData={item}
                />
            )}
        </Card>
    );
};

export default Assessment;
