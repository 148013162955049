import { Grid, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import useJobContext from "../../useJobContext";
import Assessment from "./Assessment";
import { getTestsFromAssessment, useGetAssessments } from "./helper";
import Loading from "customHooks/useCompleoForm/helpers/Loading";

interface IAssessmentsGroupsList {
    tLocal: TFunction;
}
const AssessmentsGroupsList = (props: IAssessmentsGroupsList) => {
    const { tLocal } = props;
    const [data] = useJobContext();
    const { ready, itemsAssessments, itemsTests } = useGetAssessments(
        data?.JobId
    );

    if (ready) {
        if (itemsAssessments.length === 0)
            return (
                <Typography color="secondary">
                    {tLocal("noDataFound")}
                </Typography>
            );
        return (
            <Grid container spacing={2}>
                {itemsAssessments.map((item: any) => (
                    <Grid item xs={12} key={item.skGS1pk}>
                        <Assessment
                            item={item}
                            tLocal={tLocal}
                            tests={getTestsFromAssessment(
                                itemsTests,
                                item.skGS1pk
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

export default AssessmentsGroupsList;
