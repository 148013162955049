import logo from "assets/img/compleo_horizontal_branco.svg";

interface IProps {
    className: string;
}

export const LogoImported = (props: IProps) => {
    return <img className={props.className} src={logo} alt="Logo Compleo" />;
};

export const Logo = (props: IProps) => {
    return <LogoImported className={props.className} />;
};

export default Logo;
