import React from "react";
import {
    Button,
    createStyles,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    makeStyles,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import QuestionnaireModelChooseDialog from "./QuestionnaireModelChooseDialog";
import { useTranslation } from "react-i18next";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import RichText from "customHooks/useCompleoReactHookForm/Inputs/RichText/RichText";
import TextField from "customHooks/useCompleoReactHookForm/Inputs/TextField";
import QuestionnaireResult from "./QuestionnaireResult";
import AddResultButtom from "./AddResultButtom";
import ResultsModal from "./ResultsModal";
import { verifyFields } from "Pages/Questionnaire/components/helper";
import TooltipCompleo from "Components/Tooltip";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import RankingToResultsModal from "./RankingToResultsModal";
import QuestionnaireRankingToResult from "./QuestionnaireRankingToResult";
import EditIcon from "@material-ui/icons/Edit";

interface IProps {
    t: any;
    dialogChooseQuestionnaireTemplateOpen: boolean;
    setDialogChooseQuestionnaireTemplateOpen: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                marginTop: theme.spacing(2)
            }
        },
        grid: {
            marginTop: theme.spacing(3)
        },
        formControl: {
            marginTop: theme.spacing(2),
            width: "100%"
        },
        label: {
            fontSize: 12
        },
        table: {
            marginTop: theme.spacing(2)
        }
    })
);

const QuestionnaireModel = (props: IProps) => {
    // const [field, meta, helpers] = useField("screeningQuestionnaire");
    const fieldName = "screeningQuestionnaire";
    const { watch, setFieldValue } = useRhfFieldWithoutControllerOrRegister(
        fieldName
    );
    const fieldValue = watch(fieldName);

    const [hiddenFields, setHiddenFields] = React.useState<string[]>([]);
    const [resultsModalOpen, setResultsModalOpen] = React.useState(false);
    const [
        rankingToResultsModalOpen,
        setRankingToResultsModalOpen
    ] = React.useState(false);

    const handleResultsClose = () => {
        setResultsModalOpen(false);
    };
    const classes = useStyles();
    const {
        t,
        dialogChooseQuestionnaireTemplateOpen,
        setDialogChooseQuestionnaireTemplateOpen
    } = props;
    const moduleName = "QUESTIONNAIRENEW";
    const [tQuestionnaireNew, i18n, readyTranslation] = useTranslation(
        moduleName,
        {
            useSuspense: false
        }
    );
    const results = fieldValue?.results || [];
    const rankingToResults = fieldValue?.rankingToResults || [];
    const enableStartMessage = fieldValue?.enableStartMessage || false;
    const enableResults = fieldValue?.enableResults || false;
    const enableRanking = fieldValue?.enableRanking === true;
    const enableResultsByRankingScore =
        fieldValue?.enableResultsByRankingScore === true;
    const hasResults = results.length > 0;

    React.useEffect(() => {
        verifyFields(
            enableStartMessage,
            enableResults,
            enableRanking,
            enableResultsByRankingScore,
            hasResults,
            () => {},
            hiddenFields,
            setHiddenFields,
            false
        );
        if (!enableResults && (fieldValue?.results || []).length > 0) {
            setFieldValue({ ...fieldValue, results: [] });
        }
    }, [
        enableStartMessage,
        enableResults,
        enableRanking,
        enableResultsByRankingScore,
        hasResults
    ]);

    const handleEnableStartMessage = () => {
        setFieldValue({
            ...fieldValue,
            enableStartMessage: !enableStartMessage
        });
    };

    const handleRanking = () => {
        setFieldValue({
            ...fieldValue,
            enableRanking: !enableRanking
        });
    };

    const handleEnableResults = () => {
        setFieldValue({
            ...fieldValue,
            enableResults: !enableResults
        });
    };

    const handleEnableResultsByRankingScore = () => {
        setFieldValue({
            ...fieldValue,
            enableResultsByRankingScore: !enableResultsByRankingScore
        });
    };

    if (readyTranslation) {
        return (
            <Grid container className={classes.grid}>
                <Grid container>
                    <Grid item xs={11} sm={6} md={5}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableStartMessage}
                                    onChange={handleEnableStartMessage}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={tQuestionnaireNew("enableStartMessage")}
                        />
                    </Grid>
                    <Grid item xs sm md>
                        <TooltipCompleo
                            detail={tQuestionnaireNew(
                                "enableStartMessage_tooltipDetail"
                            )}
                            title={tQuestionnaireNew(
                                "enableStartMessage_tooltipTitle"
                            )}
                            buttonAriaText={tQuestionnaireNew(
                                "enableStartMessage_tooltipTitle"
                            )}
                        />
                    </Grid>
                </Grid>
                {!hiddenFields.includes("startMessage") && (
                    <Grid item xs={12}>
                        <RichText
                            t={tQuestionnaireNew}
                            name="screeningQuestionnaire.startMessage"
                            metadata={{
                                fieldName: "screeningQuestionnaire.startMessage"
                            }}
                            required={false}
                            label={tQuestionnaireNew("startMessage")}
                        />
                    </Grid>
                )}
                <GroupHeader title={tQuestionnaireNew("a_FormGroup_results")} />
                <Grid container>
                    <Grid item xs={11} sm={6} md={5}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableResults}
                                    onChange={handleEnableResults}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={tQuestionnaireNew("enableResults")}
                        />
                    </Grid>
                    <Grid item xs sm md>
                        <TooltipCompleo
                            detail={tQuestionnaireNew(
                                "enableResults_tooltipDetail"
                            )}
                            title={tQuestionnaireNew(
                                "enableResults_tooltipTitle"
                            )}
                            buttonAriaText={tQuestionnaireNew(
                                "enableResults_tooltipTitle"
                            )}
                        />
                    </Grid>
                </Grid>

                {!hiddenFields.includes("results") && (
                    <>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel
                                    className={classes.label}
                                    htmlFor={fieldName}
                                    aria-label={tQuestionnaireNew("results")}
                                    required={false}
                                >
                                    {tQuestionnaireNew("results")}
                                </FormLabel>
                                <TableContainer className={classes.table}>
                                    <Table
                                        aria-label="simple table"
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {t(
                                                        "labelQuestionnaireResult"
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {t(
                                                        "labelQuestionnaireResultActions"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.map(
                                                (
                                                    result: any,
                                                    index: number
                                                ) => (
                                                    <QuestionnaireResult
                                                        key={result.name}
                                                        index={index}
                                                        t={t}
                                                        fieldValue={fieldValue}
                                                    />
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <AddResultButtom
                                handleAddResult={() =>
                                    setResultsModalOpen(true)
                                }
                                t={t}
                            />
                        </Grid>
                    </>
                )}
                <GroupHeader title={tQuestionnaireNew("a_FormGroup_ranking")} />
                <Grid container>
                    <Grid item xs={11} sm={6} md={5}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableRanking}
                                    onChange={handleRanking}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={tQuestionnaireNew("enableRanking")}
                        />
                    </Grid>
                    <Grid item xs sm md>
                        <TooltipCompleo
                            detail={tQuestionnaireNew(
                                "enableRanking_tooltipDetail"
                            )}
                            title={tQuestionnaireNew(
                                "enableRanking_tooltipTitle"
                            )}
                            buttonAriaText={tQuestionnaireNew(
                                "enableRanking_tooltipTitle"
                            )}
                        />
                    </Grid>
                </Grid>
                {!hiddenFields.includes("enableResultsByRankingScore") && (
                    <Grid container>
                        <Grid item xs={11} sm={6} md={5}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enableResultsByRankingScore}
                                        onChange={
                                            handleEnableResultsByRankingScore
                                        }
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={tQuestionnaireNew(
                                    "enableResultsByRankingScore"
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
                {(!hiddenFields.includes("rankingToResults") ||
                    !hiddenFields.includes("warningRankingToResults")) && (
                    <>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <FormLabel
                                    className={classes.label}
                                    htmlFor={fieldName}
                                    aria-label={tQuestionnaireNew(
                                        "rankingToResults"
                                    )}
                                    required={false}
                                >
                                    {tQuestionnaireNew("rankingToResults")}
                                </FormLabel>
                                <TableContainer className={classes.table}>
                                    <Table
                                        aria-label="simple table"
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {tQuestionnaireNew(
                                                        "rankingToResults.minScore"
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {tQuestionnaireNew(
                                                        "rankingToResults.result"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rankingToResults.map(
                                                (
                                                    result: any,
                                                    index: number
                                                ) => (
                                                    <QuestionnaireRankingToResult
                                                        key={index}
                                                        index={index}
                                                        t={t}
                                                        fieldValue={fieldValue}
                                                    />
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color="secondary"
                                size="small"
                                style={{ margin: "16px" }}
                                startIcon={<EditIcon />}
                                onClick={() =>
                                    setRankingToResultsModalOpen(true)
                                }
                            >
                                {t("buttonEditQuestionnaireRankingResult")}
                            </Button>
                        </Grid>
                    </>
                )}
                <QuestionnaireModelChooseDialog
                    open={dialogChooseQuestionnaireTemplateOpen}
                    setOpen={setDialogChooseQuestionnaireTemplateOpen}
                    t={t}
                />
                <ResultsModal
                    open={resultsModalOpen}
                    handleModalClose={handleResultsClose}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                />
                <RankingToResultsModal
                    open={rankingToResultsModalOpen}
                    handleModalClose={() => setRankingToResultsModalOpen(false)}
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    hiddenFields={hiddenFields}
                />
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

interface IGroupHeaderProps {
    title: string;
}
const GroupHeader = (props: IGroupHeaderProps) => {
    const { title } = props;

    return (
        <Grid container style={{ marginTop: 16, marginBottom: 16 }}>
            <Grid item xs={12}>
                <Divider />
                <Typography variant="body1" style={{ marginTop: 8 }}>
                    <strong>{title}</strong>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default QuestionnaireModel;
