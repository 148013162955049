import React, { useContext, useState } from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useGlobalDialog } from "_ReactContext/GlobalDialogContext";

/**
 * Button component for add a new section in the assessment
 *
 * @component
 * @example
 * return (
 *   <ButtonAddSection />
 * )
 */
interface IButtonAddSectionProps {
    type?: "add" | "edit";
    values?: CompleoShared.Assessments.ISection;
    setModalAddEditSectionData: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            values?: CompleoShared.Assessments.ISection | undefined;
        }>
    >;
}

export const ButtonAddEditSection = (props: IButtonAddSectionProps) => {
    const { type = "add", setModalAddEditSectionData, values } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const { t } = ContextQuestionFieldsData || {};

    return (
        <>
            {type === "add" ? (
                <Button
                    onClick={() =>
                        setModalAddEditSectionData({
                            open: true,
                            values: {
                                showAllQuestions: true,
                                id: "",
                                name: "",
                                order: 0,
                                numberOfQuestionsToShow: 5,
                                expanded: true,
                                sectionQuestionsWeight: 1
                            }
                        })
                    }
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    {t("buttonAddSection")}
                </Button>
            ) : (
                <Tooltip title={t("COMPLEOGENERAL_Edit")}>
                    <IconButton
                        onClick={(event) => {
                            setModalAddEditSectionData({
                                open: true,
                                values: values
                            });
                            event.stopPropagation();
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        aria-label={t("COMPLEOGENERAL_Edit")}
                        color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

interface IButtonDeleteSectionProps {
    values: CompleoShared.Assessments.ISection;
}
export const ButtonDeleteSection = (props: IButtonDeleteSectionProps) => {
    const { values } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const { sectionsData = [], setFieldValueSections } =
        ContextQuestionFieldsData || {};
    const { t } = ContextQuestionFieldsData || {};
    const { readyForm, callDialog, agree } = useGlobalDialog();

    const handleDeleteSection = () => {
        callDialog({
            title: t("COMPLEOGENERAL_Delete"),
            bodyText: t("messageConfirmDeleteSection"),
            agreeButtonText: t("COMPLEOGENERAL_YES"),
            disagreeButtonText: t("COMPLEOGENERAL_NO"),
            agreeFunction: async () => {
                const newSectionsData = sectionsData.filter(
                    (section) => section.id !== values.id
                );
                if (setFieldValueSections) {
                    setFieldValueSections(newSectionsData);
                }
                return false;
            },
            disagreeFunction: () => {
                return false;
            }
        });
    };
    return (
        <Tooltip title={t("COMPLEOGENERAL_Edit")}>
            <IconButton
                onClick={(event) => {
                    handleDeleteSection();
                    event.stopPropagation();
                }}
                onFocus={(event) => event.stopPropagation()}
                aria-label={t("COMPLEOGENERAL_Edit")}
                color="secondary"
            >
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
};
