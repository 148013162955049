import React from "react";

// This is a custom hook that is used to handle possible results
// If a possible result is added or removed, this hook will update the questions to include the new possible result

interface IUseHandlePossibleResults {
    questions: CompleoShared.Assessments.IQuestion[];
    possibleResults: CompleoShared.Assessments.IPossibleResults[];
    setFieldValueQuestions: (value: any) => void;
}

export const useHandlePossibleResults = (params: IUseHandlePossibleResults) => {
    const { questions, possibleResults, setFieldValueQuestions } = params;
    React.useEffect(() => {
        questions.map((question: CompleoShared.Assessments.IQuestion) => {
            question.answers.map(
                (answer: CompleoShared.Assessments.IAnswer) => {
                    if (
                        possibleResults.length !==
                        answer.answerToResults?.length
                    ) {
                        possibleResults.map((pResult) => {
                            if (
                                !answer.answerToResults?.find(
                                    (i) => i.result === pResult.name
                                )
                            ) {
                                answer.answerToResults?.push({
                                    result: pResult.name,
                                    resultValue: 0
                                });
                            }
                        });
                        answer.answerToResults?.map((answerToResult) => {
                            if (
                                !possibleResults.find(
                                    (pResult) =>
                                        pResult.name === answerToResult.result
                                )
                            ) {
                                // remove answerToResult
                                answer.answerToResults = answer.answerToResults?.filter(
                                    (i) => i.result !== answerToResult.result
                                );
                            }
                        });
                    } //if equal, we will just modify the name for the different result
                    else {
                        answer.answerToResults.map((aResult) => {
                            if (
                                !possibleResults?.find(
                                    (i) => i.name === aResult.result
                                )
                            ) {
                                const allAnswerToResults = (
                                    answer?.answerToResults || []
                                ).map((item) => item.result);
                                const newResult = possibleResults?.find(
                                    (i) => !allAnswerToResults.includes(i.name)
                                );
                                if (newResult) {
                                    aResult.result = newResult.name;
                                }
                            }
                        });
                    }
                }
            );
        });

        setFieldValueQuestions(questions);
    }, [possibleResults]);
};
