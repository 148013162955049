import { IInputProps } from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import ContextQuestionsFieldsData from "./components/ContextQuestionsFieldsData";
import Sections from "./01_Sections/Sections";
import useQuestionsFieldsData from "./components/useQuestionsFieldsData";

interface IProps {
    questionsFieldName?: string;
    sectionsFieldName?: string;
    testTypeFieldName?: string;
    possibleResultsFieldName?: string;
}

const QuestionsMain = (props: IProps & IInputProps) => {
    const {
        questionsFieldName = "questions",
        sectionsFieldName = "sections",
        t,
        testTypeFieldName = "testType",
        possibleResultsFieldName = "possibleResults"
    } = props;

    const questionFieldsData = useQuestionsFieldsData({
        questionsFieldName,
        sectionsFieldName,
        t,
        testTypeFieldName,
        possibleResultsFieldName
    });

    return (
        <ContextQuestionsFieldsData.Provider value={questionFieldsData}>
            <Sections t={t} />
        </ContextQuestionsFieldsData.Provider>
    );
};

export default QuestionsMain;
