import { ListItem, ListItemText } from "@material-ui/core";
import { useStyles } from "../../AssessmentsListStyle";
import { IQuestionListProps } from "../Question";

const AnswerText = (props: IQuestionListProps) => {
    const { question } = props;
    const classes = useStyles();

    return (
        <ListItem onClick={() => {}}>
            <ListItemText
                primary={
                    <div
                        className={classes.responsiveImage}
                        dangerouslySetInnerHTML={{
                            __html: question.answerText || ""
                        }}
                    />
                }
            />
        </ListItem>
    );
};

export default AnswerText;
