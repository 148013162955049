export const HISTORYCOMMENTMAINHEIGHT = 200;
export const HISTORYTAGSAUTOMATIONMAINHEIGHT = 110;
export const HISTORYCHANGESTATUSHEIGHT = 155;
export const HISTORYSENDEMAILAUTOMATIONHEIGHT = 195;
export const HISTORYSENDEMAILAUTOMATIONLATERHEIGHT = 215;
export const HISTORYSENDEMAILBULKHEIGHT = 145;
export const HISTORYSENDEMAILTOUSERAUTOMATIONHEIGHT = 185;
export const HISTORYSENDEMAILTOUSERAUTOMATIONHEIGHTLATER = 195;
export const HISTORYADDTOJOBHEIGHT = 115;
export const HISTORYAPPLIEDTOJOBHEIGHT = 115;
export const HISTORYREMOVEFROMJOBHEIGHT = 95;
export const HISTORYREQUESTEVALUATIONHEIGHT = 150;
export const HISTORYREQUESTEVALUATIONAUTOMATIONHEIGHT = 215;
export const HISTORYOPINIONONTHEAPPLICANT = 215;
export const HISTORYCHANGESTAGEASSESSMENTRESULTHEIGHT = 190;
export const HISTORYSENDASSESSMENTHEIGHT = 150;
export const HISTORYJOBMATCH = 150;

export interface IApplicantHistoryProps {
    historyItem: Compleo.IObject;
    tLocal: any;
    language: string;
}

export const getIconByHistoryType = (
    historyType: CompleoShared.Common.ApplicantHistoryType
) => {
    switch (historyType) {
        case "changeJobStage":
        case "changeJobStageAssessmentResult":
        case "changeJobApplicantMatchResult":
            return "swap_horiz";
        case "addComment":
            return "comment";
        case "addTagsAutomation":
            return "label";
        case "addToJob":
            return "work";
        case "appliedForTheJob":
            return "work";
        case "removedFromJob":
            return "delete";
        case "sendEmailAutomation":
            return "mail";
        case "sendEmailAutomation_later":
            return "access_alarm";
        case "sendEmailToUsersAutomationApplicantContext":
            return "contact_mail";
        case "sendEmailToUsersAutomationApplicantContext_later":
            return "access_alarm";
        case "sendEmailBulk":
            return "mail";
        case "requestEvaluation":
        case "requestEvaluationAutomation":
            return "star";
        case "phoneCall":
            return "phone";
        case "interview":
            return "event_seat";
        case "textMessage":
            return "message";
        case "termsOfServiceAccepted":
            return "check_circle";
        case "newOrUpdateApplicantOpinion":
        case "newOrUpdateApplicantOpinionJob":
            return "announcement";
        case "sendAssessment":
        case "sendAssessmentAutomation":
        case "sendAssessmentAutomation_emailAgain":
        case "sendAssessment_emailAgain":
        case "sendAssessmentAutomation_failedApplicantAssessmentCompleted":
        case "sendAssessment_failedApplicantAssessmentCompleted":
            return "assessment";
        case "jobApplicantMatch":
        case "jobApplicantMatchAutomation":
            return "stars";
        default:
            return "fiber_manual_record";
    }
};

export const getColorByHistoryType = (
    historyType: CompleoShared.Common.ApplicantHistoryType
) => {
    switch (historyType) {
        case "changeJobStage":
        case "changeJobStageAssessmentResult":
        case "changeJobApplicantMatchResult":
            return "#f44336";
        case "addComment":
            return "#3f51b5";
        case "addTagsAutomation":
            return "#26a69a";
        case "addToJob":
            return "#2196f3";
        case "appliedForTheJob":
            return "#2196f3";
        case "removedFromJob":
            return "#f44336";
        case "sendEmailAutomation":
            return "#26a69a";
        case "sendEmailAutomation_later":
            return "#26a69a";
        case "sendEmailToUsersAutomationApplicantContext":
            return "#26a69a";
        case "sendEmailToUsersAutomationApplicantContext_later":
            return "#26a69a";
        case "sendEmailBulk":
            return "green";
        case "requestEvaluation":
            return "#fbc02d";
        case "requestEvaluationAutomation":
            return "#26a69a";
        case "phoneCall":
            return "red";
        case "interview":
            return "indigo";
        case "termsOfServiceAccepted":
            return "#26a69a";
        case "newOrUpdateApplicantOpinion":
        case "newOrUpdateApplicantOpinionJob":
            return "#3f51b5";
        case "sendAssessment":
        case "sendAssessmentAutomation":
        case "sendAssessmentAutomation_emailAgain":
        case "sendAssessment_emailAgain":
            return "#00e5ff";
        case "sendAssessmentAutomation_failedApplicantAssessmentCompleted":
        case "sendAssessment_failedApplicantAssessmentCompleted":
            return "red";
        case "jobApplicantMatch":
        case "jobApplicantMatchAutomation":
            return "#33eb91";
        default:
            return "grey";
    }
};

export const getSizeByHistoryType = (
    historyType: CompleoShared.Common.ApplicantHistoryType
) => {
    switch (historyType) {
        case "changeJobStage":
            return HISTORYCHANGESTATUSHEIGHT;
        case "changeJobStageAssessmentResult":
            return HISTORYCHANGESTAGEASSESSMENTRESULTHEIGHT;
        case "addComment":
        case "interview":
        case "phoneCall":
            return HISTORYCOMMENTMAINHEIGHT;
        case "addTagsAutomation":
            return HISTORYTAGSAUTOMATIONMAINHEIGHT;
        case "addToJob":
            return HISTORYADDTOJOBHEIGHT;
        case "appliedForTheJob":
            return HISTORYAPPLIEDTOJOBHEIGHT;
        case "removedFromJob":
            return HISTORYREMOVEFROMJOBHEIGHT;
        case "sendEmailAutomation":
            return HISTORYSENDEMAILAUTOMATIONHEIGHT;
        case "sendEmailAutomation_later":
            return HISTORYSENDEMAILAUTOMATIONLATERHEIGHT;
        case "sendEmailToUsersAutomationApplicantContext":
            return HISTORYSENDEMAILTOUSERAUTOMATIONHEIGHT;
        case "sendEmailToUsersAutomationApplicantContext_later":
            return HISTORYSENDEMAILTOUSERAUTOMATIONHEIGHTLATER;
        case "sendEmailBulk":
            return HISTORYSENDEMAILBULKHEIGHT;
        case "requestEvaluation":
            return HISTORYREQUESTEVALUATIONHEIGHT;
        case "requestEvaluationAutomation":
            return HISTORYREQUESTEVALUATIONAUTOMATIONHEIGHT;
        case "newOrUpdateApplicantOpinion":
        case "newOrUpdateApplicantOpinionJob":
            return HISTORYOPINIONONTHEAPPLICANT;
        case "sendAssessment":
        case "sendAssessmentAutomation":
        case "sendAssessmentAutomation_emailAgain":
        case "sendAssessment_emailAgain":
        case "sendAssessmentAutomation_failedApplicantAssessmentCompleted":
        case "sendAssessment_failedApplicantAssessmentCompleted":
            return HISTORYSENDASSESSMENTHEIGHT;
        case "jobApplicantMatch":
        case "jobApplicantMatchAutomation":
            return HISTORYJOBMATCH;
        default:
            return HISTORYCHANGESTATUSHEIGHT;
    }
};

export const applicantHistoryTypeList: CompleoShared.Common.ApplicantHistoryType[] = [
    "addTagsAutomation",
    "sendEmailAutomation",
    "sendEmailAutomation_later",
    "addTagsBulk",
    "sendEmailBulk",
    "removedFromJob",
    "addToJob",
    "appliedForTheJob",
    "addComment",
    "changeJobStage",
    "sendEmailToUsersAutomationApplicantContext",
    "sendEmailToUsersAutomationApplicantContext_later",
    "requestEvaluation",
    "requestEvaluationAutomation",
    "phoneCall",
    "interview",
    "textMessage",
    "termsOfServiceAccepted",
    "newOrUpdateApplicantOpinion",
    "newOrUpdateApplicantOpinionJob",
    "changeJobStageAssessmentResult",
    "sendAssessment",
    "sendAssessmentAutomation",
    "sendAssessmentAutomation_emailAgain",
    "sendAssessment_emailAgain",
    "sendAssessmentAutomation_failedApplicantAssessmentCompleted",
    "sendAssessment_failedApplicantAssessmentCompleted",
    "jobApplicantMatch"
];
