import { useContext, useState } from "react";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";
import ContextQuestionsFieldsData from "../components/ContextQuestionsFieldsData";
import Section from "./Section";
import { ButtonAddEditSection } from "./SectionsHelper";
import SectionAddEditModal from "./SectionAddEditModal";
import { Box, FormHelperText, Typography } from "@material-ui/core";
import { useGetOnDragEnd } from "../useGetOnDragEnd";
import React from "react";

interface ISectionsProps {
    t: any;
}
const Sections = (props: ISectionsProps) => {
    const { t } = props;
    const ContextQuestionFieldsData = useContext(ContextQuestionsFieldsData);
    const [modalAddEditSectionData, setModalAddEditSectionData] = useState<{
        open: boolean;
        values?: CompleoShared.Assessments.ISection;
    }>({ open: false });

    const bottomRef = React.useRef(null);

    const scrollToBottom = () => {
        const divRef: any = bottomRef.current;
        if (divRef) {
            divRef.scrollIntoView({ behavior: "smooth" });
        }
    };

    const sectionsData: CompleoShared.Assessments.ISection[] =
        ContextQuestionFieldsData?.sectionsData || [];
    const onDragEnd = useGetOnDragEnd();
    const {
        ready = false,
        errorSection,
        messageSection,
        errorQuestion,
        messageQuestion
    } = ContextQuestionFieldsData || {};

    if (ready) {
        return (
            <Box width="100%">
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems="center"
                    justifyContent={"flex-start"}
                >
                    <Box display={"flex"}>
                        <Typography
                            variant="h6"
                            color={
                                errorSection || errorQuestion
                                    ? "error"
                                    : "primary"
                            }
                        >
                            {t("sections")} *
                        </Typography>
                    </Box>
                    <Box flexGrow={1} />
                    <Box display={"flex"}>
                        <ButtonAddEditSection
                            setModalAddEditSectionData={
                                setModalAddEditSectionData
                            }
                        />
                    </Box>
                </Box>
                <FormHelperText error={errorSection || errorQuestion}>
                    {messageSection || messageQuestion}
                </FormHelperText>
                <Box>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {sectionsData.map((item, index) => (
                            <Droppable
                                droppableId={item.id}
                                direction="vertical"
                                type="sections"
                                key={item.id}
                            >
                                {(provided: DroppableProvided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <Draggable
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(
                                                provided: DraggableProvided,
                                                snapshot: DraggableStateSnapshot
                                            ) => (
                                                <Section
                                                    key={index}
                                                    section={item}
                                                    provided={provided}
                                                    setModalAddEditSectionData={
                                                        setModalAddEditSectionData
                                                    }
                                                />
                                            )}
                                        </Draggable>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </DragDropContext>
                </Box>
                <Box>
                    <div ref={bottomRef} style={{ height: "1px" }} />
                    {modalAddEditSectionData.open && (
                        <SectionAddEditModal
                            onClose={() =>
                                setModalAddEditSectionData({
                                    open: false,
                                    values: undefined
                                })
                            }
                            open={modalAddEditSectionData.open}
                            values={modalAddEditSectionData.values}
                            scrollToBottom={() => scrollToBottom()}
                        />
                    )}
                </Box>
            </Box>
        );
    } else {
        return <Loading />;
    }
};

export default Sections;
