import { apiDirectCall } from "customHooks/useApi";
import { isNotNull } from "functions/util";
import React from "react";
import { useQueryClient } from "react-query";

// score: 0 - 100
// 0 to 100 (if the type os correction is correctIncorrect, the value will be 0 for incorrect and
// 100 for correct)
// for starRating, the value will be 0 to 100 (0 for 0 stars and 100 for 5 stars with 20 points each star)

export interface IQuestionScore {
    questionId: string;
    score: number;
}
export const useCorrectTest = (
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined
) => {
    const [correctionMode, setCorrectionModeState] = React.useState(false);

    const queryClient = useQueryClient();

    const [questionScore, setQuestionScore] = React.useState<IQuestionScore[]>(
        []
    );

    React.useEffect(() => {
        const scoreDefaultData = getScoreDefaultData(test);
        setQuestionScore(scoreDefaultData);
    }, [test?.correctionData?.correctionDate]);

    const correctionStatus: CompleoShared.Assessments.ICorrectionStatus =
        test?.correctionStatus || "pending";

    const setCorrectionMode = (enabled: boolean) => {
        if (correctionStatus === "waiting_manual_correction") {
            setCorrectionModeState(enabled);
        }
    };

    const correctionTypeList: CompleoShared.Assessments.correctionType[] = [
        "correctIncorrect",
        "starRating"
    ];

    const questionsToCorrect = test?.questionsApplicant?.filter((question) =>
        correctionTypeList.includes(question?.question?.correctionType?.value)
    );

    const setScore = (questionId: string, score: number) => {
        if (
            !questionsToCorrect?.find(
                (question) => question.questionId === questionId
            )
        ) {
            throw new Error("Question not found");
        }

        const questionScoreIndex = questionScore.findIndex(
            (question) => question.questionId === questionId
        );
        if (questionScoreIndex === -1) {
            setQuestionScore([...questionScore, { questionId, score }]);
        } else {
            const newQuestionScore = [...questionScore];
            newQuestionScore[questionScoreIndex] = {
                questionId,
                score
            };
            setQuestionScore(newQuestionScore);
        }
    };

    const allQuestionsCorrected =
        questionsToCorrect?.length ===
        questionScore.filter((question) => question.score !== undefined).length;

    const finishCorrection = async () => {
        if (!allQuestionsCorrected) {
            throw new Error("Not all questions were corrected");
        }
        const postData = {
            score: questionScore,
            companyId: test?.companyId,
            applicantId: test?.applicantId,
            assessmentId: test?.assessmentId,
            testId: test?.testId
        };
        await apiDirectCall(
            "/test/applicantassessmenttestcorrection",
            "post",
            postData
        );
        await queryClient.invalidateQueries("/test/applicantassessments");
    };

    return {
        correctionMode,
        setCorrectionMode,
        correctionStatus,
        setScore,
        finishCorrection,
        questionsToCorrect,
        questionScore,
        allQuestionsCorrected
    };
};

const getScoreDefaultData = (
    test: CompleoShared.Assessments.IAssessmentApplicant | undefined
) => {
    const scoreDefaultData: IQuestionScore[] = (test?.questionsApplicant || [])
        ?.map((question) => {
            const questionDetails: CompleoShared.Assessments.IQuestion =
                question.question;
            if (questionDetails.manualCorrectionScore === undefined) {
                return null;
            }
            return {
                questionId: questionDetails.id,
                score:
                    questionDetails.manualCorrectionScore === undefined
                        ? -1
                        : questionDetails.manualCorrectionScore
            };
        })
        .filter(isNotNull);
    return scoreDefaultData;
};
