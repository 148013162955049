import React from "react";
import useJobScorecardData from "./components/useJobScorecardData";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import { addMainAndGroupRatingScorecard } from "./ApplicantRatingHelper";
import { Variant } from "@material-ui/core/styles/createTypography";
import ModalScorecardGraph from "./ModalScorecardGraph";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import { Assessment } from "@material-ui/icons";

interface IProps {
    scorecard: Compleo.IObject[];
    jobId: string;
    t: any;
}
const ApplicantScorecardView = (props: IProps) => {
    const { scorecard, jobId, t } = props;
    const [openGraph, setOpenGraph] = React.useState<{
        open: boolean;
        name?: string;
    }>({ open: false });
    const scorecardReturnData = useJobScorecardData(jobId, scorecard, true);
    const scorecardDataWithRating = addMainAndGroupRatingScorecard(
        scorecardReturnData.mainData
    );
    const scorecardData = scorecardDataWithRating.scorecard;

    if (scorecardReturnData.ready) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ScorecardGroupContainer
                        title={t("scorecard")}
                        titleVariant="body1"
                        graphButton={
                            <ButtonIconTooltipCompleo
                                label={t("charts")}
                                onClick={() => setOpenGraph({ open: true })}
                            >
                                <Assessment />
                            </ButtonIconTooltipCompleo>
                        }
                    >
                        <CompleoCompanyRating
                            evaluationValue={
                                scorecardDataWithRating.averageMainRating
                            }
                            size="small"
                            showTextValue
                        />
                    </ScorecardGroupContainer>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {scorecardData.map((item, index: number) => {
                    const isLastItem = scorecardData.length === index + 1;
                    return (
                        <Grid key={item.id} item container xs={12}>
                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                <ScorecardGroupContainer
                                    title={item.name}
                                    titleVariant="body2"
                                    graphButton={
                                        <ButtonIconTooltipCompleo
                                            label={t("charts")}
                                            onClick={() =>
                                                setOpenGraph({
                                                    open: true,
                                                    name: item.name
                                                })
                                            }
                                        >
                                            <Assessment />
                                        </ButtonIconTooltipCompleo>
                                    }
                                >
                                    <CompleoCompanyRating
                                        evaluationValue={item.averageRating}
                                        size="small"
                                        showTextValue
                                    />
                                </ScorecardGroupContainer>
                            </Grid>
                            {item.items.map((sectionItem: Compleo.IObject) => (
                                <Grid
                                    key={sectionItem.name}
                                    item
                                    xs={12}
                                    sm={6}
                                >
                                    <Grid item xs={12} sm={6}>
                                        {sectionItem.name}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompleoCompanyRating
                                            evaluationValue={
                                                sectionItem.evaluation || 0
                                            }
                                            size="small"
                                            showTextValue
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            {!isLastItem && (
                                <Grid item xs={12} style={{ marginTop: 16 }}>
                                    <Divider />
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
                <ModalScorecardGraph
                    open={openGraph.open}
                    handleClose={() => setOpenGraph({ open: false })}
                    scorecard={scorecardDataWithRating}
                    t={t}
                    itemName={openGraph.name}
                />
            </Grid>
        );
    } else {
        return <Loading />;
    }
};

interface IScorecardGroupContainer {
    children: any;
    title: string;
    titleVariant?: Variant;
    graphButton?: any;
}

const ScorecardGroupContainer = (props: IScorecardGroupContainer) => {
    const { children, title, titleVariant = "body1", graphButton } = props;

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
        >
            <Box>
                <Typography variant={titleVariant} component="span">
                    <strong>{title}</strong>
                </Typography>
            </Box>
            <Box ml={1}>{children}</Box>
            {graphButton && <Box ml={1}>{graphButton}</Box>}
        </Box>
    );
};

export default ApplicantScorecardView;
