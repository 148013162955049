import React from "react";
import { TFunction } from "i18next";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import NumberFormat from "react-number-format";
import * as formatFunctions from "functions/formatValues";

export const upperString = (value: string, t: TFunction) => {
    if (value !== undefined) {
        return (value || "").toUpperCase();
    }
};

export const maskPhone = (value: string, t: TFunction) => {
    return formatFunctions.maskPhone(value);
};

export const maskCNPJCPF = (value: string, t: TFunction) => {
    const unformattedValue = value ? value.replace(/[^0-9]/gi, "") : "";
    if (unformattedValue.length > 11) {
        return maskCNPJ(unformattedValue, t);
    } else {
        return maskCPF(unformattedValue, t);
    }
};

export const maskCPF = (value: string, t: TFunction) => {
    return formatFunctions.maskCPF(value);
};

export const maskCNPJ = (value: string, t: TFunction) => {
    return formatFunctions.maskCNPJ(value);
};

export const formatBoolean = (value: any, t: TFunction) => {
    if (value === undefined) {
        return "";
    }
    if (value.toString().toLowerCase() === "true") {
        return t("booleanTrue");
    } else {
        return t("booleanFalse");
    }
};

export const revertFormatBoolean = (value: any, t: TFunction) => {
    if (value === undefined || value === "") {
        return "";
    }
    if (value.toLowerCase() === t("booleanTrue").toLowerCase()) {
        return "true";
    } else if (value.toLowerCase() === t("booleanFalse").toLowerCase()) {
        return "false";
    } else {
        return value;
    }
};

export const complexObjectWithLanguage = (
    value: any,
    property: string,
    language: string = "en-US"
) => {
    return value[`${property}-${language}`];
};

export const complexObjectArray = (value: any, property: string) => {
    if (value === undefined || value.length === 0) {
        return "";
    }
    const values = value.map((v: any) => v[property]);
    return values.join(" - ");
};

export const formatDateTime = (value: any, t: TFunction) => {
    const d = new Date(value);
    return d.toString();
};

export const renderBulkActionStatus = (value: any, t: TFunction) => {
    return t(`BULKACTIONSTATUS_${value}`);
};

export const renderCareersDomainStatus = (value: any, t: TFunction) => {
    return t(`CAREERSDOMAINSTATUS_${value}`);
};

export const renderExportFileName = (value: any, t: TFunction) => {
    return t(`JOBEXPORTMAIN:reportName_${value}`);
};

export const renderEmailType = (value: any, t: TFunction) => {
    return t(`typeEmail_${value}`);
};

export const renderTestType = (value: any, t: TFunction) => {
    return t(`typeTest_${value}`);
};

export const renderAIFeature = (value: any, t: TFunction) => {
    return t(`aiFeatures_${value}`);
};

export const renderAICreditType = (value: any, t: TFunction) => {
    return t(`creditType_${value}`);
};

export const renderUser = (value: any, t: TFunction) => {
    if (value === "compleo@compleo.app") {
        return "-";
    }
    return value;
};
