import { TableCell } from "@material-ui/core";
import React from "react";
import {
    FieldDefault,
    FieldTitle,
    IFieldProps,
    FieldTags,
    FieldAvatar,
    FieldJobStatus,
    FieldJobCode,
    FieldDistance,
    FieldPipelineStage,
    FieldApplicantTitle,
    FieldTime,
    FieldEvaluation,
    FieldJobEvaluation,
    FieldUrl,
    FieldWhatsApp,
    FieldApplicantHistoryType,
    FieldApplicantTitleActivities,
    FieldJobScreeningQuestionnaire,
    FieldJobNumber,
    FieldJobAssessment
} from "../FieldsType";

interface IProps {
    fieldName: string;
    fieldData: Compleo.IObject;
    type?: string;
}

const TableFields = (props: IProps) => {
    const { type, fieldData, fieldName } = props;
    const propsField: IFieldProps = {
        fieldName,
        fieldData,
        returnType: "table"
    };

    switch (type) {
        case "jobTitle":
            return (
                <TableCell>
                    <FieldTitle
                        {...propsField}
                        url={`/job/view/${fieldData.pk.split(":")[1]}`}
                    />
                </TableCell>
            );
        case "jobTitleApplicantHistory":
            return (
                <TableCell>
                    <FieldDefault
                        {...propsField}
                        url={`/job/view/${
                            (fieldData?.jobData?.pk || "").split(":")[1]
                        }`}
                    />
                </TableCell>
            );
        case "jobTitleRequest":
            return (
                <TableCell>
                    <FieldTitle
                        {...propsField}
                        url={`/job/viewrequest/${fieldData.pk.split(":")[1]}`}
                    />
                </TableCell>
            );
        case "applicantTitle":
            return (
                <TableCell>
                    <FieldApplicantTitle {...propsField} />
                </TableCell>
            );
        case "tags":
            return (
                <TableCell>
                    <FieldTags {...propsField} />
                </TableCell>
            );
        case "avatar":
            return (
                <TableCell>
                    <FieldAvatar {...propsField} />
                </TableCell>
            );
        case "jobStatus":
            return (
                <TableCell>
                    <FieldJobStatus {...propsField} />
                </TableCell>
            );
        case "jobCode":
            return (
                <TableCell>
                    <FieldJobCode {...propsField} />
                </TableCell>
            );
        case "jobNumber":
            return (
                <TableCell>
                    <FieldJobNumber {...propsField} />
                </TableCell>
            );
        case "distance":
            return (
                <TableCell>
                    <FieldDistance {...propsField} />
                </TableCell>
            );
        case "pipelineStage":
            return (
                <TableCell>
                    <FieldPipelineStage {...propsField} />
                </TableCell>
            );
        case "time":
            return (
                <TableCell>
                    <FieldTime {...propsField} />
                </TableCell>
            );
        case "evaluation":
            return (
                <TableCell>
                    <FieldEvaluation {...propsField} />
                </TableCell>
            );
        case "jobEvaluation":
            return (
                <TableCell>
                    <FieldJobEvaluation {...propsField} />
                </TableCell>
            );
        case "url":
            return (
                <TableCell>
                    <FieldUrl {...propsField} />
                </TableCell>
            );
        case "whatsapp":
            return (
                <TableCell>
                    <FieldWhatsApp {...propsField} />
                </TableCell>
            );
        case "applicantHistoryType":
            return (
                <TableCell>
                    <FieldApplicantHistoryType {...propsField} />
                </TableCell>
            );
        case "applicantTitleActivity":
            return (
                <TableCell>
                    <FieldApplicantTitleActivities {...propsField} />
                </TableCell>
            );
        case "screeningQuestionnaireRanking":
            return (
                <TableCell>
                    <FieldJobScreeningQuestionnaire {...propsField} />
                </TableCell>
            );
        case "assessmentRanking":
            return (
                <TableCell>
                    <FieldJobAssessment {...propsField} />
                </TableCell>
            );
        default:
            return (
                <TableCell>
                    <FieldDefault {...propsField} />
                </TableCell>
            );
    }
};

export default TableFields;
