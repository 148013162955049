import React from "react";
import useJobContext from "../../useJobContext";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Link,
    Tooltip,
    Typography
} from "@material-ui/core";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { formatDistance, differenceInCalendarDays } from "date-fns";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import JobKanbanApplicantMainInfo from "./JobKanbanApplicantMainInfo";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import JobKanbanApplicantFields from "./JobKanbanApplicantFields";
import { IKanbanCardFieldState } from "../JobKanbanCardFields/SettingsModalCardKanbanFields";
import { getKanbanCardHeight } from "../helper";
import JobKanbanApplicantActionButtons from "./JobKanbanApplicantActionButtons";
import JobKanbanApplicantLoading from "./JobKanbanApplicantLoading";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import _ from "lodash";

// function getStyle(style: any, snapshot: DraggableStateSnapshot) {
//     if (!snapshot.isDropAnimating) {
//         return style;
//     }

//     if (snapshot.dropAnimation) {
//         const { moveTo, curve, duration } = snapshot.dropAnimation;
//         // move to the right spot
//         const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
//         // add a bit of turn for fun
//         // const rotate = "rotate(0.5turn)";
//         const rotate = "rotate(15deg)";

//         // patching the existing style
//         return {
//             ...style,
//             transform: `${translate} ${rotate}`,
//             // slowing down the drop because we can
//             transition: `all ${curve} ${duration}s`
//         };
//     } else {
//         return style;
//     }
// }

const useStyles = (cardHeight: number, cardContentHeight: number) => {
    return makeStyles((theme: Theme) => {
        const card = {
            padding: theme.spacing(0.5),
            minWidth: 250,
            height: cardHeight,
            margin: theme.spacing(1)
        };
        return createStyles({
            card: {
                ...card,
                boxShadow: "0 3px 6px 0 rgba(138, 148, 159, 0.2)"
            },
            cardRed: {
                ...card,
                boxShadow: "0 3px 6px 0 red"
            },
            cardMoving: {
                ...card,
                boxShadow: "0 3px 6px 0 blue"
                // transform: "rotate(45deg) translateX(180px)"
            },
            cardHeader: {
                padding: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.1)
            },
            avatar: {
                //   backgroundColor: red[500],
            },
            heading: {
                display: "inline-block",
                margin: 0,
                padding: 0,
                maxWidth: 180,
                marginTop: theme.spacing(0.5),
                fontWeight: 500
            },
            subheader: {
                fontSize: 13,
                color: theme.palette.grey[600]
                // letterSpacing: "1px"
                // marginBottom: 4
            },
            cardContent: {
                height: cardContentHeight,
                padding: theme.spacing(0.1),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                paddingLeft: theme.spacing(0.5)
            },
            actionsIcon: {
                padding: theme.spacing(0.5)
            },
            buttonRight: {
                marginLeft: "auto",
                padding: theme.spacing(0.5)
            }
        });
    });
};
interface IProps {
    ApplicantData: Compleo.IObject;
    provided: DraggableProvided;
    index: number;
    snapshot: DraggableStateSnapshot;
    stageId: string;
    CardDef: IKanbanCardFieldState;
    cardKanbanFields: Compleo.IObject;
    openApplicantView: (index: number) => void;
}

const JobKanbanApplicant = (props: IProps) => {
    const {
        ApplicantData,
        provided,
        index,
        snapshot,
        stageId,
        CardDef,
        cardKanbanFields,
        openApplicantView
    } = props;
    const cardHeight = getKanbanCardHeight(CardDef);
    const removeActionBar =
        CardDef.fieldsDefinition.filter((i) => i.local === "primary").length ===
            0 && CardDef.cardSize === "small";

    const [isUpdating, setIsUpdating] = React.useState(false);
    const [data] = useJobContext();
    const { t, language } = data;
    const classes = useStyles(
        cardHeight.cardHeight,
        cardHeight.cardContentHeight
    )();
    const stageData = data.LocalStateData.allStages.filter(
        (item) => item.id === stageId
    )[0];
    // const a: KanbanCardSizeType = "small"
    const dateAddedToStage = ApplicantData.jobData.dateAddedToStage;
    const dateRelative = formatDistance(
        new Date(dateAddedToStage),
        new Date(),
        {
            addSuffix: true
        }
    );
    const daysInStage = differenceInCalendarDays(
        new Date(),
        new Date(dateAddedToStage)
    );
    const applicantLongTimeInStage = stageData?.maxDaysInStage
        ? daysInStage > stageData?.maxDaysInStage
        : false;
    const fields = CardDef.fieldsDefinition.filter(
        (item) => item.local === "primary"
    );

    // fields.sort((a,b) => a.order - b.order);
    const isDragging = snapshot.isDragging;
    const cardClass = isDragging
        ? classes.cardMoving
        : !applicantLongTimeInStage
        ? classes.card
        : classes.cardRed;

    if (!isUpdating) {
        return (
            <Card
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={cardClass}
            >
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        CardDef.showPhoto ? (
                            <CompleoAvatar
                                idParams={{
                                    id: ApplicantData.pk.split(":")[1]
                                }}
                                type="applicant"
                                className={classes.avatar}
                            />
                        ) : null
                    }
                    // action={
                    //     <ButtonIconTooltipCompleo
                    //         label="teste"
                    //         onClick={() => {}}
                    //         className={classes.actionsIcon}
                    //     >
                    //         <MoreVertIcon fontSize="small" />
                    //     </ButtonIconTooltipCompleo>
                    // }
                    title={
                        <Link
                            component="button"
                            onClick={() => openApplicantView(index)}
                        >
                            <Tooltip title={ApplicantData.name}>
                                <Typography className={classes.heading} noWrap>
                                    {ApplicantData.name}
                                </Typography>
                            </Tooltip>
                        </Link>
                    }
                    subheader={
                        <JobKanbanApplicantMainInfo
                            daysInStage={daysInStage.toString()}
                            applicantLongTimeInStage={applicantLongTimeInStage}
                            maxDaysInStage={stageData?.maxDaysInStage}
                        />
                    }
                />
                <CardContent className={classes.cardContent}>
                    <JobKanbanApplicantFields
                        fields={fields}
                        ApplicantData={ApplicantData}
                        cardKanbanFields={cardKanbanFields}
                    />
                </CardContent>
                {!removeActionBar && (
                    <CardActions disableSpacing>
                        <JobKanbanApplicantActionButtons
                            ApplicantData={ApplicantData}
                            stageData={stageData}
                            t={t}
                            allStages={data.LocalStateData.allStages}
                            currentIndex={index}
                            stagesOnlyTotal={
                                data.LocalStateData.stagesOnlyTotal
                            }
                            setIsUpdating={setIsUpdating}
                            openApplicantView={openApplicantView}
                            readonly={data.readonly}
                        />
                    </CardActions>
                )}
            </Card>
        );
    } else {
        return <JobKanbanApplicantLoading {...props} />;
    }
};

const propsAreEqual = (
    prevProps: Readonly<IProps>,
    nextProps: Readonly<IProps>
) => {
    const isEqual =
        _.isEqual(prevProps.ApplicantData, nextProps.ApplicantData) &&
        _.isEqual(prevProps.CardDef, nextProps.CardDef) &&
        _.isEqual(prevProps.cardKanbanFields, nextProps.cardKanbanFields) &&
        _.isEqual(prevProps.provided, nextProps.provided) &&
        prevProps.snapshot.isDragging === nextProps.snapshot.isDragging &&
        prevProps.index === nextProps.index &&
        prevProps.stageId === nextProps.stageId;
    // isEqual =
    return isEqual;
};

export default React.memo(JobKanbanApplicant, propsAreEqual);
