import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        zeroStartTooltip: {
            margin: 0,
            marginRight: theme.spacing(0.3),
            padding: 0
        }
    });
});
