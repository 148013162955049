import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { TFunction } from "i18next";

interface IStageRulesCardProps {
    resultsToStageRule: {
        minimumScore: number;
        targetStage: { value: string; label: string };
    }[];
    tLocal: TFunction;
}

const StageRulesCard = (props: IStageRulesCardProps) => {
    const { resultsToStageRule, tLocal } = props;

    return (
        <Card elevation={0}>
            <CardContent>
                <Typography
                    variant="body1"
                    style={{
                        fontStyle: "italic",
                        fontWeight: 700
                    }}
                >
                    {tLocal("stageRules")}
                </Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700 }}>
                                    {tLocal("minimumScore")}
                                </TableCell>
                                <TableCell style={{ fontWeight: 700 }}>
                                    {tLocal("targetStage")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resultsToStageRule.map((rule) => (
                                <TableRow key={rule.targetStage.value}>
                                    <TableCell>{rule.minimumScore} %</TableCell>
                                    <TableCell>
                                        {rule.targetStage.label}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default StageRulesCard;
