import ApplicantHistoryTimelineItem from "./ApplicantHistoryTimelineItem";
import ApplicantHistoryTimelineItemLoading from "./ApplicantHistoryTimelineItemLoading";

const ApplicantHistoryRow = (props: any) => {
    const { data, index, style } = props;
    const { historyData, tLocal, language } = data;
    const historyItem = historyData[index];

    if (historyItem !== undefined) {
        return (
            <div style={style}>
                <ApplicantHistoryTimelineItem
                    historyItem={historyItem}
                    tLocal={tLocal}
                    language={language}
                />
            </div>
        );
    }
    return (
        <div style={style}>
            <ApplicantHistoryTimelineItemLoading
                historyItem={historyItem}
                tLocal={tLocal}
                language={language}
            />
        </div>
    );
};

export default ApplicantHistoryRow;
