import { useApiCache, apiDirectCall } from "customHooks/useApi";
import { AnyNsRecord } from "dns";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useAuthState } from "_ReactContext/AuthContext";
import { extractDataFromJobSK1AssessmentGroup } from "Pages/Job/JobView/JobAssessments/AssessmentsGroupsList/helper";

export interface IReportsShareOptions {
    label: string;
    value: string;
    dashboard: boolean;
}

const reducer = (
    state: Compleo.CustomLists.ListDefinitionType[],
    action: Compleo.CustomLists.ListDefinitionType
) => {
    const newState = state.filter(
        (item) => item.fieldName !== action.fieldName
    );
    newState.push(action);
    return newState;
};

const verifyReady = (
    fieldList: string[],
    state: Compleo.CustomLists.ListDefinitionType[]
) => {
    const currentFieldsLoaded = state.map((item) => item.fieldName);
    for (const field of fieldList) {
        if (!currentFieldsLoaded.includes(field)) {
            return false;
        }
    }
    return true;
};

interface IUseModalEvaluationList {
    t: any;
    readyTranslation: boolean;
    customerFilterIdList: string[];
    documents?: CompleoShared.S3Attachments[];
    jobId?: string;
    applicantId?: string;
}
const useModalEvaluationList: (
    params: IUseModalEvaluationList
) => [Compleo.CustomLists.ListDefinitionType[], boolean] = (
    params: IUseModalEvaluationList
) => {
    const {
        t,
        readyTranslation,
        customerFilterIdList,
        documents = [],
        jobId,
        applicantId
    } = params;
    const { company } = useAuthState();
    const [tJobView, i18n, readyTranslationJobView] = useTranslation(
        "JOBVIEW",
        {
            useSuspense: false
        }
    );

    const [listDefinition, dispatch] = useReducer(reducer, []);

    const [listResult, getList] = useApiCache(
        "/user/getuserandcontactlist",
        "post",
        {
            companyId: company.companyId,
            customerFilterIdList: customerFilterIdList
        },
        false,
        60 * 1000 * 10
    );
    const userList = listResult.response?.data || [];

    const [reportsListResult, getReportsList] = useApiCache(
        "/reports/getreportstoshareapplicantevaluation",
        "post",
        {
            companyId: company.companyId
        },
        false,
        60 * 1000 * 10
    );
    const reportsList = reportsListResult.response?.data;

    const [customCVListResult, getCustomCVList] = useApiCache(
        "/customcv/getcustomcv",
        "post",
        {
            companyId: company.companyId,
            jobId
        },
        true,
        60 * 1000 * 10
    );

    const [assessmentListResult, getCustomAssessmentList] = useApiCache(
        "/test/listassessments",
        "post",
        {
            companyId: company.companyId,
            jobId: jobId,
            applicantId: applicantId
        },
        true,
        60 * 1000 * 10
    );

    React.useEffect(() => {
        if (readyTranslation && userList.length) {
            const usersData = userList.map((item: any) => {
                const type = t(item?.type.toLowerCase());
                return {
                    value: item.email,
                    label: `${item.name} - (${type})`
                };
            });

            dispatch({
                data: usersData,
                fieldName: "evaluators",
                labelName: "label",
                valueName: "value"
            });
        }
        if (
            readyTranslation &&
            readyTranslationJobView &&
            listResult.status === "success"
        ) {
            const reportsToShare: CompleoShared.Reports.IJobReportsToRender[] = [
                "activities",
                "activitiesRecruiter",
                "funnel"
            ];
            const reportsData: IReportsShareOptions[] = reportsToShare.map(
                (item) => {
                    const report = tJobView(`reportTitle_${item}`);
                    return {
                        value: item as string,
                        label: report,
                        dashboard: false
                    };
                }
            );
            const reportsDataDash: IReportsShareOptions[] = [];
            (reportsList?.dashboards || []).map((item: any) => {
                const report = `${tJobView(`reportTitle_dashboard`)} - ${
                    item.name
                }`;
                reportsDataDash.push({
                    value: `dashboardId:${item.id}`,
                    label: report,
                    dashboard: true
                });
            });
            reportsData.sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );
            reportsDataDash.sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );

            dispatch({
                data: [...reportsData, ...reportsDataDash],
                fieldName: "reportsToShare",
                labelName: "label",
                valueName: "value"
            });
        }
        const docData = (documents || []).map((item) => {
            return {
                value: item.key,
                label: item.originalFileName
            };
        });
        dispatch({
            data: docData,
            fieldName: "documentsToShare",
            labelName: "label",
            valueName: "value"
        });

        if (jobId) {
            getCustomCVList({
                companyId: company.companyId,
                jobId
            });
        }
        if (applicantId) {
            getCustomAssessmentList();
        }
    }, [
        company.companyId,
        userList.length,
        readyTranslation,
        (documents || []).length,
        reportsListResult?.status,
        readyTranslationJobView,
        jobId
    ]);

    if (
        customCVListResult.status === "success" &&
        !verifyReady(["cvToShare"], listDefinition) &&
        readyTranslation
    ) {
        const cvList = customCVListResult?.response?.data?.map(
            (cv: Compleo.IObject) => ({
                label: cv.name,
                value: cv.skGS1pk.split(":")[1]
            })
        );

        cvList?.sort((a: Compleo.IObject, b: Compleo.IObject) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

        cvList?.unshift({
            label: t("applicantOriginalCVType"),
            value: "cv"
        });

        dispatch({
            data: cvList,
            fieldName: "cvToShare",
            labelName: "label",
            valueName: "value"
        });
    }

    if (
        applicantId &&
        assessmentListResult.status === "success" &&
        !verifyReady(["assessmentsTestsToShare"], listDefinition) &&
        readyTranslation
    ) {
        const assessmentList = (
            assessmentListResult?.response?.data?.data || []
        )
            .filter((i: Compleo.IObject) => i.statusApplicant === "finished")
            .map((item: Compleo.IObject) => ({
                label: item.name,
                value: extractDataFromJobSK1AssessmentGroup(
                    item.skGS1pk,
                    "assessmentId"
                )
            }));

        assessmentList?.sort((a: Compleo.IObject, b: Compleo.IObject) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );

        dispatch({
            data: assessmentList,
            fieldName: "assessmentsTestsToShare",
            labelName: "label",
            valueName: "value"
        });
    }

    // const ready = verifyReady(["job", "recipientes"], listDefinition);
    const ready = verifyReady(
        jobId
            ? ["evaluators", "documentsToShare", "reportsToShare", "cvToShare"]
            : ["evaluators", "documentsToShare", "reportsToShare"],
        listDefinition
    );
    // console.log("listDefinition", listDefinition);
    return [listDefinition, ready];
};

export default useModalEvaluationList;
