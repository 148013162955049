import React from "react";
import {
    fade,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
// import SubMenu from "./SubMenu";
import {
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from "@material-ui/core";
import { Router, Link } from "@gatsbyjs/reach-router";
import useSessionValues from "_ReactContext/SessionValues";

const useStyles = () => {
    const [sessionData] = useSessionValues();

    return makeStyles((theme: Theme) =>
        createStyles({
            list: {
                overflow: "hidden",
                "&:hover": {
                    overflowY: "scroll"
                }
            },
            fontMenu: {
                fontWeight: 600,
                marginLeft: theme.spacing(2)
            },
            nested: {
                paddingLeft: theme.spacing(2),
                "&:hover, &:focus": {
                    backgroundColor: theme.palette.grey[200]
                }
            },
            listIcon: {
                minWidth: 36,
                marginRight: theme.spacing(1)
            },
            icon: {
                // color: theme.palette.primary.contrastText
            },
            fontSubMenu: {
                // fontWeight: 700
                // color: "white"
            },
            groupItems: {
                marginBottom: theme.spacing(2)
            },
            divider: {
                backgroundColor: theme.palette.grey[400],
                marginTop: theme.spacing(1)
            },
            drawer: {
                width: sessionData.menuWidth,
                [theme.breakpoints.up("sm")]: {
                    width: sessionData.menuWidth,
                    flexShrink: 0
                }
            },
            drawerPaper: {
                width: sessionData.menuWidth,
                // color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.grey[200]
            },
            toolbar: theme.mixins.toolbar
        })
    );
};

interface IProps {
    navigation: any;
    t: any;
    container?: Element;
    fullMenu: boolean;
    handleDrawerToggle: () => void;
    isMobile: boolean;
}

const MenuCompleo = (props: IProps) => {
    const {
        navigation,
        t,
        container,
        fullMenu,
        handleDrawerToggle,
        isMobile
    } = props;

    const classes = useStyles()();

    const localToggleMobile = () => {
        if (isMobile) {
            handleDrawerToggle();
        }
    };

    if (isMobile && !fullMenu) {
        return null;
    } else {
        return (
            <Drawer
                container={container}
                variant={isMobile ? "temporary" : "permanent"}
                open={true}
                onClose={handleDrawerToggle}
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={
                    isMobile
                        ? {
                              keepMounted: true // Better open performance on mobile.
                          }
                        : undefined
                }
            >
                {!isMobile && <div className={classes.toolbar} />}
                <List className={classes.list}>
                    {navigation.features
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((f: any) => {
                            let items = f.items;
                            if (f.groupUniqueName === "settingsGroup") {
                                // for now we are only ordering by name the settings group
                                items = f.items.sort((a: any, b: any) =>
                                    t(a.uniqueName).localeCompare(
                                        t(b.uniqueName)
                                    )
                                );
                            }
                            return (
                                <div
                                    key={f.groupUniqueName}
                                    className={classes.groupItems}
                                    onClick={localToggleMobile}
                                    onKeyDown={localToggleMobile}
                                >
                                    <ListItemText
                                        primary={t(f.groupUniqueName)}
                                        primaryTypographyProps={{
                                            className: classes.fontMenu
                                        }}
                                    />
                                    {items.map((item: any) => (
                                        <ListItem
                                            key={item.uniqueName}
                                            button
                                            dense
                                            className={classes.nested}
                                            component={Link}
                                            to={item.route}
                                            data-testid={item.uniqueName}
                                            aria-label={t(item.uniqueName)}
                                        >
                                            <ListItemIcon
                                                className={classes.listIcon}
                                            >
                                                <Icon className={classes.icon}>
                                                    {item.icon}
                                                </Icon>
                                            </ListItemIcon>
                                            <Tooltip
                                                key={item.uniqueName}
                                                title={t(item.uniqueName)}
                                            >
                                                <ListItemText
                                                    primary={t(item.uniqueName)}
                                                    primaryTypographyProps={{
                                                        className:
                                                            classes.fontSubMenu,
                                                        noWrap: true
                                                    }}
                                                />
                                            </Tooltip>
                                        </ListItem>
                                    ))}
                                    <Divider className={classes.divider} />
                                </div>
                            );
                        })}
                </List>
            </Drawer>
        );
    }
};

export default MenuCompleo;
