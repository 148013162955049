import React from "react";
import Page from "Pages/_Layouts/DefaultInternal";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon, Typography } from "@material-ui/core";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { useAuthState } from "_ReactContext/AuthContext";
import { navigate } from "@gatsbyjs/reach-router";
import { Alert, AlertTitle } from "@material-ui/lab";

const ApplicantViewCompany = (props: any) => {
    const { uuid, companyid } = props;
    const { company, allCompanies } = useAuthState();
    const [
        wrongCompany,
        setWrongCompany
    ] = React.useState<null | Compleo.IObject>(null);

    const moduleName = "APPLICANTVIEWCOMPANY";
    const [t, i18n, readyTranslation] = useTranslation(moduleName, {
        useSuspense: false
    });

    React.useEffect(() => {
        if (Number(company.companyId) === Number(companyid)) {
            navigate(`/applicant/view/${uuid}`);
        } else {
            const currentCompany = company.companyFancyName;
            const companyApplicant = allCompanies.filter(
                (item) => item.pk === `COMPANY:${companyid}`
            )[0]?.CompanyFancyName;

            setWrongCompany({
                currentCompany,
                companyApplicant: companyApplicant || ""
            });
        }
    }, [company.companyId, companyid]);

    const ready = readyTranslation;

    if (ready && wrongCompany) {
        return (
            <Page
                featureName={t("a_FeatureName")}
                featureMainDescription={t("a_FeatureMainDescription")}
                maxWidth="md"
            >
                <Grid container style={{ marginTop: 16 }}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <AlertTitle>
                                {t("incorrectCompany", {
                                    companyApplicant:
                                        wrongCompany.companyApplicant,
                                    currentCompany: wrongCompany.currentCompany
                                })}
                            </AlertTitle>
                        </Alert>
                    </Grid>
                </Grid>
            </Page>
        );
    } else {
        return (
            <Page>
                <Loading />
            </Page>
        );
    }
};

export default ApplicantViewCompany;
