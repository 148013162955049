import { IQuestionListProps } from "../Question";
import AnswerChoices from "./AnswerChoices";
import AnswerFiles from "./AnswerFiles";
import AnswerText from "./AnswerText";
import AnswerVideo from "./AnswerVideo";

const Answer = (props: IQuestionListProps) => {
    const { question } = props;
    switch (question.question?.questiontype?.value) {
        case "multipleChoice":
        case "singleChoice":
            return <AnswerChoices {...props} />;
        case "paragraph":
        case "text":
            return <AnswerText {...props} />;
        case "file":
        case "multipleFiles":
            return <AnswerFiles {...props} />;
        case "video":
            return <AnswerVideo {...props} />;
        default:
            return null;
    }
};

export default Answer;
