import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider, Menu } from "@material-ui/core";
import { navigate } from "@gatsbyjs/reach-router";
import { useApiCache } from "customHooks/useApi";
import { useAuthState } from "_ReactContext/AuthContext";
import { extractValue } from "functions/util";

interface IProps {
    t: any;
}

const RightButtonList = (props: IProps) => {
    const { t } = props;
    const { company } = useAuthState();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);

    const typesOfAssessiments: CompleoShared.Assessments.testType[] = [
        "regular",
        "weightAnswers",
        "answersToResults"
    ];
    const options = typesOfAssessiments.map((data) => {
        return {
            label: t(`typeTest_${data}`) as string,
            value: data
        };
    });

    const postTemplateList = `/test/testcompleotemplatelist`;
    const [templateListSearchResult] = useApiCache(
        postTemplateList,
        "post",
        {
            companyId: company.companyId,
            fieldsToReturn: ["name", "pk", "skGS1pk", "testType"]
        },
        false,
        1000 * 60 * 5
    );
    const templateList = templateListSearchResult?.response?.data?.data || [];

    const handleMenuItemClick = (
        itemOption: CompleoShared.Assessments.testType
    ) => {
        navigate(`/test/new/${itemOption}`);
        setAnchorEl(null);
    };

    const handleTemplateMenuItemClick = (
        itemOption: CompleoShared.Assessments.testType,
        uuid: string
    ) => {
        navigate(`/test/new/${itemOption}/${uuid}`);
        setAnchorEl(null);
    };

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(divRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div>
            <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="split button"
                ref={divRef}
            >
                <Button onClick={handleClickListItem}>
                    {t("buttonTopRight")}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label={t("buttonTopRight")}
                    aria-haspopup="menu"
                    onClick={handleClickListItem}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <MenuItem disabled button={false}>
                    {t("buttonTopRightChooseAnOption")}
                </MenuItem>
                <Divider />
                {options.map((option, index) => (
                    <MenuItem
                        key={option.value}
                        onClick={() => handleMenuItemClick(option.value)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem disabled button={false}>
                    {t("buttonTopRightChooseATemplate")}
                </MenuItem>
                {templateList.map(
                    (option: {
                        testType: CompleoShared.Assessments.testType;
                        name: string;
                        skGS1pk: string;
                    }) => (
                        <MenuItem
                            key={option.name}
                            onClick={() =>
                                handleTemplateMenuItemClick(
                                    option.testType,
                                    extractValue(
                                        option.skGS1pk,
                                        "TESTTEMPLATEID"
                                    ) || ""
                                )
                            }
                        >
                            {option.name}
                        </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
};

export default RightButtonList;
