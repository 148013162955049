import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
    IInputProps,
    useRhfFieldWithoutControllerOrRegister
} from "customHooks/useCompleoReactHookForm/helpers/reactHookFormsHelper";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const options = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

interface IProps {
    t: any;
    possibleResults?: CompleoShared.Assessments.IPossibleResults[];
}

const AnswerToResultCustomField = (props: IProps & IInputProps) => {
    const { t, possibleResults = [] } = props;
    const fieldName = props.name || props?.metadata?.fieldName;
    const { watch, setFieldValue } = useRhfFieldWithoutControllerOrRegister(
        fieldName
    );
    const currentFieldValue: CompleoShared.Assessments.IAnswerToResults[] =
        watch(fieldName) || [];
    React.useEffect(() => {
        if (currentFieldValue.length === 0) {
            const defaultFieldValues: CompleoShared.Assessments.IAnswerToResults[] = [];
            possibleResults.map((possibleResult) => {
                const { name } = possibleResult;
                defaultFieldValues.push({
                    result: name,
                    resultValue: 0
                });
            });
            setFieldValue(defaultFieldValues);
        }
    }, [possibleResults]);

    const handleChange = (
        value: CompleoShared.Assessments.IAnswerToResults
    ) => {
        // Find the index of the item being updated
        const indexToUpdate = currentFieldValue.findIndex(
            (item) => item.result === value.result
        );

        // If the item to update is not found, just return
        if (indexToUpdate === -1) {
            return;
        }

        // Calculate the remaining percentage to distribute among the other items
        let remainingPercentage = 100 - value.resultValue;

        // Create a copy of the currentFieldValue array
        const newValue = [...currentFieldValue];

        // Update the value of the item being changed
        newValue[indexToUpdate].resultValue = value.resultValue;

        for (let i = 0; i < newValue.length; i++) {
            if (i !== indexToUpdate) {
                if (remainingPercentage <= 0) {
                    newValue[i].resultValue = 0;
                } else {
                    if (newValue[i].resultValue > remainingPercentage) {
                        newValue[i].resultValue = remainingPercentage;
                    }
                    remainingPercentage -= newValue[i].resultValue;
                }
            }
        }

        if (remainingPercentage > 0) {
            for (let i = 0; i < newValue.length; i++) {
                if (i > indexToUpdate) {
                    const maxPossibleValue = 100 - newValue[i].resultValue;
                    if (maxPossibleValue > 0) {
                        const valueToAdd = Math.min(
                            remainingPercentage,
                            maxPossibleValue
                        );
                        newValue[i].resultValue =
                            newValue[i].resultValue + valueToAdd;
                        remainingPercentage -= valueToAdd;
                    } else {
                        newValue[i].resultValue = 0;
                    }
                }
            }
        }
        setFieldValue(newValue);
    };

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            style={{ padding: 16 }}
        >
            <Typography variant="caption" style={{ marginBottom: 8 }}>
                <strong>{t("answers.answerToResults")}</strong>
            </Typography>
            <Grid container spacing={1}>
                {currentFieldValue.map(
                    (
                        possibleResult: CompleoShared.Assessments.IAnswerToResults,
                        index: number
                    ) => {
                        const { result, resultValue } = possibleResult;
                        return (
                            <Grid item xs={12} container key={result}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2">
                                        {result}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} sm={5}>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={resultValue}
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown;
                                            }>
                                        ) => {
                                            handleChange({
                                                result: result,
                                                resultValue: event.target
                                                    .value as number
                                            });
                                        }}
                                    >
                                        {options.map((option) => {
                                            return (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option} %
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <ButtonIconTooltipCompleo
                                        label="Definir como 100%"
                                        onClick={() =>
                                            handleChange({
                                                result: result,
                                                resultValue: 100
                                            })
                                        }
                                    >
                                        <CheckCircleIcon />
                                    </ButtonIconTooltipCompleo>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        );
                    }
                )}
            </Grid>
        </Box>
    );
};

export default AnswerToResultCustomField;
