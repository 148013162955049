import { Paper, Grid, Typography, Chip } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import useApplicantViewContext from "../useApplicantViewContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        chipCard: {
            marginRight: theme.spacing(0.5),
            marginLeft: 8,
            borderRadius: 6
        }
    })
);

interface IApplicantMatchJobDataProps {
    jobData: Compleo.IObject;
    tLocal: any;
    language: string;
}
const ApplicantMatchJobData = (props: IApplicantMatchJobDataProps) => {
    const { jobData, tLocal, language } = props;
    const classes = useStyles();
    const jobTitle = jobData?._updatedJobData?.title || jobData.JobTitle;
    const [data] = useApplicantViewContext();
    const { applicantData, jobDataInsideJob, t } = data;
    const jobMatch = applicantData?.JobMatch?.filter(
        (item: Compleo.IObject) => item.jobId === jobData.JobId
    )[0];
    const isCurrentJob = jobDataInsideJob?.jobId === jobData?.JobId;

    return (
        <Paper className={classes.paperContainer} key={jobData.JobId}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>
                        {jobTitle}
                        {isCurrentJob && (
                            <Chip
                                className={classes.chipCard}
                                style={{
                                    backgroundColor: "indigo",
                                    color: "white"
                                }}
                                size="small"
                                label={t("currentJobTag")}
                            />
                        )}
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" style={{ marginTop: 16 }}>
                    <strong>{tLocal("matchScore")}:</strong> {jobMatch?.score}%
                </Typography>
                <Typography variant="body1" style={{ marginTop: 16 }}>
                    <strong>{tLocal("reason")}:</strong>{" "}
                    {language === "pt-BR"
                        ? jobMatch?.scoreReasoningLocalLanguage
                        : jobMatch?.scoreReasoning}
                </Typography>
            </Grid>
        </Paper>
    );
};

export default ApplicantMatchJobData;
